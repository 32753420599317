.wrapper_check_box label {
  display: grid;
  grid-template-columns: 18px 1fr;
  grid-gap: 0 16px;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.015em;
  color: #000000;
  position: relative;
  cursor: pointer;
  width: max-content;
}

.wrapper_check_box_authenticity label {
  align-items: start;
}

.upload_work_wrapper .authenticity_certificate_wrapper .authenticity_certificate_content .wrapper_check_box_authenticity label {
  align-items: center;
}

.wrapper_check_box a {
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.015em;
  color: #000000;
  text-decoration: underline;
}

.wrapper_check_box input {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
}

.checkbox_un_active > div {
  word-break: break-word;
}

.text_error {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--new-error);
  margin: 0;
  margin-right: 4rem;
}

.wrapper_check_box .check_error {
  border: 1px solid var(--error);
}

.wrapper_check_box label > span {
  width: 18px;
  position: relative;
  height: 18px;
  border: 1px solid #333;
  box-sizing: border-box;
  margin-right: 18px;
  transition: all 0.4s;
}

.wrapper_check_box_authenticity label > span {
  margin: 4px 0 0;
}

.wrapper_check_box label > p {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.015em;
  color: #000000;
  white-space: break-spaces;
}

.wrapper_check_box_authenticity label > p {
  font-weight: 500;
  font-size: 12px;
}

.wrapper_check_box .checkbox_active > span {
  animation: bounce 250ms;
}

.wrapper_check_box .checkbox_active > span::before {
  content: url('../../../assets/img/tick-tock.svg');
  position: absolute;
  right: -6px;
  top: -5px;
}

@keyframes checked-box {
  0% {
    width: 0;
    height: 0;
    border-color: transparent;
    transform: translate(0, 0) rotate(45deg);
  }
  33% {
    width: 4px;
    height: 0;
    transform: translate(0, 0) rotate(45deg);
  }
  100% {
    width: 4px;
    height: 8px;
    border-color: #c69c6d;
    transform: translate(0px, -7px) rotate(45deg);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 568px) {
  .wrapper_check_box label {
    width: 100%;
  }
}
