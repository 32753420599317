.wrapper {
    padding: 1.5rem 0;
}

.wrapper .tabButton + .tabButton {
    border-left: 0
}

.tabs {
    margin-bottom: 3rem;
}

.wrapper .tabs .tabButton {
    font-size: 0.875rem;
    min-width: 8rem;
}

.historyTitle {
    margin-top: 3rem;
}

@media (max-width: 768px) {
    .wrapper .tabs .tabButton {
        min-width: 0;
        width: 33.3%;
    }
}