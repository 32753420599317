.wrapper {
    margin-top: 50px;
}

.wrapper .reset_title {
    font-weight: 600;
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 28px;
}

.wrapper .description {
    font-weight: normal;
    font-size: 18.58px;
    line-height: 30px;
    color: #000
}

.reset_form .wrapper_info,
.reset_form {
    margin-top: 50px;
}

.reset_form {
    display: grid;
    grid-gap: 24px;
}

.reset_form .wrapper_info {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    grid-gap: 20px;
    margin-top: 50px;
}

.reset_form .info {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18.58px;
    line-height: 28px;
    color: #17A300;
    display: flex;
    align-items: center;
}

.reset_form .info span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18.58px;
    line-height: 28px;
}

.reset_form .info .check_icon {
    height: 20px;
    width: 30px;
}

.reset_form button {
    max-width: 200px;
    justify-self: end;
}

@media (max-width: 500px) {
    .reset_form .wrapper_info {
        grid-template-columns: 1fr !important;
    }

    .reset_form .wrapper_info button {
        width: 100%;
        max-width: unset;
        justify-self: unset;
    }
}