.new_landing_fonts {
    font-family: 'Poppins', sans-serif !important;
}

/*h1, h2, h3, h4, h5, h6, p, span, div, label, input, li, button, textarea, text {*/
/*    font-family: inherit !important;*/
/*}*/

.home_page_wrapper {
    margin: 0 0 0;
}

.home_page_wrapper > .head_section {
    min-height: 85vh;
}

.home_page_wrapper > .head_section .head_bg_image {
    height: 85vh;
    background: url("../../assets/img/new_landing/header_slider.png") no-repeat center;
    background-size: cover;
    width: 100%;
    min-height: 550px;
}

.home_page_wrapper > .head_section .head_bg_image .head_bg_opacity {
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    opacity: 0.9;
    padding: 0 10%;
    position: relative;
    display: flex;
    align-items: center;
}

.home_page_wrapper > .head_section .head_bg_image .head_bg_opacity h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: 0.05em;
    margin: 0 0 2rem;
    color: #FFFFFF;
    max-width: 55%;
}

.home_page_wrapper > .head_section .head_bg_image .head_bg_opacity p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    margin: 0 0 3rem;
    max-width: 500px;
}

.home_page_wrapper > .head_section .head_bg_image .head_bg_opacity .artist_info {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: start;
}

.home_page_wrapper > .head_section .head_bg_image .head_bg_opacity .artist_info .artist_info_position {
    position: absolute;
    bottom: 4.5rem;
    right: 10%;
}

.home_page_wrapper > .head_section .slick-slide {
    z-index: -1;
}

.home_page_wrapper > .head_section .slick-current {
    z-index: 10;
}

.latest_artworks .slick-slide img {
    display: flex;
    object-fit: cover;
    max-width: 100%;
    width: 100%;
}

.latest_artworks .slick-track {
    display: flex;
    align-items: center;
}

.home_page_wrapper > .head_section .head_bg_image .head_bg_opacity .artist_info button {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    border: 2px solid #FFFFFF;
    padding: .75rem 2.5rem;
    transition: .3s all;
}

.home_page_wrapper > .head_section .head_bg_image .head_bg_opacity .artist_info button:hover {
    background: #FFFFFF;
    color: #000000;
}

.home_page_wrapper > .head_section .head_bg_image .head_bg_opacity .artist_info h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--beige);
    margin: 0 0 1.5rem;
}

.home_page_wrapper > .head_section .head_bg_image .head_bg_opacity .artist_info .about_artist {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 1.5rem;
    margin: 0;
}

.home_page_wrapper > .head_section .head_bg_image .head_bg_opacity .artist_info .about_artist .avatar {
    display: grid;
    align-items: center;
    max-width: 5rem;
    max-height: 5rem;
}

.home_page_wrapper > .head_section .head_bg_image .head_bg_opacity .artist_info .about_artist .avatar img {
    max-width: 100%;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    object-fit: cover;
}

.avatar_test {
    width: 1px;
    height: 1px;
}

.home_page_wrapper > .head_section .head_bg_image .head_bg_opacity .artist_info .about_artist h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 95.4%;
    color: var(--beige);
    margin: 0 0 1rem;
}

.home_page_wrapper > .head_section .head_bg_image .head_bg_opacity .artist_info .about_artist span {
    font-size: 14px;
    line-height: 21px;
    color: var(--beige);
    margin: 0 0 4px;
    display: block;
}

.home_page_wrapper > .head_section .head_bg_image .head_bg_opacity .artist_info .about_artist span:last-of-type {
    margin: 0;
}

.home_page_wrapper > .head_section .slick-dots {
    bottom: 2rem;
}

.home_page_wrapper > .head_section .slick-dots li button:before {
    font-size: 10px;
    content: '\2B22';
    opacity: .2;
    color: #FFFFFF;
}

.home_page_wrapper > .head_section .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #FFFFFF;
}

.home_page_wrapper > .role_choose_wrapper {
    margin: 6rem 0;
    padding: 0 10%;
}

.home_page_wrapper > .role_choose_wrapper .cards_container {
    padding: 0 36px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4.5rem;
    align-items: center;
}

.home_page_wrapper > .role_choose_wrapper .cards_container .card_item {
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    transition: .3s all;
}

.home_page_wrapper > .role_choose_wrapper .cards_container .card_item:hover {
    border: 2px solid #000000;
}

.home_page_wrapper > .role_choose_wrapper .cards_container .content {
    padding: 0 3.5rem 3rem 3rem;
}

.home_page_wrapper > .role_choose_wrapper .cards_container img {
    display: block;
    max-width: 100%;
}

.home_page_wrapper > .role_choose_wrapper .cards_container h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: #000000;
    margin: 0 0 1.5rem;
}

.home_page_wrapper > .role_choose_wrapper .cards_container p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
    margin: 0 0 2rem;
}

.home_page_wrapper > .role_choose_wrapper .cards_container .options_wrapper {
    margin: 0 0 2rem;
}

.home_page_wrapper > .role_choose_wrapper .cards_container .options, .home_page_wrapper > .role_choose_wrapper .cards_container .checkmark {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 20px;
    align-items: center;
    margin: 0 0 4px;
}

.home_page_wrapper > .role_choose_wrapper .cards_container .options div {
    background: #000000;
    width: 4px;
    height: 4px;
}

.home_page_wrapper > .role_choose_wrapper .cards_container .options span {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
}

.home_page_wrapper > .role_choose_wrapper .cards_container .checkmark {
    grid-gap: 1.5rem;
    margin: 0;
    cursor: pointer;
}

.home_page_wrapper > .role_choose_wrapper .cards_container .checkmark span {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
}

.home_page_wrapper > .role_choose_wrapper .cards_container .card_item svg rect {
    fill: #fff;
    transition: .3s all;
}

.home_page_wrapper > .role_choose_wrapper .cards_container .card_item:hover svg rect {
    fill: #000;
}

.home_page_wrapper > .role_choose_wrapper .cards_container .card_item svg path {
    stroke: #000;
    transition: .3s all;
}

.home_page_wrapper > .role_choose_wrapper .cards_container .card_item:hover svg path {
    stroke: #fff;
}

.latest_artworks {
    background: var(--beige);
    padding: 4rem 10%;
    margin: 0 0 4.5rem;
}

.latest_artworks > .title {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    margin: 0 0 3.5rem;
}

.latest_artworks > .title h2, .home_video_section h2, .home_works_wrapper h2, .home_artists_wrapper h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    color: #000000;
    margin: 0;
}

.latest_artworks .view_more_link {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 45px;
    color: #000000;
}

.latest_artworks .view_more_link:hover {
    text-decoration-line: underline;
}

.latest_artworks > .content {
    display: flex;
    flex-wrap: wrap;
    margin: -12px;
    align-items: center;
}

.latest_artworks > .content a {
    height: 240px;
    margin: 12px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: calc(67% - 24px);
}

.latest_artworks > .content a:nth-child(1), .latest_artworks > .content a:nth-child(4), .latest_artworks > .content a:nth-child(5), .latest_artworks > .content a:nth-child(8) {
    width: calc(33% - 24px);
}

.latest_artworks > .content a .artist_content {
    height: 100%;
    width: 100%;
    position: relative;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 90%);
    padding: 0 1.5rem 1.5rem 1.5rem;
    display: flex;
    align-items: flex-end;
    opacity: 0;
    transition: .3s all;
    overflow: hidden;
}

.latest_artworks > .content a:hover .artist_content {
    opacity: 1;
}

.latest_artworks > .content a .artist_content > .artist_info {
    height: max-content;
    display: grid;
    grid-template-columns: 1fr max-content;
    justify-content: space-between;
    align-items: center;
    grid-gap: 8px;
    width: 100%;
    transition: .4s all;
    transform: translateY(10px);
}

.latest_artworks > .content a:hover .artist_content > .artist_info {
    transform: translateY(0);
}

.latest_artworks > .content a .artist_content > .artist_info .favorite path {
    fill: transparent;
    transition: .3s all;
}

.latest_artworks > .content a .artist_content > .artist_info .favorite_active path {
    fill: #FFFFFF;
}

.latest_artworks > .content a .artist_content > .artist_info .avatar {
    display: grid;
    grid-template-columns:max-content 1fr;
    grid-gap: 1rem;
    align-items: center;
}

.latest_artworks > .content a .artist_content > .artist_info .avatar div {
    display: grid;
    align-items: center;
}

.latest_artworks > .content a .artist_content > .artist_info .avatar img {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100%;
    object-fit: cover;
}

.latest_artworks > .content a .artist_content > .artist_info .avatar h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 95.4%;
    color: var(--beige);
    margin: 0 0 .5rem;
}

.latest_artworks > .content a .artist_content > .artist_info .avatar p {
    font-style: italic;
    font-size: 12px;
    line-height: 18px;
    color: var(--beige);
}

.banner_section .banner_image_wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;
    height: 700px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding: 74px 10%;
}

.banner_section .banner_image_wrapper .banner_left_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.banner_section .banner_image_wrapper .banner_left_wrapper p {
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    display: flex;
    word-break: break-word;
}

.banner_section .banner_image_wrapper .banner_left_wrapper .banner_label_text {
    font-size: 14px;
    font-weight: 300;
}

.banner_section .banner_image_wrapper .banner_left_wrapper .banner_heading_text {
    font-size: 48px;
    font-weight: 600;
}

.banner_section .banner_image_wrapper .banner_left_wrapper .banner_body_text {
    font-size: 16px;
    font-weight: 400;
}

.banner_section .banner_image_wrapper .banner_right_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.banner_section .banner_image_wrapper .banner_right_wrapper button {
    display: flex;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    padding: 0 38px;
    color: #FFFFFF;
    border: 2px solid #FFFFFF;
    transition: .3s all;
    height: 42px;
    width: max-content;
}

.home_video_section.seen-on-wrapper .slick-slide {
    margin: 0 16px;
}

.home_video_section.seen-on-wrapper .slick-slide img {
    max-height: 67px;
}

.home_video_section.seen-on-wrapper .slick-track {
    height: 67px;
    overflow: hidden;
}

.home_video_section {
    margin: 6.5rem 0;
    padding: 0 10%;
}

.home_video_section h2 {
    margin: 0 0 3rem;
}

.home_video_section .video_wrapper {
    display: grid;
    grid-gap: 6rem;
    align-items: center;
    grid-template-columns: 5fr 7fr;
}

.home_video_section .video_wrapper h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    margin: 0 0 1.5rem;
    text-transform: uppercase;
    color: #000000;
}

.home_video_section .video_wrapper p {
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.015em;
    color: #000000;
}

.home_video_preview .play_button {
    justify-self: center;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 4rem;
    height: 4rem;
    border: 1px solid #F6F6F1;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    display: grid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    align-self: center;
    inset: 50%;
    transform: translate(-50%, -50%);
}

video {
    width: 100%;
}

.home_video_preview .play_button svg, .home_video_preview .play_button path {
    transition: .2s all;
}

.home_video_preview:hover .play_button path {
    fill: #FFFFFF;
}

.home_works_wrapper {
    margin: 6.5rem 0;
    padding: 0 10%;
}

.home_works_wrapper h2 {
    margin: 0 0 3.5rem;
}

.home_works_wrapper .works_container {
    display: grid;
    grid-template-columns: 15% 75%;
    align-items: start;
    justify-content: space-between;
}

.home_works_wrapper .works_container .tabs_content {
    height: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
}

.home_works_wrapper .works_container .tabs_content span {
    display: block;
    font-size: 12px;
    line-height: 45px;
    color: #2B2B2E;
    margin: 0 0 3.5rem;
}

.home_works_wrapper .works_container .tabs_content .sort_by_buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
}

.home_works_wrapper .works_container .tabs_content .sort_by_buttons h5 {
    cursor: pointer;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.33px;
    transition: .3s all;
    width: max-content;
}

.home_works_wrapper .works_container .tabs_content .sort_by_buttons h5:hover {
    text-decoration: underline;
}

.home_works_wrapper .view_more_link {
    font-weight: 500;
    font-size: 20px;
    line-height: 45px;
    color: #000000;
    display: block;
    width: max-content;
    margin: auto 0 1rem;
}

.home_works_wrapper .view_more_link:hover {
    text-decoration-line: underline;
}

.home_works_wrapper .works_container .works_slider_container, .home_artists_wrapper .artists_slider_container {
    margin: 0 -12px;
}

.home_works_wrapper .works_container .works_slider_container .slick-slide, .home_artists_wrapper .artists_slider_container .slick-slide {
    padding: 0 12px;
}

.slider_arrows_container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: max-content;
    height: 32px;
}

.slider_arrow_left {
    left: -3rem;
}

.slider_arrow_right {
    right: -3rem;
}

.home_artists_wrapper {
    padding: 0 10%;
    margin: 0 0 8.5rem;
}

.home_artists_wrapper h2 {
    margin: 0 0 3.5rem;
}

.home_artists_wrapper > .artists_navigation {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 2rem;
}

.home_artists_wrapper > .artists_navigation > .tabs {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    align-items: center;
    grid-gap: 3.5rem;
}

.home_artists_wrapper > .artists_navigation > .tabs h5 {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.33px;
    cursor: pointer;
    transition: .3s all;
    width: max-content;
}

.home_artists_wrapper > .artists_navigation > .tabs h5:hover {
    text-decoration: underline;
}

.home_artists_wrapper .view_more_link {
    font-weight: 500;
    font-size: 20px;
    line-height: 45px;
    color: #000000;
    display: grid;
    align-items: center;
}

.home_artists_wrapper .view_more_link:hover {
    text-decoration-line: underline;
}

.popular_image_title_container {
    margin: 24px 0 48px 0;
    display: flex;
    flex-direction: column;
}

/*.slick-slide img {*/
/*    display: block;*/
/*    max-width: 262px;*/
/*}*/

.public-artwork-slider {
    padding: 0 10px 0 10px;
}

/*.slick-slide*/
/*{*/
/*    max-width: 262px;*/
/*    margin-left: 20px;*/
/*}*/

.popular_image_title_container span {
    color: #000000;
}

.popular_image_title_container span:first-child {
    font-size: 18px;
    font-weight: 600;
    display: block;
    line-height: 25px;
    margin: 0 0 8px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.popular_image_title_container span:nth-child(2) {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    line-height: 24px;
    margin: 0 0 13px 0;
}

.popular_image_title_container span:last-child {
    font-size: 20px;
    font-weight: 400;
}

.popular-section-container {
    display: grid;
    grid-template-columns: 1fr 16px;
    grid-gap: 10px;
}

.ver-icon-center {
    margin-top: 5px;
}

.experiences_wrapper {
    margin: 0;
    padding: 3.5rem 10%;
    background-color: var(--beige);
}

.experiences_wrapper .slick-track .slick-slider .slick-slide {
    padding: 0;
    height: auto;
}

.experiences_wrapper .slick-slide > div,
.experiences_wrapper .slick-slide > div > div {
    height: 100%;
}

.experiences_wrapper .slick-track {
    display: flex;
}

.experiences_wrapper .slick-track .slick-slide {
    height: auto;
}

.home_section_header {
    display: grid;
    grid-template-columns: 43% 55%;
    justify-content: space-between;
}

.home_section_description {
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.8;
}

@media (max-width: 1200px) {
    .home_page_wrapper > .head_section .head_bg_image .head_bg_opacity, .home_page_wrapper > .role_choose_wrapper, .home_video_section, .home_works_wrapper, .home_artists_wrapper {
        padding: 0 7.5%;
    }

    .home_page_wrapper > .head_section .head_bg_image .head_bg_opacity .artist_info .artist_info_position {
        right: 7.5%;
    }

    .home_page_wrapper > .role_choose_wrapper .cards_container .content {
        padding: 0 2rem 2rem 2rem;
    }

    .latest_artworks {
        padding: 4rem 7.5%;
    }

    .home_video_section .video_wrapper {
        grid-gap: 3rem;
    }

    .home_video_section .video_wrapper h3 {
        font-size: 28px;
        line-height: 44px;
    }

    .home_works_wrapper .works_container {
        grid-template-columns: 20% 75%;
    }

    .home_page_wrapper > .head_section .head_bg_image .head_bg_opacity h2 {
        max-width: 70%;
    }

    .home_page_wrapper > .role_choose_wrapper .cards_container {
        padding: 0;
        grid-gap: 2.5rem;
    }

    .home_artists_wrapper {
        margin: 0 0 5rem;
    }
}

@media (max-width: 1100px) {
    .home_page_wrapper > .role_choose_wrapper .cards_container .content {
        padding: 0 1.5rem 1.5rem 1.5rem;
    }

    .home_video_preview {
        height: 260px;
    }
}

@media (max-width: 1160px) {
    .home_page_wrapper {
        margin: 0 0 0;
    }
}

@media (max-width: 1025px) {
    .home_page_wrapper > .head_section {
        min-height: 60vh;
    }

    .home_page_wrapper > .head_section .head_bg_image {
        height: 60vh;
    }
}

@media (max-width: 1024px) {
    .latest_artworks > .content a .artist_content {
        opacity: 1;
    }

    .latest_artworks > .content a .artist_content > .artist_info {
        transform: translateY(0);
    }
}

@media (max-width: 992px) {
    .home_page_wrapper > .head_section .head_bg_image .head_bg_opacity, .home_page_wrapper > .role_choose_wrapper, .home_video_section, .home_works_wrapper, .home_artists_wrapper {
        padding: 0 5%;
    }

    .home_page_wrapper > .head_section .head_bg_image .head_bg_opacity .artist_info .artist_info_position {
        right: 5%;
    }

    .home_page_wrapper > .head_section .head_bg_image .head_bg_opacity .artist_info h3 {
        margin: 0 0 1rem;
    }

    .latest_artworks {
        padding: 4rem 5%;
    }

    .latest_artworks > .content a .artist_content {
        padding: 0 1rem 1rem 1rem;
    }

    .home_video_section .video_wrapper {
        grid-gap: 3rem;
        grid-template-columns: 1fr;
    }

    .home_video_preview {
        height: 346px;
        width: 100%;
    }

    .home_video_section .video_wrapper h3 {
        font-size: 28px;
        line-height: 44px;
    }

    .home_works_wrapper .works_container {
        grid-template-columns: 100%;
    }

    .home_page_wrapper > .head_section .head_bg_image .head_bg_opacity h2 {
        font-size: 36px;
        line-height: 52px;
        margin: 0 0 1.5rem;
        max-width: 80%;
    }

    .home_page_wrapper > .head_section .head_bg_image .head_bg_opacity p {
        max-width: 70%;
    }

    .home_page_wrapper > .role_choose_wrapper .cards_container {
        padding: 0;
        grid-gap: 2.5rem;
    }

    .slider_arrow_left {
        left: -1.5rem;
    }

    .slider_arrow_right {
        right: -1.5rem;
    }

    .latest_artworks > .content a {
        height: 221px;
    }

    .home_page_wrapper > .head_section {
        min-height: 70vh;
    }

    .home_page_wrapper > .head_section .head_bg_image {
        height: 70vh;
    }

    .home_works_wrapper h2 {
        margin: 0 0 .75rem;
    }

    .home_works_wrapper .works_container .tabs_content .sort_by_buttons {
        grid-gap: 20px;
        margin: 0 0 5rem;
    }

    .home_works_wrapper .view_more_link {
        margin: 2rem 0 0;
    }

    .home_works_wrapper .works_container .tabs_content span {
        margin: 0 0 2rem;
    }

    .slider_arrows_container {
        position: absolute;
        top: -12.5%;
        transform: translateY(12.5%);
    }

    .slider_arrow_left {
        left: 1rem;
    }

    .slider_arrow_right {
        right: 1rem;
    }

    .home_artists_wrapper > .artists_navigation {
        grid-template-columns: 1fr;
        margin: 0 0 5rem;
    }

    .home_artists_wrapper > .artists_navigation > .tabs {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }

    .home_artists_wrapper > .view_more_link {
        margin: 3rem 0 0;
    }

    .home_video_section {
        padding: 0;
    }

    .home_video_section h2, .home_video_section .video_wrapper > .video_text_content {
        padding: 0 5%;
    }

    .latest_artworks > .title h2, .home_video_section h2, .home_works_wrapper h2, .home_artists_wrapper h2 {
        font-size: 36px;
        line-height: 44px;
    }
    .banner_section .banner_image_wrapper {
        grid-template-columns: 1fr;
    }
    .banner_section .banner_image_wrapper .banner_left_wrapper {
        display: block;
    }
    .banner_section .banner_image_wrapper .banner_right_wrapper {
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 900px) {
    .home_page_wrapper > .role_choose_wrapper .cards_container {
        grid-template-columns: 1fr;
        grid-gap: 4.5rem;
    }

    .home_page_wrapper > .role_choose_wrapper .cards_container .card_item:hover, .home_page_wrapper > .role_choose_wrapper .cards_container .card_item {
        border: unset;
    }
}

@media (max-width: 650px) {


    .latest_artworks > .content a {
        margin: 4px 0;
        width: 100%;
    }

    .latest_artworks > .content {
        margin: 0;
    }

    .latest_artworks > .content a:nth-child(1), .latest_artworks > .content a:nth-child(4), .latest_artworks > .content a:nth-child(5), .latest_artworks > .content a:nth-child(8) {
        width: 100%;
    }

    .latest_artworks .view_more_link {
        margin: 1.5rem 0 0;
        display: block;
        width: max-content;
    }
}

@media (max-width: 568px) {
    .home_video_section.seen-on-wrapper .slick-track {
        margin: 0 -32px;
    }
    .home_video_section.seen-on-wrapper .slick-slide {
        margin: 0 32px;
    }
    .home_page_wrapper > .head_section .head_bg_image .head_bg_opacity, .home_page_wrapper > .role_choose_wrapper, .home_video_section, .home_works_wrapper, .home_artists_wrapper {
        padding: 0 1rem;
    }

    .latest_artworks > .title h2, .home_video_section h2, .home_works_wrapper h2, .home_artists_wrapper h2 {
        font-size: 24px;
        line-height: 32px;
    }

    .home_page_wrapper > .head_section .head_bg_image .head_bg_opacity h2 {
        font-size: 24px;
        line-height: 30px;
        margin: 0 0 12px;
    }

    .home_page_wrapper > .head_section .head_bg_image .head_bg_opacity p {
        font-size: 14px;
        margin: 0 0 1rem;
        max-width: 100%;
    }

    .home_page_wrapper > .head_section .slick-dots {
        text-align: left;
        padding-left: 6px;
    }

    .latest_artworks {
        padding: 2.5rem 1rem 1.5rem;
    }

    .latest_artworks > .title {
        grid-template-columns: 1fr;
        margin: 0 0 1rem;
    }

    .home_video_section {
        padding: 0;
    }

    .home_video_preview {
        height: 210px;
        margin: 0;
    }

    .home_page_wrapper > .role_choose_wrapper {
        margin: 2.5rem 0 4rem;
        padding: 0;
    }

    .home_page_wrapper > .role_choose_wrapper .cards_container .card_item img {
        width: 100%;
    }

    .home_page_wrapper > .role_choose_wrapper .cards_container .content {
        padding: 0 1rem;
    }

    .home_video_section h2 {
        margin: 0 0 1.5rem;
    }

    .home_video_section .video_wrapper h3 {
        font-size: 18px;
        line-height: 32px;
        margin: 0 0 1rem;
    }

    .home_video_section .video_wrapper {
        grid-gap: 1rem;
    }

    .home_video_section {
        margin: 0;
    }

    .home_works_wrapper {
        margin: 3.5rem 0;
    }

    .home_works_wrapper .works_container .tabs_content .sort_by_buttons {
        grid-gap: 2rem;
        margin: 0 0 3.5rem;
    }

    .slider_arrows_container {
        display: none !important;
    }

    .home_works_wrapper .works_container .works_slider_container, .home_artists_wrapper .artists_slider_container {
        margin: 0 -16px;
    }

    .home_artists_wrapper h2 {
        margin: 0 0 1rem;
    }

    .home_artists_wrapper > .artists_navigation > .tabs {
        grid-gap: 1.5rem;
    }

    .home_artists_wrapper > .artists_navigation {
        margin: 0 0 3.5rem;
    }

    .home_artists_wrapper {
        margin: 0 0 2.5rem;
    }

    .latest_artworks_overflow {
        overflow: hidden;
    }

    .experiences_wrapper {
        background-color: transparent;
    }

    .experiences_wrapper .home_section_header {
        grid-template-columns: 1fr;
        grid-gap: 0.75rem;
        margin-bottom: 3.5rem;
    }

    .home_works_wrapper .works_container .tabs_content {
        min-height: 0;
    }

    .home_works_wrapper .view_more_link {
        margin-top: 3.5rem;
    }
    .banner_section .banner_image_wrapper .banner_right_wrapper button {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .home_page_wrapper > .head_section .head_bg_image .head_bg_opacity h2 {
        max-width: 90%
    }
}

@media (max-width: 370px) {
    .home_page_wrapper > .head_section .head_bg_image {
        min-height: 400px;
    }
}

@media (max-width: 325px) {
    .home_video_preview {
        height: 177px;
        margin: 0;
    }

    .home_page_wrapper > .head_section {
        min-height: 80vh;
    }

    .home_page_wrapper > .head_section .head_bg_image {
        height: 80vh;
    }

    .home_page_wrapper > .head_section .head_bg_image .head_bg_opacity h1 {
        max-width: 100%;
    }
}
