.range_slider {
    position: relative;
    width: 100%;
}

.range_slider .input-range__slider {
    background: #000000;
    border: 1px solid #000000;
}

.range_slider .input-range__track--active {
    background: #000000;
}

.range_slider .input-range__label {
    display: none;
}

.range_slider_new {
    padding: 0.875rem 0 0.5rem;
}

.range_slider_new .input-range__slider {
    background: #000000;
    border: 1px solid #000000;
    border-radius: 0;
    height: 1.5rem;
    margin-left: -2px;
    margin-top: -14px;
    width: 4px;
}

/*.range_slider_new .input-range--disabled .input-range__slider {*/
/*    background: rgba(0, 0, 0, .5);*/
/*    border: 1px solid rgba(0, 0, 0, .5);*/
/*    box-shadow: none;*/
/*    transform: none;*/
/*}*/

/*.range_slider_new .input-range--disabled .input-range__track {*/
/*    background: rgba(0, 0, 0, .5);*/
/*    cursor: not-allowed;*/
/*}*/

.range_slider_new .input-range__label {
    display: none;
}

.range_slider_new .input-range__slider:active {
    transform: scale(1.2);
}

.range_slider_new .input-range__track {
    background: rgba(0, 0, 0, .2);
    height: 2px;
}

.range_slider_new .input-range__track--active {
    background: #000000;
}

.range_slider_new .input-range {
    height: auto;
}

.range_slider_new_error .input-range__track {
    background: rgba(255, 73, 73, 0.5);
}

.range_slider_new_error .input-range__slider {
    background: #FF4949;
    border: 1px solid #FF4949;
}
