.background_image {
    height: 85vh;
    background-size: cover;
    width: 100%;
    min-height: 550px;
    z-index: 1;
    background-position: center;
}
.main_container {
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    padding: 0 10%;
    position: relative;
    display: grid;
    grid-template-rows: 3fr 2fr;
    justify-content: center;
    text-align: center;
    align-items: flex-end;
    z-index: 10;
}

.first_main_section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top_main_title {
    font-size: 48px;
    font-weight: 600;
    color: #FFFFFF;
}

.top_about {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
}

.contact_button {
    border: 2px solid #FFFFFF;
    width: 148px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    cursor: pointer;
    transition: .4s;
}

.contact_button:hover {
    background-color: #FFFFFF;
}

.contact_button_content:hover {
    color: black;
}

.contact_button_content {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 12px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .4s;
}


.bottom_top_section_text_container {
    margin-bottom: 80px;
}

.bottom_top_section_text {
    font-size: 16px;
    font-weight: 300;
    color: #FFFFFF;
    cursor: pointer;
}

.bottom_top_section_text_to {
    text-decoration: underline
}

@media (max-width: 747px) {
    .top_main_title {
        font-size: 30px;
    }
    .top_about {
        font-weight: 300;
    }
    .main_container {
        grid-template-rows: 4fr 2fr;
    }
}
