.block_title {
    font-size: 32px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 32px;
}

.block_container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
}

.block_item {
    height: 230px;
    border: 1px solid #000000;
    padding: 35px 33px 10px 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.block_item_text {
    margin-top: 20px;
}

@media (max-width: 1100px) {
    .block_container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 680px) {
    .block_container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 450px) {
    .block_container {
        grid-template-columns: repeat(1, 1fr);
    }
}
