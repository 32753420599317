.infoItem {
    padding: 1.5rem 0;
}

.infoItem:not(:last-child) {
    border-bottom: 2px solid #000000;
}

.infoItemGrid {
    display: grid;
    align-items: center;
    grid-template-columns: 260px 1fr;
    grid-gap: 2rem;
    padding-bottom: 1.5rem;
}

.basicInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
}

.imageWrapper {
    position: relative;
    overflow: hidden;
}

.imageWrapper::before {
    content: "";
    display: block;
    padding-bottom: 62.5%;
}

.imageWrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text {
    font-weight: 300;
    font-size: 0.875rem;
}

.priceText {
    font-weight: 300;
    font-size: 1rem;
}

.title {
    font-size: 1.25rem;
    font-weight: 600;
}

.block {
    width: 100%;
}

.smallText {
    font-size: 0.875rem;
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 0.5rem;
    grid-column-gap: 1.5rem;
    font-size: 0.875rem;
    font-weight: 600;
}

.mapWrapper {
    position: relative;
    width: 100%;
    height: 300px;
    margin-top: 1rem;
}

.gridRow {
    display: grid;
    grid-gap: 0.625rem;
    grid-template-columns: 1fr 11rem;
    align-items: center;
}

.gridRowInner {
    display: grid;
    font-weight: 300;
    font-size: 0.875rem;
    grid-gap: 1.5rem;
    line-height: 1.75;
    grid-template-columns: 140px 1fr;
}

.preWrap {
    white-space: pre-wrap;
}

@media (max-width: 1440px) {
    .gridRowInner {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }
}

@media (max-width: 1200px) {
    .gridRow {
        grid-template-columns: 1fr;
        justify-content: flex-start;
    }
}

@media (max-width: 768px) {
    .infoItemGrid {
        position: relative;
        grid-template-columns: 105px 1fr;
        padding-bottom: 60px;
    }

    .link {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}