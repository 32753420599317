.subscription_plan_wrapper {
    padding: 3rem 10% 6.5rem;
    background: var(--beige);
}

.subscription_plan_navigation {
    font-size: 13px;
    line-height: 1.46;
    color: var(--dark-grey);
    display: block;
    margin: 0 0 13px;
}

.subscription_plan_wrapper_content h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 64px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    margin: 0 0 2.5rem;
    text-align: center;
}

.subscription_plan_bill {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    justify-content: center;
    grid-gap: 15px;
    margin: 0 0 22px;
}

.subscription_plan_bill span {
    font-size: 14px;
    line-height: 1.43;
    color: var(--dark-grey);
    white-space: pre;
    width: max-content;
}

.switch_label {
    width: 49px;
    height: 25px;
    padding: 0;
}

.switch_button {
    top: 4px;
    left: 3px;
    width: 1rem;
    height: 1rem;
}

.subscription_plan_cards_wrapper {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    width: max-content;
    margin: 0 auto;
}

.subscription_plan_cards_wrapper .subscription_plan_card {
    background-color: transparent;
    padding: 4.75rem 2rem 2rem;
    height: 100%;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    border: 1px solid #000000;
    transition: .5s all;
    outline: 1px solid transparent;
}

.subscription_plan_cards_wrapper .subscription_plan_card:hover {
    outline: 1px solid #000000;
}

.subscription_plan_card_description {
    font-size: 13px;
    line-height: 1.07;
    color: var(--grey);
    display: block;
    margin: 2px 0 18px;
}

.subscription_plan_card h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #000000;
    margin: 0 0 4px;
}

.subscription_plan_card p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #000000;
    margin: 0 0 2rem;
}

.subscription_plan_card_text_content {
    margin: 0 0 4rem;
}

.subscription_plan_card_text_content p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
    text-align: left;
    margin: 0;
}

.subscription_plan_card_price {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: center;
    margin: 0 0 2.5rem;
}

.subscription_plan_card_price h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
    margin: 0;
}

.subscription_plan_card_price small {
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
}

.subscription_plan_card_text_content_item {
    display: grid;
    grid-gap: 12px;
    align-items: start;
    grid-template-columns: max-content 1fr;
    margin: 0 0 8px;
}

.subscription_plan_card_text_content_item svg {
    margin: 8px 0 0;
}

.subscription_plan_card_text_content_item:last-of-type {
    margin: 0;
    max-width: 300px;
}

.plan_block_selector {
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: center;
    grid-gap: 24px;
    margin-bottom: 26px;
}

.plan_block_selector__active {
    font-size: 14px;
    font-weight: 500;
    text-underline: black;
    cursor: pointer;
    border-bottom: 2px solid black;
}

.plan_block_selector__not_active {
    font-size: 14px;
    color: #000000;
    font-weight: 300;
    cursor: pointer;
    padding-bottom: 2px;
}

@media (max-width: 1200px) {
    .subscription_plan_wrapper {
        padding: 3rem 7.5% 6.5rem;
    }

    .subscription_plan_cards_wrapper {
        padding: 0;
    }
}

@media (max-width: 992px) {
    .subscription_plan_wrapper {
        margin: 74px 0 0;
        padding: 3rem 5% 6.5rem;
    }

    .subscription_plan_cards_wrapper {
        grid-gap: 20px;
        grid-template-columns: max-content;
        justify-content: center;
    }

    .subscription_plan_cards_wrapper .subscription_plan_card {
        padding: 1rem 3rem 2rem;
    }
}

@media (max-width: 568px) {
    .subscription_plan_wrapper {
        padding: 2rem 1rem 4rem;
    }

    .subscription_plan_cards_wrapper {
        grid-template-columns: 1fr;
    }

    .subscription_plan_cards_wrapper .subscription_plan_card {
        padding: 1rem 20px 2rem;
    }
}

@media (max-width: 375px) {
    .subscription_plan_cards_wrapper {
        width: 100%;
    }
}
