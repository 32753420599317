.tool_tip_upload_photo_error, .tool_tip_error {
    border: 1px solid black;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    background: #28293D;
    border-radius: 4px;
    padding: 5px 8px;
    position: absolute;
    left: -2rem;
    margin-top: 1rem;
    z-index: 10;
    color: #fff;
    width: max-content;
}

.tool_tip_upload_photo_error span:before, .tool_tip_error span:before {
    content: '';
    width: 10px;
    height: 10px;
    background: #28293D;
    position: absolute;
    left: 50%;
    top: -3px;
    transform: rotate(42deg) translateX(-50%);
}

.tool_tip_error {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    margin-top: 0;
    top: 2rem;
}

.tool_tip_upload_photo_error {
    top: 80%;
    transform: translate(-50%, -80%);
}

.tool_tip_error span:before {
    content: '';
    width: 10px;
    height: 10px;
    background: #28293D;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(42deg);
    top: -6px;
}

.tooltip_wrapper {
    position: relative;
    width: max-content;
}

.tooltip_wrapper:hover .tooltip {
    display: block;
}

.tooltip_wrapper .tooltip_img {
    display: grid;
    align-items: center;
    padding: 8px;
}

.tooltip_wrapper .tooltip_img:hover {
    display: grid;
    align-items: center;
    padding: 8px;
}

.tooltip_wrapper .tooltip_img:hover + .tooltip {
    display: block;
}

.tooltip_wrapper .tooltip {
    display: none;
    position: absolute;
    top: -44px;
    padding: 20px;
    right: -172px;
    background: #000;
    max-width: 194px;
    width: max-content;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.015em;
    text-decoration-line: underline;
    color: #F7F6F1
}

@media (max-width: 568px) {
    .tool_tip_upload_photo_error, .tool_tip_error {
        left: 0;
    }
}

@media (max-width: 500px) {
    .tooltip_wrapper .tooltip {
        right: unset;
        left: 20px;
        top: -56px;
    }
}

@media (max-width: 500px) {
    .tooltip_wrapper .tooltip {
        left: -60px;
        top: -60px;
    }
}
