.content_wrapper {
    padding: 0 140px;
}

.list_block_section_wrapper {
    padding: 104px 0 56px 0;
}

.video_preview_landing {
    margin-bottom: 50px;
}

.home_video_preview .play_button {
    justify-self: center;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 4rem;
    height: 4rem;
    border: 1px solid #F6F6F1;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    display: grid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    align-self: center;
    inset: 50%;
    transform: translate(-50%, -50%);
}

.home_video_preview:hover .play_button path {
    fill: #FFFFFF;
}

.frame_img {
    width: 100%;
    margin-bottom: 50px;
}

@media (max-width: 1100px) {
    .content_wrapper {
        padding: 0 20px;
    }
}
