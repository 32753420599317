.main_profile_wrapper {
    margin: 3rem 0 0;
}

.main_profile_wrapper .artist_profile_buttons, .main_profile_wrapper .artist_profile_buttons .classic_digital_buttons {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    margin: 0 0 3rem;
}

.main_profile_wrapper .artist_profile_buttons, .main_profile_wrapper .artist_profile_buttons .classic_digital_buttons h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
}

.main_profile_wrapper .artist_profile_buttons .classic_digital_buttons {
    justify-content: space-between;
    margin: 0;
}

.main_profile_wrapper .upload_works_wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    grid-gap: 1.5rem;
    margin: 0 0 4.5rem;
}

.main_profile_wrapper .upload_works_wrapper .works_card {
    border: 2px solid rgba(0, 0, 0, 0.16);
    transition: .3s;
    display: flex;
    flex-direction: column;
    position: relative;
}

.main_profile_wrapper .upload_works_wrapper .works_card > .artwork_hidden, .main_profile_wrapper .upload_works_wrapper .works_card > .artwork_sold {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #000000;
    text-align: center;
    padding: 8px 0;
    display: grid;
    align-items: center;
    justify-content: center;
    outline: 2px solid #000000;
}

.main_profile_wrapper .upload_works_wrapper .works_card > .artwork_sold {
    background: var(--beige);
    outline: 2px solid var(--beige);
}

.main_profile_wrapper .upload_works_wrapper .works_card > .artwork_hidden span, .main_profile_wrapper .upload_works_wrapper .works_card > .artwork_sold span {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
}

.main_profile_wrapper .upload_works_wrapper .works_card > .artwork_sold span {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    text-transform: uppercase;
}

.main_profile_wrapper .upload_works_wrapper .works_card:hover {
    border: 2px solid rgba(0, 0, 0, 1);
}

.main_profile_wrapper .upload_works_wrapper .works_card img {
    height: 192px;
    width: 100%;
    object-fit: cover;
}

.main_profile_wrapper .upload_works_wrapper .works_card .card_content {
    background: #FFFFFF;
    transition: .3s;
    padding: 1rem 1.5rem;
}

.main_profile_wrapper .upload_works_wrapper .works_card:hover .card_content {
    background: #000000;
}

.main_profile_wrapper .upload_works_wrapper .works_card h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 45px;
    color: #000000;
    transition: .3s;
}

.main_profile_wrapper .upload_works_wrapper .works_card:hover h4 {
    color: #FFFFFF;
}

.main_profile_wrapper .upload_works_wrapper .works_card p {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    transition: .3s;
}

.main_profile_wrapper .upload_works_wrapper .works_card:hover p {
    color: #FFFFFF;
}

.main_profile_wrapper .upload_works_wrapper .works_card .edit_delete_wrapper {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
    justify-content: space-between;
}

.main_profile_wrapper .upload_works_wrapper .works_card .edit_delete_wrapper .popup_wrapper {
    position: relative;
}

.main_profile_wrapper .upload_works_wrapper .works_card .edit_delete_wrapper .svg_wrap {
    padding: 10px;
    display: grid;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.main_profile_wrapper .upload_works_wrapper .works_card .edit_delete_wrapper .svg_wrap rect {
    transition: .3s;
}

.main_profile_wrapper .upload_works_wrapper .works_card:hover .edit_delete_wrapper .svg_wrap rect {
    fill: #FFFFFF;
}

.main_profile_wrapper .upload_works_wrapper .works_card .edit_delete_wrapper .popup {
    background: var(--beige);
    padding: 10px 1rem;
    position: absolute;
    top: 22px;
    right: 2rem;
    z-index: 10;
}

.main_profile_wrapper .upload_works_wrapper .works_card .edit_delete_wrapper .popup span {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
    padding: 10px 4px;
    display: block;
    border-bottom: 1px solid #000000;
    width: 6rem;
    cursor: pointer;
}

.main_profile_wrapper .upload_works_wrapper .works_card .edit_delete_wrapper .popup span:last-of-type {
    border-bottom: none;
}

@media (max-width: 1500px) {
    .main_profile_wrapper .upload_works_wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 992px) {
    .main_profile_wrapper .upload_works_wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 568px) {
    .main_profile_wrapper .upload_works_wrapper {
        grid-template-columns: 1fr;
    }

    .main_profile_wrapper .upload_works_wrapper .works_card img {
        height: auto;
    }

    .main_profile_wrapper .artist_profile_buttons, .main_profile_wrapper .artist_profile_buttons .classic_digital_buttons {
        grid-template-columns: repeat(2, 1fr);
        margin: 0 0 2.5rem;
    }

    .main_profile_wrapper .artist_profile_buttons .classic_digital_buttons {
        grid-template-columns: 1fr;
        margin: 0 0 2.5rem;
    }

    .main_profile_wrapper .artist_profile_buttons, .main_profile_wrapper .artist_profile_buttons button {
        width: 100%;
    }

    .main_profile_wrapper .artist_profile_buttons {
        grid-template-columns: 1fr;
        grid-gap: 2rem;
    }

    .main_profile_wrapper .artist_profile_buttons .classic_digital_buttons {
        margin: 0;
    }
}

@media (max-width: 350px) {
    .main_profile_wrapper .artist_profile_buttons {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }
}