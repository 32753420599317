.new_artwork_public .wrapper_artwork_info {
  display: grid;
  grid-template-columns: 550px calc(100% - 550px);
  grid-gap: 32px;
  word-break: break-word;
  min-width: 0;
  min-height: 0;
}

.new_artwork_public .wrapper_artwork_info * {
  font-family: 'Poppins', sans-serif !important;
}

.new_artwork_public .wrapper_artwork_recently_viewed {
  margin-top: 5rem;
}

.new_artwork_public .wrapper_artwork_recently_viewed h3 {
  font-weight: 600;
  font-size: 32px;
  line-height: 56px;
  color: #000;
}

.new_artwork_public .wrapper_artwork_recently_viewed__list {
  padding: 3rem 0;
  border-width: 2px 0;
  border-style: solid;
  border-color: #000;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 24px;
}

.new_artwork_public .wrapper_artwork_recently_viewed__list .artwork_info_card {
  max-width: 340px;
}

.new_artwork_public .wrapper_artwork_info__info {
  display: grid;
  grid-gap: 24px;
  height: max-content;
  width: calc(100% - 32px);
}

.new_artwork_public .wrapper_artwork_info__info .artist_preview {
  display: grid;
  grid-template-columns: 90px 1fr max-content;
  align-items: center;
  height: max-content;
  grid-gap: 24px;
}

.new_artwork_public .wrapper_artwork_info__info .artist_preview .avatar {
  width: 89px;
  height: 89px;
}

.new_artwork_public .wrapper_artwork_info__info .artist_preview .avatar img {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  object-fit: cover;
}

.new_artwork_public .wrapper_artwork_info__info .wrapper_artist_preview_info {
  display: grid;
}

.new_artwork_public
  .wrapper_artwork_info__info
  .wrapper_artist_preview_info
  .country {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.06em;
  color: #000;
}

.new_artwork_public
  .wrapper_artwork_info__info
  .wrapper_artist_preview_info
  .name {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000;
}

.new_artwork_public
  .wrapper_artwork_info__info
  .wrapper_artist_preview_info
  span {
  cursor: pointer;
}

.new_artwork_public
  .wrapper_artwork_info__info
  .wrapper_artwork_info__categories {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}

.new_artwork_public
  .wrapper_artwork_info__info
  .wrapper_artwork_info__categories
  p {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.015em;
  color: #000;
  margin: 0 12px;
}

.new_artwork_public .wrapper_artwork_info__info .artwork_info .title {
  margin-bottom: 32px;
}

.new_artwork_public .wrapper_artwork_info__info .artwork_info .title span {
  font-weight: 600;
  font-size: 32px;
  line-height: 50px;
  word-break: break-all;
  color: #000;
}

.new_artwork_public
  .wrapper_artwork_info__info
  .artwork_info
  .wrapper_artwork_info__details {
  border-width: 2px 0;
  border-style: solid;
  border-color: #000;
  padding: 24px 0;
}

.new_artwork_public
  .wrapper_artwork_info__info
  .artwork_info
  .wrapper_artwork_info__details {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 0 3.5rem;
}

.new_artwork_public
  .wrapper_artwork_info__info
  .artwork_info
  .wrapper_artwork_info__details
  p
  > span,
.new_artwork_public
  .wrapper_artwork_info__info
  .artwork_info
  .wrapper_artwork_info__details
  a,
.new_artwork_public
  .wrapper_artwork_info__info
  .artwork_info
  .wrapper_artwork_info__details
  > span,
.new_artwork_public
  .wrapper_artwork_info__info
  .artwork_info
  .wrapper_artwork_info__details
  div
  > span {
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.015em;
  color: #000;
}

.new_artwork_public
  .wrapper_artwork_info__info
  .artwork_info
  .wrapper_artwork_info__details
  > button {
  width: max-content;
  margin-bottom: 24px;
}

.new_artwork_public
  .wrapper_artwork_info__info
  .artwork_info
  .wrapper_artwork_info__details
  a {
  text-decoration: underline;
}

.new_artwork_public
  .wrapper_artwork_info__info
  .artwork_info
  .wrapper_artwork_info__details
  p
  > span:after {
  content: ',';
}

.new_artwork_public
  .wrapper_artwork_info__info
  .artwork_info
  .wrapper_artwork_info__details
  p
  > span:last-child:after {
  content: '';
}

.new_artwork_public
  .wrapper_artwork_info__info
  .artwork_info
  .wrapper_artwork_info__details
  .value {
  font-weight: 300;
}

.new_artwork_public
  .wrapper_artwork_info__info
  .artwork_info
  .wrapper_artwork_info__description
  .title {
  margin: 24px 0 0;
}

.new_artwork_public
  .wrapper_artwork_info__info
  .artwork_info
  .wrapper_artwork_info__description
  .title
  span {
  font-size: 20px;
  line-height: 30px;
}

.new_artwork_public
  .wrapper_artwork_info__info
  .artwork_info
  .wrapper_artwork_info__description
  .description {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.015em;
  color: #000;
}

/*slider*/

.new_artwork_public .wrapper_artwork_slider {
  position: relative;
}

.new_artwork_public .wrapper_artwork_slider .every_image {
  height: 350px;
  width: 100%;
}

.new_artwork_public .wrapper_artwork_slider .artwork_sold {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  padding: 6px 0;
  text-align: center;
  background: #f6f6f1;
}

.new_artwork_public .wrapper_artwork_slider .artwork_sold span {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #000;
}

.new_artwork_public .wrapper_artwork_slider .every_image img {
  object-fit: cover;
  object-position: center;
  height: inherit;
  width: inherit;
}

.new_artwork_public .wrapper_artwork_slider .every_image span {
  object-fit: cover;
  object-position: center;
  height: inherit;
  width: inherit;
}

.new_artwork_public .wrapper_artwork_slider__body {
  height: 96px;
  margin-top: -6px;
  background: #000;
}

.new_artwork_public .wrapper_artwork_slider__body .slick-track {
  display: flex;
  justify-content: center;
}

.new_artwork_public .wrapper_artwork_slider__body .slick-slide div,
.new_artwork_public .wrapper_artwork_slider__body .slick-cloned {
  height: 96px;
  width: 68px;
  display: grid !important;
  align-content: center;
  justify-self: center;
}

.new_artwork_public .wrapper_artwork_slider__body .every_image_body img {
  height: 80px;
  width: inherit;
  object-fit: cover;
  opacity: 0.6;
}

.new_artwork_public .wrapper_artwork_slider__body .every_image_body span {
  height: 80px;
  width: inherit;
  object-fit: cover;
  opacity: 0.6;
}

.new_artwork_public .wrapper_artwork_slider__body .slick-current img {
  filter: unset;
  opacity: 1;
  -webkit-filter: unset;
}

.new_artwork_public
  .wrapper_artwork_slider__body
  .slick-initialized
  .slick-slide {
  width: 68px !important;
  margin: 0 4px;
}

.wrapper_statistic_performance
  .statistic_profile_views
  .wrapper_views_info
  .every
  b {
  font-weight: 600;
}

.new_artwork_public .art_price_body_slider_arrow_left {
  left: 0.5rem;
}

.new_artwork_public .art_price_body_slider_arrow {
  top: 109%;
  transform: unset;
  z-index: 1000;
}

.new_artwork_public .art_price_body_slider_arrow svg {
  transform: rotateY(180deg);
}

.new_artwork_public .art_price_body_slider_arrow_right {
  right: 0.5rem;
}

/*slider*/

.new_artwork_public .wrapper_artwork_info__artwork_body {
  background: #f6f6f1;
  padding: 2rem 24px 24px;
}

.new_artwork_public .wrapper_artwork_info__artwork_body .wrapper_description,
.new_artwork_public .wrapper_artwork_info__artwork_body .wrapper_preview {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  /*grid-gap: 3rem;*/
}

.new_artwork_public .wrapper_artwork_info__artwork_body .wrapper_description {
  margin-top: 1.5rem;
}

.new_artwork_public
  .wrapper_artwork_info__artwork_body
  .wrapper_description
  button {
  justify-self: center;
}

.new_artwork_public
  .wrapper_artwork_info__artwork_body
  .wrapper_description
  .info,
.new_artwork_public
  .wrapper_artwork_info__artwork_body
  .wrapper_preview
  .secured_by {
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: #000;
}

.new_artwork_public
  .wrapper_artwork_info__artwork_body
  .wrapper_preview
  .price {
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;
  justify-self: end;
  color: #000;
}

.new_artwork_public .wrapper_artwork_info__artwork_body .wrapper_shipping_list {
  border-top: 2px solid #000;
  padding: 24px 0 0;
  margin-top: 24px;
  display: grid;
  grid-gap: 16px;
}

.new_artwork_public
  .wrapper_artwork_info__artwork_body
  .wrapper_shipping_list
  .every_point {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 0 12px;
}

.new_artwork_public
  .wrapper_artwork_info__artwork_body
  .wrapper_shipping_list
  .every_point
  svg {
  margin-top: 4px;
}

.new_artwork_public .wrapper_artwork_info__shipping_details {
  padding: 24px 40px;
  background: #000;
  height: 200px;
  display: grid;
}

.new_artwork_public .wrapper_artwork_info__shipping_details .tabs {
  display: flex;
  margin: 0 -8px;
  height: max-content;
}

.new_artwork_public .wrapper_artwork_info__shipping_details .tabs .every_tab {
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.33px;
  margin: 0 8px;
  cursor: pointer;
  color: #fff;
}

.new_artwork_public
  .wrapper_artwork_info__shipping_details
  .tabs
  .every_tab_active {
  text-decoration: underline;
}

.new_artwork_public .wrapper_artwork_info__shipping_details .info {
  height: max-content;
}

.new_artwork_public .wrapper_artwork_info__shipping_details .info span {
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.015em;
  color: #fff;
}

.new_artwork_public .wrapper_artwork_info__shipping_details .learn_more {
  display: grid;
  justify-content: end;
  align-self: end;
}

.new_artwork_public .wrapper_artwork_info__shipping_details .learn_more a {
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  text-decoration-line: underline;
  color: #fff;
}

.artwork-sold {
  background-color: #eb4250;
  height: 42px;
  width: 68px;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  padding: 0 15px 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*viewer*/

.react-viewer-close {
  background: #fff !important;
  border-radius: unset !important;
}

.react-viewer-close .react-viewer-icon {
  color: #000 !important;
  left: 12px;
  top: 10px;
}

.react-viewer-toolbar li[data-key='rotateLeft'],
.react-viewer-toolbar li[data-key='reset'] {
  display: none;
}

.react-viewer-toolbar li[data-key='next'],
.react-viewer-toolbar li[data-key='prev'],
.react-viewer-toolbar li[data-key='zoomOut'],
.react-viewer-toolbar li[data-key='zoomIn'],
.react-viewer-toolbar li[data-key='rotateRight'] {
  background: #fff !important;
}

.react-viewer-toolbar li[data-key='zoomOut'] .react-viewer-icon,
.react-viewer-toolbar li[data-key='zoomIn'] .react-viewer-icon {
  color: #000 !important;
}

.react-viewer-list {
  height: 90px !important;
}

.react-viewer-list > li {
  height: 80px !important;
  margin-top: 5px;
  width: 68px !important;
  overflow: unset !important;
}

.react-viewer-list > li > img {
  height: inherit !important;
  width: inherit !important;
  object-fit: cover;
}

.react-viewer-toolbar {
  height: 40px !important;
}

.react-viewer-toolbar li {
  border-radius: unset !important;
  width: 40px !important;
  height: 40px !important;
  margin-right: 0 !important;
}

.react-viewer-icon {
  vertical-align: text-bottom !important;
}

.react-viewer-icon-rotateRight {
  position: relative;
  top: 6px;
  left: 2px;
}

.react-viewer-icon-zoomOut {
  position: relative;
  top: -3px;
  left: -2px;
}

.react-viewer-icon-zoomIn {
  position: relative;
  top: 6px;
  left: 4px;
}

.react-viewer-icon-prev,
.react-viewer-icon-next {
  position: relative;
  top: 6px;
  left: 1px;
}

.react-viewer-icon-zoomIn:before {
  content: url('../../assets/img/viewer_plus.svg') !important;
}

.react-viewer-canvas {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}

.react-viewer-icon-prev:before {
  content: url('../../assets/img/viewer_vector_left.svg') !important;
}

.react-viewer-icon-next:before {
  content: url('../../assets/img/viewer_vector_right.svg') !important;
}

.react-viewer-icon-zoomOut:before {
  content: url('../../assets/img/viewer_minus.svg') !important;
}

/*.react-viewer-icon-zoomOut:before {*/
/*    content: url("../../assets/img/viewer_minus.svg") !important;*/
/*}*/

.react-viewer-icon-rotateRight:before {
  content: url('../../assets/img/viewer_rotate.svg') !important;
}

.react-viewer-close .react-viewer-icon-close:before {
  content: url('../../assets/img/close_vector_black.svg');
}

.new_artwork_public .ril__toolbar {
  bottom: 0;
  top: unset;
}

.nft-fixed-price-title-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;
}

.wrapper-artwork-info-nft-bottom {
  background-color: #000000;
  padding: 12px 40px;
  display: flex;
  justify-content: center;
}

.wrapper-artwork-info-nft-bottom .learn_more a {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  text-decoration-line: underline;
  display: flex;
  justify-content: center;
  align-items: center;
}

.public-artwork-nft-block-container {
  color: #ffffff;
  background-color: #000000;
  width: 68px;
  padding: 6px 15px;
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  top: 0;
  right: 0;
}

.public-artwork-nft-block-wrapper {
  position: relative;
}

.file-download-text {
  cursor: pointer;
  text-decoration: underline;
}

.nft-price-dollars {
  display: flex;
  justify-content: flex-end;
}

.public-artwork-name-container {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 10px;
  align-items: center;
}

/*viewer*/

@media (max-width: 1400px) {
  .new_artwork_public .wrapper_artwork_info {
    grid-template-columns: 400px calc(100% - 400px);
  }

  .new_artwork_public .wrapper_artwork_info__artwork_body .wrapper_description,
  .new_artwork_public .wrapper_artwork_info__artwork_body .wrapper_preview {
    grid-gap: 24px;
  }

  .new_artwork_public .wrapper_artwork_slider__body .every_image_body img {
    height: 70px;
  }
  .new_artwork_public .wrapper_artwork_slider__body .every_image_body span {
    height: 70px;
  }

  .new_artwork_public .wrapper_artwork_info__info {
    display: grid;
    grid-gap: 24px;
    height: max-content;
    width: calc(100% - 32px);
  }
}

@media (max-width: 992px) {
  .new_artwork_public .wrapper_artwork_info {
    grid-template-columns: 300px calc(100% - 300px);
    grid-gap: 20px;
  }

  .new_artwork_public .wrapper_artwork_info__info {
    width: calc(100% - 20px);
  }

  .new_artwork_public .wrapper_artwork_info__artwork_body,
  .new_artwork_public .wrapper_artwork_info__shipping_details {
    padding: 16px;
  }

  .new_artwork_public .wrapper_artwork_slider__body .slick-slide div,
  .new_artwork_public .wrapper_artwork_slider__body .slick-cloned {
    width: 44px;
    height: 72px;
  }

  .new_artwork_public .wrapper_artwork_info__info .artist_preview {
    grid-template-columns: 92px 1fr;
  }

  .new_artwork_public
    .wrapper_artwork_slider__body
    .slick-initialized
    .slick-slide {
    width: 44px !important;
  }

  .new_artwork_public .wrapper_artwork_slider__body {
    height: 72px;
  }

  .new_artwork_public .wrapper_artwork_slider__body .every_image_body img {
    height: 56px;
  }
  .new_artwork_public .wrapper_artwork_slider__body .every_image_body span {
    height: 56px;
  }

  .new_artist_public .home_artists_header_wrapper .follow_artist {
    margin-top: 16px;
  }

  .new_artwork_public .wrapper_artwork_slider .every_image {
    height: 224px;
  }

  .new_artwork_public .wrapper_artwork_info__artwork_body .wrapper_description,
  .new_artwork_public .wrapper_artwork_info__artwork_body .wrapper_preview {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .new_artwork_public
    .wrapper_artwork_info__artwork_body
    .wrapper_description
    button,
  .new_artwork_public
    .wrapper_artwork_info__artwork_body
    .wrapper_preview
    .price {
    grid-row: 1;
    justify-self: center;
  }

  .new_artwork_public .wrapper_artwork_info__artwork_body .wrapper_description,
  .new_artwork_public .wrapper_artwork_info__artwork_body .wrapper_preview {
    grid-gap: 16px;
  }
}

@media (max-width: 700px) {
  .new_artwork_public
    .wrapper_artwork_info__info
    .artwork_info
    .wrapper_artwork_info__description
    .title
    span,
  .new_artwork_public .wrapper_artwork_recently_viewed h3 {
    font-size: 24px;
    line-height: 30px;
  }

  .new_artwork_public .wrapper_artwork_recently_viewed__list {
    margin-top: 2rem;
    padding: 1rem 0;
  }

  .new_artwork_public .wrapper_artwork_info {
    grid-template-columns: 100%;
  }

  .new_artwork_public .wrapper_artwork_slider .every_image {
    height: 320px;
  }

  .new_artwork_public .wrapper_artwork_info__info .artist_preview {
    grid-template-columns: 1fr;
  }

  .new_artwork_public
    .wrapper_artwork_info__info
    .artist_preview
    .follow_artist
    button,
  .new_artwork_public
    .wrapper_artwork_info__info
    .artist_preview
    .wrapper_artist_preview_info,
  .new_artwork_public .wrapper_artwork_info__info .artist_preview .avatar {
    justify-self: center;
  }

  .new_artwork_public
    .wrapper_artwork_info__info
    .wrapper_artwork_info__categories {
    justify-content: center;
  }

  .new_artwork_public
    .wrapper_artwork_info__info
    .artwork_info
    .wrapper_artwork_info__details
    div,
  .new_artwork_public
    .wrapper_artwork_info__info
    .artwork_info
    .wrapper_artwork_info__details
    .value {
    justify-self: end;
    word-break: break-all;
  }

  .new_artwork_public .wrapper_artwork_info__info .artwork_info .title span {
    font-size: 24px;
    line-height: 30px;
  }

  .new_artwork_public .wrapper_artwork_info__info {
    grid-gap: 16px;
    margin-top: 16px;
    width: 100%;
  }

  .new_artwork_public .wrapper_artwork_info__info .follow_artist {
    display: grid;
    justify-content: center;
  }

  .new_artwork_public .wrapper_artwork_info__info .artwork_info {
    margin-top: 36px;
  }

  .new_artwork_public .wrapper_artwork_info__info .wrapper_artist_preview_info {
    text-align: center;
  }

  .new_artwork_public
    .wrapper_artwork_info__info
    .artwork_info
    .wrapper_artwork_info__details {
    padding: 16px 0;
  }

  .new_artwork_public
    .wrapper_artwork_info__info
    .artwork_info
    .wrapper_artwork_info__description
    .title {
    margin-top: 32px;
  }

  .new_artwork_public
    .wrapper_artwork_info__artwork_body
    .wrapper_shipping_list
    .every_point
    span {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
  }

  .new_artwork_public
    .wrapper_artwork_info__artwork_body
    .wrapper_shipping_list {
    grid-gap: 8px;
  }

  .new_artwork_public
    .wrapper_artwork_info__artwork_body
    .wrapper_shipping_list
    .every_point
    svg {
    margin-top: 8px;
  }

  .new_artwork_public .wrapper_artwork_info__shipping_details .tabs {
    margin: 0 -4px;
  }

  .new_artwork_public .wrapper_artwork_info__shipping_details .tabs .every_tab {
    margin: 0 4px;
  }

  .new_artwork_public .wrapper_artwork_info__shipping_details {
    height: 176px;
  }

  .new_artwork_public .wrapper_artwork_recently_viewed__list {
    grid-template-columns: unset;
    grid-auto-flow: column;
    overflow-x: auto;
    padding-bottom: 0;
  }

  .new_artwork_public
    .wrapper_artwork_recently_viewed__list
    .artwork_info_card {
    width: 280px;
  }
}

@media (max-width: 450px) {
  .new_artwork_public .wrapper_artwork_slider .every_image {
    height: 224px;
  }
}
