.admin_content_wrapper_user {
    padding: 2rem 4rem;
}

.admin_content_wrapper_user > .navigation {
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    margin: 0 0 2rem;
}

.admin_content_wrapper_user > .navigation a {
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.7);
    text-transform: capitalize;
}

.admin_content_wrapper_user > .navigation span {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}

.admin_content_wrapper_user > .content {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: start;
    justify-content: space-between;
    margin: 0 0 2rem;
}

.admin_content_wrapper_user > .content .content_about {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 1rem;
}

.admin_content_wrapper_user > .content .content_about h3 {
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.374px;
    color: #333333;
    margin-bottom: 16px;
}

.admin_content_wrapper_user > .content .content_about span {
    font-size: 16px;
    line-height: 155%;
    text-align: right;
    color: #828181;
    margin-bottom: 2px;
}

.admin_content_wrapper_user > .content .content_about img {
    border-radius: 100%;
    height: 111px;
    width: 111px;
    object-fit: cover;
}

.content_payment_content {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: end;
}

.content_payment_block button {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    padding: 7px 34px;
    text-align: center;
    color: #000;
    background: transparent;
    border-radius: 4px;
    border: 1px solid #000;
}

.admin_content_wrapper_user > .content .content_payment_block span {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #27AE60;
    padding: 7px 34px;
    border: 1px solid #AE4B84;
    border-radius: 4px;
}

.content_payment_verified button {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    padding: 7px 2rem;
    text-align: center;
    color: #FFFFFF;
    background: #000;
    border-radius: 4px;
}

.admin_content_wrapper_user > .content .content_payment_verified span {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #27AE60;
    padding: 7px 27px;
    border: 1px solid #27AE60;
    border-radius: 4px;
}

.admin_content_wrapper_user > .categories {
    display: flex;
    flex-wrap: wrap;
}

.admin_content_wrapper_user > .categories p {
    font-size: 13px;
    line-height: 157%;
    letter-spacing: 0.06em;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.7);
    background: #F8F8FB;
    border-radius: 20px;
    padding: 4px 10px;
    margin-right: 1.5rem;
}

.admin_content_wrapper_user > .user_info > .email_country {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    align-items: center;
    margin: 3rem 0 1.5rem;
}

.admin_content_wrapper_user > .user_info > .email_country div {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 1rem;
    align-items: center;
}

.admin_content_wrapper_user > .user_info > .email_country div span {
    font-size: 18px;
    line-height: 120%;
    color: #000000;
}

.admin_content_wrapper_user > .user_info > .email_country div a, .admin_content_wrapper_user > .user_info > .email_country div p {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
}

.admin_content_wrapper_user > .user_info > .portfolio_about {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    justify-content: space-between;
    grid-gap: 2rem;
    margin: 0 0 2.25rem;
}

.admin_content_wrapper_user > .user_info > .portfolio_about h3 {
    font-size: 24px;
    line-height: 36px;
    margin: 0 0 1.5rem;
    color: #000000;
}

.admin_content_wrapper_user > .user_info > .portfolio_about .social_item {
    display: grid;
    grid-template-columns: 26px 1fr;
    grid-gap: 1rem;
    align-items: center;
    margin: 0 0 1.5rem;
}

.admin_content_wrapper_user > .user_info > .portfolio_about .social_item:last-of-type {
    margin: 0;
}

.admin_content_wrapper_user > .user_info > .portfolio_about .social_item a, .admin_content_wrapper_user > .user_info > .portfolio_about .social_item span {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    width: max-content;
    display: block;
}

.admin_content_wrapper_user > .user_info > .portfolio_about .social_item a.file_proof {
    color: rgba(0, 0, 0, 0.7);
}

.admin_content_wrapper_user > .user_info > .portfolio_about p {
    font-size: 16px;
    line-height: 155%;
    color: #000000;
}

.admin_content_wrapper_user > .user_info > .works_container h3 {
    font-size: 24px;
    line-height: 36px;
    margin: 0 0 1rem;
    color: #000000;
}

.admin_content_wrapper_user > .user_info > .works_container .work_item {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: start;
    grid-gap: 2.5rem;
    margin: 0 0 1.5rem;
}

.admin_content_wrapper_user > .user_info > .works_container .work_item:last-of-type {
    margin: 0;
}

.admin_content_wrapper_user > .user_info > .works_container .work_item img {
    width: 263px;
    height: 263px;
    object-fit: cover;
}

.admin_content_wrapper_user > .user_info > .works_container .work_item p {
    font-size: 16px;
    line-height: 155%;
    color: #000000;
}

.nft-detailed-header {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 2px solid;
}

.nft_order_go_back {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr;
    grid-gap: 8px;
    color: #000;
}

.nft-detailed-header-orders {
    font-size: 24px;
    font-weight: 600;
}

.nft-detailed-first-section {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 35px;
    align-items: center;
}

.nft-detailed-first-section-second-row {
    display: grid;
    grid-template-rows: repeat(3, max-content);
    grid-gap: 3px;
}

.nft-detailed-first-section-second-row-bold {
    font-weight: 600;
    font-size: 14px;
}

.nfts-detailed-title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 16px;
}

.nfts-detailed-second-section-container {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 35px;
    align-items: center;
}

.margin-bottom-40 {
    margin-bottom: 50px;
}

.line-block {
    border-bottom: 2px solid #000000;
    padding-bottom: 25px;
    margin-bottom: 40px;
}

.nfts-detailed-buttons-container {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 16px;
    justify-content: center;
    align-items: center;
}

.nfts-detailed-buttons-container div {
    width: 89px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}

.nfts-detailed-cancel-approve {
    background-color: #000000;
    border: 1px solid #000000;
    color: #FFFFFF;
}

.nfts-detailed-cancel-button {
    background-color: transparent;
    border: 1px solid #000000;
    color: #000000;
}

.nfts-detailed-cancel-button-disabled {
    opacity: 0.5;
    cursor: not-allowed!important;
}

.admin_nft_order {
    width: 150px;
}
