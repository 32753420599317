/*  #Global css for all files  */
:root {
    --dark-grey: #2b2b2e;
    --ugly-purple: #bc4386;
    --new-black: #000000;
    --turquoise: #01afa8;
    --slate: #4e5c6a;
    --lite-grey: #dbddde;
    --pale-grey: #f5fcff;
    --grey: #cccbcb;
    --error: #fe3e3e;
    --white: #f5f5f5;
    --beige: #F6F6F1;
    --new-error: #FF4949;
    --app-height: 100vh;
}

@font-face {
    font-family: 'Circe';
    src: local('Circe Thin'), local('Circe-Thin'),
    url('assets/fonts/Circe-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: local('Circe ExtraLight'), local('Circe-ExtraLight'),
    url('assets/fonts/Circe-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: local('Circe Light'), local('Circe-Light'),
    url('assets/fonts/Circe-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: local('Circe'), local('Circe-Regular'),
    url('assets/fonts/Circe-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: local('Circe Bold'), local('Circe-Bold'),
    url('assets/fonts/Circe-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: local('Circe ExtraBold'), local('Circe-ExtraBold'),
    url('assets/fonts/Circe-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

*, ::after, ::before {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    color: unset;
    font-size: unset;
    line-height: unset;
    background: #fff;
    /*overflow-x: hidden;*/
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    min-width: 320px;
}

p, ul, li, pre {
    margin-bottom: 0
}

button, textarea,
input, a,
input:focus,
button:focus,
textarea:focus {
    outline: none;
    text-decoration: none;
    cursor: pointer;
}

button {
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline: none;
    margin: 0;
}

input, textarea, label {
    cursor: auto;
    padding: 0;
    margin: 0;
}

input,
textarea {
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-appearance: none;
    cursor: text;
}

header, main, nav, section, div {
    display: block;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6, p, span, div, label, input, li, button, textarea, text {
    font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

.wrapper_app {
    min-height: calc(var(--app-height) - 345px);
    background: #fff;
    margin: 104px 0 0;
}

/*.wrapper_app_design {*/
/*    margin: 104px 0 0;*/
/*}*/

.wrapper_un_verified_user {
    text-align: center;
    display: grid;
    align-content: center;
    justify-content: center;
}

.grid-align {
    display: grid;
    width: max-content;
    align-items: center;
}

.slick-slider {
    cursor: grab;
}

.slick-slide div, .slick-cloned {
    outline: none !important;
}

.react-viewer-transition {
    position: relative;
    z-index: 100000;
}

.wrapper_every_page {
    margin-top: 73px;
    overflow-x: hidden;
}

.no_items_style {
    width: 100%;
    display: grid;
    text-align: center;
    height: 100px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}

.section_title_wrapper {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 42px;
    color: #2A303C;
}

.d-block {
    display: block;
}

.wrapper_pagination {
    display: flex;
    justify-content: center;
}

.wrapper_pagination .pagination > * + * {
    margin-left: 6px;
}

.wrapper_pagination .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper_pagination .pagination button {
    background-color: var(--new-black);
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: #ffffff;
    padding: 2px 15px;
    transition: .4s all;
}

.pagination_list_buttons > a, .pagination_list_buttons_active > a {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: var(--dark-grey);
}

.pagination_list_buttons_active > a {
    color: var(--new-black);
}

.wrapper_new_pagination {
    display: flex;
    justify-content: center;
    border: 2px solid #000000;
    border-right: none;
    border-left: none;
    padding: 2.5rem 10%;
    margin: 0 0 6rem;
}

.wrapper_new_pagination.flex-end {
    justify-content: flex-end;
    border-bottom: unset;
    padding-right: 0;
    padding-bottom: 0;
}

.artworks_listing .show-more {
    margin: 0 0 24px auto;
    grid-column: -1/1;
}

.wrapper_new_pagination .pagination > * + * {
    margin-left: 3.5rem;
}

.wrapper_new_pagination .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}

.wrapper_new_pagination .pagination button {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.wrapper_new_pagination .pagination .pagination_list_buttons_disabled button, .wrapper_new_pagination .pagination .pagination_list_buttons_disabled a {
    font-weight: normal;
    pointer-events: none;
}

.wrapper_new_pagination .pagination_list_buttons > a, .wrapper_new_pagination .pagination_list_buttons_active > a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.wrapper_new_pagination .pagination_list_buttons_active > a {
    font-weight: 600;
}

.overflow_scrollbar::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.overflow_scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #ededed;
}

/* Handle */
.overflow_scrollbar::-webkit-scrollbar-thumb {
    background-color: #c3c3c2;
    border: 1px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}

.wrapper_upload_work__ranges__estimated .estimate_price {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.25;
    color: #a12161;
    padding-left: 12px;
}

a:hover {
    color: unset;
}

.custom_error {
    color: var(--error);
    font-size: 12px;
    font-weight: bold;
}

.new_modal_wrapper .modal-window-content {
    background: var(--beige);
    border-radius: 0;
    padding: 1rem 1.5rem 1.5rem;
    max-width: 800px;
    max-height: 90vh;
    overflow: auto;
}

.new_modal_wrapper .modal-window-content .title {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 20px;
    margin: 0 0 1.5rem;
    border-bottom: 1px solid #000000;
}

.delete-title-container {
    display: grid;
    margin: 0 0 1.5rem;
}

.new_modal_wrapper .modal-window-content .title h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: unset;
    color: #000000;
    margin-bottom: 0;
}

.new_modal_wrapper .modal-window-content .title svg {
    justify-self: end;
    cursor: pointer;
}

.new_modal_wrapper .modal-window-content p {
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.06em;
    color: #000000;
    margin: 0 0 2.5rem;
}

.new_modal_wrapper .modal-window_buttons button:first-child:hover {
    background: #fff;
    color: #000;
}

.ant-tooltip-inner {
    background-color: black !important;
    border-radius: 0 !important;
    font-weight: 300 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    letter-spacing: 0.015em !important;
    color: #F7F6F1 !important;
    padding: 20px !important;
}

.ant-modal-wrap {
    z-index: 10000 !important;
}

.ant-select-dropdown {
    z-index: 10050 !important;
}

.header-premium {
    z-index: 10000 !important;
    border: 1px solid #000000;
}

.header-premium .ant-dropdown-menu {
    padding: 0;
}

.header-premium .ant-dropdown-menu .ant-dropdown-menu-item {
    background-color: black;
    color: white;
}

.header-premium .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    background-color: white;
    color: black;
}
.header-premium-content {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    width: max-content;
    cursor: pointer;
    transition: .3s;
}

.header-premium-content:hover {
    text-decoration: underline;
}

.header-premium-content.active-link {
    font-weight: 700;
}

.header-premium-content svg {
    margin-left: 4px;
}

@media (max-width: 1200px) {
    .wrapper_app {
        margin: 73px 0 0;
    }

    .wrapper_app_design {
        margin: 104px 0 0;
    }

    .wrapper_new_pagination {
        padding: 2rem 7.5%;
    }
}

@media (max-width: 1160px) {
    .wrapper_app_design {
        margin: 74px 0 0;
    }
}


@media (max-width: 992px) {
    .wrapper_every_page {
        margin-top: 60px;
    }

    .wrapper_app {
        margin: 60px 0 0;
    }

    .wrapper_new_pagination {
        padding: 1.5rem 5%;
    }
}

@media (max-width: 568px) {
    .wrapper_new_pagination {
        padding: 1rem;
    }

    .wrapper_new_pagination .pagination > * + * {
        margin-left: 2.5rem;
    }
}

@media (max-width: 400px) {
    .wrapper_new_pagination .pagination > * + * {
        margin-left: 2rem;
    }
}

@media (max-width: 325px) {
    .wrapper_new_pagination .pagination > * + * {
        margin-left: 1.75rem;
    }
}