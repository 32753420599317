.wrapper {
    padding-top: 1.5rem;
}

.tabs {
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #333333;
}

.bannerWrapper {

}

.topBanner {
    display: flex;
    flex-direction: column;
    background-color: #F6F6F1;
    padding: 24px;
    gap: 8px;
}

.bannerTitle {
    font-size: 20px;
    font-weight: 600;
}

.bannerSubtitle {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.bottomBanner {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 31px;
    background-color: #000000;
    padding: 24px;
}

.bottomBannerText {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

@media (max-width: 576px) {
    .bottomBanner {
        grid-template-columns: 1fr;
    }
}