.admin_content_wrapper_mailing {

}

.admin_content_wrapper_mailing > .title {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    margin: 0 0 2rem;
    padding: 0 4rem;
}

.admin_content_wrapper_mailing > .title h1 {
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.374px;
    color: #333333;
    margin: 0;
}

.admin_content_wrapper_mailing > .title button {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    grid-gap: 12px;
    align-items: center;
    background: #000;
    border-radius: 4px;
    color: #FFFFFF;
    padding: 7px 16px 7px 13px;
}

.admin_content_wrapper_mailing > .subtitle {
    display: grid;
    grid-template-columns: 264px 1fr;
    align-items: center;
    margin: 0 0 14px;
    padding: 0 4rem;
}

.admin_content_wrapper_mailing > .subtitle h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #000000;
}

.admin_content_wrapper_mailing > .mailing_content {
    border-top: 1px solid #EFEFF4;
}

.admin_content_wrapper_mailing > .mailing_content div {
    padding: 16px 4rem 14px;
    border-bottom: 1px solid #EFEFF4;
    display: grid;
    grid-template-columns: 264px 1fr;
    align-items: center;
}

.admin_content_wrapper_mailing > .mailing_content span {
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}