.wrapper {
    display: flex;
    flex-direction: column;
    // margin: 60px 0;

    .img {
        max-width: 100%;
        width: 100%;
        max-height: 100vh;
        height: auto;
        object-fit: cover;
    }

    .text {
        margin-top: 20px;
        font-style: italic;
        font-weight: 300;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #000000;
    }

    .button {
        margin-top: 20px;
        align-self: center;
    }


    @media screen and (min-width: 568px) {
        .img {
            // height: 310px;
            // max-height: 480px;
        }
    }

    @media screen and (min-width: 1440px) {
        .img {
            // height: 400px;
            // max-height: 625px;
        }
    }
}
