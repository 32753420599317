.wrapper {
    position: relative;
    width: 100%;
    height: 900px;
    flex-grow: 1;
}

.wrapper > div {
    width: 100vw !important;
    left: 50%;
    transform: translateX(-50%);
}

.withFilter > div {
    width: calc(90vw - 17.875rem) !important;
    left: -1.5rem;
    transform: translateX(0);
}

.wrapper :global(.gm-style .gm-style-iw-c),
.wrapper :global(.gm-style-iw-d) {
    width: 210px;
    padding: 0;
    overflow: hidden !important;
    top: 15px;
}

.wrapper :global(.gm-style-iw-tc) {
    display: none;
}

.wrapper :global(.gm-style-iw-d > div) {
    width: 210px;
}

.imageWrapper {
    position: relative;
    width: 210px;
}

.imageWrapper::before {
    content: "";
    display: block;
    padding-bottom: 50%;
}

.imageWrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.infoContent {
    padding: 0.75rem;
    font-size: 0.75rem;
}

.infoPrice {
    font-size: 0.75rem;
    font-weight: 300;
    margin-top: 0.75rem;
}

.infoPrice b {
    font-weight: 400;
    font-size: 1rem;
}

.infoTitle {
    font-weight: 600;
    font-size: 0.875rem;
}