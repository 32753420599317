.root {
    display: grid;
    align-items: flex-start;
    grid-gap: 1rem;
    padding-bottom: 1rem;
    height: 100%;
}

.link {
    color: inherit;
    display: grid;
    grid-gap: 1rem;
}

.link:hover, .link:focus {
    color: inherit;
}

.root :global(.slick-next),
.root :global(.slick-prev) {
    color: #ffffff;
    top: auto;
    bottom: 0.25rem;
    width: 1.625rem;
    height: 1.625rem;
    z-index: 2;
}

.root :global(.slick-dots) {
    bottom: 1.275rem;
    z-index: 1;
}

.root :global(.slick-dots li),
.root :global(.slick-dots li button),
.root :global(.slick-dots li button:before) {
    width: 1rem;
    height: 1rem;
    padding: 0;
}

.root :global(.slick-dots li button:before) {
    font-size: 0.5rem;
    color: #FFFFFF;
}

.root :global(.slick-dots li.slick-active button:before) {
    color: #FFFFFF;
}

.root :global(.slick-prev) {
    left: 0.625rem;
}

.root :global(.slick-next) {
    right: 0.625rem;
}

.photos {
    position: relative;
    min-width: 0;
    overflow: hidden;
}

.photos::before {
    content: "";
    display: block;
    padding-bottom: 75%;
}

.photos :global(.slick-slider) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.photos :global(.slick-slider),
.photos :global(.slick-list),
.photos :global(.slick-track),
.photos :global(.slick-slide div) {
    height: 100%;
}

.photos::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    pointer-events: none;
}

.photosSlide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.root .defaultImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    display: grid;
    grid-gap: 0.5rem;
    margin-bottom: auto;
}

.badge {
    display: inline-flex;
    padding: 0 4px;
    line-height: 1.375;
    border: 1px solid #000000;
    font-size: 0.625rem;
    border-radius: 2px;
}

.root .noMargin {
    margin: 0;
}

.price {
    position: relative;
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.1;
    padding-right: 0.625rem;
}

.price + .price {
    border-left: 1px solid currentColor;
    padding-left: 0.625rem;
}

.priceTotal {
    font-weight: 400;
    font-size: 1.25rem;
}

.actions {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: auto;
}

.title {
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 600;
}

.address {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.875;
}

.rentable {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
}

@media (max-width: 576px) {
    .actions {
        flex-direction: column;
        align-items: normal;
    }
}