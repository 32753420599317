.header {
    padding: 3rem 10%;
    background-color: var(--beige);
}

.title {
    font-size: 2.25rem;
    font-weight: 600;
    letter-spacing: 0.02em;
}

.body {
    display: grid;
    grid-template-columns: 1fr 20rem;
    padding: 1.5rem 10% 3rem;
}

.left {
    padding-right: 2.5rem;
}

.aside {
    padding-left: 1.5rem;
    border-left: 2px solid #000000;
}

.inner {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid #000000;
}

.guestForm {
    margin-bottom: 1rem;
}

.confirmButton {
    margin-top: 5rem;
}

.formWrapper {
    margin-top: 4rem;
}

.aside .asideTitle {
    margin-bottom: 1rem;
    padding-bottom: 1.5rem;
    border-bottom: 2px solid #000000;
}

.asideDescription {
    font-weight: 300;
    font-size: 0.875rem;
    margin: 2.5rem 0;
}

.asideRow {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 1rem;
    font-size: 1.25rem;
}

.asideRow + .asideRow {
    margin-top: 0.5rem;
}

.asideRowTotal {
    font-weight: 600;
    font-size: 1.5rem;
}

.calendarContainer {
    margin-top: 1rem;
}

@media (max-width: 992px) {
    .body {
        grid-template-columns: 1fr;
        padding-left: 5%;
        padding-right: 5%;
        grid-gap: 2.5rem;
    }

    .aside {
        padding-left: 0;
        border: 0;
    }

    .left {
        padding-right: 0;
    }
}

@media (max-width: 568px) {
    .body {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .formWrapper {
        margin-top: 2.5rem;
    }

    .confirmButton {
        margin-top: 2.5rem;
    }
}