.first_section_wrapper {
    background-color: var(--pale-grey);
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 0 40px;
}

.preview_block {
    background-color: white;
    transition: all 500ms ease;
    padding: 25px 25px;
    position: relative;
}

.wrapper_text svg path,
.subscribe_list_right svg path {
    fill: var(--turquoise);
    stroke: #fff;
    stroke-width: 0.3px
}

.preview_block:hover {
    background-color: #00a59e;
    transform: scale3d(0.985, 0.985, 0.985) translateX(0);
    transition: all 500ms ease;
}

.preview_block::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 250px;
    z-index: -1;
    height: 250px;
    background: url("../../assets/img/guide_hover.svg");
    background-size: cover;
    transition: all 500ms ease;
}

.artist_section::after {
    background: url("../../assets/img/artist_hover.svg");
}

.preview_block:hover .advantages_list > div > div {
    background-color: white;
    transition: all 500ms ease;
}

.preview_block:hover .switch_block svg path,
.preview_block:hover .subscribe_list_right svg path,
.preview_block:hover .wrapper_text svg path {
    fill: #fff;
}

.preview_block:hover span,
.preview_block:hover p,
.preview_block:hover h3 {
    color: #fff;
}

.discover_text {
    padding-bottom: 60px;
}

.discover_text h1 {
    margin-top: 60px;
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
}

.discover_text p {
    margin-top: 20px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
}

.discover_text button {
    margin-top: 45px;
    background-color: var(--new-black);
    border: none;
    color: #ffffff;
    padding: 10px 42px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    width: 174px;
    transition: all 500ms ease;

}

.discover_text button:hover {
    background: rgba(0, 0, 0, 0);
    color: var(--new-black);
    box-shadow: inset 0 0 0 1px var(--new-black);
}

.art_object {
    margin-top: 33px;
    display: block;
}

.art_object img {
    width: 41vw;
    height: 41vw;
    max-height: 417px;
    max-width: 556px;
    object-fit: contain;
}

.art_object > div {
    margin-bottom: -33px;
}

.head_slider_navigation {
    width: 50%;
    display: grid;
    justify-content: end;
    grid-template-columns: 100%;
}

.head_slider_navigation > div {
    width: 225px;
    margin-left: auto;
}

.head_slider_navigation .slick-slider {
    cursor: auto;
    margin-right: 80px;
}

.head_slider_navigation .slick-slide {
    cursor: pointer;
    padding: 0 4px;
}

.switch_block div:last-child {
    cursor: pointer;
}

.head_slider_navigation_item {
    height: 4px;
    background: var(--lite-grey);
    transition: .3s all;
}

.slick-current .head_slider_navigation_item {
    background: var(--turquoise);
}

.wrapper_two {
    margin-top: 100px;
}

.second_section_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 20px;
}


.subscribe_list_right {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 32px 31px;
    margin-top: 30px;
    align-items: stretch;
    position: relative;
}

.subscribe_list_right p {
    color: var(--slate);

}

.wrapper_text h3 {
    margin-left: 100px;

    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
}

.right h3 {
    margin-left: 100px;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
}


.subscribe_list_right .empty_desc {
    grid-column: 2/-1;
}

.section_padding {
    padding: 0 10%;
}

.empty_desc p {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
}

.advantages_list > div {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 11px 20px;
}

.advantages_list > div > div {
    height: 4px;
    width: 4px;
    background-color: var(--turquoise);
    border-radius: 50%;
}


.advantages_list p {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;

}

.switch_block {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 0 15px;
    justify-content: end;
    margin-top: 20px;
    position: relative;
    z-index: 10;
}

.switch_block div:first-child {
    width: 121px;
    height: 0;
    border: solid 1.5px #e1e3eb;
    background-color: var(--lite-grey);

}

.third_section_wrapper {
    background-color: #f5f5f5;
    position: relative;
    padding-top: 114px;
    margin-top: 80px;
}

.art_wrapper {
    display: flex;
    max-width: 70%;
}

.painting_text {
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
}

.photo_list {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 4px;
}

.photo_list p {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
}

.photo {
    display: grid;
    grid-gap: 0 4px;
}

.photo a {
    height: max-content;
}

.photo img {
    -webkit-filter: drop-shadow(13px 13px 16px rgba(34, 34, 34, 0.5));
    filter: drop-shadow(13px 13px 16px rgba(34, 34, 34, 0.5));
    width: 100%;
    min-height: 160px;
}

.first {
    margin: 25px 0 0 20px;
}

.art_object .slick-list {
    -webkit-filter: drop-shadow(13px 13px 16px rgba(34, 34, 34, 0.5));
    filter: drop-shadow(13px 13px 16px rgba(34, 34, 34, 0.5));
}

.second {
    margin: -70px 10px 0 44px;
}

.third {
    margin: 0 44px 3px 0;
}

.fourth {
    margin: 71px 0 0 20px;
}

.fifth {
    margin: 0 0 0 44px;
}

.collection_switch {
    margin-top: 21px;
    display: grid;
    align-items: center;
    grid-gap: 0 15px;
    justify-content: end;
    grid-template-columns: max-content 1fr;
    padding-bottom: 72px;
    grid-column: 1/-1;
}

.collection_switch div {
    height: 0;
    border: solid 1.5px #e1e3eb;
    background-color: var(--lite-grey);
}

.fourth_section_wrapper {
    margin-top: 70px;
}

.video_preview_landing {
    background: #fff;
    height: 435px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    box-shadow: 20px 20px 20px 0 rgba(0, 0, 0, 0.13);
    position: relative;
}

.video_preview_landing video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    outline: none;
}

/*.video_preview_landing > div {*/
/*    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);*/
/*    background-color: #ffffff;*/
/*    width: 5.5rem;*/
/*    height: 5.5rem;*/
/*    display: grid;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    border-radius: 100%;*/
/*    position: relative;*/
/*    z-index: 1000;*/
/*    cursor: pointer;*/
/*}*/

/*.video_preview_landing > div div {*/
/*    background-color: #ffffff;*/
/*    width: 3em;*/
/*    height: 3rem;*/
/*    border: 1px solid var(--lite-grey);*/
/*    display: grid;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    border-radius: 100%;*/
/*}*/

/*.video_preview_landing > div img {*/
/*    margin: auto;*/
/*}*/

.fourth_section_wrapper_position {
    position: relative;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 105px;
    align-items: center;
}

.fourth_section_wrapper_position .video_subscribe {
    align-self: start;
}

.fourth_section_wrapper_about_img {
    position: absolute;
    left: -2.75rem;
}

.switch_block div:last-child,
.collection_switch a {
    text-decoration: none;
    color: black;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 15px;
}

.video_preview_landing button {
    border-radius: 50%;
    border: 1px solid #0a0a0a;
    width: 2em;
    height: 2em;
    background-color: #ffffff;
}

.video_subscribe h3 {
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
}

.video_subscribe p {
    margin-top: 30px;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
}

.fifth_section_wrapper {
    margin-top: 70px;
}

.art_list h1 {
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: normal;
}

.art_list ul {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: 0.33px;
}

.art_list {
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr;
    grid-gap: 60px;
}

.art_list ul {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 40px;
}


.art_price_head {
    border-bottom: solid 1px var(--lite-grey);
    display: grid;
    grid-template-columns: max-content max-content 1fr;
    align-items: start;
    grid-gap: 4.4vw;
    margin-bottom: 30px;
}

.art_price_head h3 {
    font-size: 26px;
    font-weight: bold;
    margin-top: -16px;
    line-height: 1.73;
    color: var(--dark-grey);
}

.art_price_head_tab {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.46;
    letter-spacing: 0.33px;
    color: var(--lite-grey);
    border-bottom: 3px solid #fff;
    text-transform: uppercase;
    cursor: pointer;
    transition: .3s all;
    padding-bottom: 16px;
    white-space: pre;
    width: max-content;
}

.art_price_head_tab_active {
    border-bottom: 3px solid var(--turquoise);
    color: var(--dark-grey);
}

.art_price_head a, .art_price_body_link a {
    display: grid;
    justify-content: end;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.44;
    color: var(--dark-grey);
    width: max-content;
    margin-left: auto;
    white-space: pre;
}

.art_price_body_link a {
    justify-content: center;
    margin: 24px auto 0;
}

.art_price_head div:nth-child(2) {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(4, max-content);
    grid-gap: 40px;
}

.art_price_body_slider {
    position: relative;
    width: 100%;
}

.art_price_body_slider .art_price_body_card {
    max-width: 340px;
}

.art_price_body_slider .slick-prev {
    z-index: 50;
}

.art_price_body_slider_arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: max-content;
}

.art_price_body_slider_arrow_left {
    left: -2.5rem;
}

.art_price_body_slider_arrow_right {
    right: -2.5rem;
}

.art_price_body_slider .slick-list {
    margin: 0 -20px !important;
}

.art_price_body_slider .slick-slide > div {
    padding: 0 20px;
}

.landing_latest_article_wrapper {
    padding: 0 10%;
    margin: 70px 0 0;
    position: relative;
}

.landing_latest_article_wrapper > .title {
    display: grid;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 30px;
}

.landing_latest_article_wrapper > .title > a, .landing_latest_article_wrapper > .mobile_blog_collections {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.44;
    color: var(--dark-grey);
}

.landing_latest_article_wrapper > .mobile_blog_collections {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    text-align: center;
    align-items: center;
    grid-gap: 14px;
    width: max-content;
    width: -moz-max-content;
    margin: 0 auto;
}

.landing_latest_article_wrapper h2 {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.73;
    text-align: left;
    color: var(--dark-grey);
    margin: 0;
}

.landing_latest_article_wrapper > .side_text {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.28px;
    color: #dbddde;
    position: absolute;
    top: 50%;
    left: calc(5% - 38px);
    transform: rotate(-90deg) translateY(-50%);
    text-transform: uppercase;
}

.landing_latest_article_wrapper > .cards_section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.landing_latest_article_wrapper > .cards_section > .card_item {
    width: calc(25% - 23px);
    height: 240px;
    position: relative;
    margin: 0 0 40px;
}

.landing_latest_article_wrapper > .cards_section > .card_item img {
    max-width: 100%;
    max-height: 240px;
    height: 240px;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.landing_latest_article_wrapper > .cards_section > .card_item > .text_content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0 22px 20px;
    text-align: center;
    top: 0;
    background: linear-gradient(359.62deg, #232326 -36.06%, rgba(35, 35, 38, 0) 60%);
}

.landing_latest_article_wrapper > .cards_section > .card_item > .text_content h3 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.4;
    color: #ffffff;
    margin: 0 0 2px;
}

.landing_latest_article_wrapper > .cards_section > .card_item > .text_content span {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.86;
    color: #ffffff;
}

.landing_latest_article_wrapper > .cards_section > .card_item_bigger {
    width: calc(50% - 23px);
    height: 240px;
}

@media (max-width: 1200px) {
    .section_padding {
        padding: 0 7%;
    }

    .third_section_wrapper {
        padding-top: 72px;
    }

    .landing_latest_article_wrapper > .cards_section > .card_item {
        width: calc(50% - 20px);
    }

    .landing_latest_article_wrapper > .cards_section > .card_item:last-of-type, .landing_latest_article_wrapper > .cards_section > .card_item:nth-child(5) {
        margin: 0 0 50px;
    }

    .fourth_section_wrapper_about_img {
        position: absolute;
        left: -2rem;
    }

    .landing_latest_article_wrapper {
        padding: 0 7%;
    }

    .landing_latest_article_wrapper > .side_text {
        left: -1rem;
    }
}

@media (max-width: 1150px) {
    .first_section_wrapper {
        margin-top: 75px;
    }
}

@media (max-width: 1024px) {
    .photo_list {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem 4px;
    }

    .landing_latest_article_wrapper {
        padding: 0 5%;
    }

    .landing_latest_article_wrapper > .side_text {
        left: -2rem;
    }

    .second_section_wrapper > div {
        display: grid;
        justify-content: center;
        box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);

    }

    .photo img {
        /*width: unset;*/
        max-width: 320px;
        margin: 0 auto;
    }

    .photo {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0;
    }

    .second,
    .first,
    .fifth,
    .fourth,
    .third {
        margin: 0;
    }

    .section_padding {
        padding: 0 5%;
    }

    .third_section_wrapper {
        padding-top: 114px;
    }

    .first_section_wrapper {
        grid-gap: 1rem;
    }

    .fourth_section_wrapper_position {
        grid-template-columns: 1fr 1fr;
        grid-gap: 50px;
    }

    .art_price_head_tab {
        padding-bottom: 8px;
    }

    .video_subscribe p {
        margin-top: 1rem;
    }

    .art_price_body_slider_arrow_left {
        left: -2rem;
    }

    .art_price_body_slider_arrow_right {
        right: -2rem;
    }

    .left_footer {
        margin: 0 0 24px 16px;
    }
}

@media (max-width: 992px) {

    .section_padding {
        padding: 12px 1.5rem;
    }

    .landing_latest_article_wrapper {
        padding: 0 1.5rem;
    }

    .first_section_wrapper {
        margin-top: 60px;
        grid-template-columns: 50% 50%;
        align-items: center;
        padding-bottom: 2rem;
    }

    .art_object {
        margin-top: 0;
    }

    .discover_text {
        padding-bottom: 0;
    }

    .head_slider_navigation .slick-slider {
        margin-right: 0;
    }

    .discover_text h1 {
        font-size: 42px;
        margin-top: 40px;
    }

    .art_object > div {
        margin-bottom: 0;
    }

    .head_slider_navigation {
        width: 100%;
        justify-content: center;
        grid-template-columns: 100%;
    }

    .head_slider_navigation > div {
        margin: 0 auto;
    }

    .art_object img {
        max-width: 100%;
    }

    .subscribe_list_right {
        grid-template-columns: unset;
        grid-gap: 1rem 31px;
    }

    .switch_block {
        margin: 31px 0 0;
        align-items: center;
        grid-gap: 0 15px;
        justify-content: center;
        padding-bottom: 80px;
    }

    .wrapper_text img {
        margin: 0 auto;
    }

    .right h3 {
        margin-left: 0;
    }

    .wrapper_text h3 {
        margin-left: 0;
    }

    .third_section_wrapper {
        padding-top: 4rem;
    }

    .art_price_body_slider_arrow {
        top: 115%;
        transform: translateY(-115%);
    }

    .art_price_body_slider_arrow_right {
        right: -.5rem;
        padding: .5rem;
    }

    .art_price_body_slider_arrow_left {
        left: -.5rem;
        padding: .5rem;
    }

    .art_price_body_slider .slick-slide > div {
        padding: 0 15px;
    }

    .art_price_body_slider .slick-list {
        margin: 0 -15px !important;
    }

    .art_price_body_select .custom_dropdown_wrapper {
        width: max-content;
        margin: 0 auto;
    }

    .art_price_body_select .custom_dropdown_select > span {
        font-size: 13px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1.46;
        letter-spacing: 0.33px;
        color: var(--dark-grey);
    }

    .art_price_body_select {
        margin-bottom: 20px;
    }

    .fourth_section_wrapper_position {
        grid-gap: 2rem;
    }
}


@media (max-width: 800px) {
    .switch_block {
        grid-template-columns: 1fr max-content;
    }

    .switch_block > div:first-child {
        width: unset;
    }
}

@media (max-width: 768px) {
    .fourth_section_wrapper_position {
        grid-template-columns: 1fr;
        grid-gap: 1.5rem;
    }

    .landing_latest_article_wrapper > .cards_section > .card_item {
        margin: 0 0 24px;
    }

    .landing_latest_article_wrapper > .cards_section > .card_item {
        width: calc(50% - 12px);
    }

    .landing_latest_article_wrapper > .title {
        margin: 0 0 20px;
    }
}

@media (max-width: 568px) {
    .section_padding {
        padding: 0 1rem;
    }

    .art_price_body_slider_arrow_left {
        left: -.25rem;
    }

    .video_preview_landing {
        margin: 0;
        width: 100%;
        height: 200px;
    }

    .photo img {
        max-width: 100%;
    }

    .art_price_body_slider_arrow_right {
        right: .75rem;
    }

    .art_object img {
        margin: 0 auto;
    }

    .first_section_wrapper {
        margin-top: 60px;
        grid-template-columns: 100%;
        padding: 0 14px 2rem;
        grid-gap: 30px;
    }

    .discover_text {
        text-align: center;
    }

    .art_price_head h3 {
        font-size: 20px;
    }

    .discover_text h1 {
        font-size: 35px;
        line-height: 1.14;
        margin-top: 25px;
    }

    .discover_text p {
        margin-top: 15px;
    }

    .discover_text button {
        margin-top: 15px;
        padding: 10px 0;
        width: 169px;
    }

    .second_section_wrapper {
        grid-template-columns: 1fr;
        grid-gap: 25px;
    }

    .wrapper_text {
        text-align: left;
    }

    .empty_desc {
        margin-top: 0;
    }

    .subscribe_list_right {
        grid-gap: 20px 31px;
        margin-top: 20px;
    }

    .switch_block {
        grid-template-columns: auto;
        margin: 20px 0 0;
        grid-gap: 0 28px;
        padding-bottom: 30px;
    }

    .switch_block div:first-child {
        visibility: hidden;
    }

    .photo_list {
        grid-template-columns: 1fr;
        grid-gap: 2rem 0;
    }

    .third_section_wrapper {
        padding-top: 50px !important;
    }

    .painting_text {
        font-size: 30px;
    }

    .photo .category_filter {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        grid-gap: 12px;
        align-items: center;
        justify-content: center;
        width: max-content;
        margin: 17px auto 0;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.45;
        color: var(--dark-grey);
    }

    .photo a > img {
        min-height: unset;
    }

    .collection_switch {
        grid-template-columns: 1fr max-content;
        padding-bottom: 50px;
    }

    .collection_switch div {
        width: 100%;
    }

    .fourth_section_wrapper_position h3 {
        font-size: 30px;
        font-weight: bold;
        line-height: 1.5;
        color: var(--dark-grey);
    }

    .art_price_body_slider .art_price_body_card {
        margin: 0 auto;
    }

    .collection_switch {
        grid-template-columns: 1fr max-content;
    }

    .preview_block {
        background-color: #00a59e;
        transform: scale3d(0.985, 0.985, 0.985) translateX(0);
    }

    .preview_block .advantages_list > div > div {
        background-color: white;
    }

    .preview_block .switch_block svg path,
    .preview_block .subscribe_list_right svg path,
    .preview_block .wrapper_text svg path {
        fill: #fff;
    }

    .preview_block span,
    .preview_block p,
    .preview_block h3 {
        color: #fff;
    }
}

@media (max-width: 568px) {
    .landing_latest_article_wrapper > .cards_section > .card_item {
        width: 100%;
        margin: 0 0 20px;
    }

    .landing_latest_article_wrapper {
        padding: 0 1rem;
    }

    .landing_latest_article_wrapper > .cards_section > .card_item:last-of-type, .landing_latest_article_wrapper > .cards_section > .card_item:nth-child(5) {
        margin: 0 0 20px;
    }

    .landing_latest_article_wrapper > .cards_section > .card_item:last-of-type {
        margin: 0 0 30px;
    }

    .art_price_body_slider .slick-slide > div {
        padding: 0 10px;
    }
}

@media (max-width: 400px) {
    .photo > img {
        width: 280px;
    }
}
