.new_artists {
    padding: 3rem 10% 0;
}

.new_artists h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #000
}

.new_artists .section_preview {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.new_artists .section_preview__text {
    padding: 36px 56px;
    display: grid;
    align-content: center;
    background: #000;
    text-align: left;
}

.new_artists .section_preview__text span {
    font-weight: 600;
    text-transform: capitalize;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.02em;
    color: #FFF
}

.new_artists .section_preview__img {
    width: 100%;
}

.new_artists .section_preview__img img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.new_artists .section_filter {
    margin: 3rem 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 1.5rem;
    align-items: center;
}

.new_artists .section_filter .listing_search {
    min-width: 300px;
}

.new_artists .section_filter .every_filter {
    min-width: 190px;
}

.new_artists .artists_list {
    display: grid;
    margin-bottom: 2rem;
}

.new_artists .artists_list .every_artist {
    padding: 32px 0;
    border-style: solid;
    border-width: 2px 0 0;
    border-color: #000;
    display: grid;
    grid-gap: 32px;
    grid-template-columns: max-content max-content 1fr;
}

.new_artists .artists_list .every_artist .gallery_wrapper a,
.new_artists .artists_list .every_artist .avatar_wrapper {
    width: 135px;
    height: 135px;
}

.new_artists .artists_list .every_artist .gallery_wrapper img,
.new_artists .artists_list .every_artist .avatar_wrapper img {
    height: inherit;
    width: inherit;
    object-fit: cover;
}

.new_artists .artists_list .every_artist .avatar_wrapper img {
    border-radius: 50%;
}

.new_artists .artists_list .every_artist .info_wrapper {
    display: grid;
    grid-gap: 8px;
}


.new_artists .artists_list .every_artist .info_wrapper .followers,
.new_artists .artists_list .every_artist .info_wrapper .cv_entries {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000
}

.new_artists .artists_list .every_artist .info_wrapper a {
    font-weight: 500;
    font-size: 16px;
    text-decoration-line: underline;
    color: #000
}

.new_artists .artists_list .every_artist .info_wrapper .name {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-decoration-line: unset;
}


.new_artists .artists_list .every_artist .gallery_wrapper {
    justify-self: end;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 32px;
}

.artist-name-container {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 10px;
    align-items: center;
}

.portfolio-name-container-svg {
    margin-top: 46px;
}

@media (max-width: 1300px) {
    .new_artists .artists_list .every_artist,
    .new_artists .artists_list .every_artist .gallery_wrapper {
        grid-gap: 24px;
    }
}


@media (max-width: 1200px) {
    .new_artists {
        padding: 3rem 7.5% 0;
    }
}

@media (max-width: 992px) {
    .new_artists {
        padding: 2rem 5% 0;
    }

    .new_artists .section_filter .listing_search {
        min-width: 220px;
    }

    .new_artists .section_filter .every_filter {
        min-width: 130px;
    }
}

@media (max-width: 768px) {
    .new_artists {
        padding: 2rem 5% 0;
    }

    .new_artists .section_preview__text span,
    .new_artists h1 {
        font-size: 24px;
        line-height: 34px;
    }

    .new_artists .section_preview__text {
        padding: 20px;
    }

    .new_artists .section_preview {
        grid-template-columns: 1fr;
        margin-top: 2rem;
    }

    .new_artists .section_preview__img {
        height: 170px;
    }

    .new_artists .section_filter .listing_search,
    .new_artists .section_filter .every_filter {
        min-width: unset;
    }

    .new_artists .artists_list .every_artist {
        grid-template-columns: 1fr;
    }

    .new_artists .artists_list .every_artist .avatar_wrapper {
        width: 199px;
        height: 199px;
        justify-self: center;
    }

    .new_artists .artists_list .every_artist .info_wrapper {
        justify-self: center;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        grid-gap: 8px 24px;
    }

    .new_artists .artists_list .every_artist .info_wrapper a,
    .new_artists .artists_list .every_artist .info_wrapper .name {
        grid-column: 1/-1;
        text-align: center;
    }

    .new_artists .artists_list .every_artist .gallery_wrapper {
        justify-self: center;
    }

    .new_artists .artists_list .every_artist .gallery_wrapper {
        justify-self: center;
        overflow: auto;
        width: 100%;
    }
}

@media (max-width: 650px) {
    .new_artists .section_filter {
        grid-template-columns: 1fr;
        grid-gap: 24px;
        margin-top: 2rem;
    }

}

