.profile_support_wrapper {
    max-width: 726px;
    width: 100%;
    margin: 3.5rem auto 120px;
}

.profile_support_wrapper h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    margin: 0 0 1.5rem;
}

.profile_support_wrapper p.description {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
    margin: 0 0 .5rem;
}

.profile_support_wrapper .need_help {
    width: 50%;
    margin: 0 0 2.5rem;
}

@media (max-width: 568px) {
    .profile_support_wrapper .need_help {
        width: 100%;
        margin: 0 0 1.5rem;
    }

    .profile_support_wrapper p.description {
        margin: 0 0 1.5rem;
    }

    .profile_support_wrapper h2 {
         margin: 0 0 20px;
     }
}