.admin_artworks_content_wrapper {
    padding: 2rem 4rem;
}

.admin_artworks_content_wrapper .wrapper_table {
    overflow: scroll;
}

.admin_artworks_content_wrapper .wrapper_page_head_preview {
    display: grid;
    grid-template-columns: max-content 1fr;
}

.admin_artworks_content_wrapper .verified_content {
    justify-self: end;
    display: grid;
    grid-template-columns: max-content max-content max-content;
    height: max-content;
    align-self: center;
}


.admin_artworks_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem 20px;
    align-items: center;
}

.admin_artworks_container_card {
    border: 1px solid #EFEFF4;
    border-radius: 5px;
    height: 100%;
}

.admin_artworks_container_card img {
    max-width: 100%;
    width: 100%;
    height: 205px;
    object-fit: contain;
}

.admin_artworks_container_card > .image {
    display: grid;
    align-items: center;
}

.admin_artworks_container_card > .content {
    display: grid;
    align-items: center;
    padding: 8px 1rem 1rem 1rem;
    background: #F8F8FB;
}

.admin_artworks_container_card > .content h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #060606;
    margin: 0 0 2px;
}

.admin_artworks_container_card > .content h6 {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    margin: 0;
    color: #F2994A;
}

.admin_artworks_container_card > .content p {
    font-size: 12px;
    line-height: 16px;
    color: #656565;
    margin: 0;
}

.admin_artworks_container_card > .content h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 155%;
    color: #060606;
    margin: 0;
}

.admin_artworks_container_card > .content .price {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    margin: 0 0 2px;
}

.admin_artworks_container_card > .content .category {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 8px;
    align-items: center;
}

.admin_artworks_container_card > .content .category span {
    font-size: 10px;
    line-height: 12px;
    color: #656565;
}

.admin_content_wrapper_institutions.admin_artworks_content_wrapper {
    padding: 0 4rem;
}

.admin_content_wrapper_institutions.admin_artworks_content_wrapper .admin_artworks_head .wrapper_list_filters {
    grid-template-columns: max-content;
}

.admin_content_wrapper_institutions .category_wrapper > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
}

.admin_artworks_content_wrapper .admin_artworks_head {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 40px;
    margin-bottom: 1.5rem;
    align-items: center;
}

.admin_artworks_content_wrapper .admin_artworks_orders_head {
    display: grid;
    grid-template-columns: max-content max-content 1fr;
    grid-gap: 40px;
    margin-bottom: 1.5rem;
    align-items: center;
}

.admin_artworks_content_wrapper .admin_artworks_head .wrapper_list_filters {
    display: grid;
    grid-template-columns: repeat(5, max-content);
    grid-gap: 32px;
    justify-content: end;
}

.admin_artworks_content_wrapper .admin_artworks_head .wrapper_list_filters .every_filter {
    position: relative;
}

.admin_artworks_content_wrapper .admin_artworks_head .wrapper_list_filters .every_filter > div {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 0 8px;
    height: max-content;
    cursor: pointer;
    align-items: center;
}

.admin_artworks_content_wrapper .admin_artworks_head .wrapper_list_filters .every_filter__popup {
    position: absolute;
    width: 256px;
    padding: 0;
    max-height: 193px;
    top: 28px;
    right: 0;
    border: solid 1px var(--lite-grey);
    background: #fff;
    z-index: 100;
    overflow-x: auto;
}

.admin_artworks_content_wrapper .admin_artworks_head .wrapper_list_filters .price .every_filter__popup {
    height: 150px;
    display: grid;
    justify-content: center;
    align-content: center;
    grid-template-columns: 1fr;
    max-width: 250px;
    padding: 0 20px 0;
    overflow-x: hidden;
}

.admin_artworks_content_wrapper .admin_artworks_head .wrapper_list_filters .input_range {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-gap: 12px;
    align-items: center;
    border: none;
}

.admin_artworks_content_wrapper .admin_artworks_head .wrapper_list_filters .input_range .input_component > span {
    color: var(--grey);
    font-size: 12px;
    line-height: 100%;
}

.admin_artworks_content_wrapper .admin_artworks_head .wrapper_list_filters div:last-child {
    border-bottom: none;
}

.admin_artworks_content_wrapper .admin_artworks_head .wrapper_list_filters .every_filter__popup > div {
    border-bottom: 1px solid var(--lite-grey);
    padding: 12px 8px;
    cursor: pointer;
}

.admin_artworks_content_wrapper .admin_artworks_head .wrapper_list_filters .every_filter__popup > div:last-child {
    border-bottom: none;
}

.admin_artworks_content_wrapper .admin_artworks_head .wrapper_list_filters .every_filter__popup > div > span {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: .48px;
    color: var(--dark-grey);
}

.admin_artworks_content_wrapper:not(.admin_content_wrapper_institutions) .admin_artworks_head .wrapper_list_filters .every_filter__popup > div > span:last-child {
    color: var(--lite-grey);
}

.admin_artworks_content_wrapper .admin_artworks_head .wrapper_list_filters .sort_by .every_filter__popup > div > span {
    color: var(--dark-grey);
}

.admin_artworks_content_wrapper .admin_artworks_head .wrapper_search_input {
    position: relative;
    width: max-content;
    display: block;
}

.admin_artworks_content_wrapper .admin_artworks_head .wrapper_search_input input {
    border: 1px solid #333333;
    border-radius: 7px;
    line-height: 16px;
}

.download-table-list-of-works {
    width: 100%;
    border: 1px solid #000000;
    border-radius: 6px;
    height: 32px;
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 0 15px;
    align-items: center;
    justify-content: center;
}

.nfts-img-info-container {
    display: grid;
    align-items: center;
    padding: 12px 16px;
    background: #F8F8FB;
    color: #000000;
}

.nfts-img-info-container h4 {
    max-width: 240px;
    overflow: hidden;
}

.nfts-author_name {
    font-size: 16px;
    font-weight: 600;
}

.nfts-title-and-year {
    font-size: 14px;
    font-weight: 300;
}

.nfts-price {
    font-size: 20px;
    font-weight: 400;
}

.wrapper-list-filters-nft {
    display: grid;
    grid-template-columns: repeat(2, max-content) !important;
}

.nft-orders-filter-container-content {
    position: relative;
}

.nft-orders-filter-container {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 0 8px;
    align-items: center;
    cursor: pointer;
}

.nft-orders-filter-items div {
    border-bottom: 1px solid var(--lite-grey);
    padding: 12px 8px;
    cursor: pointer;
}

.nft-orders-filter-items {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    width: 256px;
    border: solid 1px var(--lite-grey);
    right: 0;
    max-height: 193px;
    overflow-x: auto;
}

.chosen-order-nft {
    padding: 5px 5px 4px 20px;
    border-radius: 100px;
    background-color: var(--new-black);
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: #ffffff;
    width: max-content;
    width: -moz-max-content;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 20px;
    align-items: center;
    margin: 0 1rem 1rem 0;
}

@media screen and (max-width: 1300px) {
    .admin_artworks_content_wrapper .admin_artworks_head .wrapper_search_input .wrapper_list_filters {
        grid-gap: 12px;
    }

    .admin_artworks_content_wrapper .admin_artworks_head {
        grid-gap: 20px;
    }

    .admin_artworks_content_wrapper .admin_artworks_head .wrapper_list_filters {
        grid-gap: 16px;
    }

    .admin_artworks_content_wrapper .admin_artworks_head .wrapper_search_input input {
        max-width: 140px;
    }
}
