.wrapper_admin_panel_navigation {
    display: grid;
    justify-content: space-between;
    background: #FFFFFF;
    position: fixed;
    height: var(--app-height);
    left: 0;
    top: 0;
    grid-template-rows: max-content 1fr;
    bottom: 0;
    grid-template-columns: 1fr;
    width: 256px;
}

.wrapper_admin_panel_log_out {
    display: grid;
    align-content: end;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    cursor: pointer;
    bottom: 40px;
    left: 2rem;
    margin: calc(0.75rem + 20px) 15%;
    align-items: center;
}

.wrapper_admin_panel_navigation_logo {
    display: block;
    margin: 4.5rem auto;
}

.wrapper_admin_panel_log_out span {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
}

.wrapper_admin_panel_navigation_content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    padding-left: 2rem;
}

.wrapper_admin_panel_navigation_content a {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: .5rem;
    width: max-content;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    opacity: .5;
    transition: .3s all;
}

.wrapper_admin_panel_navigation_padding {
    padding: .75rem 0;
    transition: .3s all;
    border-right: 4px solid #fff;
}

.wrapper_admin_panel_navigation_padding_active {
    border-right: 4px solid #000;
}

.wrapper_admin_panel_navigation_content g, .wrapper_admin_panel_navigation_content svg, .wrapper_admin_panel_navigation_content path {
    transition: .3s all;
}

.wrapper_admin_panel_navigation_content .admin_bar_active_link g, .wrapper_admin_panel_navigation_content .admin_bar_active_link {
    opacity: 1 !important;
}

.wrapper_admin_panel_content {
    margin-left: 256px;
    min-height: var(--app-height);
    background: #F8F8FB;
    padding: 40px 5rem 40px 4.5rem;
}

.admin_content_wrapper h1 {
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.374px;
    color: #333333;
    margin: 0 0 1.5rem;
}

.admin_wrapper_modal h4 {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
    margin: 0;
}

.admin_wrapper_modal p {
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin: 0 0 2rem;
}

.admin_wrapper_modal_line {
    width: 100%;
    height: 1px;
    background: #CECECE;
    margin: 1rem 0;
}

.admin_wrapper_modal img {
    position: absolute;
    top: 2rem;
    cursor: pointer;
    right: 2rem;
}

.admin_wrapper_modal textarea::placeholder {
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    opacity: 0.5;
}

.admin_wrapper_modal textarea {
    resize: none;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    display: flex;
    border-radius: 7px;
    padding: .5rem 1rem;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    cursor: text;
}

.admin_wrapper_modal button {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    background: #000000;
    border-radius: 4px;
    padding: 7px 34px;
    width: max-content;
    margin: 2rem 0 0 auto;
    display: block;
}

.admin_wrapper_modal_reason {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
}

.admin_wrapper_modal_reason h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.admin_wrapper_modal_reason span {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.admin_pagination {
    margin: 3rem 0 0;
    padding: 1rem 0;
}