.form_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
}

.input_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 550px;
    width: 100%;
}

.input_title {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: rgba(0, 0, 0, .5);
    margin: 0 0 1rem;
}

.input_style {
    border: none;
    border-bottom: 1px solid #000000;
    padding: 0 16px 4px 8px;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
    width: 100%;
    background: transparent;
    cursor: auto;
}

.form_title {
    font-size: 32px;
    font-weight: 600;
}

.send_button {
    border: 2px solid #000000;
    width: 111px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    transition: .4s;
}

.send_button span {
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.send_button:hover {
    background-color: black;
}

.send_button span:hover {
    color: #FFFFFF;
}

.error_message {
    color: red;
}

.error_container {
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr;
    grid-gap: 12px;
    margin-top: 12px;
}

.error_style {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--new-error);
    margin: 0;
}
