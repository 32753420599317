.wrapper {
  margin-top: 50px;

  .title {
    font-weight: 600;
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 50px;
  }

  .errors {
    margin-top: 25px;

    & > * {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .apple_button {
    background: black;
    border: 1px solid black;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px 0;

    span {
      margin: 4px 0 0 13px;
    }
  }

  .form {
    .form_input {
      margin-bottom: 35px;
    }

    .form_row_2 {
      display: flex;
      margin: 0 -11px;

      .form_col {
        width: 50%;
        padding: 0 11px;
      }
    }
    .investor_registration_button_container {
      display: grid;
      grid-template-columns: 1fr 1fr max-content;
      grid-gap: 20px;
    }

    .form_footer {
      display: flex;
      flex-direction: column;
      margin-top: 54px;

      .footer_social {
        margin: 15px 0 20px;
      }

      .footer_btns {
        //button:last-child {
        //    display: none;
        //}
        @media (max-width: 500px) {
          button:last-child {
            width: 100%;
          }
        }
      }

      .footer_reg_btn_sm {
        margin: auto;
      }
    }
  }

  @media (max-width: 500px) {
    .investor_registration_button_container {
      display: grid;
      grid-template-columns: 1fr!important;
      grid-gap: 20px;
    }
  }

  @media screen and (min-width: 980px) {

    .errors {
      display: none;
    }

    .form {
      .form_row_2 {
        margin: 0 -20px;

        .form_col {
          width: 50%;
          padding: 0 20px;
        }
      }

      .form_input {
        margin-bottom: 30px;
      }
    }

    .form_footer {
      display: flex;
      flex-direction: row-reverse !important;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 54px !important;

      .footer_btns {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        button:last-child {
          margin-top: 25px;
          display: inline-block !important;
        }
      }

      .footer_reg_btn_sm {
        display: none;
      }
    }
  }
  @media screen and (min-width: 980px) and (max-height: 900px) {
    margin-top: 70px;
    .title {
      font-size: 36px;
      line-height: 1.5;
    }
  }
  @media screen and (min-width: 980px) and (max-height: 790px) {
    margin-top: 50px;
    .title {
      font-size: 36px;
      line-height: 1.5;
    }
  }
}
