.stay_in_loop_wrapper {
    background: url("../../assets/img/new_landing/stay_in_loop_bg.png") no-repeat bottom;
    background-size: cover;
}

.stay_in_loop_bg {
    background: rgba(0, 0, 0, 0.6);
    padding: 6.5rem 10%;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: end;
}

.stay_in_loop_wrapper h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 45px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.stay_in_loop_wrapper p {
    font-size: 16px;
    line-height: 45px;
    color: #FFFFFF;
    margin: 0 0 12px;
}

.stay_in_loop_wrapper .successful_email span {
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    background: rgba(246, 246, 241, 0.1);
    margin: 0 0 12px;
    display: block;
    width: max-content;
    padding: 10px 9px;
}

.stay_in_loop_wrapper form {
    max-width: 500px;
}

.stay_in_loop_wrapper label {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    position: relative;
}

.stay_in_loop_wrapper label > span {
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: var(--error);
    background: none;
    padding: 0;
}

.stay_in_loop_wrapper label button {
    display: grid;
    align-items: center;
}

.stay_in_loop_wrapper a {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-decoration-line: underline;
    color: #FFFFFF;
}

.stay_in_loop_wrapper label input::placeholder {
    font-weight: 300;
    font-size: 16px;
    line-height: 45px;
    color: #FFFFFF;
    opacity: 0.6;
}

.stay_in_loop_wrapper label input {
    border: 1px solid #FFFFFF;
    background: transparent;
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    color: #FFFFFF;
    height: 40px;
    padding: 8px 15px;
    transition: .3s all;
}

@media (max-width: 1200px) {
    .stay_in_loop_bg {
        padding: 6.5rem 7.5%;
    }
}

@media (max-width: 992px) {
    .stay_in_loop_bg {
        padding: 4.5rem 5% 3rem;
        background: rgba(0, 0, 0, 0.7);
        grid-template-columns: 1fr;
        grid-gap: 3.25rem;
    }

    .stay_in_loop_wrapper form {
        max-width: 400px;
    }
}

@media (max-width: 568px) {
    .stay_in_loop_wrapper label input {
        width: 100%;
    }

    .stay_in_loop_wrapper p, .stay_in_loop_wrapper .successful_email span {
        font-size: 14px;
    }
}

@media (max-width: 325px) {
    .stay_in_loop_wrapper a {
        font-size: 18px;
        line-height: 28px;
    }

    .stay_in_loop_wrapper p, .stay_in_loop_wrapper .successful_email span {
        font-size: 12px;
    }
}