.top_banner_wrapper {
    grid-gap: 0;
    align-items: center;
    background: #000;
    display: grid;
    grid-template-columns: auto 1fr auto;
    height: 78px;
    gap: 24px;
    justify-content: space-between;
    left: 0;
    padding: 0 10%;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 3499;
}

.top_banner_wrapper .brush_wrapper {
    height: 78px;
}

.top_banner_wrapper .title {
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
}

.top_banner_wrapper .top_banner_button_wrapper {
    display: flex;
    flex: 1;
}

@media (max-width: 1160px) {
    .top_banner_wrapper {
        grid-template-columns: 1fr auto;
    }
    .top_banner_wrapper .brush_wrapper {
       display: none;
    }
}

@media (max-width: 992px) {
    .top_banner_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 10%;
        grid-template-columns: 1fr;
        height: auto;
        margin-top: 15px;
    }
}

@media (max-width: 650px) {
    .top_banner_wrapper {
        padding: 20px 24px;
    }
    .top_banner_wrapper .top_banner_button_wrapper {
        width: 100%;
    }
}