.wrapper_popup {
    position: fixed;
    z-index: 2510;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: grid;
    background: rgba(0, 0, 0, .63);
    justify-content: center;
    align-content: center;
}

.wrapper_popup .popup {
    background: #fff;
    display: grid;
    grid-gap: 20px 0;
    position: relative;
    padding: 40px 48px;
    max-width: 400px;
}

.wrapper_popup_to_top {
    align-content: start;
}

.wrapper_popup_to_top .popup {
    margin-top: 112px;
}

.wrapper_popup_to_top .popup .wrapper_title {
    text-align: center;
}

.wrapper_popup_to_top .popup .wrapper_title span {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.45;
    color: var(--dark-grey);
}

.wrapper_popup_to_top .popup .wrapper_description span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.63;
    color: var(--slate);
}

.wrapper_popup .popup .wrapper_close_img {
    position: absolute;
    right: 20px;
    cursor: pointer;
    top: 20px;
}
