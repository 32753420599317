.upload_work_wrapper {
  max-width: 726px;
  width: 100%;
  margin: 4rem auto;
}

.upload_work_wrapper > .artwork_hidden {
  background: #000000;
  padding: 8px 0;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 0 2rem;
}

.upload_work_wrapper > .artwork_hidden span {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
}

.upload_work_wrapper > .title_wrap {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 8px;
  align-items: center;
  margin: 0 0 8px;
}

.upload_work_wrapper > .title_wrap h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.tooltip_wrap {
  position: relative;
  display: grid;
  align-items: center;
}

.tooltip_wrap .tooltip_content {
  position: absolute;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding: 0 20px;
  top: -80px;
  opacity: 0;
  right: 0;
  transition: 0.3s;
  z-index: -1;
  width: 194px;
}

.tooltip_wrap .tooltip_content a {
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.015em;
  color: #f7f6f1;
  transition: 0.3s;
}

.tooltip_wrap .tooltip_content a:hover {
  text-decoration-line: underline;
}

.tooltip_wrap svg:hover + .tooltip_content,
.upload_work_wrapper > .title_wrap .tooltip_wrap:hover .tooltip_content {
  opacity: 1;
  pointer-events: auto;
  z-index: 10;
}

.upload_work_wrapper > .subtitle {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.015em;
  color: #000000;
  margin: 0 0 1rem;
}

.upload_work_wrapper > .title_wrap .tooltip_wrap_active .tooltip_content {
  opacity: 1;
  pointer-events: auto;
  z-index: 10;
}

.upload_work_wrapper > .basic_info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 26px 35px;
  align-items: start;
  margin: 0 0 2rem;
}

.upload_nft_work_options_container {
  display: flex;
  flex-direction: column;
}

.other-style-container {
  display: flex;
  flex-direction: column;
}

.upload_work_wrapper .upload_work_options_container {
  max-width: unset;
  margin: 20px 0 0;
}

.upload_work_wrapper .upload_work_options_container > .categories_options {
  background: #000000;
  border-radius: 32px;
  padding: 4px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  grid-gap: 8px;
  margin: 0 8px 12px 0;
}

.upload_work_wrapper > .resize_description {
  margin: 0 0 3rem;
}

.upload_work_wrapper > .price_calculator_wrapper h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin: 0 0 1rem;
}

.upload_photos_wrapper {
  display: grid;
  grid-template-columns: 1fr 120px;
  grid-gap: 20px;
  align-items: start;
  margin: 0 0 2rem;
  width: 100%;
}

.upload_photos_wrapper .photo_wrapper {
  display: grid;
  align-items: center;
  cursor: pointer;
}

.upload_photos_wrapper .dropzone img {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 331px;
  object-fit: cover;
}

.upload_photos_wrapper .small_previews {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.upload_photos_wrapper .small_previews img {
  height: 68px;
}

.upload_photos_wrapper .photo_wrapper .photo_content {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  background: #000000;
  height: 68px;
}

.upload_photos_wrapper .photo_wrapper .photo_content_big {
  height: 331px;
}

.upload_photos_wrapper .photo_wrapper .no_preview_photo {
  display: grid;
  justify-content: center;
  align-items: center;
  background: #000000;
}

.upload_photos_wrapper .photo_wrapper .no_preview_photo svg {
  margin: 0 auto;
}

.upload_photos_wrapper .photo_wrapper .no_preview_photo p {
  margin: 1.5rem 0 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.upload_photos_wrapper .photo_wrapper .no_preview_photo span {
  font-weight: 500;
}

.upload_work_wrapper .price_calculator_wrapper .measures_wrapper,
.upload_work_wrapper .price_calculator_wrapper .materials_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  align-items: center;
}

.upload_work_wrapper .price_calculator_wrapper .upload_work_params {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}

.upload_work_wrapper
  .price_calculator_wrapper
  .upload_work_params
  .upload_work_params__info {
  display: grid;
  grid-template-columns: max-content 32px;
  grid-gap: 36px;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 36px;
}

.upload_work_wrapper
  .price_calculator_wrapper
  .upload_work_params
  .upload_work_params__info
  .input_component
  .border_bottom_input {
  border-bottom: 2px solid #000000;
  padding: 0;
  text-align: center;
  width: 32px;
}

.upload_work_wrapper
  .price_calculator_wrapper
  .upload_work_params
  .upload_work_params__info
  > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  color: #000000;
}

.upload_work_wrapper .price_calculator_wrapper .price_calculator_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 116px;
  align-items: start;
  margin: 0 0 1.5rem;
}

.upload_work_wrapper .price_calculator_wrapper .materials_wrapper {
  grid-gap: 32px;
}

.upload_work_wrapper .price_calculator_wrapper .final_price_wrapper {
  background: var(--beige);
  text-align: center;
  padding: 15px 8% 20px;
  margin: 0 0 4rem;
}

.upload_work_wrapper .price_calculator_wrapper .final_price_wrapper h6 {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.015em;
  color: #000000;
}

.upload_work_wrapper .price_calculator_wrapper .final_price_wrapper h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.upload_work_wrapper
  .price_calculator_wrapper
  .final_price_wrapper
  .mobile_price {
  margin: 0 0 18px;
}

.upload_work_wrapper .price_calculator_wrapper .final_price_wrapper p {
  font-weight: 300;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.015em;
  color: rgba(0, 0, 0, 0.5);
}

.upload_work_wrapper
  .price_calculator_wrapper
  .final_price_wrapper
  .final_price_input {
  display: grid;
  align-items: center;
  justify-content: center;
  margin: 0 0 18px;
}

.upload_work_wrapper .optional_info_wrapper > .description_text {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.015em;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 0 8px;
}

.upload_work_wrapper .optional_info_wrapper > .update_cv {
  font-weight: 500;
  font-size: 16px;
  line-height: 45px;
  color: #000000;
  display: block;
  width: max-content;
  margin: 0 0 2.5rem;
}

.upload_work_wrapper .optional_info_wrapper > .update_cv:hover {
  text-decoration-line: underline;
}

.upload_work_wrapper .optional_info_wrapper > .optional_info_content h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin: 0 0 8px;
}

.upload_work_wrapper .optional_info_wrapper > .optional_info_content > p {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.015em;
  color: #000000;
  margin: 0 0 16px;
}

.upload_work_wrapper
  .optional_info_wrapper
  > .optional_info_content
  > .optional_packing {
  display: grid;
  grid-template-columns: 1fr;
  width: max-content;
  grid-gap: 8px;
  align-items: center;
  margin: 0 0 2.5rem 1rem;
}

.upload_work_wrapper
  .optional_info_wrapper
  > .optional_info_content
  > .exhibitions_publications_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  grid-gap: 50px;
  align-items: center;
}

.end-upload-work-line-nft {
  margin: 2rem 0 2.5rem;
}

.upload_work_wrapper .end_upload_work_line {
  height: 2px;
  width: 100%;
  background: #000000;
  margin: 4rem 0 2.5rem;
}

.upload_work_wrapper .terms_of_service {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}

/* .upload_work_wrapper .terms_of_service {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 8px;
  align-items: center;
  margin: 0 0 2rem;
  justify-content: start;
} */

.upload_work_wrapper .apply_cancel {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;
}

.upload_work_wrapper .apply_cancel > span {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.015em;
  color: #000000;
  margin-top: 12px;
  grid-column: 2;
}

.upload_work_wrapper .all_fields_error {
  display: grid;
  grid-template-columns: max-content;
  justify-content: end;
  grid-column: 1/-1;
  align-items: center;
}

.upload_work_wrapper .authenticity_certificate_wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 3550;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.upload_work_wrapper
  .authenticity_certificate_wrapper
  .authenticity_certificate_content {
  position: absolute;
  max-width: 738px;
  width: 100%;
  padding: 1rem 1.5rem 2.75rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--beige);
  max-height: 650px;
  overflow-y: auto;
}

.upload_work_wrapper
  .authenticity_certificate_wrapper
  .authenticity_certificate_content
  .title {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #000000;
  padding: 0 0 1rem;
  margin: 0 0 2rem;
}

.upload_work_wrapper
  .authenticity_certificate_wrapper
  .authenticity_certificate_content
  .title
  h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 45px;
  color: #000000;
}

.upload_work_wrapper
  .authenticity_certificate_wrapper
  .authenticity_certificate_content
  .title
  svg {
  cursor: pointer;
}

.upload_work_wrapper
  .authenticity_certificate_wrapper
  .authenticity_certificate_content
  .basic_info {
  display: grid;
  grid-template-columns: 373px 1fr;
  grid-gap: 1.5rem;
  align-items: center;
  margin: 0 0 1.5rem;
  width: 100%;
}

.upload_work_wrapper
  .authenticity_certificate_wrapper
  .authenticity_certificate_content
  .basic_info
  .images
  img {
  max-width: 100%;
  max-height: 100%;
  width: 373px;
  height: 208px;
  object-fit: cover;
  object-position: center;
}

.upload_work_wrapper
  .authenticity_certificate_wrapper
  .authenticity_certificate_content
  .basic_info
  .about {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  width: max-content;
  grid-gap: 0 1.5rem;
}

.upload_work_wrapper
  .authenticity_certificate_wrapper
  .authenticity_certificate_content
  .basic_info
  .about
  h5 {
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.015em;
  color: #000000;
}

.upload_work_wrapper
  .authenticity_certificate_wrapper
  .authenticity_certificate_content
  .basic_info
  .about
  span {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.015em;
  color: #000000;
}

.upload_work_wrapper
  .authenticity_certificate_wrapper
  .authenticity_certificate_content
  .texts {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  align-items: center;
  margin: 0 0 1.5rem;
}

.upload_work_wrapper
  .authenticity_certificate_wrapper
  .authenticity_certificate_content
  .texts
  p {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.015em;
  color: #000000;
}

.upload_work_wrapper
  .authenticity_certificate_wrapper
  .authenticity_certificate_content
  .agree_terms {
  margin: 0 0 2.75rem 1rem;
  width: 60%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  align-items: center;
}

.upload_work_wrapper
  .authenticity_certificate_wrapper
  .authenticity_certificate_content
  .agree_terms
  .wrapper_check_box
  label
  > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.015em;
  color: #000000;
}

.advanced-settings-container {
  display: grid;
  grid-template-columns: 1fr 40px;
  grid-gap: 12px;
  align-items: center;
  cursor: pointer;
  margin-top: 25px;
}

.advanced-settings-expanded,
.advanced-settings-container {
  background-color: black;
  padding: 0 24px;
}

.advanced-settings-container h2 {
  margin-right: 10px;
  color: white;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.015em;
}

.advanced-settings-container .square {
  border: 2px solid white;
  background-color: black;
}

.advanced-settings-container .square > div {
  background-color: white;
}

.advanced-settings-expanded
  .custom_dropdown_wrapper
  label.custom_dropdown_border
  > input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.advanced-settings-expanded .input_component span.input_component_title_new {
  color: white;
}

.advanced-settings-expanded .button_default {
  border-color: white;
  color: white;
  margin-bottom: 38px;
}

.advanced-settings-expanded
  .custom_dropdown_wrapper
  label.custom_dropdown_border
  > input {
  color: white;
  background-color: black;
}

.advanced-settings-expanded .input_component .border_bottom_input,
.advanced-settings-expanded .custom_dropdown_border,
.advanced-settings-expanded label.custom_dropdown_border {
  border-bottom: 1px solid white;
}

.advanced-settings-expanded .input_component .border_bottom_input {
  color: white;
  text-align: justify;
}

.advanced-settings-expanded .file_previews .uploaded_files_wrapper a,
.advanced-settings-expanded .new_custom_dropdown_wrapper_title {
  color: rgba(255, 255, 255, 0.4);
}

.advanced-settings-expanded .custom_dropdown_wrapper path {
  fill: white;
}

.advanced-settings-expanded .file_previews .uploaded_files_wrapper a path {
  fill: white;
  stroke: white;
}

.advanced-settings-expanded .new_dropdown_wrapper_search_disabled path {
  fill: rgba(255, 255, 255, 0.3);
}

.advanced-settings-expanded {
  max-height: 0;
  overflow: hidden;
  transition: 0.5s max-height;
}

.advanced-settings-expanded.active-expand {
  max-height: 750px;
}

.advanced-settings-expanded
  .upload_work_options_container
  > .categories_options {
  border: 1px solid white;
}

.nfc-radio-button-margin .checkbox_wrapper:first-child {
  margin-bottom: 10px;
}

.create-nfc-price-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;
}

.title-nft-container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  margin: 0 0 2rem;
}

.upload_photos_nft_wrapper {
  display: grid;
  grid-template-columns: 1fr;
}

.advanced-settings-nft-styles {
  width: 50%;
  margin-bottom: 26px;
}

.advanced-settings-title {
  margin: 25px 0 32px 0;
}

.upload-display-nft-image-title {
  margin: 24px 0 16px 0;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 300;
}

.subtitle-text-file-upload {
  font-size: 14px;
  font-weight: 300;
}

.wrong_ethereum_address_container {
  display: grid;
  margin: 1rem 0 0;
  grid-template-columns: max-content 1fr;
  grid-gap: 12px;
  align-items: center;
  color: #ff4949;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

@media (max-width: 768px) {
  .upload_work_wrapper .price_calculator_wrapper .price_calculator_content {
    grid-gap: 48px;
  }

  .upload_work_wrapper
    .authenticity_certificate_wrapper
    .authenticity_certificate_content {
    max-width: 100%;
    width: calc(100% - 2rem);
    padding: 1rem 1rem 3rem;
    max-height: 550px;
  }

  .upload_work_wrapper
    .authenticity_certificate_wrapper
    .authenticity_certificate_content
    .basic_info {
    grid-template-columns: 100%;
  }

  .upload_work_wrapper
    .authenticity_certificate_wrapper
    .authenticity_certificate_content
    .basic_info
    .images
    img {
    width: 100%;
  }

  .upload_work_wrapper
    .authenticity_certificate_wrapper
    .authenticity_certificate_content
    .texts {
    grid-gap: 1.5rem;
    margin: 0 0 2rem;
  }

  .upload_work_wrapper
    .authenticity_certificate_wrapper
    .authenticity_certificate_content
    .agree_terms {
    margin: 0 0 3rem;
    width: 100%;
    grid-gap: 8px;
  }
}

@media (max-width: 568px) {
  .advanced-settings-nft-styles {
    width: 100%;
  }

  .upload_work_wrapper
    .authenticity_certificate_wrapper
    .authenticity_certificate_content
    button {
    width: 100%;
  }

  .upload_work_wrapper {
    margin: 2.5rem auto 6.5rem;
  }

  .upload_photos_wrapper {
    grid-gap: 1rem;
    grid-template-columns: 1fr;
  }

  .upload_photos_wrapper .small_previews {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }

  .upload_photos_wrapper .photo_wrapper .photo_content_big {
    height: 191px;
  }

  .upload_work_wrapper > .basic_info {
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    margin: 0 0 1.5rem;
  }

  .upload_work_wrapper > .resize_description {
    margin: 0 0 3.5rem;
  }

  .upload_work_wrapper .price_calculator_wrapper .price_calculator_content {
    grid-template-columns: 1fr;
    grid-gap: 52px;
    margin: 0 0 3rem;
  }

  .upload_work_wrapper .price_calculator_wrapper .materials_wrapper {
    grid-gap: 24px;
  }

  .upload_work_wrapper .price_calculator_wrapper .final_price_wrapper {
    width: calc(100% + 2rem);
    text-align: left;
    padding: 1rem;
    margin: 0 0 2rem -1rem;
  }

  .upload_work_wrapper
    .price_calculator_wrapper
    .final_price_wrapper
    .final_price_input {
    grid-template-columns: 1fr;
    margin: 0 0 2rem;
  }

  .upload_work_wrapper
    .price_calculator_wrapper
    .final_price_wrapper
    .mobile_price {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 36px;
  }

  .upload_work_wrapper .optional_info_wrapper > .description_text {
    margin: 0 0 16px;
  }

  .upload_work_wrapper .optional_info_wrapper > .update_cv {
    margin: 0 0 3.5rem;
  }

  .upload_work_wrapper .apply_cancel {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  .upload_work_wrapper .apply_cancel > span {
    grid-column: 1;
  }

  .upload_work_wrapper .apply_cancel button {
    width: 100%;
  }

  .upload_work_wrapper .apply_cancel button:last-of-type {
    grid-column: 1/-1;
    grid-row: 2;
  }

  .upload_work_wrapper .all_fields_error {
    grid-column: 1;
    grid-row: 1;
  }

  .upload_photos_wrapper .dropzone img {
    height: 191px;
  }

  .upload_photos_wrapper .small_previews img {
    height: 68px;
  }
}
