.wrapper {
    display: grid;
    grid-template-columns: 1fr 360px;
    min-height: 700px;
    grid-gap: 1.5rem;
}

.calendar {
    border: 1px solid #000000;
}

.aside {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    padding: 1.5rem;
    background-color: #ffffff;
    border: 1px solid #000000;
}

.closeIcon {
    background-color: var(--beige);
}

.label {
    display: inline-block;
    margin-bottom: 0.5rem;
    color: rgba(0, 0, 0, .5);
}

.asideActions {
    display: grid;
    grid-gap: 1rem;
}

@media (max-width: 1200px) {
    .wrapper {
        grid-template-columns: 1fr 300px;
    }
}

@media (max-width: 992px) {
    .wrapper {
        grid-template-columns: 1fr;
    }
}