.root :global(.ant-tabs-nav) {
    margin-bottom: 0;
}

.root :global(.ant-tabs-nav::before) {
    display: none;
}

.root :global(.ant-tabs-tab) {
    padding-top: 0;
    padding-bottom: 1rem;
    font-size: 1rem;
    opacity: 0.4;
}

.root :global(.ant-tabs-ink-bar) {
    background-color: #000000;
}

.root :global(.ant-tabs-tab.ant-tabs-tab-active),
.root :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn),
.root :global(.ant-tabs-tab:hover) {
    opacity: 1;
    color: #000000;
}