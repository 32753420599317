.wrapper_article_page {
    overflow-x: hidden;
}

.mb_40 {
    margin: 0 0 40px;
}

.wrapper_article_page .wrapper_preview_section {
    height: 414px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-position: center;
    margin: 0 0 5rem;
}

.wrapper_article_page .wrapper_preview_section > .preview_text {
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: auto;
    text-align: center;
    padding: 30px 5% 20px;
    max-width: 60%;
}

.wrapper_article_page .wrapper_preview_section > .preview_text span {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.84px;
    color: var(--new-black);
    text-transform: uppercase;
    display: block;
    margin: 0 auto 6px;
}

.wrapper_article_page .wrapper_preview_section > .preview_text h1 {
    font-size: 44px;
    font-weight: bold;
    line-height: 1.16;
    text-align: center;
    color: var(--dark-grey);
    margin: 0 0 8px;
}

.wrapper_article_page .wrapper_preview_section > .preview_text > .inline {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    grid-gap: 6px;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    justify-content: center;
    margin: 0 auto;
    letter-spacing: 0.84px;
    color: #dbddde;
}

.wrapper_article_page .share_container {
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: translateX(-50%);
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 1rem;
    align-items: center;
}

.wrapper_article_page .share_container > .share {
    display: flex;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 100px;
    background-color: #ffffff;
    width: 94px;
    height: 38px;
    justify-content: center;
}

.wrapper_article_page .share_container > .share span {
    font-size: 16px;
    font-weight: bold;
    color: var(--dark-grey);
    padding: 0 0 0 .5rem;
}

.wrapper_article_page .share_container > .social {
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: repeat(3, max-content);
    align-items: center;
}

.wrapper_article_page .share_container > .social button {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #ffffff;
    border-radius: 100px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.wrapper_article_page .share_container > .social .facebook {
    background-color: #4263a0;
}

.wrapper_article_page .share_container > .social .telegram {
    background-color: #5cade4;
}

.wrapper_article_page .share_container > .social .twitter {
    background-color: #9fd8ff;
}

.wrapper_article_page .content {
    padding: 0 10%;
}

.wrapper_article_page .content .table_of_content {
    padding: 0 17%;
    margin: 0 0 70px;
}

.wrapper_article_page .content .table_of_content:last-of-type {
    margin: 0;
}

.wrapper_article_page .content .table_of_content_wrapper {
    margin: 0 0 70px;
}

.wrapper_article_page .content p {
    font-weight: 300;
    color: var(--slate);
}

.wrapper_article_page .content .bigger_text {
    font-size: 20px;
    line-height: 1.4;
    margin: 0 0 40px;
}

.wrapper_article_page .content .regular_text, .wrapper_article_page .content .regular_text p, .wrapper_article_page .content .regular_text span {
    font-size: 16px;
    line-height: 1.75;
}

.wrapper_article_page .content .regular_text p {
    min-height: 15px;
}

.wrapper_article_page .content pre {
    white-space: inherit;
}

.wrapper_article_page .content h6 {
    font-size: 20px;
    font-weight: bold;
    color: var(--dark-grey);
    margin: 0 0 20px;
}

.wrapper_article_page .content .navigation_links {
    margin: 0 0 100px;
}

.wrapper_article_page .content .navigation_links > a {
    display: block;
    font-size: 20px;
    line-height: 1.4;
    color: #000000;
    text-decoration: underline;
    margin: 0 0 14px;
}

.wrapper_article_page .content .navigation_links > a:last-of-type {
    margin: 0;
}

.wrapper_article_page .content .image_left_block, .wrapper_article_page .content .image_right_block {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 5rem;
    align-items: start;
    margin: 0 0 70px;
    padding: 0 17% 0 10%;
}

.wrapper_article_page .content .image_right_block {
    grid-template-columns: 2fr 1fr;
    padding: 0 10% 0 17%;
}

.wrapper_article_page .content .image_left_block img, .wrapper_article_page .content .image_right_block img {
    max-width: 100%;
}

.wrapper_article_page .content .image_left_block > .text, .wrapper_article_page .content .image_right_block > .text {
    margin: 36px 0 0;
}

.wrapper_article_page .content h2 {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.37;
    color: var(--dark-grey);
    margin: 0 0 20px;
}

.wrapper_article_page .content > .video_block {
    margin: 0 0 70px;
    padding: 0 17%;
}

.wrapper_article_page .content > .video_block .video_image {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
}

.wrapper_article_page .content > .image_right_section {
    margin: 0 0 57px;
    padding: 0 10% 0 17%;
}

.wrapper_article_page .content h3 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.71;
    color: var(--dark-grey);
    margin: 0 0 20px;
}

.wrapper_article_page .content .list_section {
    padding: 0 17%;
}

.wrapper_article_page .content ul {
    position: relative;
    text-align: left;
    padding-left: 1.5rem;
    margin: 0;
}

.wrapper_article_page .content ul li {
    font-size: 16px;
    font-weight: 300;
    color: var(--slate);
    margin: 0 0 2px;
}

.wrapper_article_page .content ul li:last-of-type {
    margin: 0;
}

.wrapper_article_page .content ul li:before {
    content: "";
    background: var(--new-black);
    width: .5rem;
    height: .5rem;
    border-radius: 100%;
    position: absolute;
    left: 0;
    margin: 8px 0 0;
}

.wrapper_article_page .content .quote_section {
    position: relative;
    margin: 0 0 40px;
    padding: 0 17% 0 10%;
}

.wrapper_article_page .content .quote_section {
    position: relative;
    margin: 0 0 60px;
}

.wrapper_article_page .content .quote_section .quote_icon {
    position: absolute;
    top: -40px;
    left: calc(10% - 16px);
}

.wrapper_article_page .content .quote_section .quote_text {
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.59;
    letter-spacing: normal;
    text-align: left;
    color: var(--dark-grey);
    margin: 0 0 30px;
    padding: 0 24px 0 0;
}

.wrapper_article_page .content .quote_section .quote_author {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    margin: 0 0 40px auto;
    justify-content: end;
}

.wrapper_article_page .content .quote_section .quote_text_padding {
    padding-left: 80px;
}

.wrapper_article_page .content .quote_section .quote_author .avatar {
    width: 64px;
    height: 64px;
    border-radius: 4px;
    object-fit: cover;
}

.wrapper_article_page .content .quote_section .quote_author h5 {
    font-size: 20px;
    font-weight: normal;
    line-height: 1.1;
    text-align: right;
    color: #000000;
}

.wrapper_article_page .content .quote_section .quote_author span {
    line-height: 1.1;
    text-align: right;
    color: #000000;
    font-size: 16px;
    font-weight: 300;
    display: block;
    margin: 0 0 0 auto;
}

.wrapper_article_page .content .authors_section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 46px;
    align-items: start;
    margin: 0 0 70px;
    justify-content: space-between;
}

.wrapper_article_page .content .authors_section .card {
    text-align: center;
}

.wrapper_article_page .content h4 {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    color: #000000;
    margin: 0 0 10px;
}

.wrapper_article_page .content .authors_section .card div {
    width: 105px;
    height: 105px;
    border-radius: 100%;
    background: #f0f0f0;
    margin: 0 auto 28px;
}

.wrapper_article_page .discover_section {
    background: var(--white);
    padding: 90px 0 100px;
    margin: 0 0 70px;
}

.wrapper_article_page .latest_articles_section {
    padding: 0 10% 40px;
}

.wrapper_article_page .latest_articles_section h2 {
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: normal;
    text-align: left;
    color: var(--dark-grey);
    margin: 0 0 30px;
}

.wrapper_article_page .latest_articles_section > .wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 46px;
    align-items: start;
}

.wrapper_article_page .latest_articles_section > .wrapper .card > .card_preview {
    width: 100%;
    max-width: 100%;
    height: 240px;
    object-fit: cover;
    margin: 0 0 28px;
}

.wrapper_article_page .latest_articles_section > .wrapper .card > .category {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    margin: 0 0 5px;
}

.wrapper_article_page .latest_articles_section > .wrapper .card > .category > span {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.84px;
    text-align: left;
    color: #c7629a;
    text-transform: uppercase;
}

.wrapper_article_page .latest_articles_section > .wrapper .card > .category > .time {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 14px;
}

.wrapper_article_page .latest_articles_section > .wrapper .card > .category > .time > span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.79;
    letter-spacing: 0.11px;
    text-align: right;
    color: #dbddde;
}

.wrapper_article_page .latest_articles_section > .wrapper .card > h4 {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.09;
    color: #000000;
    margin: 0 0 10px;
}

.wrapper_article_page .latest_articles_section > .wrapper .card > p {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: var(--slate);
}


@media screen and (max-width: 1200px) {
    .wrapper_article_page {
        margin-top: 73px;
    }

    .wrapper_article_page .content {
        padding: 0 7.5%;
    }

    .wrapper_article_page .latest_articles_section {
        padding: 0 7.5% 40px;
    }

    .wrapper_article_page .content .table_of_content {
        padding: 0 12%;
    }

    .wrapper_article_page .content .image_left_block, .wrapper_article_page .content .image_right_block {
        grid-gap: 3rem;
        padding: 0 12% 0 5%;
    }

    .wrapper_article_page .content .image_right_block {
        padding: 0 5% 0 12%;
    }

    .wrapper_article_page .latest_articles_section > .wrapper {
        grid-gap: 32px;
    }

    .wrapper_article_page .latest_articles_section > .wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 1024px) {
    .wrapper_article_page {
        margin-top: 60px;
    }
}

@media (max-width: 992px) {
    .wrapper_article_page {
        margin-top: 60px;
    }

    .wrapper_article_page .content {
        padding: 0 5%;
    }

    .wrapper_article_page .latest_articles_section {
        padding: 0 5% 40px;
    }

    .wrapper_article_page .content .table_of_content {
        padding: 0 10%;
    }

    .wrapper_article_page .content .image_left_block, .wrapper_article_page .content .image_right_block {
        padding: 0 10% 0 3%;
    }

    .wrapper_article_page .content .image_right_block {
        padding: 0 3% 0 10%;
    }
}

@media (max-width: 568px) {
    .wrapper_article_page .content {
        padding: 0 1rem;
    }

    .wrapper_article_page .latest_articles_section {
        padding: 0 1rem 30px;
    }

    .wrapper_article_page .content .table_of_content {
        padding: 0;
        margin: 0 0 40px;
    }

    .wrapper_article_page .content .image_left_block, .wrapper_article_page .content .image_right_block {
        padding: 0;
        grid-template-columns: 1fr;
        grid-gap: 30px;
        margin: 0 0 40px;
    }

    .wrapper_article_page .content .image_right_block {
        padding: 0;
    }

    .wrapper_article_page .content .image_left_block > .text, .wrapper_article_page .content .image_right_block > .text {
        margin: 0;
    }

    .wrapper_article_page .content .image_right_block > img {
        grid-column-start: 1;
        grid-row-start: 1;
    }

    .wrapper_article_page .latest_articles_section > .wrapper {
        grid-template-columns: 100%;
    }

    .wrapper_article_page .content .table_of_content_wrapper {
        margin: 0 0 40px;
    }

    .wrapper_article_page .wrapper_preview_section {
        height: 260px;
        position: relative;
        margin: 0 0 5.5rem;
    }

    .wrapper_article_page .content .bigger_text {
        text-align: center;
    }

    .wrapper_article_page .share_container {
        position: static;
        bottom: 0;
        left: 0;
        transform: translateX(0);
        display: grid;
        grid-template-columns: repeat(2, max-content);
        grid-gap: 1rem;
        justify-content: center;
        margin: 0 0 30px;
    }

    .wrapper_article_page .wrapper_preview_section > .preview_text {
        margin: auto;
        padding: 34px 1rem 20px;
        max-width: 100%;
        width: calc(100% - 2rem);
        position: absolute;
        bottom: -4rem;
        left: 0;
        right: 0;
    }

    .wrapper_article_page .wrapper_preview_section > .preview_text h1 {
        font-size: 30px;
        line-height: 1.33;
    }

    .wrapper_article_page .content h2 {
        font-size: 26px;
        line-height: 1.19;
    }
}