.wrapper {
  position: relative;
  height: 24px;
  display: flex;
  align-items: center;
  color: #FF4949;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  &::before {
    content: "";
    display: block;
    width: 26px;
    height: 24px;
    background-image: url('../../../../assets/img/onboarding/triangle_red.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 20px;
  }

  @media screen and (min-width: 980px) {
    font-size: 11.5046px;
    line-height: 17px;
  }
}