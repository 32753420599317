.wrapper {
    display: block !important;
    padding: 0;
    margin: 0;
    border: 0;
}

.input input:global(.custom_input) {
    border-width: 2px;
    padding: 0.688rem 1rem;
    border-color: #000000;
}

.header {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 3rem 10% 0;
    min-height: 200px;
    z-index: 2;
}

.contentBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--beige);
    z-index: -1;
}

.headerInner {
    display: flex;
    justify-content: space-between;
    grid-gap: 1rem;
    margin-bottom: auto;
}

.title {
    font-weight: 600;
    font-size: 2rem;
    letter-spacing: 0.02em;
    margin-bottom: 0;
    color: #000000;
}

.content {
    padding: 3rem 10%;
}

.contentWithMap {
    padding-bottom: 0;
}

.topFilters {
    display: grid;
    grid-template-columns: 20% calc(40% - 4.5rem) 20% 20%;
    grid-gap: 1.5rem;
    align-items: flex-start;
}

.contentInner {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr;
    padding-top: 2rem;
}

.contentInnerWithFilters {
    display: grid;
    grid-template-columns: 16.25rem 2px calc(100% - 19.25rem - 2px);
}

.divider {
    background-color: #000000;
    height: 100%;
}

.experiencesList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem;
    width: 100%;
    min-width: 0;
}

.contentInnerWithFilters .experiencesList {
    grid-template-columns: repeat(3, 1fr);
}

.headerTabs {
    display: flex;
    align-items: center;
}

.headerTabButton {
    color: #000000;
    background-color: transparent;
    border: 2px solid #000000;
}

.headerTabButton + .headerTabButton {
    border-left: 0;
}

.headerTabButtonActive {
    color: #ffffff;
    background-color: #000000;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-top: 2px solid #000000;
}

.tabsWrapper {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

.filterButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
}

.inner {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    min-height: 12rem;
}

.noDataBlock {
    text-align: center;
}

@media (max-width: 1200px) {
    .header, .content {
        padding-left: 7.5%;
        padding-right: 7.5%;
    }

    .contentInnerWithFilters {
        grid-template-columns: 12.25rem 2px calc(100% - 15.25rem - 2px);
    }
}

@media (max-width: 992px) {
    .header {
        padding-left: 5%;
        padding-right: 5%;
    }

    .content {
        padding: 1.5rem 5%;
    }

    .contentInner {
        display: block;
        padding-top: 1.5rem;
    }

    .headerInner {
        flex-direction: column;
    }

    .topFilters {
        grid-template-columns: 1fr auto;
        padding-bottom: 1.5rem;
        border-bottom: 2px solid #000000;
    }

    .filterButton {
        order: 1;
        padding: 0.125rem;
    }

    .filterButtonOpen {
        color: #ffffff;
        background-color: #000000;
    }

    .contentInnerWithFilters .experiencesList,
    .experiencesList {
        grid-template-columns: 1fr 1fr;
    }

    .experiencesList {
        margin-top: 1.5rem;
    }
}

@media (max-width: 768px) {
    .header {
        padding-top: 1.5rem;
        min-height: 0;
    }

    .title {
        font-size: 1.5rem;
    }

    .tabsWrapper {
        margin-top: 2.5rem;
    }
}

@media (max-width: 576px) {
    .contentInnerWithFilters .experiencesList,
    .experiencesList {
        grid-template-columns: 1fr;
    }
}