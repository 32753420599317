.new_artist_public {

}

.new_modal_wrapper_profile_popup_width .modal-window-content {
    width: 700px;
}

.new_modal_wrapper_profile_popup_width .modal-window-content .head {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 1rem;
    align-items: center;
    padding: 0 0 1rem;
    margin: 0 0 1.5rem;
    border-bottom: 1px solid #000000;
}

.new_modal_wrapper_profile_popup_width .modal-window-content .head h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    margin: 0;
}

.new_modal_wrapper_profile_popup_width .modal-window-content .head svg {
    font-weight: 600;
    cursor: pointer;
}

.new_modal_wrapper_profile_popup_width .modal-window-content p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.06em;
    color: #000000;
    margin: 0 0 2.5rem;
}

.new_artist_public .home_artists_header_wrapper .about_video_artist_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
}

.new_artist_public .home_artists_header_wrapper .about_artist_wrapper .block-seo {
    padding: 0;
    margin-top: 40px;
}

.new_artist_public .home_artists_header_wrapper .about_artist_wrapper .block-seo p {
    line-height: 22px;
}


.new_artist_public .home_artists_header_wrapper .about_artist_wrapper {
    margin-top: 3rem;
}

.new_artist_public .home_artists_header_wrapper .about_artist_wrapper .video_preview_landing {
    box-shadow: unset;
    position: relative;
}

.new_artist_public .home_artists_header_wrapper .about_artist_wrapper p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000
}

.new_artist_public .home_works_wrapper h3,
.new_artist_public .home_artists_header_wrapper .about_artist_wrapper h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 56px;
    color: #000
}

.new_artist_public .home_artists_header_wrapper .follow_artist {
    margin-top: 16px;
}

.new_artist_public .home_works_wrapper {
    padding: unset;
    margin-top: 56px;
}

.new_artist_public .home_works_wrapper .works_container {
    margin-top: 4rem;
}

.new_artist_public .new_artist_public_wrapper_every__item {
    max-width: 340px;
}

.new_artist_public .pre_footer {
    background: #000;
    padding: 40px 4rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    grid-gap: 4rem;
}

.new_artist_public .pre_footer > div {
    text-align: center;
    display: grid;
}

.new_artist_public .pre_footer > div .title {
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    color: #fff
}

.new_artist_public .pre_footer > div .description {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #fff
}

.new_artist_public .cv_public_previews {
    margin-top: 56px;
    padding: 0;
}

.new_artist_public .faq_section .faq_questions_wrapper .question_wrap {
    padding: 1.5rem 0;
}

.new_artist_public .faq_section .faq_questions_wrapper .question_wrap:first-child {
    border-top: 2px solid #000;
}

.new_artist_public .cv_public_previews .public_cv {
    background: #F6F6F1;
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-top: 2rem;
}

.new_artist_public .cv_public_previews .public_cv .about_wrapper {
    padding: 3rem;
}

.new_artist_public .cv_public_previews .public_cv .about_wrapper .user_information_wrapper {
    padding: 0;
    background: transparent;
}

.new_artist_public .cv_public_previews .public_cv .about_wrapper .user_information_wrapper .info {
    grid-template-columns: 1fr;
    grid-gap: 20px;
}

.new_artist_public .cv_public_previews .public_cv .about_wrapper .user_information_wrapper .info h2 {
    margin-bottom: 0;
}

.new_artist_public .cv_public_previews .public_cv .about_wrapper .user_information_wrapper .info p {
    text-align: center;
    padding: 0;
}

.new_artist_public .cv_public_previews .public_cv .about_wrapper .user_information_wrapper .wrapper_email {
    margin: 20px 0;
    display: grid;
}

.new_artist_public .cv_public_previews .public_cv .about_wrapper .user_information_wrapper .user_links {
    display: flex;
    margin: 40px -10px 0;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.new_artist_public .cv_public_previews .public_cv .about_wrapper .user_information_wrapper .user_links a {
    margin: 0 10px;
}

.new_artist_public .cv_public_previews .public_cv .about_wrapper .user_information_wrapper .wrapper_email .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000
}

.new_artist_public .cv_public_previews .public_cv .about_wrapper .user_information_wrapper .wrapper_email .email {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000
}

.new_artist_public .cv_public_previews .public_cv .cv_info_wrapper {
    border-left: 1px solid #000;
    padding: 2rem 3rem;
}


.new_artist_public .cv_public_previews .public_cv .cv_info_wrapper .about h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000
}

.new_artist_public .cv_public_previews .public_cv .profile_section_body_cv {
    padding: 0;
}

.new_artist_public .cv_public_previews .public_cv .profile_section_body_cv .entries_section_preview__list_wrapper {
    grid-template-columns: 1fr 1fr max-content;
}

.new_artist_public .cv_public_previews .public_cv .profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title span {
    word-break: break-all;
}

.new_artist_public .cv_public_previews .public_cv .profile_section_body_cv .entries_section_preview__title span {
}

.new_artist_public .cv_public_previews .public_cv .profile_section_body_cv .wrapper_category_title {
    margin-top: 40px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000
}

.new_artist_public .cv_public_previews .public_cv .cv_info_wrapper .about p {
    display: block;
    margin-top: 9px;
    padding-right: 0;
}

.about-artist-basic {
    filter: blur(3px);
}

.about-artist-premium {
    filter: blur(0px);
}

.about-artist-content-container {
    margin-top: 16px;
    padding: 24px;
    background-color: #000000;
    color: #FFFFFF;
}

.art-advisory-item-container {
    display: grid;
    font-size: 14px;
    font-weight: 200;
    grid-template-columns: 20px 1fr;
    grid-gap: 10px;
    margin: 14px 0 14px 0;
    align-items: center;
}

.new_artist_public .new_statistic_public .wrapper_statistic_views {
    margin-top: 3rem;
}

.new_artist_public .new_statistic_public {
    margin-bottom: 3rem;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity {
    margin-top: 3rem;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .preview_wrapper .title,
.new_artist_public .new_statistic_public .wrapper_artist_activity .preview_wrapper {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .preview_wrapper .title {
    grid-gap: 4px;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .description {
    display: grid;
    grid-template-columns: max-content max-content 1fr;
    grid-gap: 4px;
    align-items: center;
    font-weight: 300;
    font-size: 16px;
    justify-self: end;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .description .tooltip_wrapper .tooltip {
    left: -72px;
    top: -60px;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .description .score {
    font-weight: 600;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .active_tabs_container {
    display: flex;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .active_tabs_container button {
    margin: 0;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .wrapper_active_stats {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    grid-gap: 2rem;
    align-self: center;
    justify-self: end;
}

.new_artist_public .new_statistic_public .wrapper_active_stats .every_stats {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 16px;
    align-items: center;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .recharts-legend-wrapper,
.new_artist_public .new_statistic_public .wrapper_artist_activity .recharts-cartesian-axis-tick-line,
.new_artist_public .new_statistic_public .wrapper_artist_activity .recharts-cartesian-axis-line {
    display: none !important;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .customizeToolTip {
    background: #F6F6F1;
    padding: 20px 24px;
    max-width: 320px;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .customizeToolTip .title {
    font-size: 12px;
    line-height: 19px;
    letter-spacing: .4px;
    color: #000;
    margin-bottom: 12px;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .customizeToolTip .wrapper_events span {
    color: #000;
    align-self: start;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .customizeToolTip .wrapper_events .every_events {
    display: grid;
    grid-template-columns: 18px 1fr max-content;
    align-items: center;
    align-self: start;
    grid-gap: 0 12px;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .customizeToolTip .wrapper_events .every_events .color_block {
    content: '';
    width: 18px;
    height: 18px;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .customizeToolTip .wrapper_events .every_events .awards {
    background: #E1DCDC;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .customizeToolTip .wrapper_events .every_events .media {
    background: #5B5757;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .customizeToolTip .wrapper_events .every_events .museums {
    background: #6D9199;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .customizeToolTip .wrapper_events .every_events .exhibitions {
    background: #363434;
}

.new_artist_public .new_statistic_public .wrapper_artist_activity .customizeToolTip .wrapper_events .every_events .city {
    justify-self: end;
    padding-left: 20px;
}


.new_artist_public .home_works_wrapper .works_container .works_slider_container, .home_artists_wrapper .artists_slider_container {
    margin: 0;
}

.new_artist_public .cv_public_previews .public_cv .user_information_wrapper > .info > .avatar_container {
    padding: 0;
    margin: 0 auto;
}

.new_artist_public .new_statistic_public #statistic_profile_views {
    position: relative;
    width: 100%;
    overflow-x: auto;
}

.new_artist_public .new_statistic_public #statistic_profile_views .no_data_wrapper {
    position: absolute;
    display: grid;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
    bottom: 0;
}

.new_artist_public .new_statistic_public #statistic_profile_views .no_data_container {
    justify-self: center;
    align-self: center;
    background: #F6F6F1;
    padding: 100px 120px;
}

.new_artist_public .new_statistic_public #statistic_profile_views .no_data_container span {
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000
}

.new_artist_public .new_statistic_public .wrapper_legend {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    display: grid;
    grid-gap: 12px;
}

.new_artist_public .new_statistic_public .wrapper_legend .legend_every {
    display: grid;
    grid-template-columns: 16px 1fr;
    grid-gap: 12px;
    align-items: center;
}

.new_artist_public .new_statistic_public .wrapper_legend .legend_every span {

}

.new_artist_public .new_statistic_public .wrapper_legend .legend_every div {
    content: '';
    width: 16px;
    height: 16px;
}

.temporary-art-advisory-container {
    display: block;
    margin-top: 9px;
    padding-right: 0;
    position: relative;
}

.temporary-art-advisory-container p {
    -webkit-filter: blur(3px);
    filter: blur(3px);
}

.temporary-art-advisory-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    padding: 15px;
    border: 1px solid #000;
    background-color: #f6f6f1;
}

@media (max-width: 992px) {
    .new_artist_public .home_works_wrapper .works_container .tabs_content .sort_by_buttons {
        grid-gap: 32px;
    }
}

@media (max-width: 768px) {
    .new_artist_public .pre_footer {
        grid-template-columns: repeat(2, 1fr);
        padding: 3rem;
        grid-gap: 2rem;
    }

    .new_artist_public .cv_public_previews .public_cv,
    .new_artist_public .home_artists_header_wrapper .about_video_artist_wrapper {
        grid-template-columns: 1fr;
    }

    .new_artist_public .cv_public_previews .public_cv .about_wrapper,
    .new_artist_public .cv_public_previews .public_cv .cv_info_wrapper {
        padding: 1rem;
    }


    .new_artist_public .cv_public_previews .public_cv .cv_info_wrapper {
        border-width: 1px 0 0 0;
        border-style: solid;
        border-color: #000;
    }

    .new_modal_wrapper_profile_popup_width .modal-window-content {
        width: calc(100% - 2rem);
        margin: 0 auto;
    }
}

@media (max-width: 600px) {

    .new_artist_public .faq_section .faq_questions_wrapper .question_wrap {
        overflow-x: hidden;
    }

    .new_artist_public .wrapper_statistic_performance .statistic_wrapper__area_chart .recharts-wrapper > svg, .wrapper_statistic_performance .statistic_wrapper__area_chart .recharts-wrapper {
        width: unset !important;
    }

    .new_artist_public .wrapper_statistic_performance .statistic_wrapper__area_chart .recharts-wrapper {
        margin-bottom: 30px;
    }

    .new_artist_public .wrapper_statistic_performance .statistic_wrapper__area_chart .recharts-wrapper,
    .new_artist_public .new_statistic_public #statistic_profile_views {
        overflow: auto;
    }


    .new_artist_public .new_statistic_public #statistic_profile_views .no_data_container {
        justify-self: center;
        align-self: center;
        text-align: center;
        background: #F6F6F1;
        padding: 40px 60px;
    }

}

@media (max-width: 500px) {
    .new_artist_public .pre_footer {
        grid-template-columns: repeat(2, 1fr);
        padding: 2rem;
        grid-gap: 1rem;
    }

    .new_artist_public .new_statistic_public .wrapper_legend {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 12px;
        justify-content: space-between;
    }


    .new_artist_public .new_statistic_public .wrapper_artist_activity .preview_wrapper {
        grid-template-columns: 1fr;
        grid-gap: 24px;
    }

    .new_artist_public .new_statistic_public .wrapper_artist_activity .preview_wrapper .description {
        justify-self: start;
    }

    .new_artist_public .pre_footer .title {
        font-size: 36px;
        line-height: 72px;
    }

    .new_artist_public .pre_footer .description {
        font-size: 14px;
        line-height: 26px;
    }

    .new_artist_public .new_statistic_public .wrapper_artist_activity .active_tabs_container {
        flex-direction: column;
    }

    .new_artist_public .new_statistic_public .wrapper_artist_activity .active_tabs_container button {
        width: 100%;
    }

    .new_artist_public .new_statistic_public #statistic_profile_views .customizeToolTip {
        max-width: 280px;
    }
}
