.nft-header-container {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
    font-size: 20px;
    font-weight: 600;
    margin-top: 56px;
}

.nft-header-button {
    display: flex;
    justify-content: flex-end;
    padding: 12px 40px;
    border: 2px solid #000000;
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    align-items: center;
}