.footer {
    padding: 5rem 10% 4rem;
    background: #000000;
}

.footer > .footer_information {
    display: grid;
    align-items: center;
    grid-template-columns: 70% 20%;
    grid-gap: 10%;
    justify-content: space-between;
    margin: 0 0 1.75rem;
}

.footer > .footer_information > .navigation {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    grid-gap: 4rem;
    align-items: start;
}

.footer .navigation_item.regional-settings .navigation_options {
    margin-top: 32px;
}

.footer .navigation_item.regional-settings .navigation_options > * + * {
    margin-top: 16px;
}

.footer .navigation_item.regional-settings {
    margin-bottom: auto;
}

.footer .navigation_item.regional-settings button {
    margin-top: 32px;
}

.footer .navigation_item.regional-settings .navigation_options .value,
.footer .navigation_item.regional-settings .navigation_options .key {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    opacity: 0.6;
}

.footer .navigation_item.regional-settings .navigation_options .value {
    font-weight: 600;
    text-transform: capitalize;
}

.footer .navigation_item.regional-settings h3,
.footer > .footer_information > .navigation .navigation_item h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0 0 2rem;
}

.footer > .footer_information > .navigation .navigation_item .navigation_options {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
}

.footer > .footer_information > .navigation .navigation_item .navigation_options div {
    display: grid;
    align-items: center;
}

.footer > .footer_information > .navigation .navigation_item .navigation_options a {
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    width: max-content;
}

.footer > .footer_information > .social_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
}

.footer > .footer_information > .social_wrapper > a {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    border: 2px solid #FFFFFF;
    padding: .75rem 1rem;
    color: #FFFFFF;
    display: block;
    width: 100%;
    text-align: center;
    transition: .3s all;
}

.footer > .footer_information > .social_wrapper > a:hover {
    background: #FFFFFF;
    color: #000000;
}

.footer .footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 42px;
}

.footer .copyright {
    color: white;
    margin-top: 12px;
    display: block;
}

.regional-settings-wrapper {
    display: flex;
    flex-direction: column;
}

.footer > .footer-bottom .footer_social_links,
.footer > .footer_information > .social_wrapper .footer_social_links {
    display: grid;
    grid-template-columns: repeat(6, max-content);
    grid-gap: 1.5rem;
    align-items: center;
    justify-content: center;
}

.footer > .footer-bottom .footer_social_links a,
.footer > .footer_information > .social_wrapper .footer_social_links a {
    display: grid;
    align-items: center;
}

.footer > .footer_logotype {
    display: grid;
    align-items: center;
    justify-content: end;
    width: max-content;
    padding: 0 7% 0 0;
}

@media (max-width: 1200px) {
    .footer {
        padding: 5rem 7.5% 4rem;
    }

    .footer > .footer_information > .navigation {
        grid-gap: 2.5rem;
    }

    .footer > .footer-bottom .footer_social_links,
    .footer > .footer_information > .social_wrapper .footer_social_links {
        grid-gap: 1rem;
    }
}

@media (max-width: 992px) {
    .footer .navigation_item.regional-settings {
        max-width: 184px;
    }

    .footer > .footer_information {
        grid-template-columns: 1fr;
        margin: 0 0 1.75rem;
        grid-gap: 3rem;
        padding: 0;
    }

    .footer > .footer_logotype {
        justify-content: center;
        margin: 0 auto;
        padding: 0;
    }

    .footer > .footer_information > .social_wrapper > a {
        margin: 0 auto;
        padding: .75rem 3rem;
        width: max-content;
    }

    .footer {
        padding: 4rem 5% 3rem;
    }
}

@media (max-width: 650px) {
    .footer {
        padding: 2rem 1rem 4rem;
    }

    .footer > .footer_information > .navigation {
        grid-template-columns: max-content;
        grid-gap: 4rem;
        justify-content: center;
    }

    .footer > .footer_information > .social_wrapper {
        grid-template-columns: 1fr;
        grid-gap: 0;
        grid-column-start: 1;
        grid-row-start: 1;
        justify-content: center;
    }

    .footer > .footer_information > .social_wrapper .footer_social_links {
        grid-gap: 1rem 1.75rem;
    }

    .footer > .footer_information {
        margin: 0 0 3rem;
    }

    .footer .artsted_digital_link {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        text-decoration-line: underline;
        color: #FFFFFF;
        display: block;
        width: max-content;
        margin: 0 auto 3rem;
    }
}

@media (max-width: 550px) {
    .footer .footer-bottom {
        flex-direction: column;
    }

    .footer .footer-bottom .social_wrapper {
        margin-top: 32px;
    }

    .footer .navigation_item.regional-settings {
        margin-inline: auto;
    }
}