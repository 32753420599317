.profile_categories_wrapper {
    margin: 3.5rem 0 6rem;
}

.profile_categories_wrapper h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    margin: 0 0 1rem;
}

.profile_section_body_categories {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 40px;
}

.profile_section_body_categories span {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.06em;
    color: #000000;
    margin: 0 24px 16px 0;
    display: block;
}

.profile_section_body_categories span:last-of-type {
    margin-right: 0;
}

.categories_item_wrapper h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    margin: 0 0 2rem;
}

.categories_item_wrapper .categories_navigation, .categories_item_wrapper .categories_navigation .tabs {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    margin: 0 0 2rem;
}

.categories_item_wrapper .categories_navigation .tabs {
    margin: 0;
}

.categories_item_wrapper .categories_navigation a, .categories_item_wrapper a.go_to_category {
    font-weight: 500;
    font-size: 16px;
    line-height: 45px;
    text-align: right;
    color: #000000;
    width: max-content;
    display: block;
    margin-left: auto;
}

.categories_item_wrapper .categories_navigation a:hover {
    text-decoration-line: underline;
}

.categories_item_wrapper .art_price_body_slider {
    padding: 0;
}

@media (max-width: 992px) {
    .wrapper_profile .slider_arrows_container {
        top: 50%;
        transform: translateY(-50%);
    }

    .wrapper_profile .slider_arrow_left {
        left: -2.5rem;
    }

    .wrapper_profile .slider_arrow_right {
        right: -2.5rem;
    }
}

@media (max-width: 568px) {
    .profile_categories_wrapper {
        margin: 2.5rem 0 6rem;
    }

    .profile_categories_wrapper h2 {
        margin: 0 0 1.5rem;
    }

    .profile_section_body_categories span {
        margin: 0 24px 8px 0;
    }

    .categories_item_wrapper .categories_navigation, .categories_item_wrapper .categories_navigation .tabs {
        grid-template-columns: 1fr;
        margin: 0 0 2.5rem;
    }

    .categories_item_wrapper .categories_navigation .tabs {
        grid-template-columns: 1fr 1fr;
        margin: 0;
    }

    .categories_item_wrapper .categories_navigation .tabs button {
        width: 100%;
    }
}