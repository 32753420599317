.wrapper {
    min-width: 0;
    position: relative;
}

.root {
    display: flex;
    flex-direction: column;
    position: relative;
    color: #000000;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, 0.16)
}

.root:hover, .root:focus {
    color: #000000;
}

.root :global(.slick-next),
.root :global(.slick-prev) {
    color: #ffffff;
    top: auto;
    bottom: 1rem;
    z-index: 2;
}

.root :global(.slick-dots) {
    bottom: 1rem;
    z-index: 1;
}

.root :global(.slick-dots li),
.root :global(.slick-dots li button),
.root :global(.slick-dots li button:before) {
    width: 1rem;
    height: 1rem;
}

.root :global(.slick-dots li button:before) {
    font-size: 0.5rem;
    color: #FFFFFF;
}

.root :global(.slick-dots li.slick-active button:before) {
    color: #FFFFFF;
}

.root :global(.slick-prev) {
    left: 1rem;
}

.root :global(.slick-next) {
    right: 1rem;
}

.imageWrapper {
    position: relative;
    min-height: 10rem;
    flex-grow: 1;
    background-color: var(--beige);
}

.imageWrapper::before {
    content: "";
    display: block;
    padding-bottom: 110%;
}

.imageWrapper :global(.slick-slider) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-width: 0;
}

.imageWrapper :global(.slick-list),
.imageWrapper :global(.slick-track),
.imageWrapper :global(.slick-track div) {
    height: 100%;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.imageWrapper::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    pointer-events: none;
}

.inner {
    flex-shrink: 0;
    padding: 1rem;
}

.root .address {
    font-size: 0.875rem;
    font-weight: 300;
    margin-bottom: 0;
}

.menu :global(.ant-dropdown-menu) {
    min-width: 12rem;
    padding: 0.25rem 1rem;
    background-color: #000000;
    border-radius: 0;
}

.menu :global(.ant-dropdown-menu .ant-dropdown-menu-item) {
    color: #F7F6F1;
    font-weight: 300;
    padding: 0.625rem 0;
    border-radius: 0;
}

.menu :global(.ant-dropdown-menu-item:not(:last-child)) {
    border-bottom: 1px solid #F7F6F1;
}

.menu :global(.ant-dropdown-menu-item:hover) {
    color: #F7F6F1;
    background-color: #000000;
}

.actionButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: #000000;
    background-color: #FFFFFF;
}

.actionButton:hover {
    color: #ffffff;
    background-color: #000000;
}

.root .defaultImage {
    height: 100%;
    width: 100%;
    object-fit: contain;
}