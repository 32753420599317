.not_verified_wrapper {
    position: fixed;
    z-index: 2600;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: var(--new-black);
    padding: 34px 3rem 1.75rem 1.75rem;
    top: 60%;
    right: 0;
    transform: translateY(-50%);
    animation: verification-appear .3s forwards;
}

@keyframes verification-appear {
    from {transform: translateX(100%)}
    to {transform: translateX(0)}
}

.not_verified_close_modal {
    position: absolute;
    top: .25rem;
    right: .25rem;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    cursor: pointer;
    display: grid;
    justify-content: center;
    align-items: center;
}

.not_verified_close_modal img {
    display: block;
    margin: auto;
}

.not_verified_content {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 18px;
    margin-bottom: 1.5rem;
}

.not_verified_content p {
    font-size: 18px;
    font-style: italic;
    line-height: 1.33;
    color: #ffffff;
}

.not_verified_wrapper > span {
    cursor: pointer;
    display: grid;
    align-items: center;
    grid-gap: 14px;
    grid-template-columns: repeat(2, max-content);
    font-size: 16px;
    font-weight: bold;
    line-height: 1.44;
    color: #ffffff;
    width: max-content;
    margin-left: auto;
}

.not_verified_wrapper > span:hover {
    color: white;
}

@media (max-width: 568px) {
    .not_verified_wrapper {
        padding: .75rem 1rem .75rem 1rem;
        width: 100%;
        top: 100%;
        transform: translateY(-100%);
    }

    .not_verified_close_modal {
        /*top: -2.5rem;*/
        /*right: 1rem;*/
        width: 28px;
        height: 28px;
    }

    .not_verified_content {
        grid-gap: 12px;
        margin-bottom: .5rem;
    }
}