.wrapper_profile__wrapper_links {
    background: var(--beige);
    padding: 4rem 0 1rem;
}

.wrapper_profile__main_content {
    padding: 0 10% 20px;
}

.wrapper_profile__wrapper_links > div::-webkit-scrollbar {
    display: none;
}

.wrapper_profile__wrapper_links > div {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-gap: 3rem;
    max-width: 726px;
    width: 100%;
    margin: 0 auto;
    transition: .3s all;
}

.wrapper_profile .links_scroll_line_wrap {
    width: 100%;
    height: 2px;
    background: transparent;
    margin: 0 auto;
    position: relative;
    display: grid;
    justify-content: center;
}

.wrapper_profile .links_scroll_line_wrap .links_scroll_line {
    width: 50px;
    height: 2px;
    background: #000000;
    position: absolute;
    top: 50%;
    left: 0;
    transition: .3s;
    transform: translateY(-50%);
}

.profile_head_section {
    display: grid;
    grid-template-columns: max-content 1fr;
    background-image: url("../../../assets/img/profile_background.png");
    background-size: cover;
    grid-gap: 0 40px;
}

.wrapper_categories .every_category {
    padding: 4px 20px;
    background: var(--new-black);
    border-radius: 16px;
    z-index: 10;
    width: max-content;
}

.wrapper_categories .every_category span {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: .42px;
    color: #fff;
}

.right_section_investor {
    display: grid;
    align-items: center;
    justify-self: end;
}

.right_section_investor .wrapper_categories {
    display: grid;
    grid-gap: 4px;
}

.padding_profile {
    padding: 0 10%;
}

.left_section {
    display: grid;
    align-items: center;
    grid-template-columns: 169px 1fr;
    padding: 37px 0;
    grid-gap: 0 40px;
}

.photo_icon {
    text-align-last: center;
    position: relative;
    display: grid;
}

.photo_icon .wrapper_user_photo {
    height: 120px;
    width: 120px;
    background: #a12161;
    display: grid;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    justify-self: center;
    grid-template-columns: 100%;
}

.photo_icon .wrapper_user_photo img {
    height: 64px;
    width: 64px;
    justify-self: center;
    align-self: center;
}

.photo_icon .checkmark_wrapper {
    position: absolute;
    bottom: 6px;
    right: 48px;
}

.wrapper_user_photo .slick-dots {
    bottom: -20px;
}

.wrapper_user_photo .slick-dots li button:before {
    color: #fff;
}

.wrapper_user_photo .slick-dots li.slick-active button:before {
    opacity: .75;
    color: var(--new-black);
}

.photo_icon .wrapper_user_photo_slider img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.photo_icon .checkmark_wrapper img:first-child {
    position: absolute;
    padding: 4px;
    background: var(--dark-grey);
    border-radius: 50%;
}

.photo_icon .checkmark_wrapper img:last-child {
    position: relative;
    z-index: 10;
    right: -9px;
    bottom: -1.5px;
}

.social_networks {
    display: grid;
    margin-top: 41px;
}

.social_networks ul {
    margin-top: 10px;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 14px;
}

.social_networks img {
    width: 35px;
}

.second_profile_wrapper {
    grid-template-columns: 1fr 4fr;
    display: grid;
    background-color: var(--pale-grey);
    padding-bottom: 64px;
    padding-top: 10px;
}

.wrapper_profile__wrapper_links a {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.33px;
    color: rgba(0, 0, 0, .4);
    transition: .3s;
}

.wrapper_profile__wrapper_links a:hover {
    color: #000000;
}

.wrapper_profile__wrapper_links a.nav_bar_active_link {
    color: #000000;
    font-weight: 600;
}

.wrapper_profile__wrapper_links a {
    position: relative;
}

.nav_bar_with_divider::after {
    content: "";
    position: absolute;
    right: -1.5rem;
    top: 0;
    width: 2px;
    background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
}

.verification-popup-wrapper .modal-window-content > div {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.verification-popup-wrapper .modal-window-content .verification-variants {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0 24px;
}

.verification-popup-wrapper .modal-window-content .verification-variants > span {
    margin-top: -28px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: var(--dark-grey);
}

.verification-popup-wrapper .modal-window-content .verification-variants > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.verification-popup-wrapper .modal-window-content .verification-variants > div span {
    margin-top: 8px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: var(--grey);
}

.verification-popup-wrapper .modal-window-content .verification-variants .image-wrapper {
    height: 115px;
    width: 124px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px var(--grey);
}

.verification-popup-wrapper .modal-window-content .verification-variants .image-wrapper.center-image img {
    margin-left: -12px;
    margin-bottom: -8px;
}

.verification-popup-wrapper .modal-window-content {
    max-width: 578px;
    padding: 40px 78px;
}

.verification-popup-wrapper .verification-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.45;
    color: var(--dark-grey);
    margin-bottom: 14px;
}

.verification-popup-wrapper .verification-text {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.63;
    color: var(--slate);
}

.wrapper_profile__navigation {
    display: grid;
    grid-template-columns: 170px calc(100% - 170px);
    background: var(--pale-grey);
    grid-gap: 0 20px;
    min-height: 451px;
    padding: 30px 10% 60px;
    position: relative;
}

.wrapper_profile__navigation .wrapper_profile__wrapper_links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    position: sticky;
    position: -webkit-sticky;
    top: 98px;
    height: max-content;
}

.take-photo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.take-photo-wrapper button {
    width: 150px;
}

.take-photo-wrapper > section {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.take-photo-wrapper > section div,
.take-photo-wrapper img:not(.take-photo-wrapper section img),
.take-photo-wrapper video {
    width: 80%;
    margin: 20px 0 24px;
}

.take-photo-wrapper > section div {
    height: 201px;
    border: solid 1px var(--grey);
}

.take-photo-wrapper > section div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.take-photo-wrapper > section div img:not(.result-img) {
    margin-bottom: -3px;
    margin-left: -6px;
}

.take-photo-wrapper > section div img.result-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

@media (max-width: 1200px) {
    .wrapper_profile__navigation {
        padding: 30px 6% 60px;
    }
}

@media screen and (max-width: 1200px) {
    .profile_head_section {
        margin-top: 72px;
    }

    .wrapper_profile__main_content {
        padding: 0 7.5% 20px;
    }
}

@media screen and (max-width: 1025px) {

    .wrapper_profile__navigation .wrapper_profile__wrapper_links > div::-webkit-scrollbar {
        display: none;
    }


    .wrapper_profile__wrapper_links a:hover {
        color: #000000;
    }

    .wrapper_profile__wrapper_links a.nav_bar_active_link {
        border-bottom: 2px solid #000000;
        font-weight: 600;
    }


    .wrapper_profile__wrapper_links a {
        padding: 0 0 14px;
        border-bottom: 2px solid transparent;
    }

    .wrapper_profile .links_scroll_line_wrap .links_scroll_line {
        display: none;
    }

    .wrapper_profile__wrapper_links {
        padding: 2.5rem 1rem 0;
    }

    .wrapper_profile__navigation {
        min-height: calc(var(--app-height) - 550px);
        grid-template-columns: 100%;
        grid-gap: 26px;
        padding: 30px 5% 60px;
    }

    .wrapper_profile__navigation .wrapper_profile__wrapper_links {
        grid-template-columns: auto;
        grid-auto-flow: column;
        grid-gap: 20px;
        position: static;
        top: 0;
        padding: 26px 20px 8px 20px;
        justify-content: space-between;
        overflow-x: auto;
    }
}

@media screen and (max-width: 992px) {
    .wrapper_profile__main_content {
        padding: 0 5% 20px;
    }

    .profile_head_section {
        margin-top: 60px;
    }

    .photo_icon .wrapper_user_photo {
        height: 110px;
        width: 110px;
    }

    .padding_profile {
        padding: 0 1px 29px 1px;
    }

    .left_section {
        grid-gap: 0 5px;
        padding: 35px 0 0 0;
    }

    .wrapper_categories {
        padding: 5px 9px;
    }

    .wrapper_profile__wrapper_links {
        padding-top: 2.5rem;
    }

    .wrapper_check_box {
        width: unset;
    }

    .support_page .button_default {
        max-width: unset;
        width: 100%;
        justify-self: center;
    }
}

@media screen and (max-width: 700px) {
    .wrapper_profile__navigation .wrapper_profile__wrapper_links {
        grid-template-columns: repeat(7, 90px);
        grid-auto-flow: column;
        grid-gap: 10px;
    }

    .wrapper_profile__main_content {
        padding: 0 1rem 20px;
    }

    .social_networks ul {
        padding: 0 32px;
    }

    .profile_head_section {
        grid-template-columns: 1fr;
        background-size: cover;
        grid-gap: 0 40px;
    }
}

@media screen and (max-width: 568px) {
    .wrapper_profile__navigation .wrapper_profile__wrapper_links {
        padding: 26px 0 8px;
        margin: 0 20px;
    }

    .wrapper_profile__main_content {
        padding: 0 1rem 20px;
    }
}