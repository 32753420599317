.table thead:global(.ant-table-thead) > tr > th {
    background-color: transparent;
    padding-top: 0;
}

.table thead:global(.ant-table-thead) > tr > th::before {
    display: none;
}

.table thead:global(.ant-table-thead) > tr > th:first-child,
.table tbody:global(.ant-table-tbody) > tr > td:first-child {
    padding-left: 0;
}

.table thead:global(.ant-table-thead) > tr > th:last-child,
.table tbody:global(.ant-table-tbody) > tr > td:last-child {
    padding-right: 0;
    text-align: right;
}

.tag {
    margin: 0;
    border-radius: 1.25rem;
    font-size: 13px;
    padding: 0.25rem 0.625rem;
    line-height: 1.5;
    border: 0;
}

.tag:global(.ant-tag) {
    color: rgba(0, 0, 0, 0.7);
    background-color: rgba(248, 248, 251, 1);
}

.tag:global(.ant-tag.ant-tag-green) {
    color: rgb(39, 174, 96);
    background-color: rgba(39, 174, 96, 0.08);
}

.paginationButtons {
    display: inline-flex;
    align-items: center;
    grid-gap: 1rem;
    font-size: 1.125rem;
    color: rgba(174, 75, 132, 1);
}

.table:global(.ant-table-wrapper) .pagination {
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 0;
}

.pagination :global(.ant-pagination-next) {
    margin-left: 3rem;
}

.pagination :global(.ant-pagination-prev) {
    margin-right: 3rem;
}

.pagination :global(.ant-pagination-disabled) {
    opacity: 0;
}

.pagination :global(.ant-pagination-item) {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0;
    color: rgba(142, 144, 166, 1);
}

.paginationDots {
    color: rgba(142, 144, 166, 1);
    font-size: 1.5rem;
    letter-spacing: 0.05em;
    line-height: 1.1;
}

.pagination :global(.ant-pagination-item-active) {
    border: 0;
    color: rgba(174, 75, 132, 1);
}