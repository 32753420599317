.button_default {
    width: 100%;
    background: #000000;
    box-sizing: border-box;
    align-items: center;
    display: grid;
    grid-auto-flow: column;
    padding: 12px 20px;
    grid-gap: 8px;
    align-content: center;
    justify-content: center;
    white-space: pre;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    transition: .3s all;
}

.button_default:hover {
    background: rgba(0, 0, 0, 0);
    color: #000000;
    box-shadow: inset 0 0 0 2px #000000;
}

.button_blue {
    background: #0373FE;
    color: #fff;
}

.button_default img {
    margin-right: 8px;
    margin-bottom: 2px;
}

.button_bordered {
    border: solid 2px #000000;
    background: transparent;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    width: max-content;
    width: -moz-max-content;
    color: var(--dark-grey);
    transition: .4s all;
}

.button_bordered:hover {
    border: solid 2px #000000;
    background: #000000;
    color: #fff
}

.button_disabled,
.button_black, .button_white {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    padding: 12px 40px;
    width: max-content;
    border: 1px solid #000000;
    outline: 1px solid #000000;
    transition: .3s all;
    background: transparent;
}

.button_disabled {
    color: #a8a8a8;
    border: 1px solid #a8a8a8;
    outline: 1px solid #a8a8a8;
    cursor: not-allowed;
}


.button_black:focus, .button_white:focus {
    outline: 1px solid #000000;
}

.button_white {
    border: 1px solid #FFFFFF;
    outline: 1px solid #FFFFFF;
    color: #FFFFFF;
}

.button_white:focus {
    outline: 1px solid #FFFFFF;
}

.button_black:hover {
    color: #FFFFFF;
    background: #000000;
    box-shadow: none;
}

.button_black_disabled:disabled {
    color: rgba(0, 0, 0, .7);
    background: rgba(0, 0, 0, .2);
    box-shadow: none;
    border: none;
    cursor: not-allowed;
}

.button_black_disabled:disabled {
    color: rgba(0, 0, 0, .7);
    background: rgba(0, 0, 0, .2);
    box-shadow: none;
    border: none;
    cursor: not-allowed;
}

.button_white:hover {
    color: #000000;
    background: #FFFFFF;
    box-shadow: none;
}

.button_bordered.button_hover_disable:hover {
    background: #fff;
    color: var(--dark-grey);
}

.button_default_error {
    border: 1px solid var(--error);
    color: var(--error);
    background: #fff;
}

.button_default_error svg path,
.button_default_error:hover svg path {
    fill: var(--error);
}

.button_default_error span {
    color: var(--error);
}

.button_default_error:hover {
    box-shadow: unset;
}

.button_disabled:hover {
    color: #a8a8a8;
    border: 1px solid #a8a8a8;
    outline: 1px solid #a8a8a8;
    background: transparent;
    cursor: not-allowed;
}

@media (max-width: 568px) {
    .button_default {
        text-align: center;
    }
}

/*.main_button:focus {*/
/*    border: 1px solid #2F80ED;*/
/*}*/

/*.main_button:hover {*/
/*    background: #2F80ED;*/
/*}*/

/*.main_button:active {*/
/*    background: #5C9BFF;*/
/*    border: none;*/
/*    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1);*/
/*}*/

/*.main_button:disabled {*/
/*    background: rgba(92, 155, 255, 0.3);*/
/*    color: rgba(47, 128, 237, 0.5);*/
/*}*/
