.header_container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 2rem 10%;
    display: grid;
    grid-template-columns: 170px 1fr auto;
    justify-content: space-between;
    align-items: center;
    background: #F6F6F1;
    z-index: 3500;
    grid-gap: 2rem;
}

.header_container_under_banner {
    top: 64px;
}

.header_container_under_banner + .collectors-hub-wrapper .collectors-hero {
    margin-top: 12px;
}

.header_banner_container {
    height: 64px;
    background: #000000;
    z-index: 3499;
    grid-template-columns: 1fr 2fr 1fr;
    padding: 0 10%;
    grid-gap: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: grid;
    justify-content: space-between;
    align-items: center;
}

.banner_block {
    height: 64px;
    width: 100%;
    display: block;
}

.header_banner_container p {
    color: white;
    font-size: 16px;
    overflow: visible;
    text-overflow: clip;
}

.header_banner_container img {
    filter: invert(1);
    justify-self: end;
    cursor: pointer;
}

.justify-self-center {
    justify-self: center;
}

.header_container * {
    font-family: 'Poppins', sans-serif;
}

.header_container > .artsted_digital_link {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 10%;
    background: #000000;
    border: 2px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_container > .artsted_digital_link a {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
}

.header_container > .artsted_digital_link a:hover {
    text-decoration-line: underline;
}

.header_container > .logo {
    display: grid;
    align-items: center;
    width: max-content;
}

.header_container > .navigation_links {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(7, max-content);
    grid-gap: 1.5rem;
    margin: 0 auto;
}

.header_container > .navigation_links a {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    width: max-content;
    transition: .3s;
}

.header_container > .navigation_links a.active-link {
    font-weight: 700;
}
.header_container > .navigation_links a:hover {
    text-decoration: underline;
}

.premium-mobile-wrapper {
    transition: .3s background-color;
}

.premium-mobile-wrapper.active {
    background-color: black;
}

.premium-mobile-wrapper .premium-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    transition: .3s;
    cursor: pointer;
}

.premium-mobile-wrapper.active .premium-head {
    padding: 10px;
    color: white;
}

.premium-mobile-wrapper .premium-head svg {
    transition: .3s transform;
}

.premium-mobile-wrapper.active .premium-head svg path {
    stroke: white;

}
.premium-mobile-wrapper.active .premium-head svg {
    transform: rotate(180deg);
}

.premium-mobile-wrapper .premium-content a {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF !important;
    padding: 12px 32px;
}

.premium-mobile-wrapper .premium-content {
    max-height: 0;
    overflow: hidden;
    transition: .3s max-height;
}

.premium-mobile-wrapper.active .premium-content {
    max-height: 200px;
}

/*.discover-now {*/
/*    font-weight: 600;*/
/*    font-size: 12px;*/
/*    line-height: 18px;*/
/*    color: #000000;*/
/*    padding: 12px 40px;*/
/*    width: max-content;*/
/*    border: 1px solid #000000;*/
/*    outline: 1px solid #000000;*/
/*    transition: .3s all;*/
/*    background: transparent;*/
/*}*/

.header_container > .search_login {
    display: grid;
    grid-template-columns: 1fr max-content max-content max-content;
    align-items: center;
    transition: .4s all;
    position: relative;
    height: 40px;
    justify-content: end;
}

.header_container > .search_login_active {
    grid-template-columns: 1fr;
    grid-gap: 0;
}

.header_container > .search_login > .login {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    margin: 0 2rem;
    color: #000000;
}

.header_container > .search_login > .register, .header_container > .search_login > .header_upload {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    padding: 9px 2.5rem;
    border: 2px solid #000000;
    transition: .3s all;
}

.header_container > .search_login > .register:hover, .header_container > .search_login > .header_upload:hover {
    background-color: #000;
    color: #fff;
}

.header_container > .search_login > .header_cart {
    display: flex;
    align-items: center;
    margin: 0 2rem;
}

.header_container > .search_login .user_avatar_wrapper {
    position: relative;
}

.header_container > .search_login .user_avatar_wrapper > .profile_popup {
    position: absolute;
    top: -18px;
    right: 0;
    background: #000000;
    padding: 1.5rem 2rem 2rem 2rem;
    transition: .4s all;
    opacity: 0;
    pointer-events: none;
}

.header_container > .search_login .user_avatar_wrapper > .profile_popup_active {
    pointer-events: auto;
    top: -24px;
    opacity: 1;
}

.header_container > .search_login .user_avatar_wrapper > .profile_popup > .name_avatar {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    grid-gap: 2.5rem;
    margin: 0 0 20px;
}

.header_container > .search_login .user_avatar_wrapper > .profile_popup > .name_avatar span {
    font-size: 18px;
    line-height: 30px;
    color: #F7F6F1;
}

.header_container > .search_login .user_avatar_wrapper > .profile_popup > .name_avatar img {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.header_container > .search_login .user_avatar_wrapper > .profile_popup a, .header_container > .search_login .user_avatar_wrapper > .profile_popup .user_logout {
    display: block;
    width: max-content;
    font-size: 18px;
    line-height: 30px;
    color: #F7F6F1;
    cursor: pointer;
    margin: 0 0 15px;
}

.header_container > .search_login .user_avatar_wrapper > .profile_popup .user_logout {
    margin: 0;
}

.header_container > .search_login .user_avatar {
    display: flex;
    align-items: center;
    margin: 0 2rem;
    cursor: pointer;
    position: static;
    z-index: 10;
}

.header_container > .search_login .user_avatar img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100%;
}

.header_container > .search_login .outside_click_wrapper {
    display: grid;
    /*width: max-content;*/
    align-items: center;
}

.search_label_layout {
    position: relative;
}

.header_container .search_container {
    position: relative;
    border: 1px solid rgba(0, 0, 0, .4);
    display: grid;
    height: 40px;
    padding-left: 38px;
    width: 100%;
    transition: .4s all;
}

.header_container .search_container_active {
    border: 1px solid rgba(0, 0, 0, 1);
}

.header_container .search_container svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 13px;
}

.header_container .search_container input::placeholder, .header_container .search_container input {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, .4);
    transition: .4s all;
}

.header_container .search_container input {
    background: transparent;
    outline: none;
    border: none;
    width: 100%;
    height: 40px;
    color: #000000;
}

.shop_icon_container {
    display: flex;
}

.header-upload-button {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    padding: 9px 2.5rem;
    border: 2px solid #000000;
    transition: .3s all;
    cursor: pointer;
}

.header-upload-button:hover {
    background-color: #000;
    color: #fff;
}

.nft_nav_container {
    display: flex;
    align-items: center;
}

@media (max-width: 1200px) {
    .header_container {
        padding: 2rem 7.5%;
        grid-template-columns: 170px max-content 1fr;
        grid-gap: 2rem;
    }

    .header_banner_container {
        grid-template-columns: 1.4fr 2fr 1fr;
    }

    .header_container > .navigation_links {
        grid-gap: 1.5rem;
    }

    .header_container > .search_login > .login, .header_container > .search_login .user_avatar,
    .header_container > .search_login > .header_cart {
        margin: 0 1.5rem;
    }

    .header_container > .search_login .user_avatar_wrapper > .profile_popup {
        right: -8px;
    }
}

@media (max-width: 1150px) {
    .header_container > .search_login {
        grid-template-columns: 38px max-content max-content;
    }

    .header_container > .search_login_active {
        grid-template-columns: 1fr;
    }

    .header_container .search_container svg {
        transform: translate(-50%, -50%);
        left: 50%;
    }

    .header_container > .search_login_active .search_container svg {
        transform: translate(0, -50%);
        left: 13px;
    }
}

/*@media (max-width: 1025px) {*/
/*    .header_container {*/
/*        grid-gap: 1.5rem;*/
/*    }*/

/*    .header_container > .artsted_digital_link {*/
/*        width: 12%;*/
/*    }*/

/*    .header_container > .search_login {*/
/*        padding-right: 25%;*/
/*    }*/

/*    .header_container > .search_login > .login, .header_container > .search_login .user_avatar,*/
/*    .header_container > .search_login > .header_cart {*/
/*        margin: 0 1rem;*/
/*    }*/

/*    .header_container {*/
/*        grid-gap: 1.25rem;*/
/*    }*/

/*    .header_container .search_container {*/
/*        padding-left: 38px;*/
/*    }*/

/*    .header_container > .search_login .user_avatar_wrapper > .profile_popup {*/
/*        right: -16px;*/
/*    }*/
/*}*/

@media (max-width: 1160px) {
    .header_container .search_container svg {
        transform: translate(0, -50%);
        left: 13px;
    }

    .header_banner_container {
        height: 100px;
        grid-template-columns: unset;
        justify-content: center;
    }

    .banner_block {
        height: 100px;
    }

    .header_banner_container p {
        font-size: 14px;
    }

    .header_banner_container img {
        position: fixed;
        top: 26px;
        right: 30px;
    }

    .header_container_under_banner {
        top: 100px;
    }

    .header_container_mobile {
        padding: 1rem 7.5%;
        display: grid;
        grid-template-columns: 1fr max-content max-content;
        grid-gap: 1.5rem;
    }

    .logout_user {
        grid-template-columns: 1fr max-content max-content;
    }

    .header_container_mobile > .mobile_logo_search {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        align-items: center;
        justify-content: space-between;
    }

    .header_container_mobile > .mobile_logo_search > a {
        display: grid;
        align-items: center;
    }

    .burger {
        padding: 14px 5px 15px;
        cursor: pointer;
        transition: all .6s;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        justify-content: center;
        grid-gap: 2px;
        height: 42px;
        width: 42px;
        position: relative;
    }

    .burger:focus, .burger:hover {
        outline: none !important;
        background: transparent !important;
    }

    .burger div {
        height: 2px;
        background: #000000;
        transition: all .4s;
        width: 32px;
    }

    .burger div:nth-child(1) {
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -35%);
    }

    .burger div:nth-child(2) {
        width: 5px;
        height: 5px;
        margin: 0 auto;
        opacity: 1;
        transition: .2s all;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .burger div:nth-child(3) {
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -65%);
    }

    .burger_crossed div:nth-child(1) {
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    .burger_crossed div:nth-child(2) {
        opacity: 0;
        position: absolute;
        left: 100%;
        transform: translate(-100%, -50%);
    }

    .burger_crossed div:nth-child(3) {
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }

    .burger_crossed {
        pointer-events: none;
    }
}

@media (max-width: 992px) {
    .header_container_mobile {
        padding: 1rem 5%;
    }
}

@media (max-width: 568px) {
    .header_container_mobile > .mobile_logo_search {
        grid-template-columns: max-content 40px;
    }

    .header_container_mobile > .mobile_logo_search_active {
        grid-template-columns: 1fr;
    }

    .header_container_mobile > .mobile_logo_search_active > a {
        display: none;
    }

    .header_container .search_container svg {
        transform: translate(-50%, -50%);
        left: 50%;
    }

    .header_container_mobile .mobile_logo_search_active .search_container svg {
        transform: translate(0, -50%);
        left: 13px;
    }

    .header_container_mobile {
        padding: 1rem;
    }
}
