.admin_login_wrapper {
    background: #F8F8FB;
    position: fixed;
    height: var(--app-height);
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.admin_login_content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30%;
    transform: translate(-50%, -50%);
}

.admin_login_content img {
    display: block;
    max-width: 224px;
    margin: 0 auto 4rem;
}

.admin_login_content form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    justify-content: center;
}

.admin_login_content form input {
    background: #FFFFFF;
    border: 1px solid #C8C7CC;
    transition: .3s all;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #333;
    padding: 12px 16px;
}

.admin_login_content form input::placeholder {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #C8C7CC;
}

.admin_login_content form input:focus {
    border: 1px solid rgba(174, 75, 132, 0.7);
}