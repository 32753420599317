.contact_us_info_footer {
    display: flex;
    justify-content: center;
    width: 40%;
    margin: 0 auto;
}

.recent_article_press {
    display: grid;
    grid-gap: 15px;
}

.recent_article_press a {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    color: var(--dark-grey);
}

.wrapper_info_comp_content {
    display: grid;
    grid-template-columns: 100%;
    align-self: flex-start;
    margin: auto 0;
}

.wrapper_component_info_footer {
    min-height: calc(95vh - 240px);
    padding: 50px 10% 3%;
    display: grid;
    grid-template-columns: 100%;
    align-items: center;

}

.answer_from_footer {
    display: grid;
    grid-gap: 20px;
    align-items: center;
    height: max-content;
}

.answer_from_footer h1,
.answer_from_footer h2 {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    color: var(--dark-grey);
}

.answer_from_footer > p {
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: var(--slate);
}

.categories_of_questions_footer {
    display: grid;
}

.categories_of_questions_footer span {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    color: var(--dark-grey);
}

.categories_of_questions_footer p {
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: var(--slate);
}

.wrapper_info_main {
    display: grid;
    grid-gap: 20px;
}

.data_collect_info {
    padding-top: 10px;
    display: grid;
    grid-gap: 10px 20px;
    grid-template-columns: max-content 1fr;
    align-items: center;
}

.data_collect_info > div {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: var(--new-black);
}

.entity_rules {
    display: grid;
    grid-gap: 15px;
}

.entity_rules h1,
.entity_rules h2 {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    color: var(--dark-grey);
}

.answer_from_footer p,
.title_of_list_purposes li,
.entity_rules p {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: var(--slate)
}

.list_block {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 5px 10px;
}

.entity_rules span,
.products_services_terms span {
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.05;
    letter-spacing: normal;
    color: var(--dark-grey);
}

.data_collect_info span {
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.05;
    letter-spacing: normal;
    color: var(--dark-grey);
}

.list_of_activity_lawful {
    display: grid;
    padding: 20px 5%;
}

.title_of_list_purposes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-bottom: 1px solid var(--lite-grey);
}

.title_of_list_purposes span {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.05;
    letter-spacing: normal;
    color: var(--dark-grey);
}

.title_of_list_purposes li,
.title_of_list_purposes p {
    text-align: center;
    align-self: center;
}

.list_with_variable_words {
    align-self: center;
}

.data_of_rules_public {
    display: grid;
    text-align-last: center;
    padding-top: 20px;
}

.contact_advisory {
    justify-items: left;
    text-transform: capitalize;
}

.contact_advisory p {
    text-align: left;
}

@media (max-width: 1200px) {
    .wrapper_component_info_footer {
        padding: 50px 7.5% 3%;
    }
}

@media (max-width: 992px) {
    .wrapper_component_info_footer {
        padding: 50px 5% 3%;
    }
}

@media (max-width: 768px ) {
    .contact_form_footer {
        padding-top: 0;
    }

    .data_collect_info,
    .list_block {
        grid-template-columns: 1fr;
    }

    .entity_rules {
        display: flex;
        grid-gap: 15px;
        flex-direction: column;
    }

    .wrapper_component_info_footer {
        padding: 20px 5% 3%;
    }

    .title_of_list_purposes span {
        align-self: center;
    }

    .list_of_activity_lawful {
        flex-direction: column;
        display: flex;
        padding: 15px 0;
    }

    .contact_us_info_footer {
        display: grid;
        grid-gap: 10px;
        flex-flow: column;
        width: 100%;
        justify-content: inherit;
    }
}