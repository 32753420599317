$form_bg: #F7F6F1;

.container {
  box-sizing: border-box;
  min-height: var(--app-height);
  @media screen and (max-height: 790px) and (min-width: 1440px) {
    .form_wrapper {

      .form__content {
        padding: 0 8%;
      }
    }
  }

  & * {
    font-family: 'Poppins', sans-serif;
  }

  .form_wrapper {
    padding: 55px 0;
    height: var(--app-height);
    overflow: auto;
    background-color: $form_bg;

    .wrapper_head {
      display: grid;
      grid-template-columns: max-content max-content;
      margin: 0 8%;
      justify-content: space-between;
      align-items: center;
      grid-gap: 40px;

      .form__logo {
        width: 170px;
        height: 34px;
        background: url('../../../assets/img/onboarding/logo.svg') no-repeat center;
        grid-column: 2;
        background-size: contain;
      }

      .form__back {
        grid-column: 1;
        grid-row: 1;
        padding: 8px 12px;

        &:hover svg path {
          fill: #fff
        }
      }

      @media (max-width: 979px) {
        margin: 0 15px;
        .form__logo {
          grid-column: 2;
        }
        .form__back {
          display: grid;
          align-items: start;
          grid-template-columns: max-content 1fr;
          grid-gap: 8px;
          border: none;
          padding: 8px 12px;
          transition: .3s;

          &:hover {
            svg path {
              fill: #fff;
            }
          }

          svg {
            vertical-align: center;
            height: 20px;

            path {
              height: 12px;
            }
          }
        }
        .form__logo {
          width: 45px;
          height: 40px;
          background: url('../../../assets/img/new_landing/artsted_logo_mobile.svg') no-repeat center;
          background-size: contain;
        }
      }


    }

    .form__content {
      padding: 0 20px;
    }
  }

  .picture_wrapper {
    position: relative;
    display: none;
    height: var(--app-height);
    background-color: $form_bg;
    background: no-repeat center;
    background-size: cover;
    font-size: 18px;
    line-height: 30px;

    .picture_artist {
      &.right {
        right: 0;
        left: auto;
      }

      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 144px;
      background-color: black;
      color: #fff;
      padding: 57px 55px 57px 40px;

      .picture_artist__photo {
        width: 64px;
        height: 64px;
        border-radius: 64px;
        object-fit: cover;
        margin: 0 20px 0 30px;
      }

      .picture_artist__name {
        font-weight: 600;
      }
    }
  }

  @media screen and (min-width: 980px) {
    display: flex;
    align-items: center;

    .form_wrapper {
      width: 51.04%;

      .form__logo {
        background-image: url('../../../assets/img/onboarding/logo.svg');
      }

      .form__content {
        padding: 0 8%;
      }
    }

    .picture_wrapper {
      width: 48.97%;
      display: block;
    }
  }
  @media screen and (max-height: 920px) and (min-width: 980px) {
    .form_wrapper {
      padding: 25px 0;
    }
  }

  @media screen and (max-height: 790px) and (min-width: 1440px) {
    .form_wrapper .form__content {
      padding: 0 8%;
    }
  }
}
