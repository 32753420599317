.root {
    display: grid;
    grid-template-columns: 1fr 20rem;
    grid-gap: 1.5rem;
    padding: 2.5rem 10%;
    background-color: var(--beige);
}

.price {
    opacity: 0.64;
    margin-top: auto;
}

.aside {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    padding: 1.5rem;
    background-color: #ffffff;
}

.closeIcon {
    background-color: var(--beige);
}

.label {
    display: inline-block;
    margin-bottom: 0.5rem;
    color: rgba(0, 0, 0, .5);
}

.asideActions {
    display: grid;
    grid-gap: 1rem;
}

@media (max-width: 992px) {
    .root {
        grid-template-columns: 1fr;
    }
}