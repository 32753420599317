.root {
    background-color: #ffffff;
    min-height: 500px;
    height: 100%;
}

.header {
    padding: 1.5rem 2rem;
}

.inner {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    grid-gap: 1rem;
    margin-bottom: 0.5rem;
}

.label {
    font-size: 1.25rem;
    font-weight: 600;
    margin-right: 1rem;
}

.headerLeft {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
}

.dateBadge {
    display: inline-flex;
    min-width: 2rem;
    height: 2rem;
}

.root :global(.rbc-date-cell) {
    text-align: left;
    padding: 0.5rem 1rem;
    font-weight: 300;
    font-size: 0.875rem;
}

.root :global(.rbc-today) {
    background-color: #ffffff;
}

.root :global(.rbc-date-cell.rbc-now) .dateBadge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    font-weight: 400;
    color: #fff;
    background-color: #000;
    border-radius: 50%;
}

.root :global(.rbc-header) {
    padding: 0.5rem 1rem;
    text-align: left;
    font-weight: 500;
    font-size: 0.875rem;
}

.headerDescription {
    font-weight: 300;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.5);
}

.root :global(.rbc-row-content),
.root :global(.rbc-row-content, .rbc-row) {
    height: 100%;
}

.dayContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
}

.price {
    color: rgba(0, 0, 0, 0.65);
}

.root .todayButton {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.root :global(.rbc-off-range) {
    background-color: rgba(0, 0, 0, 0.04);
}

@media (max-width: 992px) {
    .header {
        padding: 1.25rem;
    }
}

@media (max-width: 768px) {
    .root :global(.rbc-date-cell),
    .root :global(.rbc-header) {
        padding: 0.5rem 0.25rem;
    }

    .root :global(.rbc-date-cell.rbc-now) .dateBadge {
        min-width: 1.75rem;
        height: 1.75rem;
    }
}

@media (max-width: 500px) {
    .header {
        padding: 1rem 0.5rem;
        font-size: 0.75rem;
    }

    .headerLeft {
        display: grid;
        grid-template-columns: 1fr 30px 30px;
    }

    .label {
        font-size: 1rem;
        margin-right: 0.25rem;
    }

    .root .todayButton {
        padding: 0.25rem 0.5rem;
    }
}