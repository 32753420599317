.custom_dropdown_wrapper {
    position: relative;
    width: 100%;
}

.custom_dropdown_wrapper_title {
    display: block;
    margin: 0 0 8px;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: var(--dark-grey);
}

.new_custom_dropdown_wrapper_title {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: rgba(0, 0, 0, .5);
    margin: 0 0 1rem;
}

.custom_dropdown_wrapper label {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: .5rem;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding: 8px 10px;
}

.custom_dropdown_wrapper label > input {
    border: none;
    font-size: 16px;
    line-height: 24px;
    color: #2A303C;
    padding: 0;
    cursor: pointer;
    width: 100%;
}

.custom_dropdown_wrapper label > input:disabled {
    background: transparent;
    pointer-events: none;
    cursor: not-allowed;
}

.custom_dropdown_option {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0 10px 15px -3px rgba(26, 32, 44, 0.1), 0 4px 6px -2px rgba(26, 32, 44, 0.05);
    border-radius: 4px;
    z-index: 420;
    width: 100%;
    /*padding: 0 8px 0;*/
    left: 0;
    max-height: 0;
    overflow: hidden;
    transition: .3s all;
}

.active_custom_dropdown {
    max-height: 150px;
    overflow-y: auto;
    /*padding: 0 8px 8px;*/
}

.custom_dropdown_option span {
    font-size: 14px;
    line-height: 22px;
    color: #2A303C;
    display: block;
    border-bottom: 1px solid #EBEBF0;
    padding: 8px 8px;
    cursor: pointer;
}

.custom_dropdown_option span:last-of-type {
    border-bottom: none;
    padding: 8px;
    margin: 0;
}

.custom_dropdown_select {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: .5rem;
    cursor: pointer;
}

.custom_dropdown_border, label.custom_dropdown_border {
    border: none;
    border-bottom: 1px solid #000000;
    padding: 0 8px 4px;
    width: 100%;
    grid-template-columns: 1fr max-content;
    grid-gap: 8px;
}

.custom_dropdown_wrapper label.custom_dropdown_border > input {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    width: 100%;
    color: #000000;
}

.new_dropdown_wrapper_search_disabled .custom_dropdown_border > input, .new_dropdown_wrapper_search_disabled label.custom_dropdown_border > input {
    color: rgba(0, 0, 0, .3);
}

.custom_dropdown_wrapper_search {
    background: #fff;
    border: 1px solid var(--lite-grey);
    cursor: pointer;
}

.new_dropdown_wrapper_search {
    background: transparent;
    border: none;
    cursor: text;
}

.custom_dropdown_wrapper_search_disabled {
    pointer-events: none;
    background: #f5f5f5;
    cursor: not-allowed;
}

.new_dropdown_wrapper_search_disabled {
    pointer-events: none;
    background: transparent;
    cursor: not-allowed;
    border: none;
}

.new_dropdown_wrapper_search_disabled .custom_dropdown_border, .new_dropdown_wrapper_search_disabled label.custom_dropdown_border {
    border-bottom: 1px solid rgba(0, 0, 0, .3);
}

.new_dropdown_wrapper_search_disabled path {
    fill: rgba(0, 0, 0, .3);
}

.new_dropdown_wrapper_search_disabled input::placeholder {
    color: rgba(0, 0, 0, .3);
}

.drawer__wrapper_form_amount_currency input {
    width: 40px;
}

.custom_dropdown_select > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #28293D;
    padding: 8px;
}

.custom_dropdown_select > .active_value {
    padding: 0;
}

.custom_dropdown_select > .active_value_placeholder, .custom_dropdown_select > .active_value_new {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: rgba(0, 0, 0, .5);
}

.custom_dropdown_select > .active_value_new {
    color: rgba(0, 0, 0, 1);
}

.custom_dropdown_wrapper .activeTabIndex {
    background: #000000;
    color: #fff;
}

/*.custom_dropdown_select > img {*/
/*    transition: .3s all;*/
/*}*/

