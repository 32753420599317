.header {
    padding: 3rem 10%;
    background-color: var(--beige);
}

.title {
    font-size: 2rem;
    font-weight: 600;
}

.content {
    display: grid;
    grid-gap: 1.5rem;
    justify-content: center;
    padding: 3rem 10%;
}

.contentGrid {
    grid-template-columns: 1fr 340px;
}

.aside {
    padding-left: 1rem;
    border-left: 1px solid #000000;
}

.root :global(.cart-timer) {
    margin: 16px 0 24px;
    background-color: black;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.root :global(.cart-timer span:not(.time)) {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.015em;
    color: #FFFFFF;
}

.root :global(.cart-timer .time) {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: white;
}

.prices {
    display: grid;
    grid-gap: 0.5rem;
    padding-top: 1rem;
    margin-top: 2rem;
    margin-bottom: 2.5rem;
    border-top: 2px solid #000000;
}

.prices :global(.ant-col) {
    font-size: 1.25rem;
}

.priceTotal :global(.ant-col) {
    font-size: 1.5rem;
    font-weight: 600;
}

.promo {
    display: flex;
    align-items: center;
    grid-gap: 0.75rem;
    margin-top: 0.5rem;
}

.promo :global(.ant-typography) {
    line-height: 1;
    font-weight: 600;
}

.promoWrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 1.5rem;
    align-items: flex-end;
}

.infoBody {
    display: grid;
    grid-gap: 1.5rem;
    margin-top: 2.5rem;
}

.infoInner {
    padding-bottom: 1.25rem;
    border-bottom: 2px solid #000000;
}


.infoInnerGrid {
    display: grid;
    align-items: center;
    grid-gap: 1.25rem;
    grid-template-columns: 1fr 180px;
}

.infoInnerGridLeft {
    display: grid;
    font-weight: 300;
    font-size: 0.875rem;
    grid-gap: 1.5rem;
    line-height: 1.75;
    grid-template-columns: 140px 1fr;
}

.statusWrapper {
    color: inherit;
    text-align: center;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid #000000;
}

.statusIcon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    width: 2rem;
    height: 2rem;
    color: inherit;
    background-color: currentColor;
    border-radius: 50%;
}

.statusIcon svg path {
    fill: #ffffff;
}

.statusDescription {
    font-weight: 300;
    line-height: 2;
    font-size: 0.875rem;
    margin: 1.5rem 0;
}

.promoButton {
    text-align: right;
}

.icon {
    margin-top: 3rem;
}

.root .error {
    margin-bottom: 0;
    margin-top: 0.5rem;
}

.reservedBlock {
    padding: 0.5rem;
    margin: 1rem 0 1.5rem;
    text-align: center;
    color: #ffffff;
    background-color: #000000;
}

.reservedTimer {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.5;
    letter-spacing: 0.015em;
}

.preWrap {
    white-space: pre-wrap;
}

@media (max-width: 1440px) {
    .infoInnerGridLeft {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }
}

@media (max-width: 1200px) {
    .infoInnerGrid {
        grid-template-columns: 1fr;
        justify-content: flex-start;
    }
}

@media (max-width: 992px) {
    .content {
        padding: 1.5rem 1rem;
    }

    .header {
        padding: 1.5rem 1rem 2.25rem;
    }

    .content, .infoInnerGrid {
        grid-template-columns: 1fr;
    }

    .infoInner:last-child {
        border-bottom: 0;
    }

    .aside {
        border: 0;
        padding: 0;
    }
}

@media (max-width: 768px) {
    .title {
        font-size: 1.5rem;
    }
}

@media (max-width: 500px) {
    .promoWrapper {
        grid-template-columns: 1fr;
    }

    .promoButton button {
        width: auto;
    }
}