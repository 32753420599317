.wrapper_gallery {
    margin-top: 3rem;
}

.wrapper_gallery h2 {
    border-bottom: 2px solid #000;
    font-weight: 600;
    font-size: 32px;
    line-height: 56px;
    color: #000;
    padding-bottom: 12px;
}


.wrapper_gallery .artworks_list {
    display: unset;
}
