.modal-window-bg {
    width: 100%;
    height: var(--app-height);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3550;
    background: rgba(0, 0, 0, 0.6);
    /*backdrop-filter: blur(8px);*/
    /*-webkit-backdrop-filter: blur(8px);*/
}

.modal-window-content {
    position: fixed;
    max-width: 432px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3100;
    border: 1px solid #F7F9FC;
    box-sizing: border-box;
    border-radius: 5px;
    background: #FFFFFF;
    padding: 26px;
}

.modal-window-content h4 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.45;
    margin-bottom: 8px;
    color: var(--dark-grey);
}

.modal-window-content p {
    font-size: 18px;
    line-height: 1.45;
    margin-bottom: 8px;
    color: var(--dark-grey);
}

.modal-window_buttons {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 1.5rem;
    align-items: center;
    justify-content: end;
}

@media (max-width: 569px) {
    .modal-window-content {
        width: calc(100% - 2rem);
        margin: 0 auto;
    }

    .modal-window_buttons {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }

    .modal-window_buttons button {
        width: 100%;
    }

    .modal-window_buttons .button_bordered {
        grid-column-start: 1;
        grid-row-start: 1;
        width: 100%;
    }
}