.root.defaultButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    padding: 0.625rem 1.5rem;
    height: auto;
    font-size: 0.75rem;
    font-weight: 600;
    border-radius: 0;
    border: 2px solid #000000;
}

.root.black, .root.black:focus {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000;
}

.root.black:global(.ant-btn-default):hover {
    color: #000000;
    background-color: #ffffff;
    border-color: #000000;
}

.root.white:global(.ant-btn-default), .root.white:global(.ant-btn-default):focus {
    color: #000000;
    background-color: #ffffff;
    border-color: #000000;
}

.root.white:global(.ant-btn-default):hover {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000;
}

.root.transparent:global(.ant-btn-default), .root.transparent:global(.ant-btn-default):focus {
    color: #000000;
    background-color: transparent;
    border-color: #000000;
}

.root.transparent:global(.ant-btn-default):hover {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000;
}

.root.pink {
    border-radius: 8px;
    font-weight: 400;
    padding: 0.5rem 1.5rem;
}

.root.pink.outlined, .root.pink.outlined:hover {
    color: #AE4B84;
    background-color: transparent;
    border: 1px solid #AE4B84;
}

.root.pink.contained, .root.pink.contained:hover {
    color: #ffffff;
    background-color: #AE4B84;
    border: 1px solid #AE4B84;
}