.wrapper {
    position: relative;
    padding: 2.5rem 10%;
    z-index: 81;
}

.heroBg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 200px;
    background-color: #000000;
}

.block {
    width: 100%;
}

.heroSection {
    position: relative;
    z-index: 0;
}

.title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color :#ffffff;
}

.address {
    text-decoration: underline;
    color :#ffffff;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1.5rem;
    margin-top: 2.5rem;
}

.galleryImage {
    position: relative;
    z-index: 1;
}

.galleryImage::before {
    content: "";
    display: block;
    padding-bottom: 56.25%;
}

.galleryImage img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.galleryImage:first-child {
    grid-column: 1/3;
    grid-row: 1/3;
}

.content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: flex-start;
    grid-gap: 1.5rem;
    margin-top: 2.5rem;
}

.wrapper .price, .wrapper .content .noMargin {
    margin: 0;
}

.label {
    font-size: 0.75rem;
    font-weight: 600;
}

.text {
    font-size: 1rem;
    font-weight: 400;
}

.photos {
    margin-top: 2rem;
    margin-left: -1rem;
    margin-right: -1rem;
}

.photos :global(.slick-next),
.photos :global(.slick-prev) {
    color: #ffffff;
    top: auto;
    bottom: 1rem;
    z-index: 2;
}

.photos :global(.slick-dots) {
    bottom: 1rem;
    z-index: 1;
}

.photos :global(.slick-dots li),
.photos :global(.slick-dots li button),
.photos :global(.slick-dots li button:before) {
    width: 1rem;
    height: 1rem;
}

.photos :global(.slick-dots li button:before) {
    font-size: 0.5rem;
    color: #FFFFFF;
}

.photos :global(.slick-dots li.slick-active button:before) {
    color: #FFFFFF;
}

.photos :global(.slick-prev) {
    left: 1rem;
}

.photos :global(.slick-next) {
    right: 1rem;
}

.photos {
    position: relative;
    min-width: 0;
    overflow: hidden;
}

.photos::before {
    content: "";
    display: block;
    padding-bottom: 110%;
}

.photos :global(.slick-slider) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.photos :global(.slick-slider),
.photos :global(.slick-list),
.photos :global(.slick-track),
.photos :global(.slick-slide div) {
    height: 100%;
}

.photos::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    pointer-events: none;
}

.photosSlide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.root .defaultImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlayShowAll {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 1.25rem;
    cursor: pointer;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.5);
    text-decoration: underline;
}

.allImagesModal {
    display: grid;
    grid-gap: 1.5rem;
}

.mainPhoto {
    width: 100%;
}

.mainPhoto img {
    width: 100%;
    height: auto;
}

.imagesList {
    overflow: auto;
    width: 100%;
    display: flex;
    grid-gap: 1.5rem;
    min-width: 0;
}

.imagesListItem {
    position: relative;
    flex-shrink: 0;
    width: calc(20% - 1.5rem);
    cursor: pointer;
}

.imagesListItem::before {
    content: "";
    display: block;
    padding-bottom: 62.5%;
}

.imagesListItem img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.loaderWrapper {
    padding: 6rem 0;
    text-align: center;
}

@media (max-width: 992px) {
    .wrapper {
        padding: 1.5rem 5%;
    }

    .content {
        grid-template-columns: 1fr;
        margin-top: 1.5rem;
    }
}

@media (max-width: 768px) {
    .wrapper {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.asideCardMobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff; /* Ensure it looks nice and clear */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Add a slight shadow for visual separation */
    padding: 16px; /* Add padding for good UX */
}

.bookModalWrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    padding: 1rem;
    background-color: var(--beige);
    border: 1px solid #000000;
    position: sticky;
    top: 185px;
}

.lightMiddleText {
    color: #000000;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
}

.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000BF;
    z-index: 999;
}

.modalContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

.handleIndicator {
    width: 100px;
    height: 5px;
    background-color: var(--new-black);
}

.handleIndicatorWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.modalContainer.open {
    opacity: 1;
    transform: translateY(0);
}

.modalContainer.modalClosing {
    opacity: 0;
    transform: translateY(100%);
}
