.root {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    padding: 1rem;
    background-color: var(--beige);
    border: 1px solid #000000;
}

.root_sticky {
    position: sticky;
    top: 185px;
}

.root_fixed {
    position: fixed;
    bottom: 30px;
}