.collectors-hub-wrapper .collectors-hero {
    background: url("../../assets/img/collectors-hub-background.png") no-repeat center center;
    height: calc(100vh - 104px);
    margin-top: 104px;
    background-size: cover;
    padding-inline: 24px;
    padding-top: 118px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.collectors-hub-wrapper .collectors-shadow {
    position: absolute;
    inset: 0;
    z-index: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 12.05%, #000000 100%);
    opacity: 0.82;
}

.collectors-hub-wrapper .collectors-hero h1 {
    font-weight: 600;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    z-index: 1;
    max-width: 904px;
}

.collectors-hub-wrapper .collectors-hero span {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    max-width: 904px;
    display: block;
    margin-top: 42px;
    z-index: 1;
}

.collectors-hub-wrapper .collectors-hero button {
    width: 135px;
    height: 42px;
    background: #FFFFFF;
    border: 2px solid #000000;
    margin-top: 42px;
    outline: none;
    z-index: 1;
}

.collectors-hub-wrapper .collectors-activities {
    padding: 80px 140px;
    display: flex;
    flex-direction: column;
    background: #F6F6F1;
}

.collectors-hub-wrapper .collectors-activities > h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #000000;
    margin-bottom: 0;
}


.collectors-hub-wrapper .collectors-activities > span {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    margin-bottom: 56px;
}

.collectors-hub-wrapper .collectors-activities .collectors-activity {
    display: grid;
    grid-template-columns: 90px 1fr 262px;
    grid-gap: 112px;
    align-items: center;
    padding-block: 20px;
    border-top: 2px solid #000000;
}

.collectors-hub-wrapper .collectors-activities .collectors-activity-content {
    display: flex;
    flex-direction: column;
}

.collectors-hub-wrapper .collectors-activities .collectors-activity-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.06em;
    color: #000000;
}

.collectors-hub-wrapper .collectors-activities a.collectors-activity-title {
    text-decoration-line: underline;
    text-transform: capitalize;

}

.collectors-hub-wrapper .collectors-activities .collectors-activity-title {
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.02em;
    margin-bottom: 18px;
    color: #000000;
}

.collectors-hub-wrapper .collectors-activities .collectors-activity .collectors-activity-year {
    font-weight: 600;
    font-size: 32px;
    line-height: 64px;
    letter-spacing: 0.02em;
    color: #000000;
}

.collectors-hub-wrapper .collectors-report-wrapper {
    padding: 48px 140px;
}

.collectors-hub-wrapper .collectors-report-wrapper .collectors-report {
    display: flex;
    justify-content: space-between;
    padding: 56px 88px 0;
    background-color: black;
}

.collectors-hub-wrapper .collectors-report-wrapper .collectors-report > img {
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #FFFFFF;
    padding: 6px 8px 0;
    width: 40%;
}

.collectors-hub-wrapper .collectors-report-wrapper .collectors-report-content {
    margin-top: 18px;
}

.collectors-hub-wrapper .collectors-report-wrapper .collectors-report-content h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #FFFFFF;
    margin-bottom: 34px;
    max-width: 403px;
}

.collectors-hub-wrapper .input_form .input_component input {
    background-color: transparent;
    box-shadow: none;
    color: white;
}

.collectors-hub-wrapper .input_form button {
    padding: 0;
}

.collectors-hub-wrapper .stay_in_loop_wrapper {
    background-image: url("../../assets/img/collectors_hub_form_bg.png");
}

.collectors-hub-wrapper .stay_in_loop_bg {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 36px 140px;
    min-height: 472px;
    align-items: center;
}

.collectors-hub-wrapper .stay_in_loop_bg .stay_in_loop_left {
    padding-right: 100px;
}

.collectors-hub-wrapper .stay_in_loop_bg .stay_in_loop_right {
    border-left: 1px solid #FFFFFF;
    height: 100%;
    padding-left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.collectors-hub-wrapper .stay_in_loop_bg .stay_in_loop_right a {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    text-decoration: none;
    padding: 12px 40px;
    background-color: white;
    width: max-content;
}

.collectors-hub-wrapper .current-members {
    padding: 48px 140px;
    position: relative;
}

.collectors-hub-wrapper .current-members h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #000000;
    margin-bottom: 32px;
}

.collectors-hub-wrapper .current-members > img {
    position: absolute;
    top: 40px;
    right: 0;
}

.collectors-hub-wrapper .current-members .slider-member-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
    background: #000000;
    color: white;
    margin-top: 42px;
}

.collectors-hub-wrapper .slick-next:before {
    content: url("../../assets/img/collectors-next-arrow.svg");
}

.collectors-hub-wrapper .slick-prev:before {
    content: url("../../assets/img/collectors-previous-arrow.svg");
}

.collectors-hub-wrapper .slick-prev:before,
.collectors-hub-wrapper .slick-next:before {
    opacity: 1;
}

.collectors-hub-wrapper .slick-prev {
    top: calc(100% + 32px);
    right: 92px;
    left: unset;
}

.collectors-hub-wrapper .slick-next {
    top: calc(100% + 32px);
    right: 20px;
}

.collectors-hub-wrapper .current-members .slider-member-inner > img {
    height: 357px;
    margin-block: -42px;
}

.collectors-hub-wrapper .current-members .slider-member-inner .slider-member-content {
    display: flex;
    flex-direction: column;
    margin-left: 32px;
}

.collectors-hub-wrapper .current-members .slider-member-inner .slider-member-content .slider-member-text {
    font-weight: 400;
    font-size: 22px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 32px;
}

.collectors-hub-wrapper .current-members .slider-member-inner .slider-member-content .slider-member-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
}

.collectors-hub-wrapper .about-us {
    padding: 80px 140px;
    background: #000000;
    display: flex;
    flex-direction: column;
}

.collectors-hub-wrapper .about-us h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #FFFFFF;
}

.collectors-hub-wrapper .about-us > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    margin-block: 12px 56px;
    max-width: 737px;
}

.collectors-hub-wrapper .about-us .about-us-slider-inner {
    display: flex;
    flex-direction: column;
}

.collectors-hub-wrapper .about-us .about-us-slider-inner img {
    width: 100%;
    height: 373px;
    object-fit: cover;
}

.collectors-hub-wrapper .about-us .about-us-slider-inner.reverse-slide {
    flex-direction: column-reverse;
}

.collectors-hub-wrapper .about-us .about-us-slider-inner .about-us-content {
    padding: 56px 40px 0;
    height: 373px;

    display: flex;
    flex-direction: column;
}

.collectors-hub-wrapper .about-us .about-us-slider-inner .about-us-content .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.collectors-hub-wrapper .about-us .about-us-slider-inner .about-us-content .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}

.choose-plan {
    display: flex;
    flex-direction: column;
    padding: 80px 140px;
}

.choose-plan > h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    text-transform: uppercase;
    margin-bottom: 50px;
    color: #000000;
}

.choose-plan .plans-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 48px 24px;
}

.choose-plan .plans-list .plan {
    display: flex;
    flex-direction: column;
    border: 2px solid #000000;
    padding: 72px 32px 32px;
    height: 100%;
}

.choose-plan .plans-list .plan h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #000000;
}

.choose-plan .plans-list .plan .price {
    margin-top: 32px;
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
    text-align: center;
}

.choose-plan .plans-list .plan .price strong {
    font-weight: 600;
}

.choose-plan .plans-list .plan .features {
    display: flex;
    flex-direction: column;
    margin-block: 40px 80px;
}

.choose-plan .plans-list .plan .features > * + * {
    margin-top: 8px;
}

.choose-plan .plans-list .plan .feature {
    display: grid;
    grid-template-columns: 16px 1fr;
    align-items: center;
}

.choose-plan .plans-list .plan .feature svg {
    width: 16px;
    height: 27px;
}

.choose-plan .plans-list .plan .feature span {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
    margin-left: 12px;
}

.choose-plan .plans-list .plan a {
    color: black;
    display: block;
    padding: 12px 40px;
    width: max-content;
    border: 2px solid #000000;
    background-color: white;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    margin-top: auto;
    transition: .3s background-color, .3s color;
}

.choose-plan .plans-list .plan a:hover {
    background-color: black;
    color: white;
}

@media (max-width: 1160px) {
    .collectors-hub-wrapper .collectors-hero {
        height: calc(100vh - 74px);
        margin-top: 74px;
    }

    .collectors-hub-wrapper .collectors-hero {
        padding-bottom: 24px;
    }

    .collectors-hub-wrapper .collectors-report-wrapper,
    .collectors-hub-wrapper .collectors-activities {
        padding-inline: 24px;
    }

    .collectors-hub-wrapper .collectors-hero {
        height: auto;
        min-height: calc(100vh - 74px);
    }

    .collectors-hub-wrapper .about-us {
        padding-inline: 16px
    }
}

@media (max-width: 1100px) {
    .collectors-hub-wrapper .current-members {
        padding-inline: 16px
    }
}

@media (max-width: 1000px) {
    .collectors-hub-wrapper .collectors-report-wrapper .collectors-report > img {
        width: 30%;
        object-fit: cover;
    }

    .collectors-hub-wrapper .collectors-report-wrapper .collectors-report-content {
        margin-bottom: 24px;
    }

    .collectors-hub-wrapper .stay_in_loop_bg {
        padding-inline: 16px;
        grid-gap: 0;
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content;
    }

    .collectors-hub-wrapper .stay_in_loop_bg .stay_in_loop_left {
        padding-right: 0;
        padding-bottom: 48px;
    }

    .collectors-hub-wrapper .stay_in_loop_bg .stay_in_loop_right {
        padding-left: 0;
        padding-top: 48px;
        border-left: none;
        border-top: 1px solid white;
    }
}

@media (max-width: 900px) {
    .collectors-hub-wrapper .collectors-activities .collectors-activity {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, max-content);
        grid-gap: 12px;
        border-top: none;
    }

    .collectors-hub-wrapper .about-us .about-us-slider-inner .about-us-content {
        padding: 40px 16px 0;
    }

    .collectors-hub-wrapper .collectors-activities > .collectors-activity + .collectors-activity {
        border-top: 2px solid #000000;
    }

    .collectors-hub-wrapper .collectors-activities .collectors-activity img {
        grid-row: 1
    }

    .collectors-hub-wrapper .collectors-activities > span {
        margin-bottom: 20px;
    }
}

@media (max-width: 800px) {
    .collectors-hub-wrapper .current-members .slider-member-inner {
        flex-direction: column;
        margin-top: 178px;
        padding-inline: 16px;
        padding-bottom: 80px;
    }

    .collectors-hub-wrapper .current-members .slider-member-inner .slider-member-content {
        margin-left: 0;
    }

    .collectors-hub-wrapper .current-members .slider-member-inner > img {
        margin-top: -178px;
    }

    .collectors-hub-wrapper .current-members > img {
        right: 0;
        transform: translateX(250px);
        top: 0;
    }

    .collectors-hub-wrapper .current-members {
        padding: 48px 0 0;
    }

    .collectors-hub-wrapper .current-members h2 {
        margin-left: 16px;
    }

    .collectors-hub-wrapper .slick-dots {
        bottom: 24px;
    }

    .collectors-hub-wrapper .slick-dots li button:before {
        color: darkgrey;
    }

    .collectors-hub-wrapper .slick-dots li.slick-active button:before {
        color: white;
    }

    .collectors-hub-wrapper .current-members .slider-member-inner .slider-member-content {
        margin-top: 72px;
    }

    .collectors-hub-wrapper .collectors-report-wrapper .collectors-report {
        flex-direction: column;
    }

    .collectors-hub-wrapper .collectors-report-wrapper {
        padding: 0;
    }

    .collectors-hub-wrapper .collectors-report-wrapper .collectors-report {
        padding: 40px 16px 0;
    }

    .collectors-hub-wrapper .collectors-report-wrapper .collectors-report > img {
        width: 50%;
    }

    .collectors-hub-wrapper .collectors-report-wrapper .collectors-report-content {
        margin-bottom: 40px;
    }
}

@media (max-width: 700px) {
    .collectors-hub-wrapper .collectors-hero h1 {
        font-weight: 600;
        font-size: 30px;
        line-height: 34px;
    }

    .collectors-hub-wrapper .collectors-hero span {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.06em;
    }

    .collectors-hub-wrapper .collectors-hero {
        padding-top: 48px;
        margin: 100px 16px;
        min-height: calc(100vh - 120px);
    }

    .choose-plan {
        padding: 40px 16px;
    }
}

@media (max-width: 650px) {
    .collectors-hub-wrapper .collectors-report-wrapper .collectors-report > img {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .collectors-hub-wrapper .current-members .slider-member-inner {
        padding-inline: 16px;
    }

    .collectors-hub-wrapper .current-members h2 {
        max-width: 250px;
    }

    .collectors-hub-wrapper .current-members h2 {
        max-width: 250px;
    }
}