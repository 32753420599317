.profile_section_body_cv {
    padding: 26px 20%;
}

.profile_section_body_cv .video_wrapper {
    position: relative;
}

.profile_section_body_cv .video_wrapper .video_preview_landing {
    background: #000;
    cursor: pointer;
    box-shadow: unset;
}

.profile_section_body_cv .video_wrapper .upload_file {
    margin-top: 4rem;
}

.profile_section_body_cv .video_wrapper .delete_icon {
    display: none;
}

.profile_section_body_cv .video_wrapper:hover .delete_icon {
    display: block;
}

.profile_section_body_cv .video_wrapper .delete_icon {
    position: absolute;
    top: 0;
    border: 1px solid #000;
    right: 0;
    z-index: 10;
    cursor: pointer;
}

.about-artist-container p {
    user-select: none;
}

.profile_section_body_cv .wrapper_progress_bar {
    margin-top: 4rem;
    display: grid;
}

.profile_section_body_cv .wrapper_progress_bar span {
    color: #000;
    font-size: 16px;
    letter-spacing: 0.06em;
    display: block;
    margin-top: 16px;
}

.profile_section_body_cv .wrapper_progress_bar .progressbar-progress {
    background-color: #000 !important;
    height: 10px;
}

.profile_section_body_cv .wrapper_submit_button {
    display: grid;
    margin-top: 24px;
}

.profile_section_body_cv .wrapper_about_field {
    margin-top: 32px;
}

.profile_section_body_cv .submit-institution {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 56px;
    padding: 40px;
    background-color: black;
    align-items: center;
}

.profile_section_body_cv .submit-institution > span {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
}

.profile_section_body_cv .submit-institution button {
    border: 2px solid #FFFFFF;
    background: #000;
    color: #fff;
    margin-top: 24px;
}

.modal-window-content {
    max-width: 404px;
    width: 100%;
    max-height: 90vh;
    overflow: auto;
}

.submit-institution-modal h4 {
    font-weight: 600;
    line-height: 45px;
}

.submit-institution-modal .modal-window-content > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0;
}

.submit-institution-modal .close {
    top: 40px;
    right: 26px;
    position: absolute;
    cursor: pointer;
}

.submit-institution-modal .horizontal-line {
    height: 1px;
    background-color: black;
    margin: 16px 0 24px;
}

.submit-institution-modal .subtitle,
.submit-institution-modal .input_component span:not(.error_message) {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: rgba(0, 0, 0, .5);
    margin: 0 0 8px;
}

.submit-institution-modal .error.custom_input {
    border: none !important;
    border-bottom: 1px solid var(--error) !important;
}

.submit-institution-modal .subtitle,
.submit-institution-modal .modal-window-content > div:not(.modal-window-buttons, .checkbox_wrapper) {
    margin-top: 24px;
}

.submit-institution-modal .subtitle {
    display: block;
}

.submit-institution-modal .new_custom_dropdown_wrapper_title {
    margin-bottom: 8px;
}

.submit-institution-modal .input_component input {
    border: none;
    border-bottom: 1px solid #000000;
    padding: 0 8px 4px;
}

.submit-institution-modal .modal-window-content button {
    width: 100%;
}

.submit-institution-modal .modal-window-content .modal-window_buttons {
    grid-template-columns: 1fr 1fr;
    margin-top: 40px;
}

.submit-institution-modal .checkbox_wrapper {
    margin-top: 12px;
}

.submit-institution-modal .checkbox_wrapper p {
    margin: 0 !important;
    font-size: 14px;
    line-height: 30px;
}

.profile_section_body_cv .wrapper_about_field .wrapper_title {
    display: grid;
    grid-gap: 0 12px;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
}

.profile_section_body_cv .wrapper_about_field .wrapper_title svg {
    cursor: pointer;
}

.profile_section_body_cv .wrapper_about_field .border_bottom_input {
    padding: 8px 0;
    /*border: none;*/
}

.about-artist-container {
    position: relative;
}

.premium-accounts-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px;
    border: 1px solid #000000;
    background-color: #F6F6F1;
}

.profile_section_body_cv .entries_section_preview__title {
    margin-top: 32px;
}

.profile_section_body_cv .entries_section_preview__title span {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.33px;
    color: #000;
    text-transform: uppercase;
}

.profile_section_body_cv .entries_section_preview__list {
    margin-top: 16px;
    display: grid;
    grid-gap: 8px;
}

.profile_section_body_cv .entries_section_preview__list_wrapper {
    display: grid;
    grid-gap: 14px;
    grid-template-columns: max-content 1fr 1fr max-content max-content;
    align-items: center;
}

.profile_section_body_cv .entries_section_preview__list_wrapper svg {
    opacity: 0;
    cursor: pointer;
}

.profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title .country {
    padding-left: 6px
}

.profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title_link {
    cursor: pointer;
}

.profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title_link:hover {
    text-decoration: rgba(0, 0, 0, .6);
}

.profile_section_body_cv .entries_section_preview__list_wrapper .date,
.profile_section_body_cv .entries_section_preview__list_wrapper .description,
.profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title span {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000;
    word-break: break-word;
}

.profile_section_body_cv .entries_section_preview__list_wrapper .description,
.profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title .country {
    color: rgba(0, 0, 0, .6);
}

.profile_section_body_cv .entries_section_preview__list_wrapper .date {
    font-weight: 600;
}

.profile_section_body_cv .entries_section_preview__list_wrapper:hover svg {
    opacity: 1;
}

.profile_section_body_cv .entries_section_preview__list .empty_list {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000
}

.profile_section_body_cv .entries_section_preview__list .add_entry {
    margin-top: 8px;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    cursor: pointer;
    align-items: center;
    grid-gap: 24px;
    width: max-content;
}

.profile_section_body_cv .entries_section_preview__list .add_entry .text {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #000
}

.profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title .title .file {
    position: relative;
    padding: 4px 8px;
    vertical-align: text-top;
}

.profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title .title .file:hover .download_tool_tip {
    display: block;
}

.profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title .title .file > svg {
    opacity: 1;
    vertical-align: text-top;
}

.profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title .title .file .download_tool_tip {
    position: absolute;
    padding: 14px 16px;
    top: -54px;
    left: 20px;
    width: 130px;
    z-index: 1000;
    background: #000;
    display: none;
    cursor: pointer;
}

.profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title .title .file .download_tool_tip span {
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.015em;
    color: #F7F6F1;
    padding-left: 8px;
}

.profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title .title .file .download_tool_tip:hover span {
    text-decoration: underline;
}

.profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title .title .file .download_tool_tip svg {
    vertical-align: text-top;
}

/*cv_entries_section_body*/
.profile_section_body_cv .cv_entries_section_body {
    background: #F6F6F1;
    padding: 24px;
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px 32px;
}

.profile_section_body_cv .cv_entries_section_body .custom_dropdown_wrapper label.custom_dropdown_border {
    border: 1px solid #000;
    display: grid;
    padding: 5.5px 12px;
}

.profile_section_body_cv .cv_entries_section_body .custom_dropdown_wrapper label.custom_dropdown_border {

}

.profile_section_body_cv .cv_entries_section_body .input_component {
    margin: 0 !important;
}

.profile_section_body_cv .cv_entries_section_body .input_component input {
    padding: 10.5px 12px;
}

.profile_section_body_cv .cv_entries_section_body .custom_dropdown_wrapper input {
    background: transparent;
}

.profile_section_body_cv .cv_entries_section_body .new_custom_dropdown_wrapper_title {
    margin-bottom: 8px;
}

.profile_section_body_cv .cv_entries_section_body .custom_dropdown_wrapper label.custom_dropdown_border > input {
    padding: 5px 0;
}

.profile_section_body_cv .cv_entries_section_body .input_component input,
.profile_section_body_cv .cv_entries_section_body .input_component input::placeholder,
.profile_section_body_cv .cv_entries_section_body .custom_dropdown_wrapper label.custom_dropdown_border > input,
.profile_section_body_cv .cv_entries_section_body .new_custom_dropdown_wrapper_title,
.profile_section_body_cv .cv_entries_section_body .wrapper_picker .ant-picker-input > input,
.profile_section_body_cv .cv_entries_section_body .title,
.profile_section_body_cv .cv_entries_section_body .wrapper_proofs__file_container span,
.profile_section_body_cv .cv_entries_section_body .devide_inputs,
.profile_section_body_cv .cv_entries_section_body .input_component span {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #8E8E8E;
}

.profile_section_body_cv .cv_entries_section_body .ant-picker,
.profile_section_body_cv .cv_entries_section_body .wrapper_proofs__file_container,
.profile_section_body_cv .cv_entries_section_body .input_component input {
    background: transparent;
    border: 1px solid #000;
}

.profile_section_body_cv .cv_entries_section_body .wrapper_proofs {
    grid-column: 1/-1;
    display: grid;
    grid-gap: 9px;
    grid-template-columns: 1fr max-content 1fr;
}

.profile_section_body_cv .cv_entries_section_body .wrapper_proofs .devide_inputs {
    align-self: center;
    margin-bottom: -20px;
}

.profile_section_body_cv .cv_entries_section_body .wrapper_proofs__file_container {
    padding: 8px;
    width: 100%;
    cursor: pointer;
    position: relative;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 16px;
    align-items: center;
}

.profile_section_body_cv .cv_entries_section_body .wrapper_proofs__file {
    position: relative;
}

.profile_section_body_cv .cv_entries_section_body .wrapper_proofs__file .title {
    display: block;
    text-align: left;
    margin: 0 0 8px;
}

.profile_section_body_cv .cv_entries_section_body .wrapper_proofs__file .delete_file {
    position: absolute;
    right: 6px;
    top: 4px;
    z-index: 10;
    padding: 6px;
}

.profile_section_body_cv .cv_entries_section_body button {
    grid-column: 1/-1;
    justify-self: end;
    margin-top: 8px;
}

.profile_section_body_cv .cv_entries_section_body .wrapper_picker .title {
    margin: 0 0 8px;
    display: block;
}

.ant-picker-cell::after {
    border: unset !important;
}

.profile_section_body_cv .cv_entries_section_body .wrapper_picker .ant-picker {
    width: 100%;
    border: 1px solid #000;
}

.profile_section_body_cv .cv_entries_section_body .ant-picker,
.profile_section_body_cv .cv_entries_section_body .wrapper_picker .ant-picker-range {
    background: transparent;
    padding: 10.5px 12px;
}


.profile_section_body_cv .cv_entries_section_body .wrapper_picker .ant-picker-input > input {

}

.profile_section_body_cv .cv_entries_section_body .wrapper_picker .ant-picker-range:hover,
.profile_section_body_cv .cv_entries_section_body .wrapper_picker .ant-picker-focused {
    border: 1px solid #000 !important;
    box-shadow: none;
}

.profile_section_body_cv .cv_entries_section_body .wrapper_picker .ant-picker-range.ant-picker-focused .ant-picker-active-bar {
    background: #000;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #000 !important;
    color: #fff !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: transparent !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #F6F6F1 !important;
}

.ant-picker-header-view button:hover {
    color: unset !important;
}

.ant-picker-cell .ant-picker-cell-inner:focus-visible {
    border: unset !important;
}

/*cv_entries_section_body*/

.profile_section_body_cv .wrapper_context_field {
    grid-column: 1/-1;
}

.profile_section_body_cv .wrapper_education_field,
.profile_section_body_cv .wrapper_career_field {
    margin-top: 24px;
}

.profile_section_body_cv .wrapper_career_field__sections {
    margin-top: 24px;
}

.profile_section_body_cv .cv_entries_section_body .input_component .entry_error .error_message {
    font-weight: 500;
    font-size: 12px;
    color: var(--new-error);
}

.profile_section_body_cv .cv_entries_section_body .wrapper_picker .entry_error {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 8px;
    align-items: center;
    color: var(--new-error);
}

.profile_section_body_cv .cv_entries_section_body .wrapper_picker_error .ant-picker {
    border: 1px solid var(--new-error);
}

.profile_section_body_cv .new_modal_wrapper .modal-window-content {
    width: 1200px;
}

.ant-picker-header {
    display: grid !important;
    grid-template-columns:  20px 1fr 20px !important
}

@media (max-width: 1400px) {
    .profile_section_body_cv .new_modal_wrapper .modal-window-content {
        width: 900px;
    }
}


@media (max-width: 1100px) {
    .profile_section_body_cv {
        padding: 26px 10%;
    }

    .profile_section_body_cv .new_modal_wrapper .modal-window-content {
        width: unset;
    }

    .profile_section_body_cv .video_wrapper .delete_icon {
        display: block;
    }
}

@media (max-width: 992px) {
    .profile_section_body_cv {
        padding: 26px 10%;
    }
}

@media (max-width: 768px) {
    .profile_section_body_cv {
        padding: 2rem 1rem;
    }


    .profile_section_body_cv .entries_section_preview__list_wrapper {
        grid-template-columns: 1fr 15px 15px;
    }

    .profile_section_body_cv .entries_section_preview__list_wrapper .date {
        grid-column: 1;
        grid-row: 1;
    }

    .profile_section_body_cv .entries_section_preview__list_wrapper .edit {
        grid-column: 2;
        grid-row: 1;
    }

    .profile_section_body_cv .entries_section_preview__list_wrapper svg {
        opacity: 1;
    }

    .profile_section_body_cv .entries_section_preview__list_wrapper .delete {
        grid-column: 3;
        grid-row: 1;
    }

    .profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title {
        grid-column: 1/-1;
        grid-row: 2;
    }

    .profile_section_body_cv .entries_section_preview__list_wrapper .description {
        grid-column: 1/-1;
        grid-row: 3;
    }

    .profile_section_body_cv .entries_section_preview__title {
        margin-top: 4rem;
    }

    .profile_section_body_cv .entries_section_preview__list .add_entry {
        margin-top: 16px;
    }

    .profile_section_body_cv .entries_section_preview__title:first-child {
        margin-top: 48px;
    }

    .profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title .title .file .download_tool_tip {
        display: block;
    }

    .profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title .title .file .outside_click_wrapper {
        position: absolute;
        top: -4px;
        left: 0;
    }
}

@media (max-width: 668px) {
    .ant-picker-panel-container .ant-picker-panels {
        display: grid !important;
        max-width: unset !important;
    }
}

@media (max-width: 568px) {
    .profile_section_body_cv {
        padding: 2rem 0;
    }

    .profile_section_body_cv .new_modal_wrapper .modal-window-content {
        width: 90vw;
    }

    .profile_section_body_cv .new_modal_wrapper .modal-window-content .title {
        grid-template-columns: 1fr max-content;
    }

    .profile_section_body_cv .progressbar-container {
        border-top: 1px solid #000;
    }

    .profile_section_body_cv .cv_entries_section_body {
        grid-template-columns: 1fr;
        padding: 24px 16px;
    }

    .profile_section_body_cv .cv_entries_section_body .wrapper_proofs {
        grid-template-columns: 1fr;
        grid-gap: 16px;
    }

    .profile_section_body_cv .cv_entries_section_body .wrapper_proofs .devide_inputs {
        margin-bottom: 0;
        text-align: center;
    }

    .profile_section_body_cv .video_wrapper .upload_file {
        margin-top: 24px;
    }

    .profile_section_body_cv .entries_section_preview__list_wrapper .wrapper_title .title .file .outside_click_wrapper {
        position: absolute;
        top: -4px;
        left: -50px;
    }

    .premium-accounts-block {
        width: 100%;
        text-align: center;
    }
}
