.email_subscription_popup_wrapper {
    position: fixed;
    z-index: 5500;
    background: rgba(0, 0, 0, .63);
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.email_subscription_popup_wrapper > .content {
    position: absolute;
    z-index: 101;
    max-height: 500px;
    align-self: center;
    left: 0;
    right: 0;
    margin: auto;
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: start;
    max-width: 700px;
    width: 700px;
}

.email_subscription_popup_wrapper > .content > .left_side_image {
    display: grid;
    align-items: center;
    height: 100%;
}

.email_subscription_popup_wrapper > .content > .left_side_image img {
    width: 100%;
    max-width: 100%;
    height: inherit;
    object-fit: cover;
}

.email_subscription_popup_wrapper > .content > .text {
    background: #ffffff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem;
}

.email_subscription_popup_wrapper > .content > .text > img {
    display: block;
    margin: 0 0 1.5rem;
    width: max-content;
}

.email_subscription_popup_wrapper > .content > .text > h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    margin: 0 0 8px;
}

.email_subscription_popup_wrapper > .content > .text > p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
    margin: 0 0 1rem;
}

.email_subscription_popup_wrapper > .content > .text > form > .inline > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 45px;
    color: #000000;
    cursor: pointer;
}

.email_subscription_popup_wrapper > .content > .text > form > .inline > span:hover {
    text-decoration-line: underline;
}

.email_subscription_popup_wrapper > .content > .text .input_component span {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #8E8E8E;
    margin: 0 0 12px;
}

.email_subscription_popup_wrapper > .content > .text .input_component .border_bottom_input, .email_subscription_popup_wrapper > .content > .text .input_component .border_bottom_input::placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.2);
}

.email_subscription_popup_wrapper > .content > .text .input_component .border_bottom_input {
    color: rgba(0, 0, 0, 1);
    padding: 0 0 12px;
}

.email_subscription_popup_wrapper > .content > .text .inline {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    padding-right: 8px;
}

@media (max-width: 750px) {
    .email_subscription_popup_wrapper > .content > .text {
        padding: 40px 38px 45px;
    }


    .email_subscription_popup_wrapper > .content {
        grid-template-columns: 1fr;
        max-width: unset;
        width: calc(100% - 4rem);
        max-height: unset;
    }

    .email_subscription_popup_wrapper > .content > img {
        width: 100%;
        object-fit: cover;
    }
}

@media (max-width: 568px) {
    .email_subscription_popup_wrapper > .content {
        width: calc(100% - 2rem);
    }

    .email_subscription_popup_wrapper > .content > .left_side_image img {
        max-height: 200px;
    }

    .email_subscription_popup_wrapper > .content > .text {
        padding: 16px 16px 24px;
    }

    .email_subscription_popup_wrapper > .content > .text .inline {
        grid-template-columns: max-content;
        justify-content: center;
        text-align: center;
        grid-gap: 1.5rem;
        padding-right: 0;
    }

    .email_subscription_popup_wrapper > .content > .text > form > .inline > span {
        text-decoration: underline;
        grid-column: 1;
        grid-row: 2;
    }
}

@media (max-width: 325px) {
    .email_subscription_popup_wrapper > .content > .text > h2 {
        font-size: 23px;
        line-height: 1.17;
        margin: 0 0 9px;
    }

    .email_subscription_popup_wrapper > .content {
        width: calc(100% - 2rem);
    }
}
