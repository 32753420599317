.root {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: #656565;
    border-radius: 8px;
    border: 1px solid #EFEFF4;
}

.text {
    font-size: 0.625rem;
    color: rgba(101, 101, 101, 1);
}

.image {
    position: relative;
    overflow: hidden;
}

.image::before {
    content: "";
    display: block;
    padding-bottom: 70%;
}

.image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    flex-grow: 1;
    padding: 0.5rem 1rem;
    background-color: #F8F8FB;
}

.price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 0.625rem;
}

.price + .price {
    margin-top: 0.75rem;
}

.price b {
    font-size: 1rem;
    font-weight: 600;
    color: #000000;
}

.title {
    font-weight: 600;
    font-size: 0.875rem;
    color: #000000;
}

.address {
    font-size: 0.625rem;
}

.name {
    font-size: 0.75rem;
}

.status {
    font-weight: 600;
    font-size: 0.75rem;
}

.activeStatus {
    color: #27AE60;
}