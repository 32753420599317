.checkbox_wrapper {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
    cursor: pointer;
}

.checkbox_wrapper .input_checkbox_wrap {
    width: 28px;
    height: 28px;
    padding: 5px;
    display: grid;
    align-items: center;
    justify-content: center;
}

.checkbox_wrapper .input_checkbox_wrap .input_checkbox_border {
    border: 2px solid #000000;
    border-radius: 1px;
    width: 18px;
    height: 18px;
    padding: 4px;
    position: relative;
}

.checkbox_wrapper .input_checkbox_wrap .input_checkbox_border div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(.8);
    width: 10px;
    height: 10px;
    background: #FFFFFF;
    transition: .2s;
}

.checkbox_wrapper .input_checkbox_wrap .input_checkbox_border_active div {
    background: #000000;
    transform: translate(-50%, -50%) scale(1);
}