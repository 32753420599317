.settings_wrapper {
    width: 100%;
    margin: 3.75rem 0 6rem;
}

.settings_wrapper .personal_info_wrap {
    margin: 0 auto 4.5rem;
    max-width: 726px;
    width: 100%;
}

.settings_wrapper .personal_info_wrap .personal_info_layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2.5rem 1.5rem;
    align-items: center;
}

.settings_wrapper .personal_info_wrap .personal_info_layout .half_screen {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2.5rem 1.5rem;
    align-items: center;
    grid-column: 1/-1;
}

.settings_wrapper .personal_info_wrap .personal_info_layout .half_screen_country {
    align-items: start;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2.5rem 1.5rem;
}

.settings_wrapper .personal_info_wrap .personal_info_layout .half_screen_country a {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    text-decoration-line: underline;
    color: #000000;
    width: max-content;
    margin-left: auto;
}

.settings_wrapper .personal_info_wrap .personal_info_layout .half_screen_categories {
    grid-gap: 20px 24px;
}

.settings_wrapper .personal_info_wrap .personal_info_layout .full_screen {
    grid-column: 1/-1;
}

.settings_wrapper .personal_info_wrap .personal_info_layout .about_yourself .input_component {
    margin: 0;
    display: grid;
    align-items: center;
}

.settings_wrapper .personal_info_wrap .personal_info_layout .social_links {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    align-items: center;
}

.settings_wrapper .upload_work_options_container {
    max-width: 100%;
}

.settings_wrapper .upload_work_options_container > .categories_options {
    background: #000000;
    border-radius: 32px;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    grid-gap: 8px;
    margin: 0 24px 16px 0;
}

.settings_wrapper h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    margin: 0 auto 1.5rem;
    max-width: 726px;
    width: 100%;
}

.settings_wrapper .wrapper_change_password {
    margin: 0 auto 3.5rem;
    max-width: 726px;
    width: 100%;
}

.settings_wrapper .wrapper_change_password .half_screen {
    width: 50%;
}

.settings_wrapper .wrapper_change_password .inputs_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
}

.settings_wrapper .wrapper_change_password h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    margin: 0 0 1.5rem;
}

.settings_wrapper .subscription_plan {
    background: #000000 url("../../../assets/img/settings_subscribe_bg.svg") no-repeat center;
    background-size: cover;
    text-align: center;
    padding: 40px 0 52px;
}

.settings_wrapper .subscription_plan_active {
    background: #000000 url("../../../assets/img/settings_subscribe_bg_active.svg") no-repeat center;
    padding: 48px 0 44px;
    background-size: cover;
}

.settings_wrapper .subscription_plan_active .unsubscribe {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    text-align: right;
    letter-spacing: 0.015em;
    text-decoration-line: underline;
    color: #F5F8FF;
    cursor: pointer;
}

.settings_wrapper .subscription_plan h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
    margin: 0 auto 2.5rem;
}

.settings_wrapper .subscription_plan h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    margin: 0 0 2rem;
}

.settings_wrapper .subscription_plan .membership_content {
    max-width: 430px;
    margin: 0 auto;
}

.settings_wrapper .subscription_plan p {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: left;
    letter-spacing: 0.015em;
    color: #FFFFFF;
    margin: 0 0 1rem;
}

.settings_wrapper .subscription_plan .membership_content .options, .settings_wrapper .subscription_plan .membership_content .options .item {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    align-items: center;
    margin: 0 0 2.5rem;
}

.settings_wrapper .subscription_plan .membership_content .options .item {
    grid-template-columns: max-content 1fr;
    grid-gap: 12px;
    align-items: start;
    text-align: left;
    margin: 0;
}

.settings_wrapper .subscription_plan .membership_content .options .item span {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #FFFFFF;
}

.settings_wrapper .subscription_plan .membership_content .options .item svg {
    margin: 8px 0 0;
}

.settings_wrapper .subscription_plan .inline, .settings_wrapper .subscription_plan .inline_price {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
    justify-content: center;
    margin: 0 0 8px;
}

.settings_wrapper .subscription_plan .inline_price {
    grid-gap: 0;
    margin: 0 0 44px;
}

.settings_wrapper .subscription_plan .inline h5, .settings_wrapper .subscription_plan .inline span {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #FFFFFF;
}

.settings_wrapper .subscription_plan .inline span {
    font-weight: 300;
}

.settings_wrapper .subscription_plan a {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 12px 40px;
    display: block;
    width: max-content;
    border: 2px solid #FFFFFF;
    margin: 0 auto;
}

.settings_wrapper .notifications {
    margin: 3.5rem auto 6rem;
    max-width: 726px;
    width: 100%;
}

.delete {
    border: 1px solid #EB4250;
    width: 160px;
    height: 42px;
    color: #EB4250;
    font-weight: 600;
    align-self: flex-start;
    margin-top: 56px;
}

.settings_wrapper .notifications h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    margin: 0 0 1.5rem;
}

.settings_wrapper .notifications > .checkbox_wrapper {
    grid-template-columns: 1fr;
    justify-content: start;
    grid-gap: 12px;
    cursor: auto;
}

.settings_wrapper .notifications > .checkbox_wrapper .wrapper_check_box {
    justify-self: start;
    margin: 0;
}

@media (max-width: 1400px) {
    .settings_wrapper .subscription_plan {
        background-size: auto;
    }
}

@media (max-width: 992px) {
    .settings_wrapper .subscription_plan_active {
        padding: 2rem 0;
        background-size: contain;
        background-position: bottom;
    }

    .settings_wrapper .subscription_plan h2 {
        font-size: 28px;
        line-height: 38px;
        margin: 0 auto 2rem;
    }
}

@media (max-width: 700px) {
    .settings_wrapper .personal_info_wrap .personal_info_layout, .settings_wrapper .personal_info_wrap .personal_info_layout .half_screen, .settings_wrapper .personal_info_wrap .personal_info_layout .half_screen_country {
        grid-template-columns: 1fr;
        grid-gap: 1.5rem 0;
    }

    .settings_wrapper .upload_work_options_container > .categories_options {
        padding: 4px 8px;
        grid-gap: 8px;
        margin: 0 12px 8px 0;
    }

}

@media (max-width: 568px) {
    .settings_wrapper .wrapper_change_password .half_screen {
        width: 100%;
    }

    .settings_wrapper .subscription_plan_active, .settings_wrapper .subscription_plan {
        width: calc(100% + 2rem);
        margin: 0 0 0 -1rem;
        padding: 1rem 1rem 0;
        background: #000000;
    }

    .settings_wrapper .subscription_plan h2 {
        font-size: 24px;
        line-height: 32px;
        margin: 0 auto 1.5rem;
    }

    .settings_wrapper .subscription_plan p {
        font-size: 16px;
    }

    .settings_wrapper .subscription_plan img {
        width: calc(100% + 2rem);
        margin-left: -1rem;
    }

    .settings_wrapper .subscription_plan_active .unsubscribe {
        margin: 0 auto 3rem;
        display: block;
        width: max-content;
    }

    .settings_wrapper .subscription_plan a {
        margin: 0 auto 3rem;
    }
}
