.wrapper {
    background: #F7F6F1;
    font-size: 18px;
    line-height: 25px;
    color: #8E8E8E;

    .buy_item {
        margin-top: 30px;
        p {
            font-style: italic;
            font-weight: 300;
            font-size: 18px;
            line-height: 30px;
            color: #1A1A1A;
            margin-bottom: 20px;
        }
    }

    .buy_item_2 {
        margin-top: 60px;

        p {
            padding: 0 45px;
            font-style: italic;
            font-weight: 300;
            font-size: 18px;
            line-height: 30px;
            color: #1A1A1A;
            margin-bottom: 20px;
        }
    }

    .why h3{
        margin-bottom: 30px;
    }

    .help_wrapper {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 80px;

        .help_text {
            padding: 20px;
            background: #fff;

            h3 {
                font-weight: bold;
                font-size: 25px;
                line-height: 37px;
                color: #1A1A1A;
                margin: 16px 0;
            }

            p {
                font-weight: normal;
                font-size: 24px;
                line-height: 35px;
                color: #8E8E8E;
            }

            img {
                width: 170px;
                height: 33px;
            }
        }

        .help_img {
            max-width: 100%;
            padding: 0 20px;
        }
    }

    .recomendations {
        margin-top: -10px;
    }

    .recomendation_item {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        .recomendation_item__name {
            font-weight: 600;
            font-size: 25px;
            line-height: 35px;
            color: #000000;
            cursor: pointer;

            &:hover {
                color: rgba(178, 95, 0, 1);
            }
        }

        .recomendation_item__category {
            font-weight: 300;
            font-size: 18px;
            line-height: 35px;
            color: #8E8E8E;
            margin-top: 0;
        }
    }

    .recomendation_img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .section_illustration {
        margin: 60px 0;
    }

    .main_illustration {
        margin: 80px 0;
    }

    .slider {
        height: 100px;
        background-color: green;
    }

    h3 {
        font-weight: 600;
        font-size: 25px;
        line-height: 35px;
        color: #000000;
    }

    h4 {
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        color: #1A1A1A;
    }

    .ruoff {
        h3 {
            margin: 40px 0 20px;
        }

        h4 {
            margin-bottom: 15px;
        }

        .btn {
            width: 100%;
            padding: 10px 40px;
        }
    }

    .sunday_art {
        h3 {
            margin-bottom: 30px;
        }
    }

    h4+p+h4 {
        margin-top: 60px;
    }

    p+p {
        margin-top: 20px;
    }

    .curated {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;
        padding: 80px 0 30px;

        h2 {
            font-weight: 600;
            font-size: 30px;
            line-height: 45px;
            text-align: center;
            color: #1A1A1A;
            margin-bottom: 60px;
        }

        p {
            margin: 0;
            font-style: italic;
            font-weight: 300;
            font-size: 18px;
            line-height: 25px;
            color: #000000;
        }

        .curated_text {}
    }

    .curated_img {
        width: 100%;
        max-height: 290px;
        height: 90.62vw;
    }
}

.slider {
    background-color: gray;
    height: 100px;
}

.white_block {
    background: #fff;
    color: #1A1A1A;
    padding: 102px 32px;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    max-height: 100vh !important;
    text-align: center;

    position: sticky;
    position: -webkit-sticky;
    top: 74px;
}

.artsted_sticky_img {
    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    top: 74px;
    max-height: 100vh !important;
}

@media screen and (min-width: 568px) {
    .wrapper {

        .buy_item {
            margin-top: 50px;
            p {
                font-size: 25px;
                line-height: 30px;
            }
        }

        .buy_item_2 {
            p {
                padding: 0;
            }
        }

        .why {
            margin-top: 50px;

            h3 {
                font-size: 35px;
                line-height: 50px;
            }
        }

        .help_wrapper {
            flex-direction: row;

            .help_text {
                padding: 30px 50px;
                width: 48.82vw;

                h3 {
                    font-size: 30px;
                    line-height: 45px;
                    margin: 18px 0;
                }

                p {
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 35px;
                    color: #8E8E8E;
                }

                img {
                    width: 170px;
                    height: 33px;
                }
            }

            .help_img {
                max-width: 100%;
                padding: 0;
                width: 51.18vw;
                aspect-ratio: 4/3;
            }
        }

        .recomendations {
            margin-top: 0;
        }

        .recomendation_item {
            margin-bottom: 40px;
        }

        .main_illustration {
            margin: 120px 0;
        }

        .ruoff {
            h3 {
                margin: 35px 0 15px;
            }

            .btn {
                padding: 15px 60px;
            }
        }

        .curated {
            padding: 0;

            h2 {
                font-size: 40px;
                line-height: 60px;
                margin-bottom: 0;
            }

            p {
                font-size: 18px;
                line-height: 25px;
                text-align: center;
            }
        }

        .curated_img {
            max-height: 520px;
            max-height: calc(100vh - 74px);
            height: 50.78vw;
        }

        .curated_text {
            position: absolute;
            bottom: 60px;
        }
    }

    .white_block {
        padding: 140px 62px 140px 50px;
        font-size: 40px;
        line-height: 60px;
    }

}

@media screen and (min-width: 1440px) {
    .wrapper {
        font-size: 24px;
        line-height: 35px;

        .buy_item {
            margin-top: 40px;
        }

        .buy_item_2 {
            p {
                font-size: 25px;
                line-height: 30px;
                margin-bottom: 25px;
            }
        }

        .help_wrapper {
            flex-direction: row;

            .help_text {
                padding: 80px;
                width: 55.56vw;

                h3 {
                    font-size: 40px;
                    line-height: 60px;
                    margin: 114px 0 114px;
                }

                p {
                    font-size: 24px;
                    line-height: 35px;
                }
            }

            .help_img {
                max-width: 100%;
                padding: 0;
                width: 44.44vw;
                aspect-ratio: 4/3;
            }
        }

        .recomendation_item {
            margin-bottom: 70px;

            .recomendation_item__name {
                font-weight: 600;
                font-size: 35px;
                line-height: 50px;
            }

            .recomendation_item__category {
                font-weight: 300;
                font-size: 18px;
                line-height: 35px;
            }
        }

        h3 {
            font-size: 35px;
            line-height: 50px;
        }

        h4 {
            font-size: 24px;
            line-height: 35px;
        }

        .ruoff {
            h3 {
                margin: 35px 0 30px;
            }

            h4 {
                margin-bottom: 20px;
            }

            .btn {
                padding: 15px 78px;
            }
        }

        .curated {
            h2 {
                font-size: 55px;
                line-height: 75px;
            }

            p {
                font-size: 25px;
                line-height: 35px;
            }
        }

        .curated_img {
            max-height: 720px;
            height: 50vw;
            max-height: calc(100vh - 104px);
        }
    }

    .white_block {
        padding: 202px 115px 218px 80px;
        font-size: 55px;
        line-height: 75px;
        top: 104px;
    }

    .artsted_sticky_img {
        top: 104px;
    }
}