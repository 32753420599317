.root :global(.react-datepicker-popper) {
    z-index: 9999;
}

.root :global(.react-datepicker) {
    border-radius: 0;
    border-color: #000000;
    z-index: 9999;
}

.root :global(.react-datepicker-wrapper) {
    display: block;
}

.root :global(.react-datepicker-wrapper input) {
    cursor: pointer;
    width: 100%;
    padding: 0.8125rem 1rem;
    border: 2px solid #000000;
    font-size: 0.75rem;
    background-color: transparent;
}

.root :global(.react-datepicker-wrapper input::placeholder) {
    color: #000000;
}

.root :global(.react-datepicker__view-calendar-icon input) {
    padding-right: 2rem;
}

.root :global(.react-datepicker__day-name) {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
}

.root :global(.react-datepicker__day-name),
.root :global(.react-datepicker__day) {
    width: 2rem;
    line-height: 2rem;
    border-radius: 0;
}

.root :global(.react-datepicker__day--in-selecting-range),
.root :global(.react-datepicker__day--in-range) {
    color: #000000;
    background-color: rgba(0, 0, 0, 0.1);
}

.root :global(.react-datepicker__day--selected),
.root :global(.react-datepicker__day--range-end) {
    color: #ffffff;
    background-color: #000000;
    border-radius: 50%;
}

.root :global(.react-datepicker__calendar-icon) {
    right: 1rem;
    top: 50%;
    padding: 0;
    width: 1.25rem;
    height: 1.25rem;
    transform: translateY(-50%);
}

.root :global(.react-datepicker__month-container) {
    padding: 1.5rem;
}

.root :global(.react-datepicker__navigation) {
    top: 2rem;
}

.root :global(.react-datepicker__navigation--next) {
    right: 4rem;
}

.root :global(.react-datepicker__navigation--previous) {
    left: 4rem;
}

.root :global(.react-datepicker__header) {
    background-color: transparent;
    border: 0;
}

.root :global(.react-datepicker__current-month) {
    margin-bottom: 1rem;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 0.015em;
}

.root :global(.react-datepicker__year-read-view--down-arrow),
.root :global(.react-datepicker__month-read-view--down-arrow),
.root :global(.react-datepicker__month-year-read-view--down-arrow),
.root :global(.react-datepicker__navigation-icon::before) {
    border-width: 1px 1px 0 0;
    border-color: #000000;
}

.root.underline :global(.react-datepicker-wrapper input) {
    border: 0;
    border-bottom: 1px solid #000000;
}

.root.transparent :global(.react-datepicker-wrapper input) {
    border: 0;
}

.rootError.underline :global(.react-datepicker-wrapper input),
.rootError :global(.react-datepicker-wrapper input) {
    border-color: var(--error);
}

.root :global(.react-datepicker__close-icon) {
    width: auto !important;
    right: 0.375rem;
}

.root :global(.react-datepicker__close-icon::after) {
    width: 1.875rem;
    height: 1.875rem;
    font-size: 1.25rem;
    color: #000000;
    background-color: #ffffff;
}

@media (max-width: 500px) {
    .root {
        position: relative;
    }
}

@media (min-width: 992px) {
    .root :global(.react-datepicker) {
        display: inline-flex;
    }
}