.mobile_menu_wrapper {
    height: calc(100% - 74px);
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100%;
    width: 50%;
    z-index: 1000;
    background: var(--beige);
    transition: .6s all;
    margin: 74px 0 0;
    padding: 2.25rem 20px 0;
    overflow-y: auto;
}

.mobile_menu_wrapper_active {
    right: 0;
}

.mobile_menu_wrapper > .signed_user_wrapper {
    background: #000000;
    margin: 0 0 1rem;
    -ms-overflow-y: hidden;
    overflow-y: hidden;
}

.mobile_menu_wrapper > .signed_user_wrapper .user_content_wrapper {
    display: grid;
    padding: 1rem;
    cursor: pointer;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
}

.mobile_menu_wrapper > .signed_user_wrapper .avatar_wrapper {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 8px;
}

.mobile_menu_wrapper > .signed_user_wrapper .user_content_wrapper .upload {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    display: block;
    width: max-content;
    padding: 10px 38px;
    border: 2px solid #FFFFFF;
}

.mobile_menu_wrapper > .signed_user_wrapper .user_content_wrapper .cart {
    display: flex;
    align-items: center;
}

.mobile_menu_wrapper > .signed_user_wrapper .avatar_wrapper img {
    width: 56px;
    height: 56px;
    object-fit: cover;
    border-radius: 100%;
}

.mobile_menu_wrapper > .signed_user_wrapper .avatar_wrapper span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.mobile_menu_wrapper > .signed_user_wrapper .signed_user_content {
    padding: 0 1rem;
    transition: .3s all;
    max-height: 0;
}

.mobile_menu_wrapper > .signed_user_wrapper .signed_user_content_active {
    padding: 8px 1rem 1.5rem;
    max-height: 200px;
}

.mobile_menu_wrapper > .signed_user_wrapper .signed_user_content a, .mobile_menu_wrapper > .signed_user_wrapper .signed_user_content .user_logout {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 1.5rem;
    color: #FFFFFF;
    display: block;
    width: max-content;
    cursor: pointer;
}

.mobile_menu_wrapper > .signed_user_wrapper .signed_user_content .user_logout {
    margin: 0;
}

.mobile_menu_wrapper > .navigation_links {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    align-items: center;
    width: 100%;
    margin: 0 0 2rem;
}

.mobile_menu_wrapper > .navigation_links a {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    display: block;
    width: max-content;
    transition: .3s;
}

.mobile_menu_wrapper > .navigation_links a.active-link {
    font-weight: 700;
}
.mobile_menu_wrapper > .login_buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    align-items: center;
    justify-content: center;
    margin: 0 auto 4.25rem;
}

.mobile_menu_wrapper > .login_buttons > .register, .mobile_menu_wrapper > .login_buttons > .login {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    padding: 12px 40px;
    text-align: center;
    display: block;
    width: max-content;
    border: 2px solid #000000;
    margin: 0 auto;
}

.mobile_menu_wrapper > .login_buttons > .login {
    border: none;
}

.mobile_menu_wrapper > .artsted_digital_link {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    padding: 12px 0;
    display: block;
    width: 100%;
    background: #000000;
    border: 2px solid #000000;
    text-decoration-line: underline;
    color: #FFFFFF;
    margin: 0 0 2rem;
}

@media (max-width: 768px) {
    .mobile_menu_wrapper {
        width: 60%;
    }
}

@media (max-width: 568px) {
    .mobile_menu_wrapper {
        width: 100%;
    }
}

@media (max-width: 350px) {
    .mobile_menu_wrapper > .navigation_links {
        grid-gap: 1.5rem;
        margin: 2.25rem 0 1.5rem;
    }

    .mobile_menu_wrapper > .login_buttons {
        grid-gap: 16px;
        margin: 0 auto 2rem;
    }
}

@media (max-width: 325px) {

    .mobile_menu_wrapper {
        padding: 1rem 20px 0;
    }
}