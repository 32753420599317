.root {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  cursor: pointer;
  user-select: none;
  appearance: none;
  text-decoration: none;
  font: inherit;
  line-height: 1.5;
  padding: .25rem;
  border-radius: 0;
  background-color: transparent;
  border: 1px solid transparent;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.inherit {
  color: inherit
}

.round {
  border-radius: 50%
}

.icon {
  display: inline-block;
  flex-shrink: 0;
  user-select: none;
  fill: currentColor;
  width: 1em;
  height: 1em;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.icon.small {
  font-size: 1.25em;
}

.icon.medium {
  font-size: 1.5em;
}

.icon.large {
  font-size: 2.25em;
}

.disabled {
  opacity: .6;
  pointer-events: none ;
}
