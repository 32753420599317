.header_search_popup {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    display: grid;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-top: none;
    min-height: 160px;
}

.header_search_popup .loader_wrapper {
    height: 100%;
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
}

.header_search_popup h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    padding: 10px 37px;
    text-transform: capitalize;
}

.header_search_popup .content .items {
    width: 100%;
    padding: 10px 37px;
    background: var(--beige);
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    align-items: center;
}

.header_search_popup a {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 8px;
    width: max-content;
}

.header_search_popup a img {
    border-radius: 100%;
    width: 32px;
    height: 32px;
    object-fit: cover;
}

.header_search_popup .content span, .header_search_popup .content .items > span {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    height: 1.2em;
    white-space: nowrap;
}
