.wrapper {
    overflow: hidden;
}

.content {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}

.content.open {
    max-height: unset;
}

.toggleButton {
    cursor: pointer;
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.3);
    width: auto !important;
}

.toggleButton:hover {
    color: rgba(0, 0, 0, 0.8);
}