.user_cart_wrapper {
    margin: 52px 0 0;
}

.wrapper_payment_middleware {

}

.wrapper_payment_middleware .wrapper_title {
    text-align: center;
}

.wrapper_payment_middleware .wrapper_title span {
    font-size: 16px;
    color: var(--dark-grey);
}

.wrapper_payment_middleware .wrapper_btn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 20px;
}

.wrapper_payment_middleware .wrapper_btn button {
    width: 100%;
}

.user_cart_wrapper_content {
    padding: 0 10%;
    margin-bottom: 96px;
}

.user_cart_navigation {
    font-size: 13px;
    line-height: 1.46;
    color: var(--dark-grey);
    display: block;
    margin: 0 0 1.5rem;
}

.user_cart_wrapper_background {
    height: 150px;
    position: absolute;
    top: 89px;
    width: 100%;
    z-index: -1;
    background-color: var(--pale-grey);
}

.user_cart_container {
    display: grid;
    grid-template-columns: 1fr 365px;
    grid-gap: 24px;
    align-items: start;
    position: relative;
}

.user_cart_price_checkout {
    height: max-content;
    padding: 8px 0 8px 24px;
    background-color: #ffffff;
    border-left: 2px solid #000;
    display: flex;
    flex-direction: column;
    min-height: 690px;
}

.user_cart_price_checkout > .button_default {
    margin-top: 40px;
}

.user_cart_price_checkout > svg {
    cursor: pointer;
    margin-top: auto;
}

.user_cart_price_checkout > svg.extend {
    margin-top: 64px;
}

.user_cart_price_checkout > .empty {
    margin-top: 24px;
}

.user_cart_price_checkout > .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
}

.user_cart_price_checkout .cart-timer {
    margin: 16px 0 24px;
    background-color: black;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user_cart_price_checkout .cart-timer span:not(.time) {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.015em;
    color: #FFFFFF;
}

.user_cart_price_checkout .cart-timer .time {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: white;
}

.user_cart_price_checkout .cart-items > .cart-artwork + .cart-artwork {
    margin-top: 24px;
}

.user_cart_price_checkout .cart-artwork {
    display: grid;
    grid-template-columns: 70px 1fr;
    grid-gap: 16px;
}

.user_cart_price_checkout .cart-artwork > span {
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
}

.user_cart_price_checkout .cart-artwork .artwork-content {
    display: flex;
    flex-direction: column;
    position: relative;
}

.user_cart_price_checkout .user_cart_order_total_price_container > .line {
    width: 100%;
}

.user_cart_price_checkout .cart-artwork .artwork-content svg {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.user_cart_price_checkout .cart-artwork .artwork-content .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: black;
}

.user_cart_price_checkout .cart-artwork .artwork-content .additional-info {
    font-weight: 400;
    font-size: 13px;
    opacity: 0.5;
}

.user_cart_price_checkout .cart-artwork .artwork-content .price {
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.015em;
}

.user_cart_price_checkout .cart-artwork img {
    width: 100%;
}

.user_cart_price_checkout h2 {
    font-size: 42px;
    font-weight: bold;
    line-height: 1.48;
    color: var(--dark-grey);
    margin: 0 0 10px;
}

.user_cart_price_checkout_line {
    margin: 0 -24px;
    width: calc(100% + 48px);
    height: 1px;
    background: var(--lite-grey);
}

.user_cart_price_checkout p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.38;
    color: var(--dark-grey);
    margin: 10px 0;
}

.user_cart_price_checkout p:last-of-type {
    margin: 20px 0;
}

.user_cart_price_checkout_accept {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 11px 8px;
    align-items: center;
    margin-bottom: 20px;
}

.user_cart_price_checkout_accept div {
    width: 100%;
    height: 33px;
    border-radius: 1px;
    display: grid;
    align-items: center;
    justify-content: center;
    background-color: #fcfeff;
    padding: 4px;
}

.user_cart_price_checkout_accept div > img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.user_cart_order_details {
    position: relative;
    margin: 0 0 40px;
}

.user_cart_order h1 {
    font-size: 34px;
    font-weight: bold;
    line-height: 1.32;
    color: var(--dark-grey);
    margin: 0;
}

.user_cart_order h2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.45;
    color: var(--dark-grey);
    margin: 0 0 10px;
}

.user_cart_order p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.63;
    color: var(--dark-grey);
    margin: 0 0 40px;
}

.user_cart_order_step {
    position: absolute;
    top: -2px;
    left: -50px;
    width: 30px;
    height: 30px;
    display: grid;
    align-items: center;
    justify-content: center;
}

.user_cart_order_step span {
    display: block;
    margin: auto;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: #000;
}

.user_cart_order_item {
    display: grid;
    grid-template-columns: 115px 1fr;
    grid-gap: 2.75rem;
    align-items: center;
}

.user_cart_order_item .user_cart_order_item_content {
    border-bottom: solid 1px var(--lite-grey);
    display: grid;
    align-items: center;
    grid-template-columns: 1fr max-content 100px 105px;
    grid-gap: 24px;
}

.user_cart_order_item_border_line {
    height: 54px;
    width: 1px;
    margin: auto 0;
    background: var(--lite-grey);
}

.user_cart_order_item_user h5 {
    font-size: 17px;
    font-weight: bold;
    line-height: 2.65;
    margin: 0;
    color: var(--dark-grey);
}

.user_cart_order_item_user p {
    font-size: 16px;
    font-style: italic;
    line-height: 1.25;
    margin: 0 0 8px;
    color: var(--dark-grey);
}

.user_cart_order_item_user > div span {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.43;
    color: var(--slate);
    margin: 0;
}

.user_cart_order_item_number p {
    font-size: 17px;
    line-height: 2.65;
    color: var(--dark-grey);
    margin: 0;
}

.user_cart_order_item_content_price {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 65px);
    grid-gap: 2rem;
}

.user_cart_order_item_content_price span {
    font-size: 20px;
    font-weight: bold;
    line-height: 2.25;
    margin: 0;
    color: var(--dark-grey);
}

.user_cart_order_item_content_price img {
    cursor: pointer;
}

.user_cart_order_total_price_container {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: end;
    margin: 2rem 0 0;
}

.user_cart_order_total_price_container > .line {
    width: 46%;
    height: 3px;
    background: var(--dark-grey);
    margin: 0 0 22px auto;
}

.user_cart_order_total_price_container > .total_price {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user_cart_order_total_price_container > .total_price + .total_price {
    margin-top: 12px;
}

.user_cart_wrapper button:disabled {
    opacity: .5;
    pointer-events: none;
    cursor: not-allowed;
}

.user_cart_order_total_price_container h6 {
    font-size: 20px;
    line-height: 1.45;
    color: var(--dark-grey);
    margin: 0;
}

.user_cart_order_total_price_container .total_price.bold h6 {
    font-weight: bold;
}

.user_cart_order_total_price_container span {
    font-size: 24px;
    margin: 0;
    line-height: 1.21;
    color: #000;
}

.user_cart_order_total_price_container .total_price.bold span {
    font-weight: bold;
}

.user_cart_shipping_details, .user_cart_shipping_options {
    position: relative;
}

.user_cart_promocode .input_component_unset_margin {
    width: 100%;
}

.user_cart_promocode {
    display: flex;
    margin-top: 32px;
    flex-direction: column;
}

.user_cart_promocode .promo-row {
    display: flex;
}

.user_cart_promocode button span {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
}

.user_cart_promocode button {
    /*padding: 12px 40px;*/
    /*border: 2px solid #000000;*/
    /*font-weight: 600;*/
    /*font-size: 12px;*/
    /*line-height: 18px;*/
    margin-top: 24px;
    margin-left: 24px;

}

.user_cart_promocode .promocode-status {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    grid-column: 1/-1;
    margin-top: 12px;
}

.user_cart_promocode .promocode-status.success {
    color: #69C967;
}

.user_cart_promocode .promocode-status.error {
    color: #FF4949;
}

.user_cart_promocode .promocode-status img {
    margin-right: 12px;
}

.user_cart_shipping_details_info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 18px 44px;
    align-items: start;
    margin: 0 0 32px;
}

.user_cart_shipping_details_info .hidden-inputs {
    max-height: 0;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 18px 44px;
    align-items: start;
    grid-column: 1/-1;
    transition: .3s;
}

.user_cart_shipping_details_info .hidden-inputs.active {
    max-height: 200px;
}

.user_cart_shipping_details_info > .custom_dropdown_wrapper_title + div {
    padding: 0;
}

.user_cart_shipping_options_basic {
    margin-bottom: 20px;
}

.user_cart_shipping_options_basic, .user_cart_shipping_options_premium {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background: #ffffff;
    transition: 1s all;
    cursor: pointer;
    width: 100%;
    padding: 20px 3vw 24px 1.75vw;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 20px;
    position: relative;
}

.user_cart_shipping_options_basic_icon, .user_cart_shipping_options_premium_icon {
    z-index: 1;
}

.user_cart_shipping_options_basic::before, .user_cart_shipping_options_premium::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    z-index: -1;
    transition: opacity .4s linear;
    opacity: 0;
}

.user_cart_shipping_options_basic_icon div, .user_cart_shipping_options_premium_icon div {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffff;
}

.user_cart_shipping_options_premium_icon path, .user_cart_shipping_options_basic_icon path {
    transition: .4s all;
}

.user_cart_shipping_options_premium_active .user_cart_shipping_options_premium_icon path, .user_cart_shipping_options_basic_active .user_cart_shipping_options_basic_icon path {
    fill: var(--new-black)
}

.user_cart_shipping_options_details_text {
    display: grid;
    width: auto;
    grid-template-columns: max-content 1fr;
    grid-gap: 6px;
    align-items: baseline;
}

.user_cart_shipping_options_details {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    z-index: 1;
    max-width: 250px;
    word-break: break-all;
}

.user_cart_shipping_options_details_text span {
    font-size: 14px;
    transition: .4s all;
    font-weight: 300;
    line-height: 1.64;
    color: var(--dark-grey);
}

.user_cart_shipping_options_card_content h3 {
    font-size: 22px;
    font-weight: bold;
    transition: .4s all;
    line-height: 1.44;
    color: var(--dark-grey);
}

.user_cart_shipping_options_card_content h4 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.44;
    transition: .4s all;
    color: var(--dark-grey);
    text-transform: uppercase;
}

.user_cart_shipping_options_card_content p {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: var(--dark-grey);
    margin: 0 0 11px;
    transition: .4s all;
    z-index: 1;
    position: relative;
}

.user_cart_shipping_options_card_content_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user_cart_shipping_options_card_content {
    padding: 11px 0 0;
}

.user_cart_shipping_options_card_content_time {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    grid-gap: 4px;
    align-items: end;
}

.user_cart_shipping_options_card_date {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: max-content 1fr;
    align-items: baseline;
    z-index: 1;
}

.user_cart_shipping_options_card_date > .grid-align {
    transform: translateY(2px);
    height: max-content;
}

.user_cart_shipping_options_card_date p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.64;
    color: var(--dark-grey);
    margin: 0 0 4px;
    transition: .4s all;
}

.user_cart_shipping_options_card_date span {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.64;
    color: var(--dark-grey);
    transition: .4s all;
    margin: 0 0 4px;
}

.user_cart_shipping_options_basic_active::before, .user_cart_shipping_options_premium_active::before {
    opacity: 1;
    background: linear-gradient(290deg, #ea93be 102%, var(--new-black) 7%);
    z-index: 0;
}

.user_cart_shipping_options_basic_active h3, .user_cart_shipping_options_premium_active h3, .user_cart_shipping_options_basic_active h4, .user_cart_shipping_options_premium_active h4, .user_cart_shipping_options_basic_active p, .user_cart_shipping_options_premium_active p, .user_cart_shipping_options_basic_active span, .user_cart_shipping_options_premium_active span {
    color: #FFFFFF;
    z-index: 1;
}

.user_cart_shipping_options_card_date path, .user_cart_shipping_options_card_date circle {
    transition: .4s all;
    stroke: var(--grey);
}

.user_cart_shipping_options_basic_active .user_cart_shipping_options_card_date path, .user_cart_shipping_options_premium_active .user_cart_shipping_options_card_date path, .user_cart_shipping_options_basic_active .user_cart_shipping_options_card_date circle, .user_cart_shipping_options_premium_active .user_cart_shipping_options_card_date circle {
    transition: .4s all;
    stroke: #fff;
}

.user_cart_subscription_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.user_cart_subscription_wrapper > div {
    width: 100%;
}

.user_cart_subscription_wrapper h3 {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    color: var(--dark-grey);
    margin: 0 0 5px;
    text-align: center;
}

.user_cart_subscription_wrapper h3 + p {
    text-align: center;
}

.user_cart_subscription_wrapper p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.43;
    color: var(--dark-grey);
    margin: 0;
}

.user_cart_subscription_wrapper h4 {
    font-size: 20px;
    font-weight: bold;
    color: var(--dark-grey);
    margin: 0;
}

.user_cart_subscription_item_border_line {
    height: 1px;
    width: 100%;
}

.user_cart_subscription_item_text {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 13px;
    margin-bottom: 2px;
}

.user_cart_subscription_item_text:last-of-type {
    margin-bottom: 0;
}

.user_cart_subscription_image_wrapper {
    width: 82px;
    height: 82px;
    border-radius: 100%;
    background-color: var(--pale-grey);
    display: grid;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0px auto;
}

.user_cart_order_item_preview a {
    display: grid;
    align-items: center;
}

.user_cart_order_item_preview a > img {
    max-width: 115px;
    height: 5.5rem;
    width: 100%;
    object-fit: cover;
}

.user_cart_subscription_image_wrapper div {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #FFFFFF;
    height: 20px;
}

.user_cart_subscription_image_wrapper img {
    display: block;
    margin: 20px 0 auto;
    position: relative;
    z-index: 10;
    max-width: 100%;
}

.user_cart_subscription_item_text p {
    font-size: 16px;
    line-height: 1.44;
    color: var(--dark-grey);
    margin: 8px 0 !important;
    max-width: 320px;
}

.nft-order-item-line {
    border-top: 1px solid;
}

.nft-order-item-line .user_cart_order_item {
    padding: 10px 0 10px 0;
    border-bottom: 1px solid;
}

.nft-order-item-container {
    border-bottom: 1px solid #000000;
}

.user_cart_order_item_content_price_container {
    display: flex;
    flex-direction: column;
}

.nft-item-details-container {
    margin-bottom: 24px;
}

.nft-item-details-container h1 {
    margin-bottom: 48px;
}

.nft-full-price-container {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nft-full-price-container-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.nft-full-price-container-button {
    width: 131px;
    height: 42px;
    border: 2px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    transition: 0.3s;
}

.nft-full-price-container-button:hover {
    color: white;
    background-color: black;
}

.cart-steps {
    padding: 0 10%;
    display: flex;
    margin-top: 48px;
}

.cart-steps > * + * {
    margin-left: 24px;
}

.cart-steps .cart-step {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: .3s;
    opacity: .32;
    border-bottom: 2px solid transparent;
}

.cart-steps .cart-step svg {
    margin-left: auto;
}

.cart-steps .cart-step span {
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000000;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    margin-right: 12px;
    transition: .3s;
}

.cart-steps .cart-step.active {
    border-color: black;
    opacity: 1;
}

.cart-steps .cart-step.active span {
    background: #000000;
    color: white;
}

.order-details-wrapper .wrapper_check_box p {
    margin: 0;
}

.order-details-wrapper > .wrapper_check_box + .wrapper_check_box {
    margin-top: 8px;
}

.order-details-wrapper .continue {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.order-details-wrapper .title,
.shipping-details-wrapper .title,
.payment-details-wrapper .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    display: block;
}

.order-details-wrapper .title {
    margin-bottom: 24px;
    text-align: center;
}

.payment-details-wrapper .bank_cards h6 {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    opacity: .5;
}

.payment-details-wrapper .bank_cards .numbers {
    display: flex;
}

.payment-details-wrapper .bank_cards button {
    width: 213px;
}

.payment-details-wrapper .bank_cards .numbers svg {
    margin-right: 12px;
}

.payment-details-wrapper .title {
    margin-bottom: 4px;
}

.payment-details-wrapper .button_default path {
    transition: .3s;
}

.payment-details-wrapper .button_default:hover path {
    fill: black;
}

.shipping-details-wrapper .new-address-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.payment-details-wrapper .shipping-details-footer,
.shipping-details-wrapper .shipping-details-footer {
    display: flex;
}

.payment-details-wrapper .shipping-details-footer {
    margin-top: 56px;
}

.payment-details-wrapper .shipping-details-footer button,
.shipping-details-wrapper .new-address-header button,
.shipping-details-wrapper .shipping-details-footer button {
    width: 140px;
}

.shipping-details-wrapper .input_component span {
    color: black !important;
}

.shipping-details-wrapper .input_component .custom_input:disabled {
    color: black !important;
    pointer-events: none !important;
    background: #f5f5f5 !important;
    cursor: not-allowed !important;
}

.shipping-details-wrapper .input_component span.input_component_error_text {
    color: var(--error) !important;
}

.order-details-wrapper .continue button {
    width: 113px;
}

.order-details-wrapper .or {
    display: flex;
    align-items: center;
    margin: 44px 0;
}

.order-details-wrapper .or div {
    background-color: #000000;
    height: 2px;
    width: 100%;
}

.order-details-wrapper .or span {
    margin: 0 18px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
}

.order-details-wrapper > button {
    margin-left: auto;
    margin-top: 48px;
    width: 140px;
}

.cart-footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 124px;
    grid-gap: 24px;
    padding: 0 10%;
}

.cart-footer.mobile {
    display: none;
}

.cart-footer .footer-item {
    display: grid;
    grid-template-columns: max-content 1fr;
    background: #F6F6F1;
    grid-gap: 12px;
    padding: 24px 16px;
    border: 1px solid #000000;
}

.cart-footer .footer-item > div {
    display: flex;
    flex-direction: column;
}

.cart-footer .footer-item .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
}

.cart-footer .footer-item span:not(.title) {
    font-weight: 300;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.015em;
    margin-top: 4px;
}

.shipping-details-wrapper .new-address {
    max-height: 0;
    transition: .3s;
    overflow: hidden;
}

.shipping-details-wrapper .new-address.active {
    max-height: 1000px;
}

.shipping-details-wrapper .add-new-address {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    text-decoration-line: underline;
    display: block;
    margin-bottom: 40px;
    cursor: pointer;
}

.shipping-details-wrapper .shipping-addresses {
    margin: 32px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
}

.shipping-details-wrapper .shipping-addresses .shipping-address .shipping-address-content {
    display: flex;
    flex-direction: column;
}

.shipping-details-wrapper .shipping-addresses .shipping-address strong,
.shipping-details-wrapper .shipping-addresses .shipping-address span {
    line-height: 26px;
}

.shipping-details-wrapper .shipping-addresses .shipping-address .confirmed {
    font-weight: 600;
    line-height: 21px;
    color: #69C967;
}

.shipping-details-wrapper .shipping-addresses .shipping-address {
    padding: 16px;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 21px;
    border: 1px solid transparent;
    transition: .3s;
    position: relative;
}

.shipping-details-wrapper .shipping-addresses .shipping-address svg {
    position: absolute;
    cursor: pointer;
    top: 16px;
    right: 16px;
}

.shipping-details-wrapper .shipping-address .wrapper_check_box label {
    grid-gap: 0;
    padding-top: 4px;
    display: flex;
    align-items: flex-start;
}

.payment-details-wrapper .wrapper_check_box label span,
.shipping-details-wrapper .shipping-address .wrapper_check_box label span {
    margin-right: 0;
}

.payment-details-wrapper .wrapper_check_box {
    transition: .3s;
}

.payment-details-wrapper .wrapper_check_box label {
    grid-gap: 0;
    padding-top: 4px;
    display: flex;
}

.payment-details-wrapper .wrapper_check_box:not(.active) {
    opacity: .24;

}

.payment-details-wrapper .wrapper_check_box p {
    margin-bottom: 0;
    margin-left: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
}

.payment-details-wrapper .checkboxes-wrapper .payment-method-icons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
}

.payment-details-wrapper .checkboxes-wrapper .payment-method-icons > * + * {
    margin-left: 4px;
}

.payment-details-wrapper .checkboxes-wrapper .wrapper_check_box {
    margin-top: 24px;
    position: relative;
}

.payment-details-wrapper .checkboxes-wrapper > .wrapper_check_box + .wrapper_check_box {
    margin-top: 32px;
}

.payment-details-wrapper .bank_cards {
    margin-top: 24px;
    margin-left: 30px;
    grid-gap: 24px;
}

.shipping-details-wrapper .shipping-addresses .shipping-address.active {
    border-color: black;
}

.order-details-wrapper .error,
.payment-details-wrapper .error {
    color: var(--error) !important;
    margin-top: 12px;
    display: block;
}

.shipping-details-wrapper .save-mobile {
    display: none;
}

@media (max-width: 1200px) {
    .user_cart_wrapper_background {
        top: 73px;
    }

    .user_cart_wrapper_content {
        padding: 0 5%;
        margin-bottom: 96px;
    }

    .cart-footer,
    .cart-steps {
        padding: 0 5%;
    }

    .user_cart_order_step {
        top: -2px;
        left: -40px;
    }

    .user_cart_order_item {
        grid-gap: 1.5rem;
    }
}

@media (max-width: 1024px) {
    .user_cart_price_checkout_accept {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 992px) {
    .user_cart_wrapper_background {
        top: 60px;
    }

    .user_cart_container {
        grid-template-columns: 1fr;
        grid-gap: 40px;
    }

    .user_cart_price_checkout {
        border: none;
        min-height: 300px;
    }

    .user_cart_price_checkout_accept {
        grid-template-columns: repeat(5, 1fr);
    }

    .user_cart_wrapper {
        margin: 52px 0 0;
    }

    .user_cart_price_checkout {
        height: auto;
        position: static;
        top: 0;
        margin: 0 15%;
        padding: 18px 0 0;
    }

    .user_cart_price_checkout > svg.extend,
    .user_cart_price_checkout > svg {
        margin-top: 64px;
    }
}

@media (max-width: 850px) {
    .cart-steps {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        overflow: auto;
        padding-bottom: 12px;
        margin-top: 100px;
    }

    .cart-footer:not(.mobile) {
        display: none;
    }

    .cart-footer.mobile {
        display: block;
        margin-top: 56px;
        margin-bottom: 88px;
    }

    .cart-footer.mobile > * + * {
        margin-top: 16px;
    }

    .cart-steps .cart-step {
        width: 250px;
    }
}

@media (max-width: 650px) {
    .cart-footer,
    .shipping-details-wrapper .shipping-addresses {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 600px) {
    .user_cart_order_item {
        grid-template-columns: 1fr;
        grid-gap: 20px;
        position: relative;
    }

    .user_cart_order_item_delete_mobile {
        position: absolute;
        top: 18px;
        right: 0;
    }

    .user_cart_order_item_preview img {
        max-width: 100%;
    }

    .user_cart_order_item_details_mobile {
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
        grid-gap: 15px;
        padding: 2.25rem 0 0;
    }

    .user_cart_order_item_details_mobile h5 {
        font-size: 17px;
        font-weight: bold;
        color: var(--dark-grey);
    }

    .user_cart_order_item_details_mobile p {
        font-size: 16px;
        font-style: italic;
        line-height: 1.25;
        color: var(--dark-grey);
        margin: 0 0 4px;
    }

    .user_cart_order_item_details_mobile span {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.43;
        color: var(--slate);
    }

    .user_cart_order_item_price_mobile {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        align-items: center;
        justify-content: space-between;
    }

    .user_cart_order_item_price_mobile p {
        font-size: 17px;
        line-height: normal;
        text-align: left;
        color: var(--dark-grey);
    }

    .user_cart_order_item_price_mobile h6 {
        font-size: 20px;
        font-weight: bold;
        line-height: normal;
        color: var(--dark-grey);
    }

    .user_cart_order_item_content_price {
        grid-template-columns: max-content;
        grid-gap: 0;
    }

    .user_cart_subscription_wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 15px;
        margin: 25px 0 0;
    }

    .user_cart_subscription_wrapper_title {
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-gap: 40px;
        align-items: center;
        margin-bottom: 5px;
    }

    .user_cart_subscription_wrapper h4 {
        text-align: right;
    }
}

@media (max-width: 650px) {
    .user_cart_shipping_details_info {
        grid-template-columns: 1fr;
    }

    .user_cart_shipping_details_info .hidden-inputs {
        grid-template-columns: 1fr;

    }

    .user_cart_shipping_details_info .hidden-inputs.active {
        max-height: 300px;
    }
}

@media (max-width: 568px) {
    .user_cart_price_checkout > .button_default {
        margin-top: 56px;
    }

    .user_cart_order h1 {
        font-size: 30px;
    }

    .user_cart_order_total_price_container > .line {
        width: 100%;
        margin: 0 0 22px auto;
    }

    .user_cart_price_checkout {
        margin: 0;
    }

    .order-details-wrapper > button {
        width: 100%;
    }

    .shipping-details-wrapper .shipping-details-footer button {
        width: 100%;
    }

    .user_cart_shipping_options_card_content_time {
        grid-template-columns: 1fr;
        grid-gap: 8px;
    }

    .user_cart_shipping_options_card_content {
        padding: 0;
    }

    .user_cart_shipping_options_card_date {
        grid-gap: 12px;
        grid-template-columns: max-content max-content;
    }

    .user_cart_shipping_options_basic, .user_cart_shipping_options_premium {
        padding: 20px .75rem 24px;
        grid-template-columns: max-content 1fr;
        grid-gap: 16px;
        position: relative;
    }
}

@media (max-width: 500px) {
    .shipping-details-wrapper.add-new .shipping-details-footer,
    .shipping-details-wrapper.add-new .new-address-header button {
        display: none;
    }

    .shipping-details-wrapper.add-new .save-mobile {
        display: block;
        width: 100%;
    }
}