.btn {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000000;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  white-space: pre;
  transition: .4s;

  &:hover {
    background-color: #000;
    color: #fff;
  }

  &:disabled {
    background-color: #606060;
    cursor: not-allowed;
  }

  &.variant_link {
    display: inline-flex;
    width: fit-content;
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: underline;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    &:hover {
      background-color: inherit;
      color: inherit;
    }
  }

  padding: 16px 24px;


}