.apple_button {
    background: black;
    border: 1px solid black;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px 0;
    margin-bottom: 55px;
}

.apple_button span {
    margin: 4px 0 0 13px;
}

.facebook_button span {
    margin: 4px 0 0 13px;
}

.facebook_button {
    background: #1976D2;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px 0;
    margin-bottom: 16px;
}
