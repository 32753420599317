

.testimonials_message_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10rem;
    margin: 0 0 2rem;
}

.testimonials {
    background-color: #ffffff;
}

.testimonials_message {
    font-size: 1rem;
    font-weight: 400;
    color: #000000;
    line-height: 1.62rem;
    letter-spacing: 0.06rem;
    display: block;
    max-width: 91%
}

.testimonials_title {
    font-size: 3rem;
    font-weight: 400;
    color: #000000;
    margin: 0;
    padding: 0;
}

.info_web {
    border-bottom: 2px solid #000000;
    cursor: pointer
}

.info_web a {
    color: #000000;
}

.testimonials_feed_back {
    margin-top: 0.7rem;
    position: relative;
}

.testimonials_feed_back::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-29%);
    height: 88%;
    width: 60vw;
    background-color: #F6F6F1
}

.testimonials_text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.62rem;
    letter-spacing: 0.06rem;
    padding: 0.8rem 1rem 1.5rem 1.7rem;

    display: block;
    text-align: center;
    position: relative;
    z-index: 100;
    max-width: 100%

}

.artist_testimonials_section {
    position: relative;
    padding: 4rem 20%;
    margin: 0 -20%;
}

.artist_testimonials_section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: #000000;
    pointer-events: none;
}

.testimonials_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 406px));
    place-content: center;
    gap: 1.5rem;
    justify-content: space-evenly;
}

.testimonials_grid_wrapper {
    padding: 2rem 1.5rem;
    z-index: 100;
    background-color: #FFFFFF;

}

.testimonials_review {
    font-weight: 300;
    font-size: 0.88rem;
    letter-spacing: 0.06rem;
    color: #000000;
    margin-bottom: 1.4rem
}

.avatar_with_text {
    margin-bottom: 2rem
}

.artist_info_display {
    display: flex;
    gap: 0.6rem;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.artist_info_wrapper {
    display: flex;
    flex-direction: column;
}

.from_our_artists {
    font-weight: 300;
    font-size: 0.88rem;
    color: #000000;
    text-transform: capitalize;
    letter-spacing: 0.06rem;
    margin-top: -0.7rem
}

.artist_name {
    font-weight: 600;
    font-size: 1.5rem;
    color: #000000;
    text-transform: capitalize
}

.artist_country {
    font-weight: 275;
    font-size: 0.75rem;
    letter-spacing: 0.06rem;
    text-transform: capitalize
}

.testimonials_review_one {
    font-weight: 300;
    font-size: 0.88rem;
    letter-spacing: 0.06rem;
    color: #000000;
    margin-bottom: 4.4rem
}

.testimonials_photo {
    display: flex;
    gap: 0.6rem
}

.testimonials_details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem
}

.btn_testimonials {
    text-transform: capitalize;
    background-color: #000000;
    margin-top: 1.5rem;
    padding: 0.6rem 2rem;
    font-weight: 600;
    font-size: 0.75rem;
    color: #FFFFFF;
}

.text_details {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.06rem;
    color: #000000;
    max-width: 60%;
    text-align: center;
    line-height: 1.63rem;
    margin-bottom: 0.6rem
}

.title_details {
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 0.06rem;
    color: #000000;
    text-transform: capitalize;
    margin-bottom: 6rem
}

.art_showcase {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem;
}

.art_showcase a {
    display: grid;
    align-items: center;
    width: max-content;
}

.art_showcase .art_image_show {
    margin: 0 auto;
    display: block;
    max-width: 100%;
}

.border_bottom_art_two {
    position: relative;
    border-bottom: 1px solid rgba(121, 121, 121, 0.12);
    width: 99%;
    margin: 1.25rem 0;
}

.art_article {
    display: block;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.25rem;
    color: #000000;
    margin: 0;
}

.user_art_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}

.user_name_title {
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 0.06rem;
    color: #000000;
    text-transform: capitalize;
    margin-bottom: 6rem
}

.user_art_text {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.06rem;
    color: #000000;
    max-width: 65%;
    text-align: center;
    line-height: 1.63rem;
    margin-bottom: 0.8rem
}

.testimonial_display {
    padding: 0 0 4rem;
}

.testimonial_info_wrapper {
    display: grid;
    grid-template-columns:100%;
    grid-gap: 1rem;
}

.nft_user_name {
    font-weight: 600;
    font-size: 1.25rem;
    color: #000000;
    text-transform: capitalize;
}

.nft_user_country {
    font-weight: 400;
    font-size: 1rem;
    color: #000000;
    letter-spacing: 0.06rem;
    text-transform: capitalize;
    margin-top: -1rem

}

.avatar_user_profile {
    display: grid;
    grid-template-columns: 50px  200px;
    gap: 2rem;
    margin-bottom: 2rem;
}

.thumb_img_display img {
    display: inline-block;
    margin-bottom: 1rem;
}

.thumb_img_display img {
    padding-right: 0.8rem;
}

.border_bottom {
    position: relative;
    border-bottom: 1px solid rgba(121, 121, 121, 0.12);
    width: 82%;
    margin-bottom: 1rem;

}

.user_profile_description {
    font-weight: 300;
    font-size: 14px;
    line-height: 1.25rem;
    color: #000000;
    max-width: 85%;
    min-height: 200px;

}

.user_name_country {
    display: grid;
}

.btn_user {
    text-transform: capitalize;
    background-color: #000000;
    padding: 0.8rem 2rem;
    font-weight: 600;
    font-size: 0.75rem;
    color: #FFFFFF;
    margin-bottom: 1rem;
}

.btn_user a, .btn_user a:hover, .btn_testimonials a, .btn_testimonials a:hover {
    text-decoration: none;
    color: #FFFFFF;
}

@media only screen and (min-width: 1200px) {
    .testimonials_text {
        max-width: 100%;
        padding-left: 5rem;
        padding-right: 0.1rem;

    }
    .testimonials {
        margin-top: 2rem;
        margin-bottom: -1.5rem;
    }

    .testimonials_feed_back::after {
        transform: translateX(-30%);
        height: 86%;
        width: 48vw;
        background-color: #F6F6F1
    }

    .testimonials_message_wrapper {
        grid-gap: 10rem;
    }


    .border_bottom {
        width: 80%;
    }
    .thumb_img_display img {
        padding-right: 0.8rem
    }
    .user_profile_description {
        max-width: 84%;
    }
}

@media (min-width: 1400px) {
    .testimonials_message {
        max-width: 80%;
    }

    .testimonials {
        margin-top: 0.9rem;
        margin-bottom: -0.1rem;
    }

    .testimonials_message_wrapper {
        grid-gap: 10rem;
    }

    .testimonials_feed_back::after {
        transform: translateX(-30%);
        height: 86%;
        width: 48vw;
        background-color: #F6F6F1
    }

    .testimonials_text {
        padding-left: 5rem;
        max-width: 97%;
    }

    .testimonial_info_wrapper {
        justify-content: center;
        grid-template-columns:100%;

    }

    .thumb_img_display img {
        padding-right: 0.3rem;
    }

    .user_testimonial_ref {
        margin-left: 2.7rem;

    }

    .border_bottom {
        width: 89%;
    }

    .user_profile_description {
        max-width: 85%;
    }

}

@media (max-width: 1200px) {
    .artist_testimonials_section {
        margin: 0 -15%;
        padding: 4rem 15%;
    }

    .testimonials_grid {
        grid-template-columns: 1fr 1fr;
        justify-content: center;
    }
    .testimonials_review {

        margin-bottom: 2.7rem
    }
}

@media (max-width: 992px) {
    .artist_testimonials_section {
        margin: 0 -10%;
        padding: 4rem 10%;
    }
}

@media (max-width: 912px) {
    .testimonials {
        /*margin-left: -1.7rem;*/
    }

    .testimonials_feed_back::after {
        transform: translateX(-30%);
        height: 86%;
        width: 75vw;
        background-color: #F6F6F1
    }

    .artist_testimonials_section {
        padding: 4rem 0;
    }

    .testimonials_grid {
        grid-template-columns: minmax(100px, 406px);
    }
    .testimonials_message_wrapper {
        grid-gap: unset;
        margin-bottom: 1rem;
    }
    .testimonials_text {
        padding: 1rem 1rem 2rem 1rem ;
    }

    .art_showcase {
        grid-template-columns:  250px 250px;
        justify-content: center;
    }

    /*.art_image_show {*/
    /*    display: block;*/
    /*    height: 190px;*/
    /*    width: 190px;*/
    /*    object-fit: cover;*/
    /*}*/

    .art_article {
        max-width: 99%;
    }

    .border_bottom {
        width: 95%;
    }
    .thumb_img_display img {
        padding-right: 0.4rem;
    }


    .user_profile_description {
        max-width: 93%;
    }
}

@media (max-width: 820px) {
    .testimonials_message_wrapper {
        grid-gap: unset;
        margin-bottom: 1rem;
    }
    .testimonials_grid {
        grid-template-columns: minmax(100px, 350px);

        /*grid-template-columns: repeat(auto-fit, minmax(100px, 350px));*/
    }

    .testimonials_photo {
        height: 100px;
        width: 100px;
        object-fit: cover;
    }

    .testimonials_review_one {
        margin-bottom: 5.5rem;
    }

    /*.art_image_show {*/
    /*    display: block;*/
    /*    height: 170px;*/
    /*    width: 170px;*/
    /*    object-fit: cover;*/
    /*}*/

    .art_article {
        max-width: 100%;
    }

    .border_bottom {
        width: 71%;
    }
}

@media (max-width: 768px) {
    /*.testimonials_grid {*/
    /*    grid-template-columns:  minmax(100px, 335px));*/
    /*}*/
    /*.testimonials_grid {*/
    /*    grid-template-columns: repeat(auto-fit, minmax(100px, 335px));*/
    /*}*/
    .testimonials_message_wrapper {
        grid-gap: unset;
        margin-bottom: 1rem;
    }
    .testimonials_text {
        padding: 1rem 1rem 2rem 1rem ;
        /*max-width : 50%*/
    }

    .testimonials {
        margin-top: 1.8rem;
    }

    .artist_name {
        font-size: 1.2rem;
    }

    .testimonials_review {
        margin-bottom: 2.5rem;
    }

    .art_article {
        max-width: 100%;
    }

    .testimonials_photo {
        height: 100px;
        width: 100px;
        object-fit: cover;
    }

    .border_bottom {
        width: 75%;
    }

    .art_image_show {
        height: unset;
        width: unset;
    }
    .user_profile_description {
        max-width: 78%;
    }
}

@media (max-width: 600px) {
    .testimonials {
        /*margin-top: 2rem;*/
        /*padding-left: 2rem;*/
    }

    .testimonials_message_wrapper {
        grid-template-columns: 1fr;
        gap: unset;
    }
    .artist_testimonials_section {

        margin-top: -3.2rem;
    }

    .testimonials_feed_back::after {
        transform: translateX(-50%);
        width: 100vw;
    }

    .testimonials_text {
        text-align: left;
    }

    .art_showcase {
        grid-template-columns: 1fr 1fr;
        gap: 0.7rem;
        margin-left: 0.5rem;
    }

    .border_bottom {
        width: 97%;
    }

    .user_profile_description {
        max-width: 95%;
    }
}

@media (max-width: 540px) {
    .testimonials {
        margin-left: 1rem;
        margin-top: 2rem;
    }

    .art_showcase {
        grid-template-columns: 1fr 1fr;
        gap: unset;
        margin: 0 0.8rem;
    }

    .testimonials_feed_back::after {
        transform: translateX(-52%);
    }

    .art_image_show {
        height: 240px;
        width: 240px;
        object-fit: cover;
    }
    .border_bottom_art_two  {
        width: 96%;
    }
    .art_article {
        max-width: 95%;
    }

    .border_bottom {
        width: 58%;
    }

    .user_profile_description {
        max-width: 60%;
    }
}

@media (max-width: 414px) {
    .testimonials {
        margin-top: 2rem;
        /*padding-left: 2rem;*/
    }
    .testimonials_message {
        margin-left: 0.5rem;
    }
    .testimonials_text {
        padding: 1rem 1rem 2.3rem 0.5rem;
    }
    /*.testimonials_feed_back::after {*/
    /*    transform: translateX(-51%);*/
    /*    width: 100vw;*/
    /*}*/

    .testimonials_grid_wrapper {
        background-color: unset;
    }

    .artist_info_display {
        gap: 2rem;
        flex-direction: column;
    }

    .artist_info_wrapper {
        text-align: center;
        color: #FFFFFF;
        gap: 1rem;
    }


    /*.avatar_user_img {*/
    /*    display: block;*/
    /*    height: 198px;*/
    /*    width: 198px;*/
    /*    object-fit: cover;*/
    /*}*/

    .from_our_artists, .artist_name, .testimonials_review_one, .testimonials_review {
        color: #FFFFFF;
    }

    .artist_country, .from_our_artists {
        font-size: 1rem;
        font-weight: 300;
        margin: 0;
    }

    .artist_name {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
    }

    .btn_testimonials {
        border: 1px solid #FFFFFF;
        width: 60%;
        margin-left: 4rem;
        margin-top: 4rem;
    }

    .border_art_display {
        position: relative;
        border: 2px solid #FFFFFF;

    }

    .artist_testimonials_section {
        padding: 0;
        margin-top: -3.2rem;
    }

    .testimonials_review_one, .testimonials_review {
        margin-bottom: 1rem;
    }

    .testimonials_grid_wrapper {
        padding: 2rem 0;
    }
    .title_details, .user_name_title {
        margin-bottom: 4rem;
    }

    .user_photos {
        width: 135px;
        height: 135px;
        object-fit: cover;
    }

    .testimonials_details {
        margin-top: 3.5rem;
    }

    .text_details {
        max-width: 85%;
    }


    .art_showcase {
        grid-template-columns:  100%;
        grid-gap: 1rem;
    }
    .art_showcase .art_image_show {
        width: 100%;
        display: block;
        max-width: 100%;
        padding: 0 0.7rem ;
    }
    .border_bottom_art_two {
        width: 92%;
        left: 4%;
    }
    /*.art_image_show {*/
    /*    height: 218px;*/
    /*    width: 263px;*/
    /*    object-fit: cover;*/

    /*}*/


    .art_article {
        max-width: 95%;
        padding-left: 0.8rem;
    }
    .art_learn_more {
        padding-left: 0.8rem;

    }
    .user_art_text {
        max-width: 85%;
    }

    .border_bottom {
        width: 87%;
    }

    .btn_user {
        width: 90%;
    }

    .user_profile_description {
        max-width: 88%;
    }

    .avatar_user_profile {
        grid-template-columns: 1fr;
        place-items: center;
        grid-gap: 1rem;
    }
    .avatar_user_img {
        width:199px;
        height:199px
    }
    .user_name_country {
        grid-gap: 1rem;
        text-align: center;
    }

    .nft_user_name {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
    }

    .nft_user_country {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
    }

    .border_bottom_mob {
        position: relative;
        border-bottom: 1px solid rgba(121, 121, 121, 0.12);
        width: 90%;
        margin-top: -1rem;
        margin-bottom: 0.5rem;
    }

    .avatar_user_profile img {
        width: 92px;
        height: 92px;
        object-fit: cover;
    }
    .thumb_img_display img{
        padding-right: 1.4rem;
    }

}

@media (max-width: 393px) {

    .border_bottom_art_two {
        width: 93%;
    }
    .testimonials_text {
        padding: 1rem 1rem 2.3rem 0.5rem;
    }
    .thumb_img_display img{
        padding-right: 0.8rem;
    }
    .user_profile_description {
        max-width: 90%;
    }

}

@media (max-width: 390px) {
    .art_showcase {
        margin-left: 1rem;
    }
    .artist_testimonials_section {
        padding: 0;
        margin-top: -3.8rem;
    }
    .testimonials_text {
        padding: 1rem 1rem 2.9rem 0.5rem;
    }


    .border_bottom {
        width: 95%;
    }

    .user_profile_description {
        max-width: 93%;
    }
}


@media (max-width: 375px) {
    .user_profile_description {
        max-width: 90%;
    }
    .artist_testimonials_section {
        padding: 0;
        margin-top: -3.8rem;
    }
    .testimonials_grid_wrapper{
        padding: 2rem 0.5rem;
    }
    .art_showcase {
        margin-left: 0.5rem;
    }
    /*.art_image_show {*/
    /*    height: 169px;*/
    /*    width: 169px;*/
    /*    object-fit: cover;*/
    /*}*/

    .user_profile_description {
        min-height: 270px;
    }
    .thumb_img_display img{
        padding-right: 0.4rem;
    }
}

@media (max-width: 360px) {

    .testimonials_grid_wrapper{
        padding: 2rem 1rem;
    }

    .thumb_img_display img {
        /*padding-right: 0.5rem;*/
        height: 83px;
        width: 83px;
        object-fit: cover;
    }

    .border_bottom {
        width: 92%;
    }

    .user_profile_description {
        max-width: 92%;
        min-height: 250px;

    }
    /*.art_article {*/
    /*    max-width: 90%;*/

    /*}*/
}