.artworks_page_wrapper > .head_content {
    margin: 0 0 42px;
    padding: 3rem 10% 0;
    position: relative;
    z-index: 2;
}

.artworks_page_wrapper > .head_content .content_bg {
    position: absolute;
    z-index: -1;
    background: var(--beige);
    width: 100%;
    height: 300px;
    top: 0;
    left: 0;
    right: 0;
}

.artworks_page_wrapper > .head_content h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 64px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    margin: 0 0 1.5rem;
}

.artworks_page_wrapper > .head_content .art_container {
    display: grid;
    grid-template-columns: 5fr 7fr;
    align-items: start;
}

.artworks_page_wrapper > .head_content .art_container .text_content {
    background: #000000;
    padding: 3rem 4rem 5.5rem 3.5rem;
}

.artworks_page_wrapper > .head_content .art_container .text_content h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    margin: 0 0 1rem;
}

.artworks_page_wrapper > .head_content .art_container .text_content p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #FFFFFF;
}

.artworks_page_wrapper > .head_content .art_container .artwork_container {
    /*background: url("../../assets/img/new_artworks/artworks_head_bg.png") no-repeat center;*/
    background-size: cover;
    height: 100%;
    display: grid;
    justify-content: end;
    align-items: end;
}

.artworks_page_wrapper > .head_content .art_container .artwork_container_usual {
    background: url("../../assets/img/new_artworks/artworks_head_bg.png") no-repeat center;
    background-size: cover;
    height: 100%;
    display: grid;
    justify-content: end;
    align-items: end;
}

.artworks_page_wrapper > .head_content .art_container .artwork_container_nft {
    background: url("../../assets/img/new_artworks/artworks_head_bg_nft.jpg") no-repeat center;
    background-size: cover;
    height: 100%;
    display: grid;
    justify-content: end;
    align-items: end;

}

.artworks_page_wrapper > .head_content .art_container .artwork_container > .artist {
    background: #000000;
    padding: 1rem 1.5rem;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 1rem;
    align-items: center;
}

.artworks_page_wrapper > .head_content .art_container .artwork_container > .artist img {
    width: 3.5rem;
    height: 3.5rem;
    object-fit: cover;
    border-radius: 100%;
}

.artworks_page_wrapper > .head_content .art_container .artwork_container > .artist h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 95.4%;
    color: var(--beige);
    margin: 0 0 8px;
}

.artworks_page_wrapper > .head_content .art_container .artwork_container > .artist span {
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: var(--beige);
}

.block-seo,
.artworks_page_wrapper > .filters {
    padding: 0 10%;
}

.artworks_page_wrapper > .filters > .artworks_list {
    padding: 0;
    margin: 0;
    min-height: 150px;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters {
    background: #FFFFFF;
    grid-template-columns: 3fr 1fr;
    grid-gap: 0;
    border-right: 2px solid #000000;
    position: sticky;
    padding: 8px 24px 24px 0;
    top: 104px;
    height: calc(100vh - 104px);
    overflow: auto;
}

.header_banner_container ~ .artworks_page_wrapper > .filters > .wrapper_list_filters {
    top: 168px;
    height: calc(100vh - 168px);
}

.artworks_page_wrapper > .filters.artworks_listing {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 260px 1fr;
}

.artworks_page_wrapper .wrapper_new_pagination {
    grid-column: -1/1;
}

.artworks_page_wrapper .wrapper_list_filters .wrapper_list_filters__filters button span {
    display: flex;
    align-items: center;
}

.artworks_page_wrapper .wrapper_list_filters .wrapper_list_filters__filters button svg {
    margin-right: 4px;
}

.artworks_page_wrapper .wrapper_list_filters .wrapper_list_filters__filters button svg path {
    transition: .3s;
}

.artworks_page_wrapper .wrapper_list_filters .wrapper_list_filters__filters button:hover svg path {
    fill: black;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters > .wrapper_list_filters__filters {
    padding: 0;
    display: flex;
    flex-direction: column;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters > .wrapper_list_filters__filters > * + * {
    margin-top: 1.5rem;
}

.artworks_page_wrapper .block-seo {
    grid-column: -1/1;
}

.listing_search {
    border: 2px solid #000000;
    position: relative;
    padding: 6px 1rem 6px 42px;
    width: 100%;
    cursor: text;
}

.listing_search svg {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    pointer-events: none;
}

.listing_search input, .artworks_page_wrapper > .filters > .wrapper_list_filters > .wrapper_list_filters__filters > .artworks_search input::placeholder {
    font-size: 12px;
    line-height: 30px;
    color: #000000;
}

.listing_search input {
    padding: 0;
    border: none;
    outline: none;
    width: 100%;
    cursor: text;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters > .wrapper_list_filters__more_filters {
    padding: 0;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new {
    padding: 0;
    border: none;
    display: flex;
    flex-direction: column;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new > * + * {
    margin-top: 24px;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters .expand-filter img {
    transition: .3s transform;
    display: block;
    margin-left: auto;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters .expand-filter {
    cursor: pointer;
    position: absolute;
    top: -1px;
    right: 0;
    left: 0;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters .expand-filter.expand-active img {
    transform: rotate(180deg);
}

.artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new .styles_materials {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters p {
    margin-bottom: 0;
    width: 200px;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new .styles_materials > * {
    margin-top: 1.5rem;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters .checkbox-filter {
    position: relative;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters .checkbox-filter .checkbox_wrapper {
    margin-top: 4px;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new .more_filters_params {
    grid-gap: 0;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new .input_range {
    margin: 0 0 2rem;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    grid-gap: 12px;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new .more_filters_params .input_component .custom_input {
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    color: #000000;
    border: none;
    border-bottom: 2px solid #000000;
    padding: 4px 0;
    width: 100%;
    max-width: 32px;
    background: #fff;
    text-align: center;
}

.artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new .more_filters_params .input_range_price .input_component .custom_input {
    max-width: 42px;
}

@media (max-width: 1200px) {
    .artworks_page_wrapper > .head_content {
        margin: 0 0 42px;
        padding: 3rem 7.5% 0;
    }

    .block-seo,
    .artworks_page_wrapper > .filters {
        padding: 0 7.5%;
    }

    .artworks_page_wrapper > .head_content .art_container .text_content {
        padding: 2rem 3rem 4.5rem 2.5rem;
    }
}

@media (max-width: 1160px) {
    .artworks_page_wrapper {
        margin: 74px 0 0;
    }
}

@media (max-width: 992px) {
    .artworks_page_wrapper > .head_content {
        padding: 2rem 5% 0;
    }

    .block-seo,
    .artworks_page_wrapper > .filters {
        padding: 0 5%;
    }

    .artworks_page_wrapper > .head_content h1, .artworks_page_wrapper > .head_content .art_container .text_content h2 {
        font-size: 28px;
        line-height: 48px;
    }

    .artworks_page_wrapper > .head_content h1 {
        margin: 0 0 2rem;
    }

    .artworks_page_wrapper > .head_content .art_container .text_content {
        padding: 2rem 1rem 4.5rem 1.5rem;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters {
        grid-template-columns: 2fr 1fr;
        grid-gap: 1rem 8px;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new .styles_materials {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new {
        grid-template-columns: repeat(3, 1fr);
        margin: 0;
        grid-gap: 1rem;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters > .wrapper_list_filters__filters {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
    }
}

@media (max-width: 768px) {
    .artworks_page_wrapper > .head_content .art_container {
        grid-template-columns: 1fr;
    }

    .artworks_page_wrapper > .head_content .art_container .text_content {
        padding: 20px;
    }

    .artworks_page_wrapper > .head_content .art_container .artwork_container {
        height: 270px;
    }
}

@media (max-width: 650px) {
    .artworks_page_wrapper > .filters > .wrapper_list_filters {
        grid-template-columns: 1fr max-content;
        grid-gap: 1.5rem 2.5rem;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters > .wrapper_list_filters__filters {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }

    .listings .wrapper_list_filters__more_filters .more_filter span {
        display: grid;
        align-items: center;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new .styles_materials {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        margin: 0 0 2.5rem;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new .more_filters_params {
        padding: 0;
        margin: 0 0 2.5rem;
    }

    .artworks_page_wrapper > .head_content h1, .artworks_page_wrapper > .head_content .art_container .text_content h2 {
        font-size: 24px;
        line-height: 32px;
    }
}

@media (max-width: 600px) {
    .artworks_page_wrapper > .filters > .wrapper_list_filters > .wrapper_list_filters__filters {
        grid-template-columns: 1fr 80px;
        display: grid;
        grid-gap: 16px;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters p {
        width: 100%;
    }

    .artworks_page_wrapper > .filters.artworks_listing {
        grid-template-columns: 1fr;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new {
        position: fixed;
        inset: 0;
        transform: translateX(-100%);
        background: #F6F6F1;
        z-index: 1000000;
        overflow: auto;
        transition: .3s;
        padding: 24px;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new .more_filters_params .input_component .custom_input {
        background-color: #F6F6F1;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new.active {
        transform: translateX(0);
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters {
        padding: 24px 0;
        grid-gap: 0;
        border: none;
        border-bottom: 2px solid #000000;
        margin-bottom: 16px;
        height: max-content;
        top: 74px;
        z-index: 100;
    }

    .header_banner_container ~ .artworks_page_wrapper > .filters > .wrapper_list_filters {
        height: max-content;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new .filters-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new .filters-header > span:not(.title) {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        cursor: pointer;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new .filters-header .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: 0.02em;
        margin-right: -35px;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new .filters-header button span {
        font-size: 12px;
        line-height: 18px;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new .filters-header button {
        padding: 12px 24px;
        font-weight: 600;
        width: max-content;
    }

    .artworks_page_wrapper > .filters > .wrapper_list_filters .more_filters_wrapper_new .filters-header {
        margin-bottom: 24px;
    }

    .artworks_page_wrapper > .head_content {
        margin-bottom: 0;
    }
}

@media (max-width: 568px) {
    .block-seo,
    .artworks_page_wrapper > .filters {
        padding: 0 1rem;
    }

    .artworks_page_wrapper > .head_content .content_bg {
        height: 200px;
    }

    .artworks_page_wrapper > .head_content {
        padding: 2rem 1rem 0;
    }
}

@media (max-width: 325px) {
    .artworks_page_wrapper > .filters > .wrapper_list_filters {
        grid-gap: 1.5rem 1.5rem;
    }
}
