.form {
    display: grid;
    grid-gap: 4rem;
}

.subtitle {
    font-weight: 300;
    font-size: 0.875rem;
}

.row {
    display: grid;
    grid-gap: 2.5rem;
}

.label {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    font-weight: 500;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
}

.labelError {
    color: var(--error);
}

.mutedLabel {
    font-weight: 300;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.5);
    letter-spacing: 0.015em;
}

.inputWrapper {
    margin-bottom: 1.25rem;
}

.input input:global(.custom_input.error) {
    border-width: 0 0 1px 0 !important;
}

.input input:global(.custom_input) {
    border-width: 0 0 1px 0 !important;
    border-color: #000000;
    font-weight: 300;
    line-height: 1.5715;
    padding: 0.5rem 1rem 0.75rem;
}

.form .addItem {
    padding-left: 0;
    border: 0;
    width: auto !important;
    background-color: transparent;
}

.form .addItem:hover {
    background-color: transparent !important;
}

.footerActionRight {
    margin-left: auto;
}

.imagesWrapper {
    display: grid;
    grid-template-columns: 1fr 120px;
    align-items: flex-start;
    grid-gap: 1.25rem;
    margin-top: 0.5rem;
}

.images {
    height: 100%;
    max-height: 305px;
    overflow: auto;
}

.images :global(.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item) {
    padding: 0;
    border: 0;
    border-radius: 0;
}

.images :global(.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select),
.images :global(.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select) {
    width: 100%;
    height: 80px;
    margin: 0;
    color: #000000;
    background-color: var(--beige);
    border: 0;
    border-radius: 0;
}

.images :global(.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item::before) {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.images > :global(.ant-upload-wrapper > .ant-upload-list.ant-upload-list-picture-card > .ant-upload-list-item-container) {
    width: 100%;
    height: 80px;
    margin-right: 0;
    margin-bottom: 0;
}

.images > :global(.ant-upload-wrapper > .ant-upload-list.ant-upload-list-picture-card > .ant-upload-list-item-container) {
    margin-bottom: 1.25rem;
}

.images :global(.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action.ant-btn) {
    width: 2rem;
    height: 2rem;
    color: #fff;
    background-color: transparent !important;
}

.mainImageUpload {
    position: relative;
    background-color: var(--beige);
}

.mainImageUpload :global(.ant-upload-wrapper) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.mainImageUpload::before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 56%;
}

.mainImageUpload :global(.ant-upload-wrapper) {
    height: 100%;
}

.mainImageUpload :global(.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select) {
    width: 100%;
    height: 100%;
    margin: 0;
    border: 0;
}

.mapWrapper {
    position: relative;
    height: 300px;
}

.previewWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.previewWrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.previewWrapperOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.5);
}

.previewWrapper:hover .previewWrapperOverlay {
    opacity: 1;
}

.timePicker {
    display: grid;
    justify-content: flex-start;
    align-items: center;
    grid-template-columns: 80px auto 80px;
    border-bottom: 1px solid #000000;
}

.timePickerError {
    border-color: var(--error);
}

.preWrap {
    white-space: pre-wrap;
}

@media (max-width: 768px) {
    .form {
        grid-gap: 2.5rem;
    }

    .row {
        grid-gap: 1.5rem;
    }
}