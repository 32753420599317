.input_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 550px;
    width: 100%;
}

.input_title {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: rgba(0, 0, 0, .5);
    margin: 0 0 1rem;
}
