.faq_section_body {
    align-items: center;
    position: sticky;
    position: -webkit-sticky;
    padding-top: 20px;
    top: 90px;
    height: max-content;
}

.wrapper_every_page_faq {
    margin-top: 73px;
}

.question_list {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px 21px;

}

.wrapper_faq_questions {
    align-items: center;
    padding: 26px 5% 44px;
}


.question_list span {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--dark-grey)
}


.question_active {
    color: var(--dark-grey);
    border-left: 3px solid var(--turquoise);
    transition: .3s all;
}

.question_active span {
    font-size: 22px;
    transition: .3s all;
    padding: 6px 3px;
}

.wrapper_question_faq {
    display: grid;
    grid-template-columns: 320px 1fr;
    grid-gap: 0 40px;
    padding: 26px 15%;
    position: relative;
}

.faq_section_body p {
    padding-top: 15px;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: var(--slate)
}

.border_line {
    border: solid .5px var(--lite-grey);
}

.account_question {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 40px;
}

.authorization_buttons {
    display: flex;
    flex-direction: row;
    grid-gap: 40px;
    justify-content: center;
}

.fourth_faq_section_wrapper {
    margin-top: 60px;
}

.fourth_faq_section_wrapper h1 {
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    color: var(--dark-grey)
}

.find_answer_text {
    display: flex;
    align-self: start;
    max-width: 50%;
}


.find_answer_text span {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    color: var(--slate);
}

.find_answer_text a {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: var(--new-black);
}


.question_feedback_form {
    display: flex;
    grid-gap: 45px;
    justify-content: space-between;
}

.question_feedback_form .input_component .custom_input {
    width: 300px;
}

.faq_question_problem {
    grid-column: 1/-1;
}

.question_feedback_form .custom_dropdown_select {
    width: 100%;
    border: 1px solid rgb(219, 221, 222);
    padding: 10px 16px;
    grid-template-columns: 1fr max-content;
    display: grid;
    align-items: center;
    grid-gap: .5rem;
    cursor: pointer;
}

.wrapper_contact_form {
    grid-column: 1/-1;
    justify-self: center;
    padding-top: 30px;
}

.wrapper_contact_form button {
    padding: 12px 60px;
}

.faq_contact_us {
    padding: 70px 15%;
}

.faq_page_list {
    padding-top: 10px;
    display: grid;
    grid-gap: 10px 20px;
    grid-template-columns: max-content 1fr;
    align-items: center;
}

.faq_page_list > div {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: var(--new-black);
}

.faq_page_list span {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
}

.pricing_wrapper_faq {
    padding-top: 10px;
}

.pricing_wrapper_faq p {
    padding-top: 5px;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: var(--slate);
}

.pricing_wrapper_faq span {
    font-size: 19px;
}


@media (max-width: 1135px) {
    .account_question {
        grid-template-columns: 1fr;

    }

    .fourth_section_wrapper h1 {
        text-align: center;
    }

    .padding_faq {
        flex-flow: column;

    }

    .find_answer_text {
        max-width: 100%;
        text-align: center;
    }

    .faq_contact_us h1 {
        text-align: center;
    }
}
@media (max-width: 1000px) {
    .wrapper_question_faq {
        padding: 26px 10%;
    }
    .faq_contact_us {
        padding: 70px 10%;
    }
}
@media (max-width: 900px) {
    .wrapper_question_faq {
        padding: 26px 5%;
    }
    .faq_contact_us {
        padding: 70px 5%;
    }
}


@media (max-width: 768px) {
    .question_list a {
        border-bottom: 1px solid var(--new-black);
    }

    .question_feedback_form {
        display: grid;
        grid-gap: 10px;
        flex-flow: column;
        width: 100%;
        justify-content: inherit;
    }

    .faq_contact_us {
        padding: 20px 5%;
    }

    .wrapper_question_faq {
        display: grid;
        grid-gap: 0 30px;
        grid-template-columns: initial;
        position: relative;
    }

    .news_footer {
        margin-top: 50px;
    }

    .find_answer_text {
        max-width: 100%;
    }

    .question_list {
        margin-top: 0;

    }

    .faq_section_body {
        padding-top: 0;
    }


}

@media (max-width: 414px) {
    .faq_contact_us h1 {
        font-size: 25px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: var(--dark-grey);

    }

    .faq_section_body h1 {
        display: none;
    }

    .question_list a {
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.05;
        letter-spacing: normal;
        text-align: left;
        color: var(--dark-grey);
    }

    .question_feedback_form .input_component .custom_input {
        width: 100%;
    }

    .wrapper_question_faq {
        padding: 20px 5%;
    }

    .question_feedback_form .outside_click_wrapper {
        width: 100%;
    }

    .wrapper_contact_form {
        width: 100%;
    }

    .border_line {
        margin: 0 5%;
    }

    .faq_section_body p {
        padding: 5px 5% 0 0;
    }
}