.root {
    padding: 3.875rem 15%;
}

.root .tabButton + .tabButton {
    border-left: 0
}

.root .disabledButton + .disabledButton {
    border: 2px solid #BFBFBF;
    border-left: 0;
}

.tabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 2rem 0 2.5rem;
}

.subtitle {
    font-weight: 300;
    font-size: 0.875rem;
}

@media (max-width: 1200px) {
    .root {
        padding-left: 7.5%;
        padding-right: 7.5%;
    }
}

@media (max-width: 992px) {
    .root {
        padding-left: 0;
        padding-right: 0;
        padding-top: 3rem;
    }
}