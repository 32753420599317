.question_feedback_form {
    display: grid;
    grid-template-columns: repeat(2, 300px);
    justify-content: space-between;
}

.question_feedback_form .input_component .custom_input {
    width: 300px;
}

.contact_form {
    display: grid;
}

.faq_question_problem {
    grid-column: 1/-1;
}

.question_feedback_form .custom_dropdown_select {
    width: 100%;
    border: 1px solid rgb(219, 221, 222);
    padding: 10px 16px;
    grid-template-columns: 1fr max-content;
    display: grid;
    align-items: center;
    grid-gap: .5rem;
    cursor: pointer;
}


.wrapper_contact_form {
    grid-column: 1/-1;
    justify-self: center;
    padding-top: 30px;
}

.wrapper_contact_form button {
    padding: 12px 60px;
    color: #fff;
}

.wrapper_contact_form .custom_dropdown_wrapper {
    position: relative;
    min-width: 100%;
}

@media screen and (max-width: 750px) {
    .question_feedback_form {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 600px) {
    .question_feedback_form {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 500px) {
    .question_feedback_form .input_component .custom_input {
        width: 100%;
    }
}
