.header {
    padding: 3rem 10%;
    background-color: var(--beige);
}

.title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: flex-start;
    grid-gap: 8rem;
    padding: 2rem 10%;
}

.contacts {
    display: grid;
    grid-gap: 1rem;
    font-size: 0.875rem;
    font-weight: 300;
}

@media (max-width: 992px) {
    .content {
        grid-template-columns: 1fr;
        padding: 1.5rem 5% 2.5rem;
        grid-gap: 1.5rem;
    }

    .header {
        padding: 1.5rem 5%;
    }

    .title {
        font-size: 1.5rem;
    }
}