.profile_section_body_temp_button {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: #ffffff;
    background-color: var(--new-black);
    padding: 10px 50px;
    display: block;
    width: max-content;
    text-align: center;
    margin-top: 2rem;
}

.profile_section_body h2 {
    color: var(--dark-grey);
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 56px;
}

.portfolio_page {
    display: grid;
}

.stripe_button_wrapper .button_purple path,
.stripe_button_wrapper .button_purple rect {
    fill: white;
    transition: .3s fill;
}

.stripe_button_wrapper .button_purple:hover path,
.stripe_button_wrapper .button_purple:hover rect {
    fill: black;
}

.stripe_button_wrapper .button_bordered path,
.stripe_button_wrapper .button_bordered rect {
    fill: black;
    transition: .3s fill;
}

.stripe_button_wrapper .button_bordered:hover path,
.stripe_button_wrapper .button_bordered:hover rect {
    fill: white;
}

.profile_section_body .stripe_button_wrapper .button_purple {
    background: var(--new-black);
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: bold;
    border: 2px solid;
    line-height: 1.43;
    width: max-content;
    width: -moz-max-content;
    color: #fff;
    display: flex;
    grid-gap: 0;
}

.profile_section_body .stripe_button_wrapper .button_purple > span {
    padding: 0 0 0 8px;
}

.profile_section_body .stripe_button_wrapper .button_purple:hover > svg rect,
.profile_section_body .stripe_button_wrapper .button_purple:hover > svg path {
    fill: #fff
}


.profile_section_body .button_purple svg *,
.profile_section_body .button_bordered:hover svg * {
    fill: #fff
}

.profile_section_body .button_purple:hover svg * {
    fill: var(--new-black)
}

.profile_section_body {
    align-items: center;
    background: #fff;
    padding: 26px 5% 44px;
}

.profile_section_body .registration_categories_select .custom_dropdown_wrapper {
    max-width: 300px;
}

/*.profile_section_body img {*/
/*    transition: .4s;*/
/*    cursor: pointer;*/
/*}*/

.profile_section_body .wrapper_pagination {
    margin-top: 40px;
}

.portfolio_page a:hover {
    background: rgba(0, 0, 0, 0);
    color: var(--new-black);
    box-shadow: inset 0 0 0 1px var(--new-black);
}

.stripe_fields_wrapper .stripe_btn {
    margin: 0 auto 3.5rem;
    max-width: 726px;
    width: 100%;
}

.stripe_fields_wrapper .stripe_btn > .layout {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 24px;
    align-items: end;
}

.delete-payout-button {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    padding: 12px 40px;
    width: max-content;
    border: 1px solid #000000;outline: 1px solid #000000;
    transition: .3s all;
    background: rgb(0, 0, 0);
    color: #FFFFFF!important;
}

.stripe_fields_wrapper .stripe_btn > span {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: rgba(0, 0, 0, .5);
    display: block;
    margin: 0 0 1rem;
}

.bank_cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    align-items: end;
}

.bank_cards .gap {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    align-items: end;
}

.bank_cards .gap h6 {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
}

.stripe_fields_wrapper .stripe_btn .layout .card_placeholder {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 1.5rem;
    align-items: center;
}

.stripe_fields_wrapper .stripe_btn .layout .card_placeholder .numbers {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 12px;
    align-items: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
}

.stripe_fields_wrapper .stripe_btn .card_preview {
    background: #F6FCFF;
    padding: 0 32px;
    display: grid;
    align-items: center;
}

.stripe_fields_wrapper .stripe_btn .card_preview .card span,
.stripe_fields_wrapper .stripe_btn .card_preview .head span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000
}

.stripe_fields_wrapper .stripe_btn .card_preview .card span:last-child,
.stripe_fields_wrapper .stripe_btn .card_preview .head span:last-child {
    color: var(--new-black)
}

.stripe_fields_wrapper .stripe_btn .card_preview .card {
    justify-self: end;
}

.stripe_fields_wrapper .stripe_btn .card_preview .card span {
    font-size: 14px;
}

.about_artist,
.investor_profile_about {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
}

.investor_profile_about {
    margin-top: 13px;
}

.about_artist h2,
.investor_profile_about h2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.45;
    color: var(--dark-grey);
    margin: 0;
}

.about_artist a,
.investor_profile_about a {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    transition: .4s all;
    color: #ffffff;
    background-color: var(--new-black);
    padding: 10px 50px;
    width: max-content;
    text-align: center;
}

.about_artist h5,
.investor_profile h5 {
    display: block;
    margin: 0 0 14px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: var(--dark-grey);
}

.investor_profile_categories_wrapper {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    grid-gap: 40px;
    margin-bottom: 2rem;
}

.investor_profile_categories_container {
    display: flex;
    flex-wrap: wrap;
}

.investor_profile_categories_container span {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: 0.42px;
    color: #ffffff;
    padding: 5px 22px;
    background-color: var(--new-black);
    border-radius: 100px;
    margin: 0 15px 0 0;
}

.investor_profile_categories_wrapper a {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 14px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.44;
    color: var(--dark-grey);
    white-space: pre;
}

.investor_profile_favorites_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
}

.investor_profile_categories_wrapper div:nth-child(2) {
    margin: 0 0 0 auto;
}


.profile_section_body_stripe_info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2.5rem 1.5rem;
    margin: 0 auto 40px;
    max-width: 726px;
    width: 100%;
}

.profile_section_body_stripe_info button {
    grid-column: 1/-1;
    max-width: 168px;
}

.title-nft-content {
    margin: 0 auto 3.5rem;
    max-width: 726px;
    width: 100%;
}

@media (max-width: 1500px) {
    .investor_profile_favorites_container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {


    .about_artist button {
        max-width: 250px;
        justify-self: center;

    }

    .portfolio_page_artist_cv {
        display: grid;
    }

    .portfolio_page p {
        margin-top: 20px;
        text-align: center;

    }

    .portfolio_page a {
        justify-self: center;
        max-width: 250px;
        margin-top: 50px;
    }
}

@media (max-width: 650px) {
    .investor_profile_favorites_container {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 568px) {
    .profile_section_body_stripe_info button {
        max-width: unset;
    }

    .profile_section_body_stripe_info {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }

    .about_artist button {
        width: 100%;
    }

    .portfolio_page a {
        width: 100%;
        margin-top: 50px;
    }

    .portfolio_page .stripe_btn {
        grid-template-columns: 1fr;
        grid-gap: 16px;
        margin-bottom: 30px;
    }

    .portfolio_page .stripe_btn button {
        width: 100%;
    }

    .about_artist {
        grid-template-columns: 1fr;
    }

    .investor_profile_about {
        grid-template-columns: 1fr;
        margin-bottom: 13px;
        grid-gap: 16px;
    }

    .investor_profile_about a {
        padding: 10px 0;
        width: 100%;
    }

    .investor_profile_categories_wrapper {
        grid-template-columns: 1fr;
        grid-gap: 20px;
        margin-bottom: 1.5rem;
    }

    .stripe_fields_wrapper .stripe_btn > .layout {
        grid-template-columns: 1fr;
        max-width: 214px;
    }

    .stripe_fields_wrapper .stripe_btn > .layout button {
        width: 100%;
    }
}