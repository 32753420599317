.registration_wrapper_head {
    width: 100%;
    background: url("../../../assets/img/personal_registration/registration_navigation_bg.png") no-repeat center;
    background-size: cover;
    height: 192px;
    margin-bottom: 5rem;
}

.registration_wrapper_head > .registration_wrapper_head_container {
    background: rgba(43, 43, 46, 0.31);
    height: 192px;
    position: relative;
    display: grid;
    align-items: center;
}

.registration_wrapper_head_container h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 34px;
    font-weight: bold;
    line-height: 1.32;
    color: #ffffff;
    margin: auto;
    text-align: center;
}

.registration_wrapper_head_container .registration_wrapper_head_block {
    background: #f5f5f5;
    position: absolute;
    display: grid;
    grid-template-columns: repeat(5, max-content);
    grid-gap: 21px;
    bottom: -25%;
    left: 50%;
    justify-content: center;
    padding: 12px 0 8px;
    transform: translateX(-50%);
    width: max-content;
}

.registration_wrapper_head_container .registration_wrapper_head_block_investor {
    grid-template-columns: repeat(3, max-content);
}

.registration_wrapper_head_block > div {
    text-align: center;
    padding: 12px 0 8px;
}

.registration_wrapper_head_block > div:last-child {
    padding-right: 50px;
}

.registration_wrapper_head_block > div:first-child {
    padding-left: 50px;
}

.head_container_circle div {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    border: solid 2px var(--grey);
}

.head_container_circle_active div {
    border: solid 2px var(--new-black);
}

.head_container_circle_active path {
    fill: var(--new-black);
}

.head_container_circle span {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.43;
    color: var(--grey);
}

.head_container_circle_active span {
    color: var(--new-black);
}

.head_container_lines {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(5, max-content);
    align-items: center;
}

.head_container_lines div {
    width: 7px;
    height: 1px;
    border-radius: 2px;
    margin-top: -20px;
    border: solid 1px var(--grey);
    background-color: var(--grey);
}

.registration_wrapper_body h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
    font-weight: bold;
    line-height: 1.73;
    color: var(--dark-grey);
    text-align: center;
}

.registration_wrapper_body {
    width: max-content;
    margin: 0 auto;
}

.registration_main_info_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 18px 3.3vw;
}

.registration_main_info_container span {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: var(--dark-grey);
    display: block;
    margin-bottom: 7px;
}

.registration_main_info_container_social_block {
    display: grid;
    grid-gap: 15px 45px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
}

.registration_main_info_container_social span {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: var(--dark-grey);
    margin: 18px 0 9px;
    display: block;
}

.registration_main_info_about .upload_cv_wrapper {
    position: relative;
    width: max-content;
}

.registration_main_info_about .upload_cv_wrapper > .clear_cv {
    position: absolute;
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    right: -1.5rem;
    background: #FFFFFF;
    box-shadow: 0 10px 15px -3px rgba(26, 32, 44, 0.1), 0 4px 6px -2px rgba(26, 32, 44, 0.05);
    display: grid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--grey);
}

.registration_main_info_about .upload_cv_wrapper > .clear_cv > .delete {
    display: block;
    margin: auto;
}

.registration_main_info_about .upload_cv_wrapper > span {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: var(--dark-grey);
    margin: 10px 0 8px;
    display: block;
}

.registration_main_info_about .upload_cv_wrapper .button_bordered svg path {
    fill: var(--new-black);
}

.registration_main_info_about .upload_cv_wrapper button:hover svg path {
    fill: var(--new-black);
    transition: .4s;
}

.registration_categories_select {
    margin: 20px auto 24px;
    display: grid;
    justify-content: space-around;
}

.categories_options {
    padding: 5px 5px 4px 20px;
    border-radius: 100px;
    background-color: var(--new-black);
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: 0.42px;
    color: #ffffff;
    width: max-content;
    width: -moz-max-content;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 20px;
    align-items: center;
    margin: 0 1rem 1rem 0;
}

.categories_options_white {
    background-color: #fff;
    color: var(--new-black);
    border: 2px solid var(--new-black);
}


.categories_options img,
.categories_options svg {
    cursor: pointer;
}

.categories_options_white svg path {
    fill: #fff;
    stroke: var(--new-black);
}

.registration_categories_select .custom_dropdown_wrapper {
    max-width: 300px;
}

.categories_options_container {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
}

.registration_wrapper input[type=file] {
    display: none
}

.registration_wrapper .wrapper_navigation_btn {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    margin: 32px 0 64px;
    grid-gap: 0 20px;
}

.registration_wrapper .wrapper_navigation_btn a,
.registration_wrapper .wrapper_navigation_btn button {
    padding: 10px 60px;
}


@media (max-width: 1200px) {
    .registration_wrapper {
        margin-top: 72px;
    }
}

@media (max-width: 992px) {
    .registration_wrapper {
        margin-top: 60px;
    }
}

@media (max-width: 600px) {
    .registration_wrapper_head_block > div:last-child {
        padding-right: 32px;
    }

    .registration_wrapper_body {
        width: unset;
        padding: 0 20px;
    }


    .categories_options_container {
        max-width: 260px;
    }

    .registration_wrapper_head_block > div:first-child {
        padding-left: 32px;
    }

    .registration_wrapper_head_block .head_container_lines {
        grid-template-columns: repeat(2, max-content);
        grid-gap: 8px;
    }

    .registration_wrapper_head_container .registration_wrapper_head_block {
        grid-gap: 8px;
    }

    .registration_main_info_container {
        grid-template-columns: 1fr;
    }

    .registration_main_info_container .custom_dropdown_wrapper {
        max-width: unset;
    }

    .categories_options {
        margin: 0 .5rem .5rem 0;
    }

}

@media (max-width: 450px) {
    .registration_wrapper_head_container h1 {
        margin: unset;
        padding-top: 12px;
        align-self: start;
    }

    .registration_wrapper_head_block > div:last-child {
        padding-right: 12px;
    }

    .registration_wrapper_head_block > div:first-child {
        padding-left: 12px;
    }

    .registration_wrapper_body {
        padding: 0 20px;
    }

    .registration_wrapper .wrapper_navigation_btn a,
    .registration_wrapper .wrapper_navigation_btn button {
        padding: 10px 44px;
    }
}

@media (max-width: 400px) {
    .registration_main_info_container_social_block {
        grid-template-columns:1fr
    }
}
