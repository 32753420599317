.successful_order_wrapper {
    margin: 156px 0 18em 0;
}

.successful_order_background {
    height: 155px;
    position: absolute;
    /*top: 89px;*/
    width: 100%;
    z-index: -1;
    background-color: var(--pale-grey);
}

.order_wrapper_content {
    padding: 0 10%;
}

.order_wrapper_content h1 {
    margin-top: 20px;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left;
    color: var(--dark-grey);
}

.logo_order {
    position: relative;
    display: grid;
    margin: auto auto 2.5rem;
    justify-content: center;
}

.delivery_logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.order_details {
    display: grid;
    padding: 0 15%;
}

.order_details h2,
.order_details .checkout-success {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    color: var(--dark-grey);
}

.order_details p, .order_details a {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.63;
    font-stretch: normal;
    font-style: normal;
    color: var(--slate);
    margin-top: 20px;
}

.order_details a {
    color: #006dd9;
    text-decoration: underline;
}

.order_details a:hover {
    color: #006dd9;
}

@media (max-width: 1200px) {
    .successful_order_background {
        /*top: 73px;*/
    }

    .successful_order_wrapper {
        margin: 140px 0 18em 0;
    }
}

@media (max-width: 992px) {
    .successful_order_background {
        /*top: 60px;*/
    }

    .successful_order_wrapper {
        margin: 127px 0 18em 0;
    }
}

@media (max-width: 568px) {
    .order_details {
        display: grid;
        padding: 0 1rem;
    }

    .order_wrapper_content h1 {
        margin: 0 0 2rem;
    }

    .successful_order_wrapper {
        margin: 95px 0 18em 0;
    }
}
