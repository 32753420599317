.root {

}

.root :global(.ant-modal-close) {
    right: 1.375rem;
    top: 1.25rem;
}

.root :global(.ant-modal-close),
.root :global(.ant-modal-close-x) {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
}

.root :global(.ant-modal-content) {
    padding: 1.5rem;
    background-color: var(--beige);
}

.root :global(.ant-modal-header) {
    padding: 0 0 1.5rem 0;
    background-color: var(--beige);
    border-bottom: 1px solid #000000;
    margin: 0;
}

.root :global(.ant-modal-title) {
    font-size: 1.25rem;
}

.root :global(.ant-modal-body) {
    font-size: 1rem;
}

.root :global(.ant-modal-body:not(:empty)) {
    padding: 1.5rem 0 2.5rem;
}

.root :global(.ant-modal-footer) {
    padding: 0;
    border: 0;
}

.root :global(.ant-modal-footer .ant-btn) {
    height: 42px;
    padding: 0.375rem 2.5rem;
    border-radius: 0;
}

.root :global(.ant-modal-footer .ant-btn + .ant-btn) {
    margin-left: 1.5rem;
}

.root :global(.ant-modal-footer .ant-btn:first-child) {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000;
}

.root :global(.ant-modal-footer .ant-btn:last-child) {
    color: #000000;
    background-color: transparent;
    border-color: #000000;
    border-width: 2px;
}

@media (max-width: 500px) {
    .root :global(.ant-modal-footer) {
        display: grid;
        grid-gap: 1rem;
    }

    .root :global(.ant-modal-footer .ant-btn:last-child) {
        margin: 0;
    }
}