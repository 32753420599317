.wrapper {
  margin-top: 50px;

  .title {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 35px;
  }

  .form {
    .upload_profile_video_button_container {
      display: flex;
      justify-content: space-between;
    }
    .upload_profile_video_button_container button {
      width: 155px;
      border: 2px solid #000000;
      transition: .3s;

    }
    .upload_profile_video_button_container button:hover {
      background-color: #000000;
    }
    .form_button {
      display: grid;

      button {
        justify-self: end;
        max-width: 200px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18.58px;
        line-height: 28px;
        color: #000;

        &:hover {
          color: #fff;

          svg path {
            fill: #fff
          }
        }
      }
      @media (max-width: 500px) {
        button {
          width: 100%;
          max-width: unset;
        }
      }
    }

    .form__label {
      margin: 35px 0 20px;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #8E8E8E;
    }

    .wrapper_inputs {
      display: grid;
      grid-gap: 12px;
    }

    .form__input_link {
      margin-bottom: 17px;
    }

    .error_component {
      margin-top: 20px;
    }

    .avatar_wrapper {
      width: 400px;
      height: 400px;
      display: grid;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      & > .uploader .uploader_img {
        border-radius: 50%;
      }

      .video_preview {
        height: 240px;
        width: 400px;
        background-color: black;
        background-image: url('../../../../assets/img/onboarding/upload.svg');
        background-repeat: no-repeat;
        background-position: center;
        position: relative;

        label {
          cursor: pointer;
          position: absolute;
          width: 100%;
          top: 0;
          bottom: 0;

          video {
            position: absolute;
            z-index: 10;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }


    .form_upload {

      .form_upload__label_flex {
        display: flex;
        align-items: center;
        margin-top: 20px;
        width: max-content;

        .form_upload__icon {
          display: block;
          width: 20px;
          height: 20px;
          object-fit: contain;
          margin-right: 30px;
        }

        .form_upload__text {
          font-style: normal;
          font-weight: 600;
          border-bottom: 2px solid #8E8E8E;
          font-size: 18.58px;
          line-height: 28px;
        }
      }
    }

    .form_buttons {
      display: grid;
      grid-template-columns: max-content;
      grid-gap: 20px;
      margin-top: 50px;

      button:last-child {
        margin-top: 20px;
        align-self: center;
      }

    }
  }

  @media screen and (min-width: 980px) {
    .title {
      font-family: 'Poppins', sans-serif;
      margin-bottom: 66px;
      font-weight: 600;
      font-size: 48px;
      line-height: 56px;
    }

    .form {
      .form__label {
        margin: 30px 0 22px;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
      }

      .form_upload .form_upload__text {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
      }

      .form_buttons {
        margin-top: 48px;
        grid-template-columns: max-content 1fr;

        button:last-child {
          margin: 0;
          white-space: inherit;
        }
      }
    }
  }
  @media screen and (min-width: 980px) and (max-height: 790px) {
    margin-top: 70px;
    .title {
      font-family: 'Poppins', sans-serif;
      font-size: 36px;
      line-height: 1.5;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 500px) {
    .form .form_buttons {
      grid-template-columns: 1fr !important;

      button {
        max-width: unset;
        width: 100%;
      }
    }
    .form .avatar_wrapper {
      width: 280px;

      .video_preview {
        width: inherit;
      }
    }
  }
}


