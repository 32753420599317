.listings .wrapper_every_section {
    padding: 0 80px;
}

.listings .wrapper_list_filters {
    display: flex;
    flex-direction: column;
    /*grid-gap: 0 20px;*/
    background: #f5f5f5;
    padding: 8px 80px;
}

.artworks_listing {
    margin-bottom: 48px;
}

.artworks_listing .wrapper_list_filters .checkbox_wrapper {
    grid-template-columns: 28px 1fr;
}

.artworks_listing .wrapper_list_filters {
    padding: 0;
}

.artworks_listing .wrapper_new_pagination {
    margin-bottom: 2rem;
}

.artworks_listing .wrapper_list_filters > div:nth-child(1) {
    padding-left: 80px;
}

.artworks_listing .wrapper_list_filters > div:nth-child(2) {
    padding-right: 80px;
}

.artworks_listing .wrapper_list_filters > div:nth-child(3),
.artworks_listing .wrapper_list_filters > div:nth-child(1),
.artworks_listing .wrapper_list_filters > div:nth-child(2) {
    padding-bottom: 16px;
    padding-top: 16px;
}


.listings .wrapper_list_filters__filters {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 0 40px;
    height: max-content;
}

.artworks_listing .wrapper_list_filters__filters {
    grid-template-columns: repeat(5, max-content);
}

.listings .more_filters_wrapper {
    border-top: 1px solid var(--lite-grey);
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 200px 200px;
    grid-gap: 0 40px;
}

.listings .more_filters_wrapper > .more_filters_params {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    position: relative;
}

.listings .more_filters_wrapper > .more_filters_params span:first-child {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.44;
    color: var(--dark-grey);

}

.listings .more_filters_wrapper > .more_filters_params span:last-child {
    font-size: 14px;
    font-weight: bold;
    justify-self: end;
    line-height: 1.43;
    letter-spacing: 0.42px;
    color: var(--grey);
}

.listings .more_filters_wrapper .more_filters_params__info {
    display: grid;
    grid-template-columns: max-content 1fr;
}

.listings .more_filters_wrapper .more_filters_params__info_new {
    grid-template-columns: max-content;
}

.listings .more_filters_wrapper_new > .more_filters_params span:first-child {
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    color: rgba(0, 0, 0, .35);
    margin: 0;
}

.listings .more_filters_wrapper .more_filters_params__info_new span, .listings .more_filters_wrapper .more_filters_params__info_new span:first-child, .listings .more_filters_wrapper .more_filters_params__info_new span:last-of-type {
    font-weight: normal;
    /*font-size: 12px;*/
    /*line-height: 30px;*/
    display: block;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    line-height: 1.5715;
}

.listings .more_filters_wrapper .more_filters_params__info .range_slider {
    width: 93%;
}

.listings .wrapper_list_filters__more_filters .more_filter {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 0 8px;
    cursor: pointer;
    align-items: center;
}

.listings .wrapper_list_filters__more_filters .more_filter_new {
    display: grid;
    justify-content: end;
    grid-template-columns: max-content;
    grid-gap: 0;
    width: max-content;
    margin-left: auto;
}

.listings .wrapper_list_filters__more_filters .more_filter span {
    color: var(--grey)
}

.listings .wrapper_list_filters__more_filters .more_filter span {
    font-weight: 500;
    font-size: 20px;
    line-height: 45px;
    text-decoration-line: underline;
    color: #000000;
    user-select: none;
}

.mobile-filter-icon-container {
    width: 46px;
    height: 46px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter_block_container_button {
    height: 47px;
    width: 140px;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
}

.filter_block_container_button span {
    color: white !important;
    font-size: 12px !important;
    text-decoration-line: none !important;
}

/*.listings .wrapper_list_filters__filters .not_event > div:first-child {*/
/*    pointer-events: none;*/
/*}*/
.category_wrapper {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 0 8px;
    align-items: center;
    z-index: 5;
}

.category_wrapper_new {
    border: 2px solid #000000;
    padding: 6px 1rem;
    width: 100%;
}

.listings .wrapper_list_filters__filters .every_filter > div {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px 8px;
    height: max-content;
    cursor: pointer;
}

.listings .every_filter > div span {
    font-size: 12px;
    line-height: 30px;
    color: #000000;
}

.artist_listing .wrapper_every_section .wrapper_list_filters__filters .every_filter > div {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0 8px;
}

.listings .wrapper_list_filters__filters .every_filter img {
    transition: .4s;
    align-self: center;
}

.listings .every_filter .active {
    transform: rotateX(180deg);
}

.listings .every_filter {
    position: relative;
}

.listings .every_filter > * {
    align-self: center;
}

.listings .every_filter__popup {
    position: absolute;
    width: 256px;
    padding: 0;
    max-height: 193px;
    top: 40px;
    left: 0;
    border: solid 1px var(--lite-grey);
    background: #fff;
    z-index: 100;
    overflow-x: auto;
}

.listings .every_filter__popup_new {
    width: 100%;
    top: 45px;
    border: 1px solid #000000;
    background: #F6F6F1;
}

.listings .price .every_filter__popup > div {
    border: none;
}

.listings .every_filter__popup > div {
    border-bottom: 1px solid var(--lite-grey);
    padding: 12px 8px;
    cursor: pointer;
}

.listings .every_filter__popup_new > div {
    border-bottom: 1px solid var(--lite-grey);
    padding: 12px 8px;
}

.listings .every_filter__popup > div span {
    font-size: 16px;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    color: var(--dark-grey);
}

.listings .wrapper_list_filters__filters .every_filter__popup .numbers_of_listing_grey {
    color: var(--lite-grey);
}

.listings .wrapper_list_filters__filters .sort_by .every_filter__popup > div span {
    color: var(--dark-grey);
}

.listings .wrapper_list_filters__filters .every_filter__popup > div:last-child {
    border-bottom: none;
}

.listings .wrapper_list_filters__filters .price .every_filter__popup {
    height: 150px;
    display: grid;
    justify-content: center;
    align-content: center;
    grid-template-columns: 1fr;
    max-width: 250px;
    padding: 0 20px 0;
    overflow-x: hidden;
}

.listings .wrapper_list_filters__filters .price .every_filter__popup .input_range .input_component > span {
    color: var(--grey);
    font-size: 12px;
    line-height: 100%;
}

.listings .more_filters_wrapper > .more_filters_params .input_range,
.listings .wrapper_list_filters__filters .price .every_filter__popup .input_range {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-gap: 12px;
    align-items: center;
    border: none
}

.listings .more_filters_wrapper > .more_filters_params .input_range > span,
.listings .wrapper_list_filters__filters .price .every_filter__popup .input_range > span {
    display: block;
    margin-bottom: -18px;
}

.listings .wrapper_list_filters__search .input_component .custom_input {
    border: 0;
    background: inherit;
}

.listings .wrapper_list_filters__search .input_component .search_icon {
    cursor: default;
}

.listings .categories_options_container {
    margin: 26px 0;
    max-width: unset;
}

.listings .categories_options_container_new {
    margin: 0;
    max-width: 600px;
    padding: 0 !important;
    grid-column: -1/1;
}

.listings .categories_options_container_new .categories_options {
    padding: 8px 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    width: max-content;
    width: -moz-max-content;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    background: #000000;
    border: none;
    border-radius: 200px;
    margin: 0 1.5rem 1.5rem 0;
}

.listings .artists_list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 40px;
    padding-top: 40px;
}

.artists_list .profile_information_about_user {
    display: grid;
    grid-template-columns: 1fr;
}

.artworks_list {
    display: flex;
    margin-top: 40px;
    align-items: center;
    flex-direction: column;
}

.masonry_layout {
    columns: 7 420px;
    column-gap: 1.5rem;
    margin: 0 auto;
}

.masonry_layout > div, .masonry_layout > a {
    width: 420px;
    margin: 0 1.5rem 1.5rem 0;
    display: inline-block;
}

.masonry_layout > div a {
    overflow: hidden;
}

.masonry_layout > div img, .masonry_layout > a img {
    object-fit: contain;
    display: block;
    height: auto !important;
}

.listings .artists_list > a {
    background-color: #f5f5f5;
    max-width: 450px;
    border: 1px solid var(--lite-grey);
    height: max-content;
}

.listings .wrapper_gallery {
    height: 160px;
    width: 100%;
}

.listings .artists_list > a .artworks_placeholder {
    height: 160px;
    width: 100%;
    object-fit: cover;
}

.listings .wrapper_gallery img {
    height: inherit;
    width: inherit;
    object-fit: cover;
}

.listings .artists_list a .profile_information_about_user {
    padding: 20px;
    grid-gap: 20px;
}

.listings .artists_list a .profile_information_about_user_avatar_wrapper {
    display: grid;
    align-self: start;
}

.listings .artists_list a .profile_information_about_user_avatar_wrapper_default {
    width: 77px;
    height: 77px;
    align-self: start;
}

.listings .artists_list a .profile_information_about_user_avatar_wrapper_default img {
    height: inherit;
}

.listings .artists_list a .categories_options_container {
    margin: 26px 20px 10px;
}

.listings .artists_list a .profile_information_about_user h3 {
    font-size: 20px;
    line-height: 1.45;
    color: var(--dark-grey);
}

.listings .artists_list a .profile_information_about_user span {
    font-size: 16px;
    font-style: italic;
    color: var(--dark-grey);
}

.listings .artists_list a .profile_information_about_user_user_avatar_icon_bg {
    width: 24px;
    height: 24px;
    bottom: 1px;
    right: 0;
}

.listings .artists_list a .profile_information_about_user_user_avatar_icon {
    width: 16px;
    height: 16px;
}

.listings .wrapper_pagination {
    margin-top: 60px;
}

.listings .input-range__label--value {
    top: -2rem;
}


.artists_list .profile_information_about_user_avatar_wrapper .profile_information_about_user_avatar img {
    height: 77px;
    width: 100%;
}

@media screen and (max-width: 2500px) {
    .masonry_layout {
        columns: 3;
    }

    .masonry_layout > div, .masonry_layout > a {
        width: 100%;
    }
}

@media screen and (max-width: 1570px) {
    .masonry_layout {
        columns: 2;
    }
    .listings .artists_list {
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    }
}

@media screen and (max-width: 1100px) {
    .masonry_layout {
        columns: 1;
    }
}

@media (max-width: 992px) {
    .listings .categories_options_container_new {
        grid-column: 1/-1;
    }
}

@media screen and (max-width: 768px) {
    .listings .wrapper_every_section {
        padding: 0 40px;
    }

    .listings .artists_list {
        grid-gap: 20px;
        padding-top: 40px;
    }

    .artworks_listing .wrapper_list_filters > div:nth-child(1) {
        padding-left: 40px;
    }

    .artworks_listing .wrapper_list_filters > div:nth-child(2) {
        padding-left: 40px;
    }

    .artworks_listing .wrapper_list_filters > div:nth-child(3) {
        padding: 12px 40px;
    }

    .listings .more_filters_wrapper {
        grid-gap: 0 80px;
    }

    .listings .wrapper_list_filters__filters {
        grid-gap: 0 20px;
    }

    .artist_listing .wrapper_list_filters {
        padding: 8px 40px;
    }
}

@media screen and (max-width: 650px) {
    .artist_listing .wrapper_list_filters {
        padding: 0;
    }

    .listings .every_filter__popup div {
        border-bottom: none;
        padding: 0;
    }

    .listings .every_filter__popup_new > div {
        border-bottom: 1px solid var(--lite-grey);
        padding: 12px 8px;
    }

    .masonry_layout {
        display: grid;
        grid-template-columns: 1fr;
    }

    .listings .mobile_show_filters {
        display: grid;
        padding: 20px 0;
        background: #f5f5f5;
        justify-content: center;
        align-items: center;
        grid-template-columns: max-content max-content;
        cursor: pointer;
        grid-gap: 0 8px;
    }

    .listings .mobile_show_filters span {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.44;
        color: var(--grey);
    }

    .listings .accordion_mob_filter {
        background: #f5f5f5;
        padding: 12px 0;
        display: grid;
    }

    .listings .accordion_mob_filter .more_filters_wrapper,
    .listings .accordion_mob_filter .wrapper_list_filters,
    .listings .accordion_mob_filter .wrapper_list_filters__filters {
        grid-template-columns: 1fr;
    }

    .listings .accordion_mob_filter .wrapper_list_filters > div {
        padding: 0;
    }

    .listings .accordion_mob_filter .more_filters_wrapper {
        padding: 12px 40px !important;
        display: grid;
        justify-items: center;
        grid-gap: 20px;
    }

    .listings .accordion_mob_filter .more_filters_wrapper > div {
        width: 250px;
    }

    .listings .accordion_mob_filter .more_filters_wrapper > .more_filters_params {
        grid-gap: 32px;
    }

    .listings .accordion_mob_filter .wrapper_list_filters__filters .every_filter .every_filter__popup {
        position: relative;
        background: transparent;
        border: none;
        padding: 0;
        margin: 0 0 8px;
        top: 0;
    }

    .listings .accordion_mob_filter .wrapper_list_filters__filters .not_event > div:first-child {
        pointer-events: unset;
    }

    .listings .accordion_mob_filter .wrapper_list_filters__filters .every_filter {
        padding: 0 20px;
        border-bottom: 1px solid var(--grey);
    }

    .listings .accordion_mob_filter .wrapper_list_filters__filters .every_filter:last-child {
        border-bottom: none;
    }

    .listings .accordion_mob_filter .wrapper_list_filters__search {
        margin: 20px 0;
    }

    .listings .accordion_mob_filter .wrapper_list_filters__search .input_component {
        border-bottom: 1px solid var(--grey);
        margin: 0 20px;
    }

    .listings .accordion_mob_filter .wrapper_list_filters__filters .every_filter > div:first-child {
        padding: 8px 0;
    }

    .listings .accordion_mob_filter .wrapper_list_filters__filters .every_filter > div:first-child span {
        font-weight: 600;
    }


    .listings .accordion_mob_filter .wrapper_list_filters__filters .every_filter__popup {
        width: 100%;
        display: grid;
        grid-gap: 8px;
    }

    .listings .accordion_mob_filter .wrapper_list_filters__filters .price .every_filter__popup {
        height: max-content;
        margin: 20px auto 8px;
    }

    .listings .accordion_mob_filter .wrapper_list_filters__filters .price .every_filter__popup,
    .listings .accordion_mob_filter .more_filters_params {
        padding: 0 8px;
    }

    .listings .artists_list > div {
        justify-items: center;
    }
}

@media screen and (max-width: 700px) {
    .masonry_layout {
        columns: 1;
    }

    .masonry_layout > div, .masonry_layout > a {
        margin: 0 0 1.5rem 0;
    }

    .listings .wrapper_gallery {
        height: 160px;
    }

    .listings .artists_list > a {
        margin: 0 auto;
        width: 100%;
    }

}

@media screen and (max-width: 600px) {
    .listings .artists_list a .categories_options_container {
        margin: 12px 12px 4px;
    }
}

@media screen and (max-width: 420px) {
    .listings .wrapper_every_section {
        padding: 0 20px;
    }

    .listings .artworks_list,
    .listings .artists_list {
        padding: 20px;
    }
}
