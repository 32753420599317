.root {
    margin-left: 0;
    margin-right: 0;
}

.root :global(.ant-slider-handle:focus) {
    box-shadow: none;
}

.root :global(.ant-slider-rail) {
    height: 2px;
    background-color: #000000;
    border-radius: 0;
}

.root:hover :global(.ant-slider-rail) {
    background-color: #000000;
}

.root :global(.ant-slider-handle) {
    margin-top: -10px;
    width: 4px;
    height: 24px;
    background-color: #000000;
    border: 0;
    border-radius: 0;
}

.root :global(.ant-slider-handle::after) {
    display: none;
}

.root :global(.ant-slider-track),
.root:hover :global(.ant-slider-track) {
    background-color: #000000;
    height: 2px;
}
