.artist_info_card img {
    height: 215px;
    object-fit: cover;
    width: 100%;
    display: block;
    max-width: 100%;
    margin: 0 0 1rem;
}

.artist_info_card h5 {
    font-size: 18px;
    line-height: 150%;
    margin: 0 0 1rem;
    color: #000000;
}

.artist_info_card h4 {
    font-size: 18px;
    font-weight: normal;
    line-height: 150%;
    margin: 0 0 1rem;
    color: #000000;
}

.artist_info_card .follow_button {
    background: transparent;
    padding: 10px 8px 10px 0;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    transition: .3s all;
    width: 100%;
}

.artist_info_card .follow_button span {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15em;
    color: #000000;
    padding: 0;
    transition: .3s all;
    text-transform: uppercase;
}

.artist_info_card:hover .follow_button {
    background: #000000;
}

.artist_info_card:hover .follow_button span {
    color: #FFFFFF;
    padding-left: 18px;
}

.artist_info_card .follow_button_active {
    background: #000000;
}

.artist_info_card .follow_button_active span {
    color: #FFFFFF;
    padding-left: 18px;
}

@media (max-width: 568px) {
    .slick-center .artist_info_card .follow_button {
        background: #000000;
    }

    .slick-center .artist_info_card .follow_button span {
        color: #FFFFFF;
        padding-left: 18px;
    }

    .artist_info_card:hover .follow_button {
        background: unset;
    }

    .artist_info_card:hover .follow_button_active {
        background: #000000;
    }

    .artist_info_card:hover .follow_button_active span {
        color: #FFFFFF;
        padding-left: 18px;
    }

    .artist_info_card:hover .follow_button_active span {
        color: unset;
        padding-left: unset;
    }

    .slick-center .artist_info_card:hover .follow_button {
        background: #000000;
    }

    .slick-center .artist_info_card:hover .follow_button span {
        color: #FFFFFF;
        padding-left: 18px;
    }
}