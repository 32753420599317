.admin_content_wrapper_article {
    padding: 2rem 15%;
}

.admin_content_wrapper_article > p {
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}

.admin_content_wrapper_article > .head {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    margin: 0 0 2rem;
}

.admin_content_wrapper_article > .head > .navigation {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 8px;
    align-items: center;
}

.admin_content_wrapper_article > .head > .navigation a {
    font-size: 14px;
    line-height: 24px;
    color: rgba(46, 58, 89, 0.7);
}

.admin_content_wrapper_article > .head > .navigation span {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #2E3A59;
}

.admin_content_wrapper_article > .head > span {
    font-size: 16px;
    line-height: 155%;
    color: #828181;
}

.admin_content_wrapper_article > .title {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 1rem;
    align-items: center;
    margin: 0 0 1.5rem;
}

.admin_content_wrapper_article > .title > .delete {
    cursor: pointer;
}

.admin_content_wrapper_article > .title > h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.364px;
    color: #101426;
    text-align: center;
}

.admin_content_wrapper_article > .name_category {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    margin: 0 0 1.5rem;
}

.admin_content_wrapper_article > .name_category > p {
    font-size: 16px;
    line-height: 155%;
    color: #656565;
}

.admin_content_wrapper_article > .name_category > div {
    width: 1px;
    background: rgba(101, 101, 101, 0.25);
    height: 28px;
}

.admin_content_wrapper_article > .name_category > span {
    font-size: 13px;
    line-height: 157%;
    letter-spacing: 0.06em;
    color: rgba(0, 0, 0, 0.7);
    padding: 4px 10px;
    background: #F8F8FB;
    border-radius: 20px;
}

.admin_content_wrapper_article > .blog_main_content {
    margin: 0 0 2rem;
}

.admin_content_wrapper_article > .blog_main_content > .blog_main_photo {
    max-width: 100%;
    width: max-content;
    object-fit: cover;
    height: 100%;
    max-height: 100%;
    margin: 0 auto 1rem;
    display: block;
}

.admin_content_wrapper_article > .blog_section, .admin_content_wrapper_article > .blog_section_right {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 2rem;
    align-items: start;
    margin: 0 0 2rem;
}

.admin_content_wrapper_article > .blog_section_right {
    grid-template-columns: 1fr 2fr;
}

.admin_content_wrapper_article > .blog_section_right > .text_content {
    grid-column: 2;
    grid-row: 1;
}

.admin_content_wrapper_article > .blog_section_right > .text_content pre {
    white-space: inherit;
}

.admin_content_wrapper_article > .blog_section:last-of-type {
    margin: 0;
}

.admin_content_wrapper_article > .blog_section > img {
    max-width: 100%;
}

.admin_content_wrapper_article > .blog_section > .text_content h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    margin: 0 0 1rem;
    letter-spacing: 0.364px;
    color: #101426;
}

.admin_content_wrapper_article > .blog_section > .text_content p {
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    min-height: 15px;
}

.admin_content_wrapper_article ul {
    position: relative;
    text-align: left;
    padding-left: 1.5rem;
    margin: 0;
}

.admin_content_wrapper_article ul li {
    font-size: 16px;
    font-weight: 300;
    color: var(--slate);
    margin: 0 0 2px;
}

.admin_content_wrapper_article ul li:last-of-type {
    margin: 0;
}

.admin_content_wrapper_article ul li:before {
    content: "";
    background: #000000;
    width: .5rem;
    height: .5rem;
    border-radius: 100%;
    position: absolute;
    left: 0;
    margin: 8px 0 0;
}

.admin_content_wrapper_article > .blog_section_without_photo {
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    margin: 0 0 2rem;
}

.admin_content_wrapper_article > .blog_section_without_photo:last-of-type {
    margin: 0;
}