.header {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    width: 100%;
    display: grid;
    align-content: center;
    z-index: 2500;
    grid-template-columns: 1fr 1fr;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
}

.header_navigation img {
    width: 110px;
    height: 41px;
    max-width: 100%;
}

.header_navigation {
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr;
    grid-gap: 5vw;
    padding: 1.5rem 3.5vw 1.5rem 3.5vw;
    border-right: 1px solid var(--lite-grey);
}

.header_navigation ul {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(5, max-content);
    grid-gap: 4.25vw;
}

.header_navigation li > a, .header_navigation li > button {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: var(--dark-grey);
    transition: .4s all;
}

.header_navigation li > a:hover, .header_navigation li > button:hover {
    color: #000000;
}

.header_login_section {
    display: grid;
    grid-template-columns:  1fr max-content;
    /*justify-content: space-between;*/
    align-items: center;
    grid-gap: 0 20px;
    padding: 1.5rem 3.5vw 1.5rem 40px;
}

.header_login_section_user_active {
    grid-template-columns:  1fr max-content max-content;
}

.header_login_section .cart_wrapper {
    display: grid;
    align-content: center;
    position: relative;
    justify-self: end;
}

.cart_wrapper_items_counts {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 13px;
    height: 13px;
    border-radius: 100%;
    background-color: #000000;
    display: grid;
    align-items: center;
    justify-content: center;
}

.cart_wrapper_items_counts span {
    font-size: 8px;
    font-weight: bold;
    display: block;
    margin: auto;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    padding-top: 2px;
    color: #ffffff;
}

.cart_wrapper {
    cursor: pointer;
}

.cart_wrapper .cart_wrapper_mobile_text {
    font-size: 18px;
    font-weight: bold;
    padding-left: 12px;
}

.header_sign_in {
    display: grid;
    border: none;
    padding: 0;
    justify-self: end;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 21px;
    align-items: center;
}

.header_login_section_user_active .header_sign_in {
    border-left: solid 1px var(--lite-grey);
    padding: 0 12px;
}

.header_sign_in .auth_variant, .header_sign_in span {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.44;
    color: var(--dark-grey);
    transition: .4s all;
}

.header_sign_in .auth_variant:hover {
    color: #000000;
}

.header_sign_in .auth_variant {
    cursor: pointer;
}

.header_sign_in_container {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 4px;
}

/*popup*/
.auth_popup_wrapper {
    left: 0;
    right: 0;
    position: fixed;
    z-index: 100;
    bottom: 0;
    top: 89px;
    height: calc(var(--app-height) - 89px);
    background: rgba(0, 0, 0, .63)
}

.auth_popup_wrapper .popup {
    background: #fff;
    top: 0;
    right: 0;
    border-radius: 0;
    width: 340px;
    padding: 30px;
    object-fit: contain;
    position: absolute;
    z-index: 5000;
    display: grid;
    border: 1px solid #CBD5E0;
    filter: drop-shadow(0 0 2px rgba(40, 41, 61, 0.04));
}

.auth_popup_wrapper .new_popup {
    top: 14px;
}

.auth_popup_wrapper .popup .title {
    text-align: center;
}

.auth_popup_wrapper .popup .wrapper_forgot_pass_hint {
    text-align: center;
}

.auth_popup_wrapper .popup .title span {
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: var(--dark-grey);
}

.auth_popup_wrapper .forgot_password {
    text-align: right;
    margin: 12px 0 0;
}

.auth_popup_wrapper .forgot_password span {
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: var(--dark-grey);
}

.auth_popup_wrapper .back_to_sign_in {
    display: grid;
    cursor: pointer;
    margin-top: 32px;
    justify-self: center;
    grid-auto-flow: column;
    grid-gap: 0 12px;
    width: max-content;
    align-items: center;
}

.auth_popup_wrapper .back_to_sign_in span {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: var(--lite-grey);
}

.auth_popup_wrapper .forgot_password span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: var(--dark-grey);
}

.auth_popup_wrapper .switch_auth {
    margin-top: 20px;
    text-align: center;
}

.auth_popup_wrapper .switch_auth span {
    font-size: 16px;
    line-height: 1.38;
    text-align: left;
    color: var(--dark-grey);
}

.auth_popup_wrapper .switch_auth span:last-child {
    cursor: pointer;
    color: #000000;
    padding-left: 4px;
}

.auth_popup_wrapper .wrapper_inputs_auth {
    margin-top: 12px;
}

.auth_popup_wrapper .wrapper_btn {
    margin-top: 20px;
}

.auth_popup_wrapper .wrapper_btn .auth_delimitation {
    margin: 8px 0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--lite-grey);
}

.auth_popup_wrapper .wrapper_btn .fb_sign_wrapper {
    background: #395795;
}

.auth_popup_wrapper .wrapper_btn .fb_sign_wrapper button {
    width: 100%;
    display: grid;
    grid-template-columns: 50px 1fr;
    align-items: center;
    color: #fff;
    transition: .3s all;


}

.auth_popup_wrapper .wrapper_btn .fb_sign_wrapper button:hover {
    background: white;
    color: #395795;
    box-shadow: inset 0 0 0 1px #395795;
}


.auth_popup_wrapper .wrapper_btn .fb_sign_wrapper button i {
    color: #fff;
    background: #4263a0;
    padding: 15px 0;
}

.auth_popup_wrapper .wrapper_btn .fb_sign_wrapper {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #fff;
}

.auth_popup_wrapper .agree_terms {
    margin: 12px 0;
}

.auth_popup_wrapper .wrapper_radio_btn {
    display: grid;
    grid-gap: 18px 0;
    margin: 20px 0;
    justify-content: center;
}

.header_search_section label {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 0 18px;
}

.header_search_section label span {
    font-size: 16px;
    line-height: 1.44;
    font-weight: bold;
    color: var(--dark-grey);
}

.header_search_section label img {
    cursor: pointer;
}

.header_search_section label > input::placeholder, .header_search_section label > input {
    font-size: 16px;
    line-height: 1.44;
    color: var(--lite-grey);
}

.header_search_section input {
    color: var(--dark-grey);
    border: none;
    outline: none;
    padding-left: 4px;
}

.header_search_section input:focus {
    padding-left: 4px;
}

.hamburger {
    padding: 7px;
    cursor: pointer;
    transition: all .6s;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hamburger:focus, .hamburger:hover {
    outline: none !important;
    background: transparent !important;
}

.hamburger div {
    height: 2px;
    background: var(--dark-grey);
    transition: all .3s;
}

.hamburger div:nth-child(1) {
    width: 21px;
}

.hamburger div:nth-child(2) {
    width: 21px;
    margin-top: 4px;
    transition: .6s all;
    opacity: 1;
    position: relative;
    top: 0;
    right: 0;
}

.hamburger div:nth-child(3) {
    width: 21px;
    margin-top: 4px;
}

.hamburger_crossed {
    cursor: pointer;
    transition: .4s all;
    z-index: 200;
}

.hamburger_crossed div {
    height: 2px;
    background: var(--dark-grey);
    transition: .4s all;
}

.hamburger_crossed div:nth-child(1) {
    width: 21px;
    transform: rotate(45deg);
}

.hamburger_crossed div:nth-child(2) {
    opacity: 0;
    position: relative;
    top: 0;
    right: -1000%;
}

.hamburger_crossed div:nth-child(3) {
    width: 21px;
    transform: rotate(-45deg);
    margin-top: -8.5px;
}

.hamburger_crossed {
    pointer-events: none;
}

.header_registered_user {
    cursor: pointer;
    position: relative;
}

.header_registered_user img {
    padding-left: 4px;
    transition: .4s;
}

.header_registered_user .active {
    transform: rotateX(180deg);
}

.wrapper_profile_navigation_popup {
    position: absolute;
    background: #fff;
    border-top: 2px solid var(--turquoise);
    margin: 32px 0 0 -32px;
    right: 0;
    -webkit-box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
}

.wrapper_profile_navigation_popup div,
.wrapper_profile_navigation_popup a {
    display: grid;
    grid-template-columns: max-content 106px;
    grid-gap: 0 8px;
}

.wrapper_profile_navigation_popup div span,
.wrapper_profile_navigation_popup a span {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    padding: 6px 20px;
    color: var(--dark-grey);
}

.wrapper_profile_navigation_popup svg {
    margin-left: 22px;
    align-self: center;
}

.wrapper_profile_navigation_popup a span {
    border-bottom: 1px solid var(--lite-grey);
}

.wrapper_profile_navigation_popup div {
    border-top: none;
}


.pointer_sign {
    cursor: pointer;
}

.header_search_section {
    position: relative;
}

.title_of_search_category {
    background-color: #F5F5F5;
    padding: 4px 12px;
}

.search_wrapper_popup {
    margin: 32px 0;
    border-top: 2px solid var(--turquoise);
    overflow-y: auto;
    position: absolute;
    width: 100%;
    background-color: white;
    right: 40px;
    transition: .4s;
    z-index: 22;
    -webkit-box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);

}

.search_results_of_art {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid #F5F5F5;

}

.mob_search_logo {
    width: 16px !important;;
}

.left_side_header_menu.avatar_search_header,
.avatar_search_header {
    border-radius: 50px;
    width: 32px !important;
    height: 32px !important;
    object-fit: cover;
}

.art_band_of_artistfaction {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 8px;

}

.art_wrapper_search {
    display: grid;
    grid-gap: 5px;
    padding: 3px 13px;
}

.title_of_search_category span {
    text-transform: capitalize;
    align-self: center;
}

.left_side_header_menu {
    display: flex;
    justify-content: space-between;
}


.header_search_section_adaptive {
    display: flex;
    margin-right: 15px;
    align-items: center;
    z-index: 2600;
    width: 100%;
}

.header_search_section_adaptive .outside_click_wrapper {
    width: 100%;
}

.logo_hidden_wrapper_art {
    display: none;
}

.adaptive_search input:focus {
    outline: none;
}

.input_block_wrapper {
    padding-left: 20px;
}

.adaptive_search .search_wrapper_popup {
    right: 0;
}

.mob_search_logo {
    cursor: pointer;
}

.arrow_search_logo {
    width: 8px !important;
    height: initial !important;
}

@media (max-width: 1200px) {
    .header_login_section {
        padding: 1rem 3vw 1rem 2vw;
        grid-gap: 0 8px;
    }

    .search_wrapper_popup {
        margin: 26px 0;
        right: 25px;
    }

    .header_navigation {
        padding: 1rem 2.5vw;
    }

    .wrapper_profile_navigation_popup {
        margin-top: 25px;
    }

    .header_navigation ul {
        grid-gap: 0 3vw;
    }

    .auth_popup_wrapper {
        top: 72px;
        height: calc(var(--app-height) - 72px);
    }

    .auth_popup_wrapper .new_popup {
        top: 32px;
    }

    .first_section_wrapper {
        margin-top: 74px;
    }
}

@media (max-width: 1024px) {
    .wrapper_profile_navigation_popup {
        margin-top: 24px;
        box-shadow: none;
    }

    .auth_popup_wrapper .popup {
        top: 0;
    }

    .sidebar_menu__navigation__sign_wrapper,
    .wrapper_profile_navigation_popup a {
        border-color: #fff !important;

    }

    .auth_popup_wrapper {
        top: 72px;
        height: calc(var(--app-height) - 72px);
    }


    /*.header_navigation {*/
    /*    grid-gap: 4vw;*/
    /*    padding: 1rem 2vw 1rem 3vw;*/
    /*}*/
    .first_section_wrapper {
        margin-top: 72px;
    }

    .header_navigation ul {
        grid-gap: 3vw;
    }

    .search_wrapper_popup {
        margin: 20px 0;
        right: 20px;
    }
}


@media (max-width: 1100px) {
    .header_navigation {
        padding: 1rem 1.5vw;
        grid-gap: 3vw;
    }

    .header_login_section {
        padding: 1rem 1.5vw 1rem 1.5vw;
    }
}

@media (max-width: 992px) {
    .header_navigation img, .header_mobile img {
        width: 106px;
        height: 40px;
    }

    .search_wrapper_popup {
        margin: 24px 0;
        right: 20px;
    }

    .header_search_section {
        position: initial;
    }

    .auth_popup_wrapper {
        top: 60px;
        height: calc(var(--app-height) - 60px);
    }

    .header_mobile {
        padding: 10px 17px;
        grid-template-columns: 1fr max-content;
    }

    .first_section_wrapper {
        margin-top: 50px;
    }

    .cart_wrapper {
        position: relative;
    }

    .cart_wrapper_items_counts {
        top: -6px;
        right: 0;
        left: 10px;
    }
}

@media (max-width: 568px) {
    .auth_popup_wrapper {
        height: calc(120vh - 60px);
    }
}

@media (max-width: 320px) {
    .input_block_wrapper {
        padding-left: 4px;
    }

    .header_search_section label span {
        font-size: 13px;
        line-height: 1.44;
        font-weight: bold;
        color: var(--dark-grey);
    }

    .mob_search_logo {
        width: 15px !important;
    }
}