.card_item_section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    margin-bottom: 100px;
}

.card_item_container {
    height: 313px;
    border: 1px solid #000000;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    padding: 32px 16px;
}

.card_main_title {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 32px;
}

.card_title {
    font-weight: 600;
    font-size: 22px;
    color: #000000;
    text-align: center;
    margin-bottom: 16px;
}

.list_item {
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
}

@media (max-width: 1100px) {
    .card_item_section {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 450px) {
    .card_item_section {
        grid-template-columns: repeat(1, 1fr);
    }
    .card_item_container {
        height: auto;
    }
}
