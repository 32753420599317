.wrapper {
  margin-top: 50px;

  .title {
    font-weight: 600;
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 35px;
  }

  .works_wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 170px));
    grid-gap: 40px;
    margin: 30px 0 50px;
    @media (max-width: 500px) {
      grid-template-columns: 1fr;
      grid-gap: 12px;
    }
  }

  .label {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #8E8E8E;
  }

  .input {
    margin-bottom: 15px;

    &.is_focus {

      margin-bottom: 100px;
    }

    input::placeholder {
      font-weight: 400 !important;
      font-size: 18.58px;
      line-height: 28px;
      color: #A4A4A4;
    }
  }

  .inputs_wrapper {
    margin-top: 30px;
    display: grid;
    grid-gap: 20px;
  }

  .footer {
    margin-top: 50px;

    .footer__counter {
      display: none;
    }

    @media (max-width: 500px) {
      button {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 980px) {
    .title {
      font-weight: 600;
      font-size: 48px;
      line-height: 56px;
      margin-bottom: 45px;
    }

    .label {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
    }

    .works_wrapper {
      margin: 20px 0 20px;

      .uploader {
        width: 170px;
        min-width: 170px;
        height: 170px;
      }
    }

    .footer {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;

      margin-top: 50px;

      .footer__counter {
        display: block;
      }
    }
  }

  @media (min-width: 980px) and (max-height: 900px) {
    .title {
      font-size: 36px;
      line-height: 1.5;
      margin-bottom: 3rem;
    }
  }

  @media (min-width: 980px) and (max-height: 790px) {
    margin-top: 50px;
    .title {
      margin-bottom: 2rem;
    }
    .works_wrapper {
      margin: 15px 0 30px;
    }
  }

}
