.wrapper {
    position: relative;
    background-color: #F7F6F1;
    z-index: 1000;

    .control {
        position: relative;
        height: 60px;
        width: 100%;
        border: 2px solid black;
        
        .input {
            display: block;
            width: 100%;
            height: 100%;
            font-size: 18px;
            line-height: 28px;
            padding-left: 44px;
            padding-right: 60px;
            background-color: transparent;
            outline: none;
            border: 0;
        }

        .control__value {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 44px;
            padding-right: 60px;
            font-size: 18px;
            line-height: 28px;
        }

        .control__icon {
            position: absolute;
            right: 30px;
            top: 25px;
            width: 20px;
            transition: .4s;
            height: 10px;
            background-image: url('../../../../assets/img/onboarding/select_arrow.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }


    .menu {
        position: absolute;
        border: 2px solid black;
        width: 100%;
        z-index: 1000;
        background-color: #F7F6F1;
        max-height: 150px;
        overflow-y: auto;
        .menu__item {
            display: flex;
            align-items: center;
            font-size: 18px;
            height: 30px;
            padding: 0 0 0 44px;
            cursor: pointer;

            &:hover {
                background-color: #ddd;
            }
        }
    }

    &.error {
        .control, .menu {
            border-color: #FF4949;
        }
    }

    &.is_open {
        .control {
            border-bottom: 0;

            .control__icon {
                transform: rotate(180deg);
            }
        }

        .menu {
            top: 60px;
            border-top:0;
        }
    }
}