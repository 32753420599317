.wrapper {
}

.tableWrapper {
    margin-top: 3rem;
}

.tableWrapper :global(.ant-table-wrapper .ant-table thead.ant-table-thead tr th) {
    color: rgba(0,0,0,0.4);
    text-transform: uppercase;
    background-color: transparent;
}

.tableWrapper :global(.ant-table-wrapper .ant-table thead.ant-table-thead tr th::before) {
    display: none;
}

.tableWrapper :global(.ant-table-wrapper .ant-table thead.ant-table-thead tr th:first-child),
.tableWrapper :global(.ant-table-wrapper .ant-table tbody.ant-table-tbody tr td:first-child) {
    padding-left: 0;
}

.tableWrapper :global(.ant-table-wrapper .ant-table thead.ant-table-thead tr th:last-child),
.tableWrapper :global(.ant-table-wrapper .ant-table tbody.ant-table-tbody tr td:last-child) {
    text-align: right;
    padding-right: 0;
}

.tableDate {
    font-weight: 300;
    font-size: 0.75rem;
    padding: 0.75rem;
    text-align: center;
    background: rgba(246, 246, 241, 1);
    border-bottom: 1px solid rgba(0, 0, 0, 1)
}

.tableListItem {
    display: grid;
    grid-gap: 0.75rem;
    font-size: 0.75rem;
    padding: 0.75rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 1)
}

.tag:global(.ant-tag.ant-tag-has-color) {
    color: #000000;
    font-weight: 300;
    padding: 0 0.125rem;
    line-height: 1.4;
    font-size: 0.625rem;
}

.tableListItemText {
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 0.25rem;
}

.price {
    font-size: 0.875rem;
    font-weight: 600;
}

.date {
    font-weight: 300;
}

.headerFitters {
    display: grid;
    align-items: center;
    grid-template-columns: auto 300px;
}

@media (max-width: 768px) {
    .tableWrapper {
        margin-top: 1.5rem;
    }

    .headerFitters {
        grid-template-columns: 1fr;
    }

    .datePicker {
        order: -1;
        margin-bottom: 1.5rem;
    }
}