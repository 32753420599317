.wrapper_admin_panel_content h6,
.wrapper_admin_panel_content p {
    margin-bottom: 0;
}

.admin_content_wrapper {
    background: #FFFFFF;
    border-radius: 5px;
    padding: 2rem 0;
}

.admin_content_wrapper_users {
    padding: 0 4rem;
}

.admin_content_wrapper_users label {
    position: relative;
    width: max-content;
    display: block;
}

.admin_content_wrapper_users label > img {
    position: absolute;
    top: 11px;
    right: 12px;
}

.admin_content_wrapper_users input {
    border: 1px solid #333333;
    border-radius: 7px;
    padding: 14px 40px 14px 25px;
    font-size: 14px;
    line-height: 16px;
    width: 328px;
    color: #000000;
}

.admin_content_wrapper_users > .title {
    display: grid;
    align-items: center;
    grid-gap: 82px;
    grid-template-columns: repeat(2, max-content);
    margin: 0 0 14px;
}

.admin_content_wrapper_institutions.admin_content_wrapper_users .admin_artworks_head {
    grid-template-columns: 1fr max-content max-content;
    grid-gap: 32px;
}
.admin_content_wrapper_institutions.admin_content_wrapper_users .admin_artworks_head h5 {
    margin-bottom: 0;
}

.admin_content_wrapper_institutions > .title {
    display: grid;
    align-items: center;
    grid-gap: 32px;
    grid-template-columns: max-content 170px max-content;
    margin: 0 0 14px;
}

.admin_institution_item .content-section-container.features > * + * {
    margin-top: 40px;
}

.admin_institution_item .institution-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.admin_institution_item .institution-header .go-back {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    color: black;
}

.admin_institution_item .institution-header span:not(.go-back) {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
}

.admin_institution_item .institution-header h1 {
    margin-bottom: 0;
    font-size: 24px;
}

.admin_institution_item .footer-buttons {
    display: flex;
    justify-content: center;
}

.admin_institution_item .footer-buttons > button + button {
    margin-left: 16px;
}

.admin_content_wrapper_users > .title h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}

.admin_content_wrapper_users_list {
    border-top: 1px solid #EFEFF4;
}

.admin_users_list_no_items {
    font-size: 16px;
    line-height: 22px;
    padding: 1rem 4rem 0;
    color: #000000;
}

.admin_content_wrapper_users_list > .item {
    border-bottom: 1px solid #EFEFF4;
    padding: 16px 4rem 14px;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
}

.admin_content_wrapper_users_list > .item .item_content {
    display: grid;
    grid-template-columns: 82px max-content;
    align-items: center;
    grid-gap: 85px;
}

.admin_wrapper_institutions .admin_content_wrapper_users_list > .item .item_content {
    grid-template-columns: 100px 170px 200px;
    grid-gap: 32px;
}

.admin_wrapper_institutions .categories_options_container {
    max-width: unset;
}

.admin_institution_item button,
.admin_content_wrapper_users_list > .item button,
.admin_content_wrapper_users_list > .item a {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    padding: 2px 0;
    width: 89px;
    background: #000;
    border-radius: 4px;
    color: #fff;
}

.admin_content_wrapper_users_list > .item .item_content span {
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}

.admin_wrapper_modal .modal-window-content {
    max-width: 768px;
    width: 768px;
}

.popup_window_upload_work .modal-window-content {
    max-width: 550px;
    width: 100%;
    border-radius: 0;
}
.popup_window_upload_work .modal-window-content h4 {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 600;
}

.button_bordered_fill_in {
    background: black;
    color: white;
    border: solid 2px black;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    width: max-content;
    width: -moz-max-content;
    transition: .4s all;
}

.button_bordered_fill_in:hover {
    border: solid 2px black;
    background: transparent;
    color: black;
    box-shadow: none;
}

.button_bordered_close_popup {
    border: solid 2px black;
    background: transparent;
    color: black;
}

.button_bordered_close_popup:hover {
    background: black;
    color: white;
    border: solid 2px black;
    box-shadow: none;
}

.popup_window_upload_work .modal-window-content p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
    border-top: 1px solid black;
    padding-top: 18px;
    margin-top: 18px;
    color: black;
}

.portfolio_popup_header_container {
    display: flex;
    justify-content: space-between;
}

.portfolio_popup_header_container svg {
    cursor: pointer;
}

.admin_content_wrapper_users > .verified_container {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1.5rem;
}

.verified_content h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    opacity: 0.5;
    cursor: pointer;
    padding: 0 .5rem 4px;
    transition: .4s all;
    border-bottom: 2px solid #FFFFFF;
}

.admin_content_wrapper_users > .verified_container .verified_content {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    grid-gap: 1rem;
}

.verified_content_active {
    border-bottom: 2px solid #000 !important;
    opacity: 1 !important;
}

.admin_content_wrapper_users_list > .item .users_list_buttons {
    display: grid;
    grid-gap: 1rem;
    align-items: center;
}

.admin_institution_item .admin_bordered_button,
.admin_content_wrapper_users_list .users_list_buttons > .admin_bordered_button {
    background: transparent;
    border: 1px solid #000;
    box-sizing: border-box;
    border-radius: 4px;
    color: #000;
    padding: 1px 0;
}

.search-and-download-button-container {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 24px;
}

.search-and-download-button-container button {
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    border: 1px solid #000000;
    border-radius: 6px;
    padding: 0 16px;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 10px;
}

.item-nft-orders {
    border-bottom: 1px solid #EFEFF4;
    padding: 16px 4rem 14px;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
}

.admin_bordered_nft_button {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    padding: 2px 0;
    width: 89px;
    background: #000;
    border-radius: 4px;
    color: #fff;
}

.admin_bordered_nft_button_block {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    padding: 2px 0;
    width: 89px;
    background: #fff;
    border-radius: 4px;
    color: #000;
    border: 1px solid #000000;
}

.admin_bordered_nft_button_block_canceled {
    /*background: grey;*/
    opacity: 0.5;
    cursor: not-allowed
}

.orders-items-container {
    border-bottom: 1px solid #EFEFF4;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
}

.orders-left-content-container {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    align-items: center;
    grid-gap: 85px;
}

.orders-buttons-container {
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 1rem;
    padding: 16px 0 14px;
}

.admin-nfts-container {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    padding-bottom: 14px;
    border-bottom: 1px solid #EFEFF4;
    overflow: scroll;
}

.admin-nfts-section-name {
    display: grid;
    grid-template-columns: minmax(100px, 100px) minmax(100px, 100px) minmax(110px, 110px) minmax(100px, 100px);
    align-items: center;
    grid-gap: 48px;
}

.admin-nfts-section-name .admin-nfts-section-text {
    font-weight: 700;
    font-size: 15px;
}

.admin-nft-page-content-container {
    min-height: 75vh;
}

@media (max-width: 1275px) {
    .admin_content_wrapper_users input {
        width: auto;
    }
    .search-and-download-button-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 5px;
    }
    .search-and-download-button-container button {
        height: 45px;
    }
}
