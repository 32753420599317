.user_information_wrapper {
    padding: 2.5rem 10% 0;
    background: #FFFFFF;
    width: 100%;
}

.user_information_wrapper_art {
    background: var(--beige);
}

.user_information_wrapper > .info {
    padding: 0 14%;
    display: grid;
    grid-gap: 2.5rem;
    align-items: center;
    grid-template-columns: 3fr 5fr;
    justify-content: center;
}

.user_information_wrapper > .info > .avatar_container {
    padding-left: 2.25rem;
    width: max-content;
    margin-left: auto;
}

.user_information_wrapper > .info > .avatar_container img {
    border-radius: 122px;
    width: 214px;
    height: 214px;
    object-fit: cover;
    object-position: center;
}

.user_information_wrapper > .info > .about h1 {
    color: #000;
    font-size: 36px;
    font-weight: 600;
    line-height: 36px;
    margin: 0 0 1rem;
}
.user_information_wrapper > .info > .about h2 {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.06em;
    color: #000000;
    margin: 0 0 1.5rem;
    font-weight: 400;
}

.user_information_wrapper > .info > .about p {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.06em;
    color: #000000;
    margin: 0 0 1.5rem;
}

.user_information_wrapper > .info > .about .categories {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 -4px -24px;
}

.user_information_wrapper > .info > .about .categories span {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.06em;
    color: #000000;
    margin: 0 0 4px 24px;
}

.portfolio-name-container {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 10px;
}

@media (max-width: 1200px) {
    .user_information_wrapper > .info {
        padding: 0 10%;
    }

    .user_information_wrapper {
        padding: 2.5rem 7.5% 0;
    }
}

@media (max-width: 1024px) {
    .user_information_wrapper > .info {
        padding: 0 10%;
    }

    .wrapper_profile__wrapper_links > div {
        justify-content: start;
        max-width: 100%;
        overflow-x: auto;
    }
}

@media (max-width: 992px) {

    .user_information_wrapper {
        padding: 2.5rem 5% 0;
    }

    .user_information_wrapper > .info {
        padding: 0;
        grid-gap: 2rem;
    }

    .user_information_wrapper > .info > .avatar_container {
        padding-left: 0;
    }
}

@media (max-width: 700px) {
    .user_information_wrapper > .info {
        grid-gap: 2rem;
        grid-template-columns: 1fr;
        margin-top: 20px;
    }

    .user_information_wrapper > .info > .avatar_container {
        margin: 0 auto;
    }

    .user_information_wrapper > .info > .about p {
        font-size: 20px;
    }

    .user_information_wrapper > .info > .about .categories span {
        font-size: 14px;
        margin: 0 0 4px 24px;
    }

    .user_information_wrapper {
        padding: 2rem 1rem 0;
    }
}

@media (max-width: 350px) {
    .user_information_wrapper > .info > .avatar_container img {
        width: 160px;
        height: 160px;
    }

    .user_information_wrapper > .info > .about h2 {
        font-size: 28px;
        line-height: 36px;
    }

    .user_information_wrapper > .info > .about p {
        font-size: 18px;
    }
}
