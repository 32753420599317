.wrapper {
    padding: 80px 20px;
    background: #fff;
    margin-top: 80px;
    max-width: 100%;
    width: 100%;
    overflow: hidden;

    h2 {
        padding: 0 5px;
        font-weight: bold;
        font-size: 25px;
        line-height: 37px;
        text-align: center;
        color: #000;
        text-transform: uppercase;
        margin-bottom: 40px;
    }

    .cards {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .card_wrapper {
        width: 100%;
        margin-bottom: 60px;

        &:last-child {
            margin-bottom: 0;
        }

        .card {
            position: relative;
        }

        .hovered {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 220px;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.3);
            font-weight: normal;
            font-size: 20px;
            line-height: 30px;
            color: #fff;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
        }

        .card_img {
            display: block;
            position: relative;
            width: 100%;
            max-width: 100%;
            margin-bottom: 20px;
            height: 220px;
            aspect-ratio: 16/9;
        }

        .card_title {
            font-weight: 600;
            font-size: 25px;
            line-height: 35px;
            color: #000;
            margin-bottom: 16px;
        }

        .card_description {
            font-weight: normal;
            font-size: 18px;
            line-height: 25px;
            color: #8E8E8E;
        }
    }

    @media screen and (min-width: 568px) {
        .cards {
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 -10px;

            .card_wrapper {
                width: 50%;
                flex-shrink: 0;
                padding: 0 10px;

                .card:hover {
                    cursor: pointer;
                    .card_title {
                        color: #B25F00 !important;
                    }

                    .hovered {
                        display: flex !important;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 779px) {
        margin-top: 120px;
        padding: 120px 50px;
        .cards {
            margin: 0 -10px;
            .card_wrapper {
                width: 33.33% !important;
                padding: 0 10px;

                .card_title {
                    font-size: 35px;
                    line-height: 50px;
                }

                .card_description {
                    font-size: 24px;
                    line-height: 35px;
                }
            }
        }
    }

    @media screen and (min-width: 1440px) {
        margin-top: 120px;
        padding: 120px 80px;

        .cards {
            margin: 0 -21px;

            .card_wrapper {
                padding: 21px;
            }
        }
    }
}
