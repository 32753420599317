.hero {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 3rem 10% 0;
    background-color: var(--beige);
}

.hero :global(.ant-tabs-nav-wrap) {
    justify-content: center;
}

.inner {
    padding: 2.5rem 10%;
}

.title {
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 3rem;
}

@media (max-width: 1200px) {
    .hero, .inner {
        padding-left: 7.5%;
        padding-right: 7.5%;
    }
}

@media (max-width: 992px) {
    .hero, .inner {
        padding-left: 5%;
        padding-right: 5%;
    }
}