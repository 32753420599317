.root {
    display: flex;
}

.root:global(.ant-select) :global(.ant-select-selector) {
    padding-left: 1rem;
    padding-right: 1rem;
    border-width: 2px;
    border-radius: 0;
    border-color: #000000 !important;
}

.root:global(.ant-select) :global(.ant-select-selector:hover) {
    border-color: #000000 !important;
    border-right-width: 2px;
}

.root:global(.ant-select-single) {
    height: 48px;
}

.root:global(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input) {
    height: 100%;
}

.root :global(.ant-select-selector .ant-select-selection-item),
.root :global(.ant-select-selector .ant-select-selection-placeholder) {
    line-height: 44px;
}

.root :global(.ant-select-selector .ant-select-selection-placeholder) {
    color: #000000;
}

.root :global(.ant-select-arrow) {
    right: 1rem;
    color: #000000;
}

@media (max-width: 992px) {
    .root :global(.ant-select-selector .ant-select-selection-item),
    .root :global(.ant-select-selector .ant-select-selection-placeholder) {
        line-height: 38px;
    }

    .root:global(.ant-select-single) {
        height: 42px;
    }
}