.admin_content_wrapper_tickets > .head {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    padding: 0 4rem;
    margin: 0 0 2rem;
}

.admin_content_wrapper_tickets > .head h1 {
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.374px;
    color: #333333;
    margin: 0;
}

.admin_content_wrapper_tickets > .head div {
    display: grid;
    grid-gap: 20px;
    align-items: center;
    grid-template-columns: repeat(2, max-content);
}

.admin_content_wrapper_tickets > .head h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, .5);
    padding: 0 4px 4px;
    border-bottom: 2px solid #FFFFFF;
    cursor: pointer;
    transition: .3s all;
}

.admin_content_wrapper_tickets > .head .active_tab {
    color: rgba(0, 0, 0, 1);
    border-bottom: 2px solid #000;
}

.admin_content_wrapper_tickets > .subtitle {
    display: grid;
    grid-template-columns: 130px 240px 1fr;
    align-items: center;
    margin: 0 0 14px;
    padding: 0 4rem;
    grid-gap: 20px;
}

.admin_content_wrapper_tickets > .subtitle h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}
.admin_content_wrapper_tickets > .subtitle h4:last-child {
    justify-self: start;
    padding-right: 90px;
}

.admin_content_wrapper_tickets > .content {
    border-top: 1px solid #EFEFF4;
}

.admin_content_wrapper_tickets > .content div {
    border-bottom: 1px solid #EFEFF4;
    padding: 16px 4rem 14px;
    display: grid;
    grid-template-columns: 130px 240px 1fr max-content;
    align-items: center;
    grid-gap: 20px;
}

.admin_content_wrapper_tickets > .content div span {
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}

.admin_content_wrapper_tickets > .content div button {
    padding: 5px 0;
    width: 89px;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #FFF;
    background: #000;
    border-radius: 4px;
}

.admin_wrapper_modal_tickets {
    padding: 2rem;
}

.admin_wrapper_modal_tickets img {
    top: 2rem;
    right: 2rem;
    position: absolute;
}

.admin_wrapper_modal_tickets h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    margin: 0 0 1rem;
    color: #000000;
}

.admin_wrapper_modal_tickets .line {
    width: 100%;
    margin: 0 0 1.5rem;
    background: #CECECE;
    height: 1px;
}

.admin_wrapper_modal_tickets .inline {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
}

.admin_wrapper_modal_tickets .content {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 8px;
    margin: 0 0 1rem;
}

.admin_wrapper_modal_tickets .content h6 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #000000;
}

.admin_wrapper_modal_tickets .content span {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}

.admin_wrapper_modal_tickets h5 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}

.admin_wrapper_modal_tickets p {
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin: 0 0 1.5rem;
}

.admin_wrapper_modal_tickets .button button {
    width: 145px;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    padding: 7px 0;
    text-align: center;
    color: #FFFFFF;
    background: #000;
    border-radius: 4px;
    margin: 0 0 0 auto;
}