.premium_page_wrapper > .head_section {
    padding: 5rem 0 10.5rem 10%;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    justify-content: space-between;
    grid-gap: 3rem;
    position: relative;
}

.premium_page_wrapper > .head_section h1, .premium_page_wrapper > .head_section h1 span {
    font-weight: 600;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: 0.02em;
    color: #000000;
    margin: 0 0 4rem;
}

.premium_page_wrapper > .head_section h1 span {
    font-weight: 400;
    margin: 0;
    display: block;
}

.premium_page_wrapper > .head_section h1 span {
    font-weight: 400;
    margin: 0;
    display: block;
}

.premium_page_wrapper > .visibility_section {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 10% 4rem;
    grid-gap: 7.5rem;
}

.premium_page_wrapper > .visibility_section > .visibility_item {
    display: grid;
    grid-template-columns: 5fr 6fr;
    grid-gap: 10%;
    justify-content: space-between;
    align-items: center;
}

.premium_page_wrapper > .visibility_section > .visibility_item img {
    width: 100%;
    max-width: 100%;
}

.premium_page_wrapper > .visibility_section > .visibility_item_right img {
    grid-column: 2;
    grid-row: 1;
    display: block;
    margin-left: auto;
    max-width: 100%;
}

.premium_page_wrapper > .visibility_section > .visibility_item h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    margin: 0 0 1.5rem;
    color: #000000;
}

.premium_page_wrapper > .visibility_section > .visibility_item p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    margin: 0 0 4rem;
    letter-spacing: 0.015em;
    color: #000000;
}

.premium_page_wrapper > .visibility_section > .visibility_item .links {
    display: grid;
    align-items: center;
    width: max-content;
}

.premium_page_wrapper > .visibility_section > .visibility_item .links a {
    font-weight: 500;
    font-size: 16px;
    line-height: 45px;
    color: #000000;
}

.premium_page_wrapper > .visibility_section > .visibility_item .links a:hover {
    text-decoration-line: underline;
}

.premium_page_wrapper > .global_community {
    background: #000000;
    padding: 3rem 10% 3.5rem;
    margin: 0 0 4.5rem;
}

.premium_page_wrapper > .global_community h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
    margin: 0 0 3rem;
}

.premium_page_wrapper > .global_community .content_block {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.premium_page_wrapper > .global_community .content_block h4 {
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    color: #FFFFFF;
}

.premium_page_wrapper > .global_community .content_block span {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #FFFFFF;
}

.premium_page_wrapper > .artworks_management {
    padding: 0 10%;
    margin: 0 0 4.5rem;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    align-items: start;
    justify-content: space-between;
}

.premium_page_wrapper > .artworks_management svg {
    margin: 0 auto 1.5rem;
}

.premium_page_wrapper > .artworks_management h4 {
    margin: 0 0 2rem;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #000000;
}

.premium_page_wrapper > .artworks_management .options {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
}

.premium_page_wrapper > .artworks_management .options .item {
    display: grid;
    grid-template-columns: 3px 1fr;
    align-items: start;
    grid-gap: 8px;
}

.premium_page_wrapper > .artworks_management .options .item div {
    width: 3px;
    height: 3px;
    background: #000000;
    border-radius: 100%;
    margin: 12px 0 0;
}

.premium_page_wrapper > .artworks_management .options .item span {
    text-align: left;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
}

.premium_page_wrapper > .choose_plan_section {
    padding: 0 10%;
}

.premium_page_wrapper > .choose_plan_section h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #000000;
    margin: 0 0 2.5rem;
}

.premium_page_wrapper > .choose_plan_section .plans_wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    align-items: center;
    margin: 0 0 104px;
}

.premium_page_wrapper > .choose_plan_section .plans_wrapper .plan_item {
    border: 1px solid #000000;
    outline: 1px solid #FFFFFF;
    transition: .3s all;
    height: 100%;
    padding: 0 2rem 2rem;
    display: flex;
    flex-direction: column;
}

.premium_page_wrapper > .choose_plan_section .plans_wrapper .plan_item:hover {
    outline: 1px solid #000000;
}

.premium_page_wrapper > .choose_plan_section .plans_wrapper .plan_item:hover:enabled button {
    background: #000000;
    color: #FFFFFF;
}

.premium_page_wrapper > .choose_plan_section .plans_wrapper .plan_item h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #000000;
    margin: 0 0 4px;
}

.premium_page_wrapper > .choose_plan_section .plans_wrapper .plan_item h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #000000;
    margin: 0 0 4px;
}

.premium_page_wrapper > .choose_plan_section .plans_wrapper .plan_item p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #000000;
    margin: 0 0 2rem;
}

.premium_page_wrapper > .choose_plan_section .plans_wrapper .analytics, .premium_page_wrapper > .choose_plan_section .plans_wrapper .creator {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
    padding: 4px 0;
    background: var(--beige);
    display: block;
    text-align: center;
    margin: 0 -2rem 2.5rem;
}

.premium_page_wrapper > .choose_plan_section .plans_wrapper .creator {
    color: #FFFFFF;
    background: #000000;
}

.premium_page_wrapper > .choose_plan_section .plans_wrapper .plan_item .options, .premium_page_wrapper > .choose_plan_section .plans_wrapper .plan_item .options .options_item {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    align-items: center;
    margin: 0 0 3rem;
}

.premium_page_wrapper > .choose_plan_section .plans_wrapper .plan_item .options .options_item {
    grid-gap: 12px;
    grid-template-columns: max-content 1fr;
    margin: 0;
}

.premium_page_wrapper > .choose_plan_section .plans_wrapper .plan_item .options .options_item p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
    text-align: left;
    margin: 0;
}

.premium_page_wrapper > .choose_plan_section .plans_wrapper .plan_item .price {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-template-columns: repeat(2, max-content);
    margin: 0 0 2.5rem;
    height: 32px;
}

.premium_page_wrapper > .choose_plan_section .plans_wrapper .plan_item .price h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
}

.premium_page_wrapper > .choose_plan_section .plans_wrapper .plan_item .price span {
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
}

.premium_page_wrapper > .choose_plan_section .plans_wrapper .plan_item .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.premium_page_wrapper > .special_membership {
    background: #000000 url("../../assets/img/new_premium/membership_bg.svg") no-repeat bottom;
    background-size: cover;
    margin: 0 0 4rem;
    padding: 3rem 0 5rem;
}

.premium_page_wrapper > .special_membership h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
    margin: 0 0 2.5rem;
}

.premium_page_wrapper > .special_membership .membership_content {
    padding: 0 33%;
}

.premium_page_wrapper > .special_membership .membership_content p {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #FFFFFF;
    margin: 0 0 1rem;
}

.premium_page_wrapper > .special_membership .membership_content .options, .premium_page_wrapper > .special_membership .membership_content .options .item {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    align-items: center;
    margin: 0 0 4rem;
}

.premium_page_wrapper > .special_membership .membership_content .options .item {
    grid-template-columns: max-content 1fr;
    grid-gap: 12px;
    align-items: start;
    margin: 0;
}

.premium_page_wrapper > .special_membership .membership_content .options .item span {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #FFFFFF;
}

.premium_page_wrapper > .special_membership .membership_content .options .item svg {
    margin: 8px 0 0;
}

.premium_page_wrapper > .special_membership button {
    margin: 0 auto;
}

.faq_section {
    margin: 0 0 3.5rem;
    padding: 0 10%;
}

.faq_section h2 {
    margin: 0 0 2.5rem;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #000000;
}

.faq_section .faq_questions_wrapper {
    display: grid;
    grid-template-columns: 1fr;
}

.faq_section button {
    margin: 2rem 0 0 auto;
}

.faq_section .faq_questions_wrapper .question_wrap {
    padding: 1.5rem;
    border-bottom: 2px solid #000000;
}

.faq_section .faq_questions_wrapper .question_wrap .question_title {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.faq_section .faq_questions_wrapper .question_wrap p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
    white-space: pre-wrap;
    transition: .3s all;
    padding-right: 5.5rem;
}

.faq_section .faq_questions_wrapper .question_wrap p a {
    color: unset;
    text-decoration: underline;
}

.faq_section .faq_questions_wrapper .question_wrap .dot {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: #000000;
    display: inline-block;
}

.faq_section .faq_questions_wrapper .question_wrap .question_title h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
}

.advanced-settings-container .square,
.faq_section .faq_questions_wrapper .question_wrap .question_title .square {
    width: 40px;
    height: 40px;
    border: 2px solid #000000;
    background: #FFFFFF;
    transition: .3s all;
    position: relative;
}

.advanced-settings-container .square > div,
.faq_section .faq_questions_wrapper .question_wrap .question_title .square > div {
    width: 18px;
    height: 2px;
    background: #000000;
    transition: .4s all;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.advanced-settings-container .square > div:nth-child(2),
.faq_section .faq_questions_wrapper .question_wrap .question_title .square > div:nth-child(2) {
    transform: translate(-50%, -50%) rotate(90deg);
}

.advanced-settings-container .square_active,
.faq_section .faq_questions_wrapper .question_wrap .question_title .square_active {
    background: #000;
}

.advanced-settings-container .square_active > div,
.faq_section .faq_questions_wrapper .question_wrap .question_title .square_active > div {
    background: #FFFFFF;
}

.advanced-settings-container .square_active > div:nth-child(2),
.faq_section .faq_questions_wrapper .question_wrap .question_title .square_active > div:nth-child(2) {
    transform: translate(-50%, -50%) rotate(180deg);
}

.premium_page_wrapper > .contact_us_section {
    padding: 0 10%;
    margin: 0 0 6.5rem;
}

.premium_page_wrapper > .contact_us_section h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    margin: 0 0 2.5rem;
    color: #000000;
}

.premium_page_wrapper > .contact_us_section > .wrapper {
    display: grid;
    grid-template-columns: 5fr 7fr;
    grid-gap: 1.5rem;
    align-items: start;
}

.premium_page_wrapper > .contact_us_section > .wrapper > .text_content p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
    border-bottom: 2px solid #000000;
    padding: 0 0 1.5rem;
}

.choose-plan-header-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 90px 0;
    font-size: 16px;
}

.plan-time-container {
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: center;
    grid-gap: 24px;
    margin-bottom: 26px;
}

.time-plan {
    font-size: 14px;
    color: #000000;
    font-weight: 300;
    cursor: pointer;
    padding-bottom: 2px;
}

.time-plan-active {
    font-weight: 500;
    text-underline: black;
    border-bottom: 2px solid black;
    /*transition: .1s;*/
}

.plan-price-container {
    display: flex;
}

.your-subscription-plan-info {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 300;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 8px;
    align-items: center;
    justify-content: center;
    padding-bottom: 8px;
}

.premium-subscription-plan-item {
    font-weight: 600;
}

.premium-subscription-plan-info__container {
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 0 2rem;
}

@media (max-width: 1200px) {
    .premium_page_wrapper > .head_section {
        padding: 5rem 0 10.5rem 7.5%;
    }

    .premium_page_wrapper > .visibility_section {
        padding: 0 7.5% 4rem;
    }

    .premium_page_wrapper > .global_community {
        padding: 3rem 7.5% 3.5rem;
    }

    .premium_page_wrapper > .artworks_management, .premium_page_wrapper > .choose_plan_section, .premium_page_wrapper > .contact_us_section, .faq_section {
        padding: 0 7.5%;
    }

    .premium_page_wrapper > .special_membership .membership_content {
        padding: 0 29%;
    }
}

@media (max-width: 1024px) {
    .premium_page_wrapper > .choose_plan_section .plans_wrapper {
        grid-gap: 1rem;
        margin: 0 0 5rem;
    }

    .premium_page_wrapper > .choose_plan_section .plans_wrapper .plan_item {
        padding: 0 1rem 2rem;
        display: flex;
        flex-direction: column;
    }

    .premium_page_wrapper > .choose_plan_section .plans_wrapper .analytics, .premium_page_wrapper > .choose_plan_section .plans_wrapper .creator {
        margin: 0 -1rem 2.5rem;
    }

    .premium_page_wrapper > .visibility_section > .visibility_item {
        grid-gap: 5%;
    }

    .premium_page_wrapper > .visibility_section > .visibility_item h3 {
        font-size: 28px;
        line-height: 38px;
        margin: 0 0 1rem;
    }

    .premium_page_wrapper > .visibility_section > .visibility_item p {
        margin: 0 0 1.5rem;
    }

    .premium_page_wrapper > .global_community .content_block {
        grid-template-columns: repeat(2, max-content);
        grid-gap: 3rem;
        justify-content: center;
    }
}

@media (max-width: 992px) {
    .premium_page_wrapper > .head_section {
        padding: 3.5rem 0 9rem 5%;
        grid-gap: 0;
        overflow: hidden;
        grid-template-columns: 3fr 1fr;
    }

    .premium_page_wrapper > .head_section img {
        position: absolute;
        right: -350px;
        top: 15%;
    }

    .premium_page_wrapper > .visibility_section {
        padding: 0 5% 4.5rem;
        grid-gap: 4.5rem;
    }

    .premium_page_wrapper > .global_community {
        padding: 3rem 5%;
        margin: 0 0 5.5rem;
    }

    .premium_page_wrapper > .artworks_management, .premium_page_wrapper > .choose_plan_section, .premium_page_wrapper > .contact_us_section, .faq_section {
        padding: 0 5%;
    }

    .premium_page_wrapper > .choose_plan_section .plans_wrapper {
        grid-gap: 2rem;
        grid-template-columns: 1fr 1fr;
    }

    .premium_page_wrapper > .special_membership .membership_content {
        padding: 0 20%;
    }

    .premium_page_wrapper > .special_membership .membership_content .options {
        margin: 0 0 2.75rem;
    }
}

@media (max-width: 768px) {
    .premium_page_wrapper > .contact_us_section > .wrapper {
        grid-template-columns: 1fr;
        grid-gap: 2.5rem;
    }
}

@media (max-width: 568px) {
    .premium_page_wrapper > .visibility_section > .visibility_item {
        grid-template-columns: 1fr;
        grid-gap: 2rem;
    }

    .premium_page_wrapper > .artworks_management, .premium_page_wrapper > .choose_plan_section, .premium_page_wrapper > .contact_us_section, .faq_section {
        padding: 0 1rem;
    }

    .premium_page_wrapper > .artworks_management {
        grid-template-columns: 1fr;
        grid-gap: 2.5rem;
    }

    .premium_page_wrapper > .choose_plan_section .plans_wrapper {
        grid-template-columns: 1fr;
    }

    .premium_page_wrapper > .visibility_section {
        padding: 0 1rem 4.5rem;
        grid-gap: 3rem;
    }

    .premium_page_wrapper > .global_community {
        padding: 3rem 1rem;
        margin: 0 0 5.5rem;
    }

    .premium_page_wrapper > .global_community .content_block {
        grid-template-columns: repeat(2, 1fr);
    }

    .premium_page_wrapper > .global_community .content_block h4 {
        font-size: 32px;
        line-height: 72px;
    }

    .faq_section .faq_questions_wrapper .question_wrap {
        padding: 1.5rem 0;
    }

    .faq_section .faq_questions_wrapper .question_wrap p {
        padding-right: 0;
    }

    .premium_page_wrapper > .visibility_section > .visibility_item_right img {
        grid-column: unset;
        grid-row: unset;
    }

    .premium_page_wrapper > .head_section h1, .premium_page_wrapper > .head_section h1 span {
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.05em;
        margin: 0 0 3rem;
    }

    .premium_page_wrapper > .visibility_section > .visibility_item h3 {
        font-size: 24px;
        line-height: 32px;
    }

    .premium_page_wrapper > .global_community h2 {
        font-size: 24px;
        line-height: 32px;
    }

    .premium_page_wrapper > .special_membership h2 {
        font-size: 24px;
        line-height: 32px;
        margin: 0 0 1.5rem;
    }

    .premium_page_wrapper > .special_membership .membership_content {
        padding: 0 1rem;
    }

    .premium_page_wrapper > .special_membership .membership_content p {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.015em;
    }

    .premium_page_wrapper > .head_section img {
        right: 0;
        top: 35%;
    }

    .premium_page_wrapper > .head_section {
        padding: 3.5rem 0 9rem 1rem;
        grid-template-columns: 3fr 1fr;
    }
}

@media (max-width: 332px) {
    .premium_page_wrapper > .head_section img {
        right: -40px;
        top: 35%;
    }
}


