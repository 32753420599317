.top_banner_wrapper_info_banner {
    grid-gap: 0;
    background: #F6F6F1;
    display: grid;
    grid-template-columns: 160px 1fr auto;
    min-height: 139px;
    gap: 24px;
    justify-content: space-between;
    left: 0;
    padding: 0 24px 0 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 3499;
}

.top_banner_wrapper_info_banner .brush_wrapper_info_banner {
    height: 139px;
    display: flex;
    align-items: flex-end;
}

.top_banner_wrapper_info_banner .info_text_wrapper_info_banner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 24px 0;
}

.top_banner_wrapper_info_banner .info_text_wrapper_info_banner .title_info_banner {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    font-family: 'Poppins', sans-serif;
}

.top_banner_wrapper_info_banner .info_text_wrapper_info_banner .subtitle_info_banner {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    font-family: 'Poppins', sans-serif;
}

.top_banner_wrapper_info_banner .top_banner_button_wrapper_info_banner {
    display: flex;
    align-items: center;
    flex: 1;
}

@media (max-width: 1160px) {
    .top_banner_wrapper_info_banner {
        padding: 0 24px 0 24px;
    }
}

@media (max-width: 992px) {
    .top_banner_wrapper_info_banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 10%;
        grid-template-columns: 1fr;
        height: auto;
        margin-top: 15px;
    }
    .top_banner_wrapper_info_banner .brush_wrapper_info_banner {
        display: none;
    }
}

@media (max-width: 650px) {
    .top_banner_wrapper_info_banner {
        padding: 20px 24px;
    }
    .top_banner_wrapper_info_banner .top_banner_button_wrapper_info_banner {
        width: 100%;
    }
}