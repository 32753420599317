.login {
  margin-top: 50px;

  .title {
    margin: 0;
    font-weight: 600;
    font-size: 38px;
    line-height: 48px;
  }

  .facebookLoginContainer {
    background-color: #FFFFFF;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 13px;
    align-items: center;
    padding: 16px;
    cursor: pointer;
    max-width: 261px;
  }
  .facebookLoginContainer span {
    font-weight: 500;
    font-size: 18px;
  }

  .form {
    margin-top: 50px;

    .input {
      &:first-child {
        margin-bottom: 35px;
      }
    }
  }

  .form_footer {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    grid-gap: 24px;

    .footer_social {
      margin: 15px 0 20px;
    }

    .footer_btns {
      button:last-child {
        display: none;
      }

      @media (max-width: 500px) {
        button {
          max-width: unset;
          width: 100%;
        }
      }
    }

    .footer_reg_btn_sm {
      margin: auto;
    }
  }

  .errors {
    margin-top: 25px;

    & > * {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (min-width: 980px) {
    .errors {
      display: none;
    }
    .title {
      font-weight: 600;
      font-size: 48px;
      line-height: 56px;
      margin: 0;
    }
    .form {
      margin-top: 58px;
    }
    .form_footer {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 50px;

      .footer_btns {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        button:last-child {
          margin-top: 25px;
          display: inline-block;
        }
      }

      .footer_social {
        margin: 0;
      }

      .footer_reg_btn_sm {
        display: none;
      }
    }
  }
}