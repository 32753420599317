.wrapper {
    margin-top: 50px;
    .title {
        font-weight: 600;
        font-size: 38px;
        line-height: 48px;
    }

    .subtitle {
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        margin: 20px 0 50px;
    }

    .label {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #8E8E8E;
        margin-bottom: 20px;
    }

    .text_wrapper {
        border-bottom: 2px solid black;
        .textarea {
            resize: none;
            height: 140px;
            display: block;
            width: 100%;
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            border: 0;
            background: transparent;
        }
    }

    .error_component {
        margin-top: 20px;
    }

    .footer {
        .char_counter {
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin: 30px 0 50px;
        }
        @media (max-width: 500px) {
            button {
                width: 100%;
            }
        }
    }

    &.error {
        .text_wrapper {
            border-bottom-color: #FF4949;
        }

        .char_counter {
            color: #FF4949;
        }
    }

    @media screen and (min-width: 980px) {
        .title {
            font-weight: 600;
            font-size: 48px;
            line-height: 56px;
        }

        .subtitle {
            font-weight: normal;
            font-size: 18px;
            line-height: 30px;
            margin: 30px 0 56px;
        }

        .label {
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 30px;
        }

        .footer {
            margin-top: 65px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .char_counter {
                font-weight: normal;
                font-size: 20px;
                line-height: 35px;
                margin: 0;
            }
        }
    }
}