.inner {
    display: grid;
    grid-gap: 2rem;
    margin-top: 2rem;
}

.topContent {
    display: grid;
    grid-template-columns: 340px 1fr;
    grid-gap: 2.5rem;
}

.mapWrapper {
    margin-top: 1rem;
}

.amenities {
    font-weight: 600;
    font-size: 1rem;
}

.location {
    font-size: 1rem;
}

:global(.admin_content_wrapper) .subtitle {
    margin-bottom: 1rem;
}

.contacts {
    font-size: 1.125rem;
}

.contactsValue {
    font-weight: 600;
    font-size: 1.125rem;
}

.featureTitle {
    font-weight: 400;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.featureValue {
    font-weight: 600;
    font-size: 1rem;
}

.title {
    font-weight: 400;
    font-size: 2rem;
}

.price {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.5);
}

.address {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.5);
}

.priceDivider {
    display: inline-block;
    width: 2px;
    height: 36px;
    background-color: #000000;
}

.price b {
    font-weight: 700;
    font-size: 1.75rem;
    color: #000000;
}

.topContentInfo {
    display: grid;
    grid-gap: 2.5rem;
}

.images {
    position: relative;
    margin-bottom: 3rem;
}

.images::before {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.images :global(.slick-slider) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.images :global(.slick-slider div) {
    height: 100%;
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.images :global(.slick-prev),
.images :global(.slick-next) {
    top: auto;
    bottom: -3rem;
    color: #000000;
}

.images :global(.slick-next) {
    right: 0;
}

.images :global(.slick-prev) {
    left: auto;
    right: 5rem;
}

.slides {
    position: absolute;
    bottom: -2.55rem;
    right: 2rem;
    font-size: 1rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
}

.slides span {
    font-size: 1.125rem;
    color :#000000;
}

.tabs :global(.ant-tabs-tab) {
    padding: 0.25rem 1rem;
}

.tabs :global(.ant-tabs-tab+.ant-tabs-tab) {
    margin: 0;
}

.tabs :global(.ant-tabs-ink-bar) {
    background-color: #AE4B84;
}

.preWrap {
    white-space: pre-wrap;
}

@media (max-width: 1200px) {
    .topContent {
        grid-template-columns: 260px 1fr;
    }
}