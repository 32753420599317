.root :global(.wrapper_new_pagination) {
    border: 0;
}

.list {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    margin-top: 1.5rem;
}

.tabs :global(.ant-tabs-tab) {
    padding: 0.25rem 2rem;
}

.tabs :global(.ant-tabs-tab+.ant-tabs-tab) {
    margin: 0;
}

.tabs :global(.ant-tabs-ink-bar) {
    background-color: #AE4B84;
}

.select {
}

.selectPopup {
    min-width: 9rem;
}

.select:global(.ant-select-single) {
    height: 32px;
}

.select :global(.ant-select-selector) {
    flex-grow: 1;
}

.select :global(.ant-select-selection-item-remove) {
    display: none;
}

.select :global(.ant-select-selector .ant-select-selection-item),
.select :global(.ant-select-selector .ant-select-selection-placeholder) {
    line-height: 32px;
}

.select:global(.ant-select .ant-select-selector) {
    height: 32px;
    padding-left: 0;
}

.select :global(.ant-select-arrow svg) {
    width: 12px;
}

.filtersList {
    margin-top: 1.5rem;
}

.tag, .tag :global(.ant-tag-close-icon) {
    display: inline-flex;
    align-items: center;
}

.tag :global(.ant-tag-close-icon) {
    opacity: 0.3;
}
