.root {
    align-items: center;
}

.root.start {
    align-items: flex-start;
}

.root :global(.ant-checkbox) {
    align-self: flex-start;
    top: 0;
    padding: 5px;
    outline: none !important;
}

.root :global(.ant-checkbox-checked:after) {
    display: none;
}

.root :global(.ant-checkbox-checked .ant-checkbox-inner:after) {
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    width: 10px;
    height: 10px;
    background-color: #000000;
    transform: none;
    border: 0;
    transition: none;
}

.root :global(.ant-checkbox-inner) {
    width: 18px;
    height: 18px;
    border-radius: 0;
    border-width: 2px;
    border-color: #000000;
}

.root :global(.ant-checkbox-checked .ant-checkbox-inner) {
    border-color: #000000;
    background-color: #ffffff;
}

.root:hover :global(.ant-checkbox-inner),
.root :global(.ant-checkbox:hover .ant-checkbox-inner) {
    border-color: #000000 !important;
    background-color: transparent !important;
}

.error :global(.ant-checkbox-inner) {
    border-color: var(--error);
}
