.wrapper {
  margin-top: 55px;

  .title {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 50px;
  }

  .form {

  }

  .img_profile {
    margin-bottom: 50px;

    label {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .img_upload {
      width: 120px;
      min-width: 120px;
      height: 120px;
      min-height: 120px;
      border-radius: 50%;
      background-color: black;
      background-image: url('../../../../assets/img/onboarding/upload.svg');
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      outline: none;
    }

    .img_text {
      margin-top: 30px;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .error {
    margin-top: 25px;
  }

  .form_label {
    display: flex;
    font-weight: normal;
    font-size: 16px;
    font-family: 'Poppins', sans-serif !important;
    line-height: 24px;
    color: #8E8E8E;
    margin-bottom: 20px;
  }

  .buttons {
    margin-top: 169px;

    button:last-child {
      display: none;
    }

    @media (max-width: 500px) {
      button {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 980px) {
    .title {
      margin-bottom: 66px;
    }

    .img_profile {
      label {
        flex-direction: row;

        .img_text {
          margin: 0 0 0 30px;
          font-weight: 600;
          font-size: 30px;
        }
      }
    }

    .form_label {
      font-family: 'Poppins', sans-serif !important;
      font-weight: normal;
      font-size: 18.58px;
      line-height: 28px;
      margin-bottom: 22px;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 50px;

      button:last-child {
        margin-top: 25px;
        display: inline-block;
      }
    }
  }
}
