
.additionalCardWrapper {

}

.wrapper {
  margin-top: 50px;

  .title {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 50px;
  }


  .card_wrapper {
    .card {
      position: relative;
      max-width: 310px;
      cursor: pointer;
      margin: 0 auto;

      .card_img_wrapper {
        padding: 0 0 24px 0;

        .card__img {
          width: 100%;
          height: 132px;
          max-height: 100%;
          object-fit: contain;
        }
      }

      .selected_border {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 3px solid black;
      }

      .card_content {
        padding: 0 20px 40px;

        .card__title {
          font-family: 'Poppins', sans-serif !important;
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          line-height: 45px;
        }

        .card__text {
          font-family: 'Poppins', sans-serif !important;
          font-weight: normal;
          font-size: 18px;
          line-height: 28px;
          padding: 40px 0;
        }

        .card_check {
          display: flex;
          align-items: center;

          .card_check__text {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
          }

          .card_check__icon {
            margin-right: 20px;
          }
        }
      }
    }

    & {
      .card_content .card_check .card_check__icon {
        rect {
          transition: .3s;
        }

        path {
          transition: .3s;
        }
      }
    }

    &:hover, &.selected {
      .card_content .card_check .card_check__icon {
        rect {
          fill: black;
        }

        path {
          stroke: #fff;
        }
      }
    }
  }

  .buttons {
    margin-top: 70px;
    display: grid;
    grid-template-columns: 1fr max-content;

    button:last-child {
      display: grid;
      grid-template-columns: 1fr max-content;
      grid-gap: 12px;

      &:hover {
        svg path {
          fill: #fff
        }
      }

      svg {
        transform: rotate(180deg);

        path {
          transition: .3s;
        }
      }
    }

    button:first-child {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18.58px;
      line-height: 28px;
      align-self: center;
      white-space: inherit;
    }
  }

  @media screen and (min-width: 680px) {
    .cards {
      display: flex;
      justify-content: center;
      margin: 0 -20px;
    }
  }

  @media screen and (min-width: 980px) {
    .title {
      font-size: 48px;
      line-height: 56px;
      margin-bottom: 74px;
    }

    .cards {
      display: grid;
      flex-direction: column;
      grid-template-columns: repeat(2, 1fr);
      max-width: 100%;
      margin: 0 auto;
      grid-gap: 40px;
      // align-items: flex-start;
      .card_wrapper {
        display: flex;
        justify-content: center;
        width: 100%;

        .card {
          border: 2px solid #000000;
        }

        &.selected {
          .card .selected_border {
            display: block;
          }
        }
      }
    }

    .buttons {
      justify-content: space-between;
      align-items: center;

      button:last-child {
        margin: 0;
      }
    }
  }

  @media screen and (min-width: 1300px) {
    .cards {
      flex-direction: row;
      justify-content: space-between;

      .card_wrapper {
        margin: 0;
      }
    }
  }

  @media (min-width: 1800px) {
    .cards {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media (max-width: 500px) {
    .buttons {
      grid-template-columns: 1fr;
      grid-gap: 24px;
      & button:first-child {
        grid-row: 2;
      }
    }
  }

  @media screen and (max-height: 920px) and (min-width: 980px) {
    margin-top: 70px;
    .card_wrapper {
      .card {
        .card_img_wrapper {
          height: 130px;
        }
      }
    }
  }

  @media screen and (max-height: 790px) and (min-width: 980px) {
    margin-top: 50px;
    .card_wrapper {
      .card {
        padding: 35px 20px 0;

        .card_img_wrapper {
          height: 130px;
        }

      }
    }
    .buttons {
      margin-top: 50px;
    }

    .title {
      font-size: 36px;
      line-height: 1.4;
      margin-bottom: 54px;
    }
  }
}
