.image_preview {
    margin: 0 0 1.5rem;
    width: 350px;
    height: 200px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.photo_placeholder {
    border: 1px dashed rgba(0, 0, 0, .3);
    border-radius: 4px;
    margin: 0 0 1.5rem;
    width: 162px;
    height: 162px;
    display: grid;
    align-items: center;
    justify-content: center;
    position: relative;
}

.purple_button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #AE4B84;
    border-radius: 4px;
    margin: auto;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    padding: 5px 8px
}

.photo_placeholder > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.bottom_wrapper {
    border-top: 1px solid #0000001A;
    display: grid;
    padding: 28px 24px 0 24px;
    align-items: flex-end;
    justify-content: flex-end;
}

.bottom_wrapper .purple_button {
    height: 36px;
    min-width: 100px;
}

.image_preview .purple_button {
    position: absolute;
    z-index: 2;
}

.banner_wrapper {
    padding: 0 24px;
}

.banner_wrapper input {
    border-radius: 7px;
}

.banner_wrapper p {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin: 0 0 .5rem;
    display: block;
}