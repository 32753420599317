.profile_section_inbox_unique {
    padding: 0;
}

.profile_section_inbox_unique .wrapper_list_message {
    max-height: 60vh;
    min-height: 20vh;
    overflow: hidden scroll;
}

.profile_section_inbox_unique .wrapper_list_message > div {
    border-top: 2px solid #000;
    padding: 32px 0;
}

.profile_section_inbox_unique .wrapper_list_message > div:first-child {
    border: none;
}

.profile_section_inbox_unique .wrapper_head_inbox {
    display: grid;
    margin: 40px 0 12px;
}

.profile_section_inbox_unique .wrapper_head_inbox__go_back {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr;
    grid-gap: 8px;
    color: #000
}

.profile_section_inbox_unique .wrapper_head_inbox .wrapper_title span {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.45;
    color: var(--dark-grey);
}

.profile_section_inbox_unique .wrapper_head_inbox .back {
    cursor: pointer;
}

.profile_section_inbox_unique .wrapper_head_inbox .back span {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.44;
    color: var(--lite-grey);
}

.profile_section_inbox_unique .wrapper_head_inbox .back svg {
    transform: rotate(180deg);
    margin-right: 12px;
}


.every_inbox_message .avatar_wrapper {
    width: 72px;
    height: 72px;
}

.every_inbox_message .avatar_wrapper img {
    width: inherit;
    height: inherit;
    border-radius: 50%;
    object-fit: cover;
}

.every_inbox_message .wrapper_event {
    display: grid;
    grid-gap: 12px;
    justify-items: center;
    margin-top: 20px;
}

.every_inbox_message .wrapper_event .event_preview {
    display: grid;
    justify-items: center;
    grid-gap: 12px;
}

.every_inbox_message .wrapper_event .event_preview .wrapper_tracking_link {
    display: flex;
    align-items: center;
}

.every_inbox_message .wrapper_event .event_preview a,
.every_inbox_message .wrapper_event .event_preview span {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000
}

.every_inbox_message .wrapper_event .event_preview a {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000
}

.every_inbox_message .wrapper_event .wrapper_message {
    display: flex;
}

.every_inbox_message .wrapper_event .btn_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 20px;
}

.every_inbox_message .wrapper_event .btn_wrapper button {
    padding: 12px 32px;
}

.every_inbox_message .wrapper_event .event_preview .type_cancel {
    color: #dd4242;
}


.every_inbox_message .wrapper_event .wrapper_message .name_sender {
    font-weight: bold;
    color: var(--dark-grey);
}

.every_inbox_message .wrapper_event .wrapper_message .message {
    font-size: 16px;
    line-height: 1.63;
    text-align: center;
    color: var(--slate);
}


.every_inbox_message .avatar_wrapper > .wrapper_btn {
    display: grid;
    grid-gap: 20px;
}

.every_inbox_message .wrapper_btn_popup {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
}

.every_inbox_message .wrapper_btn_popup button {
    width: 100%;
}


.platform_chat_container_footer .input_component {
    margin: 0;
}

.platform_chat_container_footer textarea {
    height: 104px;
}

.platform_chat_container_footer .button_bordered {
    padding: 10px 40px;
}

.every_inbox_message {
    display: grid;
    grid-template-columns: 72px 1fr max-content;
    grid-gap: 20px;
    padding: 16px 32px;
}

.wrapper_list_message .every_inbox_message {
    padding: unset;
}

.every_inbox_message .sender_message span {
    display: block;
    margin-top: 16px;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000
}

.every_inbox_message .sender_name {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    display: block;
    padding: 26px 0;
}

.every_inbox_message .wrapper_date {
    display: grid;
    height: max-content;
}

.every_inbox_message .wrapper_date span {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: rgba(0, 0, 0, .5);
}

.every_inbox_message .wrapper_popup .wrapper_title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.every_inbox_message .wrapper_btn {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
}

.every_inbox_message .wrapper_btn button {
    width: 100%;
}

.every_inbox_message .wrapper_popup .wrapper_btn button:first-child {
    width: 100%;
}

.wrapper_artwork_details .wrapper_order_details .section_title span {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, .3);
}

.wrapper_artwork_details .wrapper_order_details .section_title svg {
    transition: .4s;
    margin-left: 8px;
}

.wrapper_artwork_details .wrapper_order_details .section_title .active {
    transform: rotate(180deg);
}

.wrapper_artwork_details .wrapper_order_details .head {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    margin-top: 8px;
    grid-gap: 12px;
}

.wrapper_artwork_details .wrapper_order_details .head .cancel_order {
    justify-self: end;
    font-weight: 600;
    font-size: 14px;
    text-decoration: underline;
    line-height: 20px;
    color: #000
}

.wrapper_artwork_details .wrapper_order_details .head .tracking,
.wrapper_artwork_details .wrapper_order_details .head .order {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: .48px;
    color: var(--dark-grey);
    text-transform: uppercase;
}

.wrapper_artwork_details .wrapper_order_details .head .price {
    color: #000;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    grid-column: 2/-1;
    justify-self: end;
}

.wrapper_artwork_details .wrapper_order_details .head .delivery_my_sales {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000
}

.wrapper_artwork_details .wrapper_order_details .head .delivery_my_sales svg {
    margin-top: -4px;
}

.wrapper_artwork_details .wrapper_order_details .head .status span:first-child {
    font-size: 14px;
    font-weight: normal;
    color: var(--dark-grey);
}

.wrapper_artwork_details .wrapper_order_details .head .status span:last-child {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000
}

.wrapper_artwork_details .wrapper_order_details .body {
    display: grid;
    grid-template-columns: 260px 1fr;
    grid-gap: 24px;
}

.wrapper_artwork_details .wrapper_order_details .section_title_shipping {
    margin-top: 14px;
}

.wrapper_artwork_details .wrapper_order_details .body .wrapper_picture img {
    height: 100%;
    max-height: 200px;
    object-fit: contain;
    width: 100%;
}

.wrapper_artwork_details .wrapper_order_details {
    display: grid;
    grid-gap: 24px;
    margin-top: 40px;
}

.wrapper_artwork_details .wrapper_order_details .info {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 8px;
}

.wrapper_artwork_details .wrapper_order_details .shipping_details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
}

.wrapper_artwork_details .wrapper_order_details .shipping_details .wrapper_info .title,
.wrapper_artwork_details .wrapper_order_details .info_title .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000
}

.wrapper_artwork_details .wrapper_order_details .shipping_details .wrapper_info,
.wrapper_artwork_details .wrapper_order_details .wrapper_info {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 24px;
    align-items: center;
}

.wrapper_artwork_details .wrapper_order_details .shipping_details .wrapper_info .info_title,
.wrapper_artwork_details .wrapper_order_details .shipping_details .wrapper_info .info_desc,
.wrapper_artwork_details .wrapper_order_details .info_title .title,
.wrapper_artwork_details .wrapper_order_details .info_desc .desc {
    display: grid;
}

.wrapper_artwork_details .wrapper_order_details .shipping_details .wrapper_info .desc,
.wrapper_artwork_details .wrapper_order_details .info_desc .desc {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000
}

.wrapper_artwork_details .wrapper_order_details .shipping_details {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 60px;
}


.wrapper_artwork_details .wrapper_order_details .certificate {
    display: flex;
    align-items: center;
    width: max-content;
    margin-top: 20px;
}

.platform_chat_container_footer {
    padding: 16px 0;
    align-items: center;
    position: relative;
}

.platform_chat_container_footer > .maximum_amount {
    position: absolute;
    bottom: 0;
    right: 20%;
    color: var(--error);
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    display: block;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: right;
}

.wrapper_list_message .platform_chat_container_footer {
    padding: 32px 0;
    align-items: start;
}

.wrapper_artwork_details .wrapper_order_details .certificate svg {
    margin-top: -4px;
}

.wrapper_artwork_details .wrapper_order_details .certificate span {
    font-size: 16px;
    display: block;
    padding-left: 12px;
    font-weight: bold;
    color: var(--dark-grey);
}

.every_inbox_message_event {
    grid-template-columns: 1fr max-content;
}

.every_inbox_message .start_shipping {
    cursor: pointer;
    width: max-content;
}

@media screen and (max-width: 992px) {
    .wrapper_artwork_details .wrapper_order_details .shipping_details,
    .wrapper_artwork_details .wrapper_order_details .body {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 768px) {
    .wrapper_artwork_details .wrapper_order_details .shipping_details {
        grid-template-columns: 1fr;
        grid-gap: unset;
    }


    .platform_chat_container_footer {
        grid-template-columns: 44px 1fr;
    }

    .platform_chat_container_footer .input_component {
        margin-top: 12px;
        grid-column: 1/-1;
        grid-row: 1;
    }

    .platform_chat_container_footer .avatar_wrapper {
        grid-row: 2;
    }

    .every_inbox_message .wrapper_date,
    .platform_chat_container_footer button {
        justify-self: end;
    }

    /*.profile_section_inbox_unique .wrapper_list_message {*/
    /*    padding: 0 16px;*/
    /*}*/
    .wrapper_artwork_details .wrapper_order_details .head,
    .every_inbox_message {
        grid-template-columns: 1fr 1fr;
    }

    .every_inbox_message .wrapper_sender_info {
        grid-column: 1/-1;
        grid-row: 2;
    }

    .wrapper_artwork_details .wrapper_order_details .head .delivery_my_sales {
        justify-self: start;
    }

    .platform_chat_container_footer > .maximum_amount {
        bottom: 33%;
        right: 2rem;
    }

    .every_inbox_message_event {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 568px) {
    .platform_chat_container_footer {
        padding: 16px;
    }

    .platform_chat_container_footer > .maximum_amount {
        right: 1rem;
    }
}

