.main_profile_wrapper_investor {
    margin: 3.5rem 0 5.5rem;
}

.main_profile_wrapper_investor .my_portfolio_wrapper h2 {
    margin: 0 0 2rem;
}

.main_profile_wrapper_investor .my_portfolio_wrapper .listing_items_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 4rem;
    align-items: start;
}

.main_profile_wrapper_investor .my_portfolio_wrapper .my_portfolio_navigation, .main_profile_wrapper_investor .my_portfolio_wrapper .my_portfolio_navigation .navigation_buttons {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    margin: 0 0 3rem;
}

.main_profile_wrapper_investor .my_portfolio_wrapper .my_portfolio_navigation .navigation_listing_buttons {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    justify-content: space-between;
    margin: 0 0 3rem;
}

.main_profile_wrapper_investor .my_portfolio_wrapper .my_portfolio_navigation .navigation_buttons {
    margin: 0;
}

.main_profile_wrapper_investor .my_portfolio_wrapper .my_portfolio_navigation .navigation_listing_buttons {
    margin: 0;
}

.main_profile_wrapper_investor .my_portfolio_wrapper .my_portfolio_content .portfolio_item > img {
    max-width: 100%;
    width: 100%;
}

@media (max-width: 568px) {
    .main_profile_wrapper_investor .my_portfolio_wrapper .my_portfolio_navigation {
        grid-template-columns: 1fr;
        margin: 0 0 2.5rem;
        grid-gap: 1.5rem;
    }

    .main_profile_wrapper_investor .my_portfolio_wrapper .my_portfolio_navigation .navigation_buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .main_profile_wrapper_investor .my_portfolio_wrapper .my_portfolio_navigation .navigation_listing_buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .main_profile_wrapper_investor .my_portfolio_wrapper .my_portfolio_navigation .navigation_buttons button {
        width: 100%;
    }
    .main_profile_wrapper_investor .my_portfolio_wrapper .my_portfolio_navigation .navigation_listing_buttons button {
        width: 100%;
    }

}