$red: #FF4949;
$grey: #8E8E8E;

.wrapper {

    &.is_focus {
        border-bottom-color: #000000;
    }

    &.black_border {
        border-bottom-color: #000;
    }

    .label {
        display: block;
    }

    .title {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        color: $grey;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;

        .title_error {
            display: none;
            margin-left: 30px;
        }
    }

    .input_wrapper {
        position: relative;

        .input_control {
            display: inline-block;
            width: 100%;
            border: 0;
            background-color: transparent;
            outline: none;
            cursor: text;
            border-bottom: 2px solid $grey;
            padding-bottom: 8px;
            transition: .3s border-bottom-color;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            &:focus {
                border-bottom: 2px solid #000;
            }

            &::placeholder {
                color: $grey;
                font-weight: 400;
                letter-spacing: initial !important;
            }

            &::-ms-reveal,
            &::-ms-clear {
                display: none;
            }

        }

        .pass_icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            fill: black;
            height: 20px;
            width: 30px;
        }
        .check_icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 20px;
            width: 30px;
        }
    }

    &.error {
        border-bottom-color: $red;

        .input_control {
            color: $red;
        }

        .pass_icon {
            fill: $red;
        }
    }

    &.type__icon {
        padding-bottom: 10px;

        .title {
            display: none;
        }

        .input_wrapper {
            .input_control {
                padding-left: 50px;
            }

            .input_icon {
                position: absolute;
                left: 0;
                width: 20px;
                height: 20px;
                object-fit: contain;
            }
        }
    }

    @media screen and (min-width: 980px) {
        padding-bottom: 20px;

        &.error {
            .title_error {
                display: flex;
            }
        }

        .label {
            .title {
                font-weight: normal;
                font-size: 18px;
                line-height: 28px;
            }

            .input_wrapper {
                .input_control {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 28px;
                }
            }

            .pass_icon {
                height: 30px;
            }
        }
    }

    @media (min-width: 980px) and (max-height: 900px) {
        padding-bottom: 12px;
    }

    @media (min-width: 980px) and (max-height: 790px) {
        padding-bottom: 8px;

        .title {
            margin-bottom: 10px;
        }
    }
}
