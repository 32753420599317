.infoItem {
    padding: 1.5rem 0;
}

.infoItem + .infoItem {
    border-top: 2px solid #000000;
}

.block {
    width: 100%;
}

.text {
    font-size: 1rem;
}

.smallText {
    font-size: 0.875rem;
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 0.5rem;
    grid-column-gap: 1.5rem;
    font-size: 0.875rem;
    font-weight: 600;
}

.hostName {
    font-size: 1.25rem;
    font-weight: 600;
}

.mapWrapper {
    position: relative;
    width: 100%;
    height: 300px;
    margin-top: 1rem;
}

.gridRow {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr 11rem;
    align-items: center;
}

.gridRowInner {
    display: grid;
    font-weight: 300;
    font-size: 0.875rem;
    grid-gap: 1.5rem;
    line-height: 1.75;
    grid-template-columns: 140px 1fr;
}

.preWrap {
    white-space: pre-wrap;
}

@media (max-width: 1440px) {
    .gridRowInner {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }
}

@media (max-width: 768px) {
    .list, .gridRow {
        grid-template-columns: 1fr;
    }
}