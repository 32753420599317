.main_wrapper {
    padding: 50px 0;
}

.main_title {
    font-size: 32px;
    font-weight: 600;
    color: #000000;
}

.item_from_list {
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
}
