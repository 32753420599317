.premium_page_wrapper > .contact_us_section > .wrapper > .form_wrapper {
    width: 100%;
    padding-left: 2rem;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2.5rem;
}

.question_feedback_form {
    grid-template-columns: 1fr;
    grid-gap: 40px;
    width: 100%;
}

.question_feedback_form > div:not(.wrapper_contact_form_footer) {
    width: 100%;
}

.question_feedback_form .input_component .custom_input, .question_feedback_form .custom_dropdown_select, .question_feedback_form .input_component textarea {
    width: 100%;
    border: none;
    border-bottom: 1px solid #000000;
    padding: 0 8px 4px !important;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
    height: 31px;
}

.question_feedback_form .custom_dropdown_select > .active_value {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
}

/*.form_wrapper .input_component span, .form_wrapper .custom_dropdown_wrapper_title {*/
/*    font-weight: 300;*/
/*    font-size: 14px;*/
/*    line-height: 26px;*/
/*    letter-spacing: 0.015em;*/
/*    color: rgba(0, 0, 0, .5);*/
/*}*/

.form_wrapper .error.custom_input, .form_wrapper .error.textarea {
    border: none !important;
    border-bottom: 1px solid var(--error) !important;
}

@media (max-width: 768px) {
    .premium_page_wrapper > .contact_us_section > .wrapper > .form_wrapper {
        padding-left: 0;
    }
}