.wrapper {
    display: flex;
    flex-direction: column;

    .left {
        margin-bottom: 40px;
    }

    .right {
        padding: 0 20px;
    }

    &.vertical_reverse {
        flex-direction: column-reverse;

        .left, .right {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 568px) {
        flex-direction: row !important;
        justify-content: space-between;

        .left {
            width: 48.82vw;
            margin-bottom: 0;
        }

        .right {
            width: 47.26vw;
            padding: 0 50px 0 0;
        }
    }

    @media screen and (min-width: 1440px) {
        .left {
            width: 49.3vw;
        }

        .right {
            width: 44.44vw;
            padding: 0 80px 0 0;
        }
    }
}