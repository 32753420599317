.terms_of_service_container {
    height: max-content;
    width: 100%;
}

.terms_of_service_container h1 {
    display: flex;
    justify-content: center;
}

.terms_of_service-two-block {
    display: flex;
    justify-content: center;
}

.terms_of_service_content_container {
    width: 356px;
    border-top: 2px solid #000000;
}

.terms_of_service_item_container {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 12px;
    margin-top: 16px;
}

.terms_of_service_item_container span {
    font-size: 14px;
    font-weight: 300;
    color: #000000;
    line-height: 26px;
}

.terms_item_title {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
}

.terms_of_service_section_container {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 24px;
    justify-content: center;
    margin-top: 24px;
}

.terms_of_service_navigation_wrapper {
    padding: 16px 0;
    transition: .3s all;
    color: #000000;
    max-width: 356px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid;
    width: 100%;
}

.terms_of_service_navigation_wrapper_active {
    /*border-right: 4px solid #000;*/
    width: 356px;
    justify-content: space-between;
    align-items: center;
}

.terms_of_service_navigation_wrapper span {
    width: 240px;
}

.terms_of_service_active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 2px solid;
}

.terms_of_service_active_close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #000000;
    border: 2px solid;
}

.horizontal_line_terms_item {
    height: 2px;
    width: 20px;
    background-color: #000000;
    position: absolute;
}

.vertical_line_terms_item {
    height: 20px;
    width: 2px;
    background-color: #000000;
    position: absolute;
    transition: .3s transform;
}

.vertical_line_terms_item_close {
    height: 20px;
    width: 2px;
    background-color: #FFFFFF;
    position: absolute;
    transition: .3s transform;
    transform: rotate(90deg);
}

.terms_of_service_close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #000000;
}

.horizontal_line_terms_item_close {
    height: 2px;
    width: 20px;
    background-color: #FFFFFF;
    position: absolute;
}

.panel_navigation_content_terms_of_service {
    display: grid;
    grid-template-columns: 1fr;
    border-top: 1px solid;
}

.panel_navigation_content_terms_of_service a {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    grid-gap: .5rem;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    transition: .3s all;
}

.panel_navigation_content_terms_of_service a:hover {
    color: #000000;
}

.privacy_policy_item_container {
    display: grid;
    margin-top: 20px;
}

.privacy_policy_item_container span {
    font-weight: 300;
    color: #000000;
    line-height: 26px;
}

@media (max-width: 550px) {
    .terms_of_service_navigation_wrapper span {
        width: auto;
    }

    .terms_item_title {
        font-size: 0;
    }

    .terms_of_service_section_container {
        grid-template-columns: max-content;
        padding: 0 25px;
        display: inherit;
    }

    .wrapper_component_info_footer {
        padding: 0;
    }
    .terms_of_service_container h1 {
        padding-top: 36px;
    }

    .terms_of_service_navigation_wrapper {
        max-width: inherit;
    }

    .terms_of_service_navigation_wrapper_active {
        width: auto;
    }
}