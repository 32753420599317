.btn {
    display: block;
    color: rgba(178, 95, 0, 1);
    border: 2px solid #B35F00;
    border-radius: 50px;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    text-align: center;
    padding: 15px 0;

    &:hover {
        background: rgba(178, 95, 0, 1);
        color: #fff;
    }

    @media screen and (min-width: 568px) {
        font-size: 20px;
        line-height: 30px;
        padding: 15px 73px;
    }

    @media screen and (min-width: 1440px) {
        font-size: 20px;
        line-height: 30px;
    }
}