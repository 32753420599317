.new_statistic {
    margin: 3rem 0 9rem;
}

.new_statistic .wrapper_tab {
    display: flex;
}


.new_statistic .wrapper_statistic_views {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
}

.new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category {
    display: grid;
    height: 234px;
    grid-template-columns: 170px 1fr;
    grid-gap: 20px;
}

.new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category .info_wrapper {
    display: grid;
    align-content: center;
}

.new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category .pie_wrapper {
    height: 234px;
    display: grid;
    align-content: center;
}

.new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category .pie_wrapper .pie_wrapper2 {
    height: 164px;
    width: 164px;
    background: #000;
    border-radius: 50%;
}

.new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category .info_wrapper .wrapper_views_info .every {
    display: grid;
    grid-template-columns: 1fr max-content;
}

.new_statistic .statistic_wrapper__popular_category > div:first-child {
    padding-top: 0;
}

.new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category .info_wrapper span {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000
}

.new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category .info_wrapper .val {
    font-weight: 600;
}

.new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category .recharts-responsive-container {
    max-height: 234px;
}

.new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category text {
    mix-blend-mode: difference;
}

.statistic_profile_views {
    display: grid;
    grid-gap: 24px;
}

.statistic_profile_views .title {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 4px;
}

.statistic_profile_views .title .tooltip_wrapper .tooltip {
    right: unset;
    left: 24px;
    top: -56px;
}

.statistic_profile_views .title span {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000
}

.statistic_profile_views .statistic_views {
    display: grid;
    background: #000;
    padding: 56px;
}

.statistic_profile_views .statistic_views .statistic_title {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-self: center;
    align-items: center;
    grid-gap: 12px;
}

.statistic_profile_views .statistic_views .statistic_title span {
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    color: #fff
}

.statistic_profile_views .statistic_views .statistic_desc {
    max-width: 75%;
    margin: 0 auto;
    text-align: center;
}

.statistic_profile_views .statistic_views .statistic_desc span {
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #fff;
}

.wrapper_statistic_region .wrapper_geo_info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
}

.wrapper_statistic_performance .statistic_profile_views,
.wrapper_statistic_region .statistic_profile_views {
    margin-top: 3rem;
}

.wrapper_statistic_performance .statistic_profile_views .wrapper_views_info,
.wrapper_statistic_region .statistic_profile_views .wrapper_views_info {
    display: grid;
    height: max-content;
    align-self: center;
    grid-gap: 8px;
}

.wrapper_statistic_region .statistic_profile_views .wrapper_views_info .every {
    display: inherit;
    grid-template-columns: 1fr max-content;
    grid-gap: 0 40px;
    align-items: center;
}

.wrapper_statistic_region .statistic_profile_views .wrapper_views_info .every span {
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000
}

.wrapper_statistic_region .statistic_profile_views .wrapper_views_info .every .val {
    font-weight: 600;
}


.wrapper_statistic_performance .statistic_profile_views .wrapper_views_info .every {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 20px;
}

.wrapper_statistic_performance .statistic_profile_views .wrapper_views_info .every span {
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000
}


.wrapper_statistic_performance .statistic_wrapper__area_chart {
    display: grid;
    grid-template-columns: 1fr;
}

.wrapper_statistic_prices {
    margin-top: 60px;
}

.wrapper_statistic_prices_container .wrapper_statistic_prices__chart {
    overflow-x: auto;
}

.wrapper_statistic_prices_container .wrapper_statistic_prices__info {
    display: grid;
}

.wrapper_statistic_prices_container .wrapper_statistic_prices__info .statistic_wrapper__area_avg {
    display: grid;
    align-self: center;
    justify-self: center;
    grid-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
}

.wrapper_statistic_prices .wrapper_statistic_prices__info .statistic_wrapper__area_avg .every {
    display: grid;
    grid-gap: 16px;
    text-align: center;
}

.wrapper_statistic_prices .wrapper_statistic_prices__info .statistic_wrapper__area_avg .every .preview {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
}

.wrapper_statistic_prices .wrapper_statistic_prices__info .statistic_wrapper__area_avg .every .value {
    font-weight: 300;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000
}

.wrapper_statistic_prices_container .statistic_wrapper__area_legend {
    width: 80%;
    margin: 0 auto;
    display: grid;
    justify-items: start;
    height: max-content;
}

.wrapper_statistic_prices_container .statistic_wrapper__area_legend .every {
    display: grid;
    grid-template-columns: 18px 1fr;
    align-items: center;
    grid-gap: 12px;
}

.wrapper_statistic_prices_container .statistic_wrapper__area_legend .every .title {
    font-weight: normal;
    font-size: 17px;
    grid-template-columns: 1fr;
    word-break: break-word;
    line-height: 28px;
    letter-spacing: 0.06em;
    color: rgba(0, 0, 0, 0.7);
}

.wrapper_statistic_prices_container .statistic_wrapper__area_legend .every .color {
    content: '';
    width: 100%;
    height: 2px;
}

.wrapper_statistic_prices_container .wrapper_tabs {
    display: flex;
}

.wrapper_statistic_prices .wrapper_tabs .active_tabs_container {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
}

.wrapper_statistic_prices .wrapper_statistic_prices_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.wrapper_statistic_performance .statistic_wrapper__area_legend_wrapper {
    display: grid;
    grid-template-columns: max-content 1fr;
}

.wrapper_statistic_performance .statistic_wrapper__area_legend_wrapper .wrapper_btn {
    justify-self: end;
}

.wrapper_statistic_performance .statistic_wrapper__area_legend .every .title {
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: 0.06em;
    color: rgba(0, 0, 0, 0.7);
}

.wrapper_statistic_performance .statistic_wrapper__area_legend .every .color {
    content: '';
    width: 100%;
    height: 2px;
}

.wrapper_statistic_performance .statistic_wrapper__area_legend .every {
    display: grid;
    grid-template-columns: 24px max-content;
    grid-gap: 12px;
    align-items: center;
}

.wrapper_statistic_performance .statistic_wrapper__area_legend {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 40px;
}

.wrapper_statistic_performance .statistic_wrapper__area_chart .recharts-cartesian-axis-tick-line,
.wrapper_statistic_performance .statistic_wrapper__area_chart .recharts-cartesian-axis-line {
    display: none !important;
}

.wrapper_statistic_performance .statistic_wrapper__area_chart .recharts-layer {
    margin-top: 30px;
}

.wrapper_statistic_performance .statistic_wrapper__area_chart .recharts-wrapper text {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    letter-spacing: 0.45941px;
    color: #000
}

.statistic_wrapper__area_chart {
    display: grid;
    grid-template-columns: max-content 1fr
}

.customizeToolTip {
    background: #000;
    z-index: 10000;
    padding: 0 16px 12px;
    display: grid;
    max-width: 180px;
}

.customizeToolTipAvgDate {
    padding: 0 16px;
}

.customizeToolTip span {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.21;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.customizeToolTip .name {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}


.customizeToolTip .city {
    font-style: italic;
}

.recharts-wrapper > svg .recharts-pie-sector path {
    stroke: transparent !important;
}


@media (max-width: 992px) {
    /*.new_statistic .wrapper_statistic_views {*/
    /*    grid-template-columns: 1fr;*/
    /*}*/
    /*.new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category{*/
    /*    grid-template-columns: 1fr;*/
    /*}*/
    .new_statistic .wrapper_statistic_views {
        grid-gap: 32px;
    }

    .wrapper_statistic_performance .statistic_wrapper__area_chart {
        grid-template-columns: 1fr;
    }

    .wrapper_statistic_performance .statistic_wrapper__area_chart .recharts-wrapper > svg,
    .wrapper_statistic_performance .statistic_wrapper__area_chart .recharts-wrapper {
        width: 100% !important;
    }

    .wrapper_statistic_performance .statistic_wrapper__area_chart .wrapper_area_chart_info {
        justify-content: center;
    }

    .wrapper_statistic_performance .statistic_profile_views .wrapper_views_info, .wrapper_statistic_region .statistic_profile_views .wrapper_views_info {
        grid-gap: 20px;
    }

    .wrapper_statistic_performance .statistic_wrapper__area_chart .wrapper_area_chart_info .every {
        grid-gap: 60px;
    }

    .statistic_profile_views .statistic_views .statistic_desc {
        max-width: 100%;
    }

    .wrapper_statistic_performance .statistic_wrapper__area_legend_wrapper {
        grid-template-columns: 1fr;
        grid-gap: 24px;
    }

    .wrapper_statistic_performance .statistic_wrapper__area_legend_wrapper .wrapper_btn {
        justify-self: start;
    }

    .wrapper_statistic_prices .wrapper_statistic_prices_container {
        grid-template-columns: 2fr 1fr;
    }

    .wrapper_statistic_prices_container .statistic_wrapper__area_legend {
        width: 100%;
    }

    .wrapper_statistic_prices_container .wrapper_statistic_prices__info .statistic_wrapper__area_avg {
        grid-gap: 20px;
    }

}

@media (max-width: 800px) {
    .wrapper_statistic_region .wrapper_geo_info {
        grid-template-columns: 1fr;
    }

    .wrapper_statistic_region .wrapper_geo_info > div {
        display: grid;
        justify-content: center;
    }

    .new_statistic .wrapper_statistic_views {
        grid-template-columns: 1fr;
    }

    .new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category,
    .statistic_profile_views .statistic_views {
        justify-self: center;
    }

    .wrapper_statistic_region .statistic_profile_views .wrapper_views_info .every {
        grid-gap: 60px;
    }

    .new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category .pie_wrapper,
    .new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category {
        height: 164px;
    }

}

@media (max-width: 768px) {
    .wrapper_statistic_prices .wrapper_statistic_prices_container {
        grid-template-columns: 1fr;
        grid-gap: 40px;
    }

    .wrapper_statistic_prices_container .statistic_wrapper__area_legend {
        display: grid;
        justify-content: center;
    }

    .wrapper_statistic_prices_container .wrapper_statistic_prices__info {
        grid-gap: 32px;
    }
}

@media (max-width: 700px) {
    .wrapper_statistic_performance .statistic_wrapper__area_legend {
        grid-template-columns: 1fr;
        grid-gap: 24px;
    }
}

@media (max-width: 600px) {
    .new_statistic .wrapper_tab {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .wrapper_statistic_views {
        margin-top: 2rem;
    }

    .new_statistic .wrapper_tab button {
        width: 100%;
    }

    .wrapper_statistic_performance .statistic_wrapper__area_chart {
        overflow-x: auto;
    }
}

@media (max-width: 500px) {
    .wrapper_statistic_performance .statistic_wrapper__area_chart {
        grid-gap: 0;
    }

    .new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category {
        grid-template-columns: 1fr;
    }

    .wrapper_statistic_region .wrapper_geo_info > div {
        justify-content: unset;
    }

    .wrapper_statistic_region .wrapper_geo_info {
        padding: 0 5%;
    }

    .new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category {
        height: unset;
    }
    .wrapper_statistic_prices .wrapper_tabs .active_tabs_container{
        grid-template-columns: 1fr;
    }
    .wrapper_statistic_prices .wrapper_tabs .active_tabs_container button{
        margin: 0;
        width: 100%;
    }
}

@media (max-width: 400px) {
    .new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category .pie_wrapper, .new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category,
    .new_statistic .wrapper_statistic_views .statistic_wrapper__popular_category .pie_wrapper,
    .statistic_wrapper__popular_category {
        height: unset;
    }

    .new_statistic .wrapper_tab {
        grid-template-columns: 1fr;
    }
}

/*DELETE*/
@media (max-width: 500px) {
    button {
        width: 100%;
    }
}