.admin_content_wrapper_blog {
    padding: 1.75rem 4rem 2rem;
}

.admin_content_wrapper_blog > .head {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    margin: 0 0 1.5rem;
}

.admin_content_wrapper_blog > .head label {
    position: relative;
    width: max-content;
    display: block;
}

.admin_content_wrapper_blog > .head input {
    border: 1px solid #333333;
    border-radius: 7px;
    padding: 14px 40px 14px 25px;
    font-size: 14px;
    line-height: 16px;
    width: 328px;
    color: #000000;
}

.admin_content_wrapper_blog > .head label > img {
    position: absolute;
    top: 11px;
    right: 12px;
}

.admin_content_wrapper_blog > .head > .category {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 3rem;
}

.admin_new_post_wrapper {
    background: rgba(52, 38, 38, 0.2);
    position: fixed;
    width: 100%;
    height: var(--app-height);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1001;
}

.admin_new_post_wrapper .content > svg {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
}

.admin_new_post_wrapper .content {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1010;
    background: #ffffff;
    padding: 2rem;
    width: 628px;
    top: 0;
    overflow-y: auto;
    bottom: 0;
    margin: 50px auto;
}

.admin_new_post_wrapper .content h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
    margin: 0 0 1rem;
}

.admin_new_post_wrapper .line {
    background: #CECECE;
    width: 100%;
    height: 1px;
    margin: 0 0 1.5rem;
}

.admin_new_post_wrapper .content > .category {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1.5rem;
}

.admin_new_post_wrapper .content > .category > .admin_category_wrapper {
    position: relative;
}

.admin_content_wrapper_blog .wrapper_header_preview_section > .admin_category_wrapper .custom_dropdown_select > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #060606;
}

.admin_new_post_wrapper .content > .category > .admin_category_wrapper > .text {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    color: #060606;
}

.admin_new_post_wrapper .content > .category > .admin_category_wrapper .options_wrapper {
    position: absolute;
    top: 2rem;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0 10px 15px -3px rgba(26, 32, 44, 0.1), 0 4px 6px -2px rgba(26, 32, 44, 0.05);
    border-radius: 4px;
    z-index: 420;
    max-height: 0;
    overflow-y: hidden;
    transition: .3s all;
    padding: 0 8px 8px;
}

.admin_new_post_wrapper .content > .category > .admin_category_wrapper .options_wrapper_active {
    max-height: 150px;
    overflow-y: auto;
}

.admin_new_post_wrapper .content > .category > .admin_category_wrapper .options_wrapper > span {
    font-size: 14px;
    line-height: 22px;
    color: #2A303C;
    display: block;
    border-bottom: 1px solid #EBEBF0;
    padding: 8px 8px;
    cursor: pointer;
}

.admin_new_post_wrapper .content > .category > .admin_category_wrapper .options_wrapper > span:last-of-type {
    border-bottom: none;
    padding: 8px 8px 0;
    margin: 0;
}

.admin_content_wrapper_blog > .head button {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    padding: 7px 10px;
    color: #FFFFFF;
    background: #000;
    border-radius: 4px;
}

.admin_content_wrapper_blog > .head > .category {
    display: grid;
}

.admin_new_post_wrapper .content > .category > .cancel_category {
    display: grid;
    grid-gap: 10px;
    align-items: center;
    grid-template-columns: repeat(2, max-content);
    background: #F8F8FB;
    border-radius: 20px;
    padding: 4px 12px 4px 10px;
}

.admin_new_post_wrapper .content > .category > .cancel_category span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 157%;
    letter-spacing: 0.06em;
    color: rgba(0, 0, 0, 0.7);
}

.admin_new_post_wrapper .content > .category > .cancel_category > img {
    cursor: pointer;
}

.admin_new_post_wrapper .content > .submit_form_wrapper {
    display: grid;
    align-items: center;
    justify-content: end;
    width: 100%;
}

.admin_new_post_wrapper .content > .submit_form_wrapper > .submit_form {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    padding: 7px 30px;
    color: #FFFFFF;
    background: #AE4B84;
    border-radius: 4px;
    width: max-content;
    margin: 0 0 0 auto;
}

.admin_new_post_wrapper .editor {
    margin: 0 0 1.5rem;
}

.admin_new_post_wrapper .content > .add_section_button {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
    padding: 7px 8px;
    background: #FFFFFF;
    border: 1px solid #AE4B84;
    border-radius: 4px;
    width: max-content;
    margin: 0 0 1.5rem auto;
}

.admin_new_post_wrapper .content > .add_section_button span {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: #AE4B84;
}

.public-DraftStyleDefault-block {
    margin: 0 !important;
}

.rdw-editor-toolbar {
    padding: 0 !important;
    border: none !important;
    border-radius: 0 !important;
}

.rdw-editor-main {
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 7px;
    padding: 9px 14px;
    min-height: 84px;
    cursor: text;
}

.admin_new_post_wrapper .content .subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin: 0 0 .5rem;
    display: block;
}

.admin_new_post_wrapper .content .delete_blog_section {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1rem;
}

.admin_new_post_wrapper .content .delete_blog_section .subtitle {
    margin: 0;
}

.admin_new_post_wrapper .content .delete_blog_section img {
    cursor: pointer;
}

.admin_new_post_wrapper .content .input_component .custom_input, .admin_new_post_wrapper .content .input_component .textarea {
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 7px;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}

.admin_new_post_wrapper .photo_placeholder {
    border: 1px dashed rgba(0, 0, 0, .3);
    border-radius: 4px;
    margin: 0 0 1.5rem;
    width: 100%;
    height: 170px;
    display: grid;
    align-items: center;
    justify-content: center;
    position: relative;
}

.admin_new_post_wrapper .photo_placeholder > label {
    background: #AE4B84;
    border-radius: 4px;
    margin: auto;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    padding: 5px 8px
}

.admin_new_post_wrapper .photo_placeholder > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.admin_new_post_wrapper .photo_placeholder > .preview_image {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.admin_new_post_wrapper .photo_placeholder > .preview_image svg {
    position: absolute;
    z-index: 11;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
}

.admin_new_post_wrapper .photo_placeholder > .preview_image .active_img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.admin_content_wrapper_blog > .body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    grid-gap: 1rem 20px;
}

.admin_content_wrapper_blog > .body > .article_card {
    background: #F8F8FB;
    border: 1px solid #EFEFF4;
    border-radius: 5px;
    height: 100%;
    position: relative;
}

.admin_content_wrapper_blog > .body > .article_card > .preview {
    border-radius: 5px 5px 0 0;
    max-height: 205px;
    object-fit: cover;
    width: 100%;
    height: 205px;
}

.admin_content_wrapper_blog > .body > .article_card > .edit {
    position: absolute;
    top: .5rem;
    right: .5rem;
    cursor: pointer;
    opacity: 0;
    transition: .3s all;
}

.admin_content_wrapper_blog > .body > .article_card:hover > .edit {
    opacity: 1;
}

.admin_content_wrapper_blog > .body > .article_card > .card_content {
    padding: 8px 1rem 1rem 1rem;
}

.admin_content_wrapper_blog > .body > .article_card > .card_content h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #060606;
    margin: 0 0 4px;
}

.admin_content_wrapper_blog > .body > .article_card > .card_content p {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 8px;
    color: #656565;
}

.admin_content_wrapper_blog > .body > .article_card > .card_content > .category {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
}

.admin_content_wrapper_blog > .body > .article_card > .card_content > .category span {
    font-size: 10px;
    line-height: 12px;
    color: #656565;
}

.wrapper_header_preview_section {
    display: grid;
    grid-template-columns: 1fr max-content;
}

.wrapper_header_preview_section .admin_category_wrapper > div {
    display: flex;
}

.wrapper_header_preview_section .admin_category_wrapper > div .custom_dropdown_option {
    width: unset;
    right: 0;
    left: unset;
}
