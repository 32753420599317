.registration_photo_video .upload_cv_wrapper {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0 34px;
}

.registration_photo_video .upload_cv_wrapper > span {
    grid-column: 1/-1;
}

.registration_photo_video .photos {
    display: grid;
    margin: 40px 0;
    grid-template-columns: 142px repeat(4, 64px);
    grid-gap: 0 22px;
}

.registration_photo_video .the_photos_hint {
    grid-column: 1/-1;
    margin-bottom: 12px;
    height: max-content;
}

.registration_photo_video .the_photos_hint span {
    color: var(--grey)
}

.registration_photo_video .photos .empty {
    border: solid 1px var(--lite-grey);
    background-color: #fff;
}

.registration_photo_video .photos .preview {
    z-index: 1;
    position: absolute;
    bottom: -.5px;
    left: 5px;
    right: 0;
}

.registration_photo_video .every_photo {
    width: 100%;
    position: relative;
    cursor: pointer;
    height: 64px;
    border-radius: 50%;
}

.registration_photo_video .every_photo .photo_preview {
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
    position: absolute;
}

.registration_photo_video .every_photo .clear {
    position: absolute;
    right: -4px;
    top: -4px;
    z-index: 100000;
    cursor: pointer;
    padding: 4px;
}

.registration_photo_video .every_photo .clear_first {
    width: 40px;
    height: 20px;
}

.registration_photo_video .wrapper_video {
    grid-template-columns: 1fr;
    margin-top: 32px;
}

.registration_photo_video .wrapper_video .video_preview {
    padding: 100px 120px;
    border: solid 1px var(--lite-grey);
    background-color: rgba(43, 43, 46, 0.03);
    display: grid;
    position: relative;
}

.registration_photo_video .wrapper_video .video_preview .wrapper_loader {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #f5f5f5;
    position: absolute;
    z-index: 1000;
    display: grid;
    align-content: center;
    justify-items: center;
    grid-template-rows: 70px max-content;
    text-align: center;
}

.registration_photo_video .wrapper_video .video_preview .wrapper_loader .loader {
    margin-right: 24px;
}

.registration_photo_video .wrapper_video .video_preview button {
    justify-self: center;
    position: relative;
    z-index: 10;
}

.registration_photo_video .upload_cv_wrapper button:hover svg path,
.registration_photo_video .wrapper_video .video_preview button:hover svg path {
    fill: var(--new-black);
    transition: .4s;
}

.registration_photo_video .wrapper_video .video_preview video {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 9;
    object-fit: contain;
    right: 0;
    top: 0;
    bottom: 0;
}

@media (max-width: 600px) {
    .registration_photo_video .photos {
        grid-gap: 0 30px;
        grid-template-rows: 71px 71px;
    }

    .registration_photo_video .wrapper_video .video_preview {
        padding: 100px 80px;
    }

    .registration_photo_video .photos .every_photo:first-child {
        grid-row: 1/-1;
    }
}

@media (max-width: 500px) {
    .registration_photo_video .photos {
        grid-template-columns: 142px repeat(2, 64px);
    }

}

@media (max-width: 420px) {
    .registration_photo_video .photos {
        grid-gap: 0 8px;
    }

    .registration_photo_video .upload_cv_wrapper {
        grid-template-columns: 1fr;
        text-align: center;
        justify-items: center;
    }

    .registration_photo_video .wrapper_video .video_preview {
        padding: 0;
        width: 100%;
        height: 200px;
        justify-items: unset;
    }

    .registration_photo_video .upload_cv_wrapper > div {
        grid-row: 1;
    }

}
