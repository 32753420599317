.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;
    background-color: transparent;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 24px;
}

.form > * {
    margin-bottom: 24px;
}

.form > span {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
}