.root {
    font-size: 0.875rem;
    font-weight: 300;
    padding: 0.5rem 1rem 0.75rem;
    border-radius: 0;
    border-width: 0 0 1px 0;
    border-color: #000000;
    outline: none;
}

.root:global(.ant-input-sm) {
    font-weight: 600;
    font-size: 0.75rem;
    border-width: 0 0 2px 0;
    padding: 0.25rem 0.5rem 0.375rem;
}

.root:hover,
.root:focus {
    outline: none;
    border-color: #000000;
    border-width: 0 0 1px 0;
    box-shadow: none;
}

.root:global(.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled)) {
    border-width: 0 0 1px 0;
}

.label {
    display: block;
    font-weight: 300;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.5);
    letter-spacing: 0.015em;
    margin-bottom: 0.5rem;
}

.centered {
    text-align: center;
}

.root.outlined {
    padding: 0.5rem 1rem;
    border: 1px solid #000000;
    border-radius: 8px;
}

.root.outlined input {
    flex-grow: 1;
}