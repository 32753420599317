.wrapper {
  margin-top: 50px;

  .uptitle {
    font-family: 'Poppins', sans-serif !important;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
  }

  .title {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    font-size: 38px;
    line-height: 48px;
    margin: 20px 0 50px;
  }

  .search_input {
    position: relative;
    border: 2px solid #000000;
    box-sizing: border-box;
    margin-top: 40px;
    padding: 15px 0 15px 60px;

    .search_control {
      font-family: 'Poppins', sans-serif !important;
      border: none;
      outline: none;
      width: 100%;
      height: 30px;
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
      background-color: transparent;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background: url('../../../assets/img/onboarding/search.svg') no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  .categories_wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 30px -11px 0;
  }

  //.category_wrapper {
  //  width: 50%;
  //  min-width: 50%;
  // F  max-width: 50%;
  //  padding: 10px 11px;
  //}
  .categories_wrapper {
    display: flex;
    margin: 20px -15px 0;
    min-height: 360px;
    @media (max-width: 992px) {
      min-height: 196px;
    }
  }

  .category_wrapper {
    padding: 15px;
  }

  .category {
    position: relative;
    padding: 15px 32px;
    border: 2px solid black;
    cursor: pointer;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;

    .category__text {
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.selected {
      background-color: black;
      color: #fff;

      &::before {
        content: '';
        position: absolute;
        display: none;
        width: 26px;
        height: 26px;
        top: -13px;
        right: -13px;
        background: url('../../../assets/img/onboarding/close_circle.svg') no-repeat;
        background-size: contain;
      }
    }
  }

  .footer {
    margin-top: 30px;

    .counter {
      font-family: 'Poppins', sans-serif !important;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 77px;
      text-align: center;
    }

    @media (max-width: 500px) {
      button {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 980px) {
    .uptitle {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
    }
    .title {
      font-weight: 600;
      font-size: 48px;
      line-height: 56px;
      margin: 30px 0 60px;
    }
    .search_input {
      padding: 15px 0 15px 80px;

      .search_input {
        font-size: 18px;
      }

      &::before {
        left: 40px;
      }
    }

    .categories_wrapper {
      display: flex;
      margin: 50px -15px 0;
      min-height: 360px;
    }

    .category_wrapper {
      width: auto;
      min-width: auto;
      padding: 15px;
    }
    .category {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      padding: 16px 30px;

      &.selected:hover::before {
        display: block;
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;

      .counter {
        font-weight: normal;
        font-size: 20px;
        line-height: 35px;
        margin-bottom: 0;
      }
    }
  }

  @media screen and (min-width: 980px) and (max-height: 900px) {
    .title {
      font-size: 36px;
      line-height: 1.5;
      margin-bottom: 10px;
    }
    .categories_wrapper {
      margin: 30px -15px 0;
    }
    .footer {
      margin-top: 1rem;
    }
  }

  @media screen and (min-width: 980px) and (max-height: 790px) {
    margin-top: 50px;
  }
}
