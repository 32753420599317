.paginationButtons {
    display: inline-flex;
    align-items: center;
    grid-gap: 1rem;
    font-size: 1.125rem;
    color: rgba(174, 75, 132, 1);
}

.table:global(.ant-table-wrapper) .pagination {
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 0;
}

.pagination :global(.ant-pagination-next) {
    margin-left: 3rem;
}

.pagination :global(.ant-pagination-prev) {
    margin-right: 3rem;
}

.pagination :global(.ant-pagination-disabled) {
    opacity: 0;
}

.pagination :global(.ant-pagination-item) {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0;
    color: rgba(142, 144, 166, 1);
}

.paginationDots {
    color: rgba(142, 144, 166, 1);
    font-size: 1.5rem;
    letter-spacing: 0.05em;
    line-height: 1.1;
}

.pagination :global(.ant-pagination-item-active) {
    border: 0;
    color: rgba(174, 75, 132, 1);
}

@media (max-width: 768px) {
    .pagination :global(.ant-pagination-next) {
        margin-left: 0.5rem;
    }

    .pagination :global(.ant-pagination-prev) {
        margin-right: 0.5rem;
    }

    .paginationButtons {
        grid-gap: 0.5rem;
        font-size: 0.875rem;
    }

    .pagination :global(.ant-pagination-item) {
        font-size: 0.875rem;
    }
}