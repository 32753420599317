
.uploader {
    max-width: 340px;
    width: 100%;
    margin-bottom: 20px;
}

.uploader:last-child {
    margin-bottom: 0;
}

.uploader .uploader__label {
    display: block;
}

.uploader .uploader_work {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.uploader .uploader_img {
    cursor: pointer;
    height: 170px;
    width: 170px;
    background-color: black;
    background-image: url('../../../assets/img/onboarding/upload.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.uploader .uploader_img .uploaded_work_delete_icon {
    position: absolute;
    right: -12px;
    top: -12px;
    z-index: 10;
    cursor: pointer;
}

@media screen and (min-width: 980px) {
    .uploader {
        width: 170px;
        min-width: 170px;
        height: 170px;
    }
}

@media (max-width: 500px) {
    .uploader_mob {
        max-width: unset;
    }

    .uploader_mob .uploader_img {
        width: 100%;
    }
}