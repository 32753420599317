footer {
    background-color: var(--dark-grey);
    position: relative;
    z-index: 80;
}

.footer_section {
    display: grid;
    grid-template-columns: 1fr max-content;
    padding: 0 5%;
}

.six_section_wrapper {
    padding-top: 35px;
    display: grid;
    grid-template-columns: max-content  1fr;
    grid-gap: 0 40px;
}
.footer_questions_list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0 40px;
}

.investors_block {
    width: 129px;
}

.investors_block  {
    margin-top: 12px;
    padding-bottom: 22px;
}

.investors_block span {
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
}

.investors_block  a {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    color: #a3a3ad;
    transition: 0.3s;
}

.investors_block a:hover {
    color: #000000
}

.footer_right {
    display: grid;
}

.messengers {
    justify-self: center;
    align-self: center;
    margin: 35px 0 5px 0;
}

.messengers .no_circle {
    justify-self: center;
    display: grid;
    border: none;
    align-content: center;
}

.insta_log img {
    width: 29px;
}

.linked img {
    width: inherit;
    height: inherit;
}


.messengers button {
    border-radius: 50%;
    border: 1px solid white;
    width: 27px;
    height: 27px;
}

.messengers img {
    vertical-align: middle;

}

.messengers ul {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 13px;
}

.store_app {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-self: center;
    grid-gap: 0 12px;
}

.store_app a {
    display: grid;
    align-items: center;
    height: max-content;
}

.solid_line {
    padding-bottom: 9px;
    grid-column: 1/-1;
}

.solid_line div {
    width: 100%;
    opacity: 0.24;
    border: solid 0.5px #a2a2ac;
}

.solid_line p {
    text-align: center;
    margin-top: 10px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    object-fit: contain;
    opacity: 0.15;
    color: #ffffff;
}

.footer_navigation_list,
.footer_navigation {
    display: none;
}
.trustpilot_link img{
    width: 120px;
}


@media (max-width: 1200px) {

    .investors_block {
        width: 100%;
    }
}

@media (max-width: 1025px) {
    .store_app {
        grid-template-columns: 1fr;
        grid-gap: 0 12px;
    }

    .six_section_wrapper {
        grid-gap: 0 30px;
    }
}

@media (max-width: 992px) {
    .footer_section {
        grid-template-columns: 1fr;
        padding: 0 5%;
    }

    .six_section_wrapper {
        grid-gap: 32px;
        grid-template-columns: 1fr;
    }

    .six_section_wrapper {
        padding-top: 35px;
        grid-gap: 0 40px;
    }

    .footer_logo {
        grid-column: 1/-1;
        margin-bottom: 30px;
    }


    .messengers {
        margin-top: 0;
    }

    .footer_right {
        grid-template-columns: repeat(1, max-content);        justify-content: center;
        align-items: center;
        grid-gap: 1rem;
        margin: 0 0 30px;
    }
    .trustpilot_link{
        display: grid;
        text-align: -webkit-center;
    }

    .store_app {
        grid-template-columns: repeat(2, max-content);
        grid-gap: 0 12px;
    }

    .wrapper_two {
        margin-top: 4rem;
    }
}

@media (max-width: 660px) {
    .six_section_wrapper {
        grid-gap: 0 20px;
    }

    .investors_block {
        width: 100%;
    }
}

@media (max-width: 550px) {
    .six_section_wrapper {
        grid-gap: 0 12px;
        grid-template-columns: repeat(2, 1fr);
    }

    .investors_block {
        display: none;
    }

    .footer_right {
        grid-template-columns: 1fr;
    }

    .footer_navigation {
        display: grid;
        margin-bottom: 20px;
        height: max-content;
        grid-gap: 12px 0;
    }

    .footer_navigation span {
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        text-decoration: underline;
        letter-spacing: normal;
        color: #fff;
    }

    .footer_navigation .footer_navigation__active {
        color: #000000;
    }


    .footer_navigation_list .investors_block {
        display: block;
    }

    .footer_navigation_list {
        display: grid;
    }

    .investors_block  {
        padding: 0;
        margin: 0 0 20px 0;
    }
}
