.root {
    display: grid;
    grid-template-columns: 12rem auto 12rem;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 1.5rem;
    font-weight: 300;
    font-size: 0.875rem;
}

.date {
    font-weight: 400;
    font-size: 1.25rem;
    margin: 0.625rem 0;
}

.arrowIcon {
    width: 90%;
}

@media (max-width: 500px) {
    .root {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
    }
}