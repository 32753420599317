.my_sales_wrapper {
    margin: 3rem 0 0;
}

.my_sales_wrapper > .my_sales_navigation {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    margin: 0 0 3rem;
}

.my_sales_wrapper > .my_sales_navigation > .active_tabs_container {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    align-items: center;
}

.my_sales_wrapper > .my_sales_navigation > .sort_by_wrapper, .main_profile_wrapper_investor .my_portfolio_navigation .sort_by_wrapper {
    position: relative;
    width: max-content;
    margin-left: auto;
}

.my_sales_wrapper > .my_sales_navigation > .sort_by_wrapper > .sort_by, .main_profile_wrapper_investor .my_portfolio_navigation .sort_by_wrapper .sort_by {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    border: 2px solid #000000;
    cursor: pointer;
    font-size: 12px;
    line-height: 30px;
    width: 167px;
    color: #000000;
    padding: 4px 16px 4px 16px;
}

.my_sales_wrapper > .my_sales_navigation > .sort_by_wrapper .popup, .main_profile_wrapper_investor .my_portfolio_navigation .sort_by_wrapper .popup {
    position: absolute;
    top: 42px;
    left: 0;
    right: 0;
    width: 100%;
    background: #000000;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    align-items: center;
    max-height: 0;
    overflow: hidden;
    transition: .3s;
    box-shadow: 0 10px 15px -3px rgba(26, 32, 44, 0.1), 0 4px 6px -2px rgba(26, 32, 44, 0.05);
    z-index: 420;
}

.my_sales_wrapper > .my_sales_navigation > .sort_by_wrapper .popup_active, .main_profile_wrapper_investor .my_portfolio_navigation .sort_by_wrapper .popup_active {
    max-height: 100px;
}

.my_sales_wrapper > .my_sales_navigation > .sort_by_wrapper .popup span,
.main_profile_wrapper_investor .my_portfolio_navigation .sort_by_wrapper .popup span {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #F7F6F1;
    padding: 10px 20px;
    cursor: pointer;
}

.my_sales_wrapper > .my_sales_content {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    margin: 0 0 4.5rem;
}

.my_sales_wrapper > .my_sales_content .item {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem;
    transition: .3s;
    position: relative;
}

.my_sales_wrapper > .my_sales_content .item:hover {
    background: var(--beige);
}

.my_sales_wrapper > .my_sales_content .item a {
    display: grid;
    align-items: center;
}

.my_sales_wrapper > .my_sales_content .item a img {
    height: 160px;
    object-fit: cover;
    max-width: 100%;
    width: 100%;
}

.my_sales_wrapper > .my_sales_content .item h4, .my_sales_wrapper > .my_sales_content .item span {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.my_sales_wrapper > .my_sales_content .item p {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    word-break: break-word;
}

.my_sales_wrapper > .my_sales_content .item span {
    font-size: 14px;
    line-height: 20px;
    display: block;
    word-break: break-word;
}

.my_sales_wrapper > .my_sales_content .item .inline {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 1rem;
    align-items: center;
}

.my_sales_wrapper > .my_sales_content .item .inline_right {
    justify-content: end;
}

.my_sales_wrapper > .my_sales_content .item .about, .my_sales_wrapper > .my_sales_content .item .order, .my_sales_wrapper > .my_sales_content .item .price {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    align-items: center;
    padding-left: 1rem;
}

.my_sales_wrapper > .my_sales_content .item .order {
    padding-left: 1.5rem;
}

.my_sales_wrapper > .my_sales_content .item .price {
    padding: 0 2.5rem 0 0;
    text-align: right;
    grid-gap: 8px;
}

.my_sales_wrapper > .my_sales_content .item .price > div {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 8px;
}

.my_sales_wrapper > .my_sales_content .item .about_orders, .my_sales_wrapper > .my_sales_content .item .order_orders, .my_sales_wrapper > .my_sales_content .item .price_orders {
    grid-gap: 16px;
}

.my_sales_wrapper > .my_sales_content .item .price_orders > div {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 16px;
}


.my_sales_wrapper > .my_sales_content .item .price span.price_text {
    font-size: 16px;
    line-height: 24px;
}

.my_sales_wrapper > .my_sales_content .item .price span.orders_price_text {
    font-size: 20px;
    line-height: 24px;
}

.my_sales_wrapper > .my_sales_content .item .popup_wrapper {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    display: grid;
    align-items: center;
    width: max-content;
}

.my_sales_wrapper > .my_sales_content .item .popup_wrapper .dots {
    cursor: pointer;
}

.my_sales_wrapper > .my_sales_content .item .popup_wrapper .dots g {
    transition: .3s;
}

.my_sales_wrapper > .my_sales_content .item:hover .popup_wrapper .dots g {
    opacity: 1;
}

.my_sales_wrapper > .my_sales_content .item .popup_wrapper .popup {
    width: 250px;
    background: #000000;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    z-index: 100;
}

.my_sales_wrapper > .my_sales_content .item .popup_wrapper .popup a {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #F7F6F1;
    display: block;
}

.my_sales_wrapper > .my_sales_content .item .popup_wrapper .popup > div {
    background: rgba(255, 255, 255, .5);
    width: 100%;
    height: 1px;
}

.sales-orders-card-item-container {
    position: relative;
}

.sales-orders-card-item-container-nft-block {
    position: absolute;
    top: 0;
    width: 68px;
    color: #FFFFFF;
    background-color: #000000;
    padding: 6px 15px;
    font-size: 20px;
    font-weight: 700;
}

@media (max-width: 1200px) {
    .my_sales_wrapper > .my_sales_content .item {
        grid-template-columns: 1fr 1fr 1fr 200px;
        grid-gap: 1rem;
    }
}

@media (max-width: 850px ) {
    .my_sales_wrapper > .my_sales_navigation {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 2rem;
    }
}

@media (max-width: 768px) {
    .my_sales_wrapper > .my_sales_content .item {
        grid-template-columns: 1.75fr 3fr;
        padding: 0 5%;
        background: var(--beige);
    }

    .my_sales_wrapper > .my_sales_content .item .item_content {
        display: grid;
        grid-template-columns: 1fr max-content;
        justify-content: space-between;
        grid-gap: 1.5rem;
        align-items: center;
        padding: 1rem 0;
    }

    .my_sales_wrapper > .my_sales_content .item a img {
        height: 100%;
    }

    .my_sales_wrapper > .my_sales_content .item .popup_wrapper {
        position: relative;
        top: unset;
        right: unset;
        transform: unset;
    }

    .my_sales_wrapper > .my_sales_content .item .about, .my_sales_wrapper > .my_sales_content .item .order, .my_sales_wrapper > .my_sales_content .item .price {
        grid-gap: 1rem;
        padding: 0;
    }

    .my_sales_wrapper > .my_sales_content .item .price, .my_sales_wrapper > .my_sales_content .item .price > div {
        grid-gap: .5rem;
    }

    .my_sales_wrapper > .my_sales_content .item .price_orders, .my_sales_wrapper > .my_sales_content .item .price_orders > div {
        grid-gap: 1rem;
    }

    .my_sales_wrapper > .my_sales_content .item .order, .my_sales_wrapper > .my_sales_content .item .inline {
        justify-content: start;
        text-align: left;
    }

    .my_sales_wrapper > .my_sales_content .item .inline_right {
        justify-content: end;
        text-align: right;
    }

    .my_sales_wrapper > .my_sales_content {
        width: 110%;
        margin-left: -5%;
    }

    .my_sales_wrapper > .my_sales_content .item .popup_wrapper .popup {
        width: 250px;
        position: absolute;
        left: 1.5rem;
        right: unset;
        top: 2rem;
    }

    .my_sales_wrapper > .my_sales_content .item .popup_wrapper {
        margin-left: auto;
    }

    .my_sales_wrapper > .my_sales_content .item .popup_wrapper .popup {
        left: unset;
        right: 1.5rem;
        top: 1.5rem;
    }
}

@media (max-width: 700px) {
    .my_sales_wrapper > .my_sales_content {
        width: calc(100% + 2rem);
        margin-left: -1rem;
    }

    .my_sales_wrapper > .my_sales_content .item {
        padding: 0 1rem;
        background: var(--beige);
    }
}

@media (max-width: 568px) {
    .my_sales_wrapper > .my_sales_navigation > .active_tabs_container {
        grid-template-columns: 1fr 1fr;
    }

    .my_sales_wrapper > .my_sales_navigation > .active_tabs_container button, .my_sales_wrapper > .my_sales_navigation > .sort_by_wrapper, .my_sales_wrapper > .my_sales_navigation > .sort_by_wrapper > .sort_by, .main_profile_wrapper_investor .my_portfolio_navigation .sort_by_wrapper, .main_profile_wrapper_investor .my_portfolio_navigation .sort_by_wrapper .sort_by {
        width: 100%;
    }

    .my_sales_wrapper > .my_sales_content .item {
        grid-template-columns: 1fr;
    }

    .my_sales_wrapper > .my_sales_content .item .item_content {
        padding: 0 0 1rem;
    }

    .my_sales_wrapper > .my_sales_content .item .inline_right {
        justify-content: end;
        text-align: right;
    }

    .my_sales_wrapper > .my_sales_content .item .order, .my_sales_wrapper > .my_sales_content .item .order .inline, .my_sales_wrapper > .my_sales_content .item .price {
        justify-content: start;
        text-align: left;
        grid-column: 1/-1;
    }

    .my_sales_wrapper > .my_sales_content .item .price {
        display: grid;
        align-items: end;
        grid-template-columns: max-content 1fr;
    }

    .my_sales_wrapper > .my_sales_content .item .price_orders {
        grid-template-columns: 1fr;
        justify-content: end;
    }

    .my_sales_wrapper > .my_sales_content .item .price_orders > p, .my_sales_wrapper > .my_sales_content .item .price span.orders_price_text {
        width: max-content;
        margin-left: auto;
    }

    .my_sales_wrapper > .my_sales_content .item .price .delivery {
        display: grid;
        justify-content: end;
    }
}