.num_list_section_container {
    background-color: #000000;
    padding: 56px 140px 88px 140px;
}

.num_list_section_container__title {
    font-size: 32px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 24px;
}

.num_list_section_container__about {
    font-size: 16px;
    font-weight: 300;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.item_container {
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr;
    grid-gap: 0 12px;
    margin-bottom: 16px;
}

.index_style {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
}

.index_container {
    width: 45px;
    height: 43px;
    border: 1px solid #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item_text {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 20px;
}

.item_text_wrapper span {
    color: #FFFFFF;
    font-size: 18px;
}

.item_subtext {
    color: #FFFFFF;
    font-weight: 300;
    font-size: 16px;
}

@media (max-width: 1100px) {
    .num_list_section_container {
        padding: 56px 20px 88px 20px;
    }
}
