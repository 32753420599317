.root {
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1.25rem;
    text-align: center;
    font-size: 0.75rem;
    white-space: nowrap;
    padding: 0.4375rem 1rem;
    border: 2px solid #000000;
    height: 100%;
}

.button {
    font-size: 1rem;
}