.artwork_info_card {
    background: #FFFFFF;
    display: block;
    transition: .3s all;
}

.artwork_info_card:hover {
    background: var(--beige);
}

.artwork_info_card > .card_image_wrapper {
    position: relative;
}

.artwork_info_card > .card_image_wrapper > .shadow_hover {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 90%);
    opacity: 0;
    transition: .3s all;
}

.artwork_info_card:hover > .card_image_wrapper > .shadow_hover {
    opacity: 1;
}

.artwork_info_card > .card_image_wrapper > .favorite {
    position: absolute;
    background: #000000;
    bottom: 0;
    opacity: 0;
    z-index: 12;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    transition: .3s all;
}

.artwork_info_card > .card_image_wrapper > .favorite path {
    transition: .3s;
}

.artwork_info_card > .card_image_wrapper > .favorite_active path {
    fill: #FFFFFF;
}

.artwork_info_card:hover > .card_image_wrapper > .favorite {
    opacity: 1;
}

.artwork_info_card > .card_image_wrapper > .card_image {
    height: 287px;
    object-fit: cover;
    max-width: 100%;
    width: 100%;
}
.artwork_info_card > .card_image_wrapper > .card_image img {
    /*height: 287px;*/
    object-fit: cover;
    max-width: 100%;
    width: 100%;
}

.artwork_info_card .card_line {
    width: calc(100% - 5px);
    margin: 1.5rem auto 5px;
    display: block;
    background: rgba(121, 121, 121, 0.12);
    height: 1px;
}

.artwork_info_card > .card_content {
    padding: 0 1rem 1rem 1rem;
}

.artwork_info_card > .card_content h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 45px;
    text-transform: uppercase;
    color: #000000;
}

.artwork_info_card > .card_content span {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    display: block;
    margin: 0 0 4px;
    max-width: 264px;
}

.artwork_info_card > .card_content p {
    font-weight: 200;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    margin: 0 0 12px;
}

.artwork_info_card > .card_content h6 {
    font-weight: normal;
    font-size: 20px;
    line-height: 45px;
    color: #000000;
}

.artwork_info_card > .card_content .card_sold {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.artwork_info_card > .card_content .card_sold span {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    background: #eb4250;
    border: 2px solid #eb4250;
    padding: 6px 1rem;
    color: #FFFFFF;
    text-transform: uppercase;
    margin: 0;
}

.artwork_info_card .card_content .card_statistic .inline {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    grid-gap: 4px;
    align-items: center;
}

.artwork_info_card .card_content .card_statistic .inline span {
    font-size: 20px;
    line-height: 45px;
    color: #000000;
}

.artwork_info_card .card_content .card_statistic .inline h5, .artwork_info_card .card_content .card_statistic .inline h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 45px;
    text-align: right;
    color: #000000;
    width: max-content;
}

.artwork_info_card .card_content .card_statistic .inline h3 {
    font-size: 24px;
}

.artwork_info_card .card_content .card_statistic .card_line {
    margin: .5rem 0 1.5rem;
}

.artwork_info_card .card_content .card_statistic .price_change_wrapper .changed_price {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
}

.artwork_info_card .card_content .card_statistic .price_change_wrapper .changed_price p, .artwork_info_card .card_content .card_statistic .price_change_wrapper .changed_price p span {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #000000;
    display: unset;
    margin: 0;
}

.artwork_info_card .card_content .card_statistic .price_change_wrapper {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
}

.artwork_info_card .card_content .card_statistic .price_change_wrapper .price_changed_in p {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 4px;
    color: #000000;
}

.artwork_info_card .card_content .card_statistic .price_change_wrapper .price_changed_in .popup_wrapper {
    position: relative;
}

.artwork_info_card .card_content .card_statistic .price_change_wrapper .price_changed_in .popup_wrapper .active_custom_dropdown {
    max-height: 200px;
}

.artwork_info_card .card_content .card_statistic .price_change_wrapper .price_changed_in .popup_wrapper .content {
    display: grid;
    grid-template-columns: 80px max-content;
    grid-gap: 1rem;
    align-items: center;
}

.nft-artwork-block {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 700;
    padding: 6px 15px;
    background-color: #000000;
    width: 70px;
    position: absolute;
    top: 0;
    right: 0;
}

.artwork-name-list-container {
    display: grid;
    grid-template-columns: 1fr 16px;
    grid-gap: 10px;
    align-items: center;
}

@media (max-width: 568px) {
    .artwork_info_card > .card_content {
        padding: 0;
    }

    .artwork_info_card:hover {
        background: unset;
    }

    .artwork_info_card .card_line {
        width: 100%;
    }
}
