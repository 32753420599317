.wrapper {
  margin-top: 40px;

  .uptitle {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
  }

  .title {
    font-weight: 600;
    font-size: 38px;
    line-height: 48px;
    margin: 20px 0 50px;
  }

  .form {
    .form_row_2 {
      margin-bottom: 35px !important;
      display: flex;
      margin: 0 -11px;

      .form_col {
        width: 50%;
        padding: 0 11px;
      }
    }

    .form__input {
      margin-bottom: 35px;
    }

    .errors {
      margin-top: 25px;

      & > * {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .form_footer {
      display: flex;
      flex-direction: column;
      margin-top: 54px;

      .footer_social {
        margin: 15px 0 20px;
      }

      .footer_btns {
        //button:last-child {
        //  display: none;
        //}
      }

      .footer_reg_btn_sm {
        margin: auto;
      }
    }
  }

  @media screen and (min-width: 980px) {
    .errors {
      display: none;
    }
    .uptitle {
      font-weight: normal;
      font-size: 18.58px;
      line-height: 28px;
    }

    .title {
      font-weight: 600;
      font-size: 48px;
      line-height: 56px;
      margin: 25px 0 30px;
    }
    .form {
      .form_row_2 {
        margin: 0 -20px;
        margin-bottom: 30px !important;

        .form_col {
          width: 50%;
          padding: 0 20px;
        }
      }

      .form__input {
        margin-bottom: 20px;
      }

      .form_footer {
        display: flex;
        flex-direction: row-reverse !important;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 54px !important;

        .footer_btns {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          button:last-child {
            margin-top: 20px;
            display: inline-block !important;
          }
        }

        .footer_reg_btn_sm {
          display: none;
        }
      }
    }
  }
  @media screen and (min-width: 980px) and (max-height: 900px) {
    margin-top: 40px;
    .title {
      font-size: 36px;
      line-height: 1.5;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 500px) {
  .footer_btns:first-child {
    width: 100%;
    max-width: unset;
  }
}
