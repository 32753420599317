.news_footer {
    display: grid;
    grid-template-columns: max-content max-content;
    margin-top: 80px;
    padding: 36px 5%;
    background: #E5F1F5;
    background-size: cover;
    justify-content: center;
    grid-gap: 0 100px;
    position: relative;
    overflow-y: hidden;
}

.feedback_form {
    position: relative;
    z-index: 60;
}

.feedback_form h4 {
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
}

.feedback_form p {
    color: #000000;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    line-height: 2.25;
    letter-spacing: normal;
}

.input_form .input_component .custom_input {
    width: 294px;
    vertical-align: top;
    display: inline-block;
    color: #343434;
    min-width: 150px;
    background: #fbfbfb;
    border: 1px solid #cccdcf;
    padding: 8px 10px;
    box-shadow: inset 0 2px 3px #e8e8e8;
}

.input_form {
    display: flex;
    width: max-content;
}

.input_form img {
    vertical-align: middle;
}

.input_form button {
    height: 39px;
    background-color: #000000;
    color: white;
    vertical-align: top;
    display: inline-block;
    padding: 7px 10px;
    border: none;
}

.left_footer {
    display: grid;
    grid-template-columns: auto;
    align-self: end;
    align-items: center;
    justify-content: center;
    align-content: end;
    position: relative;
    z-index: 60;
}

.circle_star {
    display: grid;
}

.circle_star img {
    vertical-align: sub;
}

.circle_star > .image_container {
    background: #ffffff;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    height: 3rem;
    width: 3rem;
}

.circle_star > .image_container > img {
    display: block;
    margin: auto;
}

.learn_arrow {
    padding: 9px 0;
    grid-column: 3/-1;
    align-self: center;
}

.learn_arrow a {
    margin: 0 0 0 35px;
    color: black;
    text-decoration: none;
    font-size: 23px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.96;
    letter-spacing: normal;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 15px;
}

.news_footer .dark_blue, .news_footer .blue, .news_footer .purple {
    background: linear-gradient(-45deg, #e73c7eaa, #23a6d5aa, #23d5abaa);
    border-radius: 100%;
    width: 7vw;
    height: 7vw;
    -webkit-filter: blur(30px);
    filter: blur(30px);
    -webkit-transform: translateZ(0);
    -webkit-perspective: 1000px;
    -webkit-backface-visibility: hidden;
    position: absolute;
    top: 5%;
    right: 20%;
    animation: gradient 10s ease infinite;
    background-size: 400% 400%;
}

.news_footer .blue {
    background: linear-gradient(-45deg, #23d5abaa, #e73c7eaa, #23a6d5aa);
    width: 12vw;
    height: 12vw;
    z-index: 10;
    top: 10%;
    animation: gradient-1 10s ease infinite;
    right: 35%;
    background-size: 400% 400%;
}

.news_footer .purple {
    background: linear-gradient(-45deg, #23a6d5aa, #23d5abaa, #e73c7eaa);
    width: 9vw;
    height: 9vw;
    top: 40%;
    right: 25.5%;
    animation: gradient-2 10s ease infinite;
    background-size: 400% 400%;
}

@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 200%;
    }
    100% {
        background-position: 0 50%;
    }
}

@keyframes gradient-1 {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 200%;
    }
    100% {
        background-position: 0 50%;
    }
}

@keyframes gradient-2 {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 200%;
    }
    100% {
        background-position: 0 50%;
    }
}

/*@media (max-width: 1200px) {*/
/*    .section_padding {*/
/*        padding: 0 7%;*/
/*    }*/
/*}*/

@media (max-width: 1024px) {

    .news_footer {
        padding: 36px 5% !important;
        grid-gap: 0 40px;
    }

    .section_padding {
        padding: 0 5%;
    }


    .left_footer {
        margin: 0 0 24px 16px;
    }
}

@media (max-width: 992px) {

    .section_padding {
        padding: 12px 1.5rem;
    }

    .news_footer {
        grid-template-columns: 1fr;
    }

    .feedback_form {
        margin-top: 0;
        padding: 45px 1rem 4.5rem;
        display: grid;
        justify-content: center;
        text-align: center;
    }

    .feedback_form .input_form {
        margin: 0 auto;
    }
}

@media (max-width: 568px) {
    .section_padding {
        padding: 0 1rem;
    }

    .feedback_form p {
        margin-bottom: 40px;
        font-style: italic;
        line-height: 1.65;
    }

    .feedback_form h4 {
        font-size: 36px;
        margin-bottom: 12px;
    }

    .left_footer {
        margin: 0 0 24px 0;
    }

    .feedback_form {
        padding: 45px 0 4.5rem;
    }

    .learn_arrow a {
        margin: 0 0 0 35px;
        line-height: 1.4;
        align-items: end;
        grid-gap: 10px;
    }

    .learn_arrow {
        padding: 0;
    }

    .learn_arrow img {
        transform: translateY(-10px);
    }

    .input_form .input_component .custom_input {
        width: 220px;
    }

}
