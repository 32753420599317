.loader {
    display: inline-flex;
    position: relative;
    justify-content: center;
    width: 40px;
    height: var(--wrapper_size, 40px);
    margin: 0 auto;
}

.loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: var(--size, 40px);
    height: var(--size, 40px);
    margin: var(--margin, 20px);
    border: 6px solid;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--bgColor, #000) transparent transparent transparent;
}

.loader div:nth-child(1) {
    animation-delay: -0.45s;
}

.loader div:nth-child(2) {
    animation-delay: -0.3s;
}

.loader div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}