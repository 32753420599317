.admin_content_wrapper_landing {
    padding: 2rem 4rem 3rem;
}

.mb-3 {
    margin-bottom: 1.5rem;
}

.admin_content_wrapper_landing h1 {
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.374px;
    color: #333333;
    margin: 0 0 1.5rem;
}

.admin_content_wrapper_landing p {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin: 0 0 .5rem;
    display: block;
}

.admin_content_wrapper_landing input {
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 7px;
    padding: 9px 1rem;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}

.admin_landing_page_line {
    width: calc(100% + 8rem);
    background: rgba(0, 0, 0, .1);
    height: 1px;
    margin: 1.5rem -4rem;
}

.admin_content_wrapper_landing > .for_user_title, .admin_content_wrapper_landing > .for_user_body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    align-items: start;
    margin: 0 0 1.5rem;
}

.admin_content_wrapper_landing > .for_user_points {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    align-items: start;
    margin: 0 0 1.5rem;
}

.admin_content_wrapper_landing > .for_user_body {
    margin: 0;
}

.header_photos input {
    display: none;
}

.admin_content_wrapper_landing > .header_photos > .header_photos_wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, 162px);
    grid-gap: 1.5rem;
    align-items: center;
}

.header_photos_item_wrapper {
    width: 162px;
    height: 162px;
    position: relative;
    border-radius: 7px;
    cursor: pointer;
}

.header_photos_item_wrapper > .header_photos_item_content {
    display: grid;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    height: 100%;
}

.header_photos_item_wrapper > .header_photos_item_content button {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    padding: 5px 8px;
    width: max-content;
    background: #AE4B84;
    border-radius: 4px;
    text-align: center;
    margin: auto;
    color: #FFFFFF;
}

.header_photos_item_wrapper .preview_image {
    position: absolute;
    top: 0;
    left: 0;
}

.header_photos_item_wrapper .preview_image .active_img {
    width: 162px;
    height: 162px;
    object-fit: cover;
    border-radius: 7px;
}

.header_photos_item_wrapper .preview_image .delete {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
}

.admin_content_wrapper_landing > .wrapper_video {
    display: grid;
    grid-template-columns: 1fr;
}

.admin_content_wrapper_landing > .wrapper_video input {
    display: none;
}

.admin_content_wrapper_landing > .wrapper_video > .video_preview {
    display: grid;
    position: relative;
    width: 552px;
    height: 20rem;
    border-radius: 2px;
    align-items: center;
}

.admin_content_wrapper_landing > .wrapper_video > .video_preview video {
    z-index: 9;
    top: 0;
    bottom: 0;
    position: absolute;
    display: block;
    width: 100%;
    left: 0;
    right: 0;
    height: 100%;
}

.admin_content_wrapper_landing > .wrapper_video > .video_preview video:focus {
    outline: none;
}

.admin_content_wrapper_landing > .wrapper_video button {
    background: #000;
    border-radius: 4px;
    padding: 6px 12px;
    border: 1px solid #000;

}

.admin_content_wrapper_landing > .wrapper_video button:hover {
    color: #000;
    background: transparent;
    border: 2px solid #000;
    box-shadow: unset;
}

.admin_content_wrapper_landing > .wrapper_video button path {
    transition: .3s all;
}

.admin_content_wrapper_landing > .wrapper_video button:hover path {
    fill: #000;
}

.admin_content_wrapper_landing > .submit {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    background: #000;
    border-radius: 4px;
    display: block;
    width: max-content;
    padding: 7px 29px;
    margin-left: auto;
}