.root {
    position: relative;
}

.link {
    display: grid;
    grid-template-columns: 262px 1fr auto;
    align-items: flex-start;
    color: #000000;
    background-color: var(--beige);
}

.link:hover, .link:focus {
    color: #000000;
}

.root + .root {
    margin-top: 1rem;
}

.inactive {
    opacity: 0.64;
}

.content {
    padding: 1.5rem 2.5rem;
    font-size: 0.875rem;
}

.contentText, .address {
    font-weight: 300;
    margin: 0;
}

.action {
    display: flex;
    align-items: center;
    grid-gap: 2rem;
    padding: 0.875rem 3rem 0.875rem 0.5rem;
}

.actionButton {
    display: inline-flex;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: auto;
    padding: 0;
}

.actionButton svg, .actionButton svg rect {
    fill: currentColor;
}

.actionButton:hover {
    color: #FFFFFF;
    background-color: #000000;
}

.price {
    font-size: 1.5rem;
    font-weight: 600;
}

.menu :global(.ant-dropdown-menu) {
    min-width: 12rem;
    padding: 0.25rem 1rem;
    background-color: #000000;
}

.menu :global(.ant-dropdown-menu .ant-dropdown-menu-item) {
    color: #F7F6F1;
    font-weight: 300;
    padding: 0.625rem 0;
    border-radius: 0;
}

.menu :global(.ant-dropdown-menu-item:not(:last-child)) {
    border-bottom: 1px solid #F7F6F1;
}

.menu :global(.ant-dropdown-menu-item:hover) {
    color: #F7F6F1;
    background-color: #000000;
}

.title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.address {
    font-size: 0.875rem;
    margin-bottom: 1rem;
}

.imageWrapper {
    position: relative;
}

.imageWrapper::before {
    content: "";
    display: block;
    padding-bottom: 65%;
}

.imageWrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 768px) {
    .link {
        grid-template-columns: 1fr;
    }

    .content {
        padding: 0.75rem;
    }

    .action {
        margin-left: auto;
    }

    .actionButton {
        width: auto;
        color: #ffffff;
        background-color: #000000;
    }
}
