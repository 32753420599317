.investor_main_profile {
    margin: 3.5rem 0 6.5rem;
}

.investor_main_profile .followed_artists_wrapper {
    padding: 0;
    margin: 0 0 4.5rem;
}

.investor_main_profile h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 2rem;
    color: #000000;
}

.investor_main_profile .followed_artists_wrapper .slick-track {
    margin-left: unset;
}

@media (max-width: 992px) {
    .investor_main_profile .slider_arrows_container {
        top: 50%;
        transform: translateY(-50%);
    }

    .investor_main_profile .slider_arrow_left {
        left: -1.5rem;
    }

    .investor_main_profile .slider_arrow_right {
        right: -1.5rem;
    }
}