.block-seo {
    margin-bottom: 48px;
}

.block-seo .js_seo_content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    white-space: break-spaces;
}

.block-seo h2 {
    margin-bottom: 0.5em !important;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 1.5em !important;
}

.block-seo .js_seo_content:not(.extended) {
    max-height: 100px;
}

.block-seo .js_seo_content .extend-blur {
    content: "";
    position: absolute;
    inset: auto 0 0;
    height: 24px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 80%);
}

.block-seo .opener-holder {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    cursor: pointer;
}

.block-seo .opener-holder p {
    font-weight: 600;
}

.block-seo .opener-holder img {
    margin-left: 12px;
}