.root {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 10rem 1fr;
    grid-gap: 1.25rem;
}

.imageWrapper {
    position: relative;
    overflow: hidden;
}

.imageWrapper::before {
    content: "";
    display: block;
    padding-bottom: 62.5%;
}

.imageWrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text {
    font-weight: 300;
    font-size: 0.875rem;
}

.title {
    font-size: 1.25rem;
    font-weight: 600;
}

@media (max-width: 768px) {
    .root {
        grid-template-columns: 7rem 1fr;
        grid-gap: 1rem;
    }
}