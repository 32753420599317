.inbox_wrapper {
    padding: 0;
}

.inbox_wrapper {
    margin-top: 40px;
}

.inbox_wrapper h2 {
    padding: 0 32px;
    color: #000
}

.inbox_wrapper .wrapper_messages_list {
    padding-bottom: 60px;
}

.inbox_wrapper .wrapper_messages_list__head {
    margin-top: 8px;
    padding: 8px 5%;
    display: grid;
    grid-template-columns: 150px 1fr 1fr 100px;
    grid-gap: 20px;
    border-bottom: 2px solid #000
}

.inbox_wrapper .wrapper_messages_list__head span {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: rgba(0, 0, 0, .5);
}

.inbox_wrapper .wrapper_messages_list__body {
    padding: 0 5%;
    border-bottom: solid 2px #000;
}

.inbox_wrapper .wrapper_messages_list__body .every {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 150px 1fr 1fr 100px;
    border-bottom: solid .5px var(--lite-grey);
}

.inbox_wrapper .wrapper_messages_list__body div {
    padding: 16px 0;
}

.inbox_wrapper .wrapper_messages_list__body .name span,
.inbox_wrapper .wrapper_messages_list__body .subject span,
.inbox_wrapper .wrapper_messages_list__body .status span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 1)
}

.inbox_wrapper .wrapper_messages_list__body .date span,
.inbox_wrapper .wrapper_messages_list__body .subject span {
    font-weight: 300;
}

.inbox_wrapper .wrapper_messages_list__body a {
    color: unset;
}

@media screen and (max-width: 750px) {
    .inbox_wrapper .wrapper_messages_list__head {
        display: none;
    }

    .inbox_wrapper h2 {
        margin: 40px 0 0;
        padding: 0;
    }

    .inbox_wrapper .wrapper_messages_list__body {
        padding: 0;
    }

    .inbox_wrapper .wrapper_messages_list__body a {
        padding: 28px 0 16px;
    }

    .inbox_wrapper .wrapper_messages_list__body .every {
        grid-template-columns: 1fr 1fr;
        border-bottom: 2px solid #000;
        grid-gap: 0
    }

    .inbox_wrapper .wrapper_messages_list__body .every:last-child {
        border-bottom: none;
    }

    .inbox_wrapper .wrapper_messages_list__body div {
        padding: 0;
    }

    .inbox_wrapper .wrapper_messages_list__body .every .name {
        grid-row: 2;
        margin-top: 16px;
    }

    .inbox_wrapper .wrapper_messages_list__body .every .status {
        grid-row: 1;
        grid-column: 1;
    }

    .inbox_wrapper .wrapper_messages_list__body .every .subject {
        grid-row: 3;
    }

    .inbox_wrapper .wrapper_messages_list__body .every .date {
        grid-row: 1;
        grid-column: 2;
        justify-self: end;
    }
}