.admin_artwork_item_content_wrapper {
    padding: 2rem 4rem;
}

.admin_artwork_item_content_wrapper > .navigation {
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    margin: 0 0 2rem;
}

.admin_artwork_item_content_wrapper > .navigation a {
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.7);
    text-transform: capitalize;
}

.admin_artwork_item_content_wrapper > .navigation span {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}

.admin_artwork_item_content_wrapper > .about {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 1.5rem;
    margin: 0 0 2rem;
}

.admin_artwork_item_content_wrapper > .about .artwork_image, .admin_artwork_item_content_wrapper > .about .user > .user_avatar {
    display: grid;
    align-items: center;
}

.admin_artwork_item_content_wrapper > .about .artwork_image img {
    max-width: 376px;
    height: 269px;
    object-fit: cover;
    cursor: pointer;
}

.admin_artwork_item_content_wrapper > .about .user > .about_user {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 1rem;
    align-items: center;
    margin: 0 0 1rem;
}

.admin_artwork_item_content_wrapper > .about .user .user_avatar img {
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    object-fit: cover;
}

.admin_artwork_item_content_wrapper > .about .user h3 {
    font-weight: normal;
    font-size: 28px;
    line-height: 42px;
    color: #333333;
    margin: 0 0 4px;
}

.admin_artwork_item_content_wrapper > .about .user > .user_name span {
    font-weight: normal;
    font-size: 18px;
    line-height: 120%;
    color: #000000;
    opacity: 0.5;
}

.admin_artwork_item_content_wrapper > .about .user > p {
    font-size: 32px;
    line-height: 44px;
    color: #333333;
    margin: 0 0 1rem;
    word-wrap: break-word;
    max-width: 400px;
}

.admin_artwork_item_content_wrapper > .about .user > h4 {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    color: #333333;
    margin: 0 0 2.75rem;
}

.admin_artwork_item_content_wrapper > .about .user .buttons_container {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 1rem;
    align-items: center;
}

.admin_artwork_item_content_wrapper > .about .user .buttons_container button {
    font-weight: 600;
    font-size: 12px;
    padding: 5px 24px;
}

.admin_artwork_item_content_wrapper > .about .user .buttons_container .button_bordered {
    cursor: default;
}

.admin_artwork_item_content_wrapper > .about .user .buttons_container .button_bordered:hover {
    background: rgba(0, 0, 0, 0);
    color: var(--dark-grey);
    box-shadow: none;
}

.admin_artwork_item_content_wrapper > .content h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    color: #000000;
    margin: 0 0 1rem;
}

.admin_artwork_item_content_wrapper > .content p {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    margin: 0 0 2rem;
    color: #000000;
    word-wrap: break-word;
}

.admin_artwork_item_content_wrapper > .content > .features {
    display: grid;
    align-items: start;
    /*grid-template-columns: repeat(3, max-content);*/
    /*grid-gap: 12.5vw;*/
    grid-gap: 10px;
    margin: 0 0 2rem;
}

.admin_artwork_item_content_wrapper > .content > .features h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    /*text-transform: uppercase;*/
    color: #000000;
}

.content-item-list-container {
    display: flex;
    grid-gap: 10px;
    align-items: center;
}

.content-section-container {
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000;
    padding: 24px 0;
}

.item-title-section-style {
    font-size: 20px;
    font-weight: 600;
}

.admin_artwork_item_content_wrapper > .content > .features  div > span {
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    /*margin: 0 0 .5rem;*/
    display: block;
}

.admin_artwork_item_content_wrapper > .content > .features span:last-of-type {
    margin: 0;
}

.admin_artwork_item_content_wrapper > .content > .exhibitions {
    margin: 0 0 2rem;
}

.admin_artwork_item_content_wrapper > .content > .exhibitions p, .admin_artwork_item_content_wrapper > .content > .publication p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin: 0 0 .5rem;
}

.admin_artwork_item_content_wrapper > .content > .exhibitions a, .admin_artwork_item_content_wrapper > .content > .publication a {
    font-size: 16px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #005EBC;
    margin: 0 0 .25rem;
}

.admin_artwork_item_content_wrapper > .content > .exhibitions a:last-of-type, .admin_artwork_item_content_wrapper > .content > .publication a:last-of-type {
    margin: 0;
}

.additional-file-info {
    cursor: pointer;
    text-decoration: underline;
}

@media screen and (max-width: 1300px){
    .admin_artwork_item_content_wrapper > .about .artwork_image img {
        max-width: 275px;
    }
}
