.wrapper {
    padding: 1.5rem 0;
}

.wrapper .tabButton + .tabButton {
    border-left: 0
}

.wrapper .disabledButton + .disabledButton {
    border: 2px solid #BFBFBF;
    border-left: 0;
}

.list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem;
}

.header {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    grid-gap: 1.5rem;
    margin-bottom: 3rem;
}

.wrapper .tabs .tabButton {
    font-size: 0.875rem;
    min-width: 8rem;
}

@media (max-width: 992px) {
    .list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .list {
        grid-template-columns: repeat(2, 1fr);
    }

    .wrapper .tabs .tabButton {
        width: 33.3%;
        min-width: 0;
    }
}

@media (max-width: 576px) {
    .list {
        grid-template-columns: 1fr;
    }

    .header {
        grid-template-columns: 1fr;
    }
}