.root {
    display: flex;
    flex-direction: column;
}

.inner {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.inner :global(.ant-checkbox-wrapper + .ant-checkbox-wrapper) {
    margin-left: 0;
}

.inner :global(.checkbox_wrapper p) {
    margin: 0 !important;
}

.label {
    font-weight: 500;
    font-size: 0.75rem;
    margin-bottom: 0.625rem;
}

.inputWrapper :global(.custom_input) {
    border: 0;
    font-size: 0.75rem;
    font-weight: 600;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 2rem;
    text-align: center;
    border-bottom: 2px solid #000000;
}

.inputWrapper :global(.input_component span) {
    text-transform: lowercase;
    font-size: 0.75rem;
    font-weight: 400;
    opacity: 0.4;
    text-align: center;
}

.list {
    display: grid;
    justify-content: flex-start;
}

@media (max-width: 992px) {
    .clearButton {
        order: -1;
    }

    .inner {
        margin-top: 2rem;
        margin-bottom: 0;
    }
}