.sidebar_menu {
    position: fixed;
    top: 0;
    right: -100%;
    overflow: auto;
    background: #FFFFFF;
    width: 50%;
    z-index: 30000;
    height: calc(100% - 60px);
    transition: .6s all;
    margin-top: 60px;
}

.active-sidebar_menu {
    right: 0;
    transition: .6s all;
}

.sidebar_menu__navigation {
    display: grid;
    grid-gap: 34px 0;
    margin: 40px 0;
    text-align: center;
    justify-content: center;
}

.sidebar_menu__navigation a {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: var(--dark-grey);
}

.sidebar_menu__navigation__cart,
.sidebar_menu__navigation__sign_wrapper {
    border-style: solid;
    border-width: 1px 0;
    border-color: var(--lite-grey);
    padding: 40px 0;
    display: grid;
    justify-content: center;
}

.sidebar_menu__navigation__sign_wrapper span {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.sidebar_menu__navigation__search .header_search_section label input,
.sidebar_menu__navigation__search .header_search_section label span {
    font-size: 18px;
    font-weight: normal;
}

.sidebar_menu__navigation__search .header_search_section label input {
    color: var(--dark-grey);
}

.sidebar_menu__navigation__search .header_search_section {
    padding: 32px 20px;
}

.sidebar_menu__navigation__sign_wrapper .wrapper_profile_navigation_popup {
    position: relative;
    background: unset;
    border: unset;
    margin: unset;
    padding: unset;
}

.sidebar_menu__navigation__sign_wrapper .header_registered_user div:first-child {
    width: max-content;
    margin: auto;
}

.sidebar_menu__navigation__sign_wrapper .wrapper_profile_navigation_popup div,
.sidebar_menu__navigation__sign_wrapper .wrapper_profile_navigation_popup a {
    border-style: solid;
    border-width: 0 1px;
    border-color: var(--lite-grey);
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    justify-content: center;
    padding: 0 8px;
    grid-gap: 6px 0;
}

.sidebar_menu__navigation__sign_wrapper .wrapper_profile_navigation_popup {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 32px;
}

.sidebar_menu__navigation__sign_wrapper .wrapper_profile_navigation_popup div,
.sidebar_menu__navigation__sign_wrapper .wrapper_profile_navigation_popup a:first-child {
    border: unset;
}

.sidebar_menu__navigation__sign_wrapper .wrapper_profile_navigation_popup span {
    border: unset;
    justify-self: center;
    padding: 0;
}

.sidebar_menu__navigation__sign_wrapper .wrapper_profile_navigation_popup svg {
    justify-self: center;
    margin: 0;
}

@media (max-width: 1091px) {
    .sidebar_menu__navigation__sign_wrapper .header_registered_user .wrapper_profile_navigation_popup {
        padding-top: 12px;
    }
}

@media (max-width: 568px) {
    .sidebar_menu {
        width: 100%;
        height: 100%;
        transition: .6s all;
        margin-top: 58px;
    }
}