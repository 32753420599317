.file_previews {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 21px;
  align-items: center;
}

.file_previews .dropzone {
  width: max-content;
}

.file_previews .uploaded_files_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.file_previews .uploaded_files_wrapper a {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.35);
  width: max-content;
  margin: 0 1rem 0.75rem 0;
}

.delete_preview {
  position: relative;
  display: grid;
  align-items: center;
}

.delete_preview .delete_icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.3s;
  display: grid;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.delete_preview:hover .delete_icon {
  opacity: 1;
}

.upload_avatar_wrapper,
.change_avatar_wrapper {
  position: relative;
  display: grid;
  align-items: center;
}

.upload_avatar_wrapper .change_avatar,
.change_avatar_wrapper .delete_avatar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  background: #000000;
  transition: 0.3s;
  border-radius: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.upload_avatar_wrapper:hover .change_avatar,
.change_avatar_wrapper:hover .delete_avatar {
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}
