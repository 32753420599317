
.wrapper_upload_work .wrapper_popup .popup {
    max-width: 80vw;
    max-height: 80vh;
    padding: 0;
    overflow: hidden auto;
}

.wrapper_upload_work .wrapper_upload_artwork_info {
    padding: 44px 0;
}

.wrapper_upload_work .wrapper_upload_artwork_info .padding {
    padding: 0 52px;
}

.wrapper_upload_work .wrapper_upload_artwork_info__title {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.45;
    text-align: center;
    color: var(--dark-grey);
}

.wrapper_upload_work .wrapper_upload_artwork_info__art_preview .info {
    display: grid;
    height: max-content;
    grid-gap: 16px 0;
}

.wrapper_upload_work .wrapper_upload_artwork_info__art_preview .images .every_image > div,
.wrapper_upload_work .wrapper_upload_artwork_info__art_preview .images {
    height: 200px;
}

.wrapper_upload_work .wrapper_upload_artwork_info__art_preview .images .every_image > div {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.wrapper_upload_work .wrapper_upload_artwork_info__art_preview {
    display: grid;
    grid-template-columns: 335px 1fr;
    margin-top: 20px;
    grid-gap: 0 30px;
}

.wrapper_upload_work .wrapper_upload_artwork_info__art_preview .every_details {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 0 8px;
    align-items: center;
}

.wrapper_upload_work .wrapper_upload_artwork_info__art_preview .every_details span:last-child {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: var(--slate);
}

.wrapper_upload_work .wrapper_upload_artwork_info__art_preview .every_details span:first-child {
    font-weight: bold;
    color: var(--dark-grey);
}

.wrapper_upload_work .wrapper_upload_artwork_info__description {
    margin: 32px 0;
}

.wrapper_upload_work .wrapper_upload_artwork_info__description p:nth-child(2) {
    margin-top: 32px;
}

.wrapper_upload_work .wrapper_upload_artwork_info__description p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.63;
    color: var(--slate);
}

.wrapper_upload_work .wrapper_upload_artwork_info__terms_wrapper {
    border-top: solid 1px var(--lite-grey);
}

.wrapper_upload_work .wrapper_upload_artwork_info__confirm {
    display: grid;
    justify-content: center;
}

.wrapper_upload_work .wrapper_upload_artwork_info__confirm button {
    padding: 12px 62px;
    margin-top: 20px;
}

@media screen and (max-width: 1000px) {
    .wrapper_upload_work .wrapper_upload_artwork_info .padding {
        padding: 0;
    }

    .wrapper_upload_work .wrapper_upload_artwork_info__description,
    .wrapper_upload_work .wrapper_upload_artwork_info .padding > * {
        padding: 0 20px;
    }

    .wrapper_upload_work .wrapper_upload_artwork_info .wrapper_check_box {
        width: 90%;
    }

}

@media screen and (max-width: 880px) {
    .wrapper_upload_work .wrapper_upload_artwork_info__art_preview {
        display: grid;
        grid-template-columns: 77vw;
        grid-template-rows: 250px 1fr;
        grid-gap: 20px 0;
    }
}

@media screen and (max-width: 470px) {
    /*.wrapper_upload_work .wrapper_upload_artwork_info__art_preview .images .every_image img,*/
    /*.wrapper_upload_work .wrapper_upload_artwork_info__art_preview .images {*/
    /*    margin: auto;*/
    /*}*/
    .wrapper_upload_work .wrapper_upload_artwork_info .wrapper_check_box {
        width: 100%;
    }
}